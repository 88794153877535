import checkIcon from "assets/core/check.svg";
import checkIconWhite from "assets/core/check_white.svg";
import downChevron from "assets/core/down_chevron.svg";
import { i18n } from "i18n/localisation";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const Accordion = ({ section, isChecked, isOpen, onToggle }) => {
  const uploadPropertyData = useSelector((state) => state.myProperties);

  const propertyData = useMemo(
    () => ({
      photos: uploadPropertyData.photos,
      videos: uploadPropertyData.videos,
      floorPlans: uploadPropertyData.floorPlans,
      virtualTours: uploadPropertyData.virtualTours,
      price: uploadPropertyData.price,
      bedrooms: uploadPropertyData.bedrooms,
      bathrooms: uploadPropertyData.bathrooms,
      size: uploadPropertyData.size,
      detailedType: uploadPropertyData.detailedType,
      description: uploadPropertyData.description,
      location: uploadPropertyData.municipality,
    }),
    [uploadPropertyData],
  );

  const validationMapping = {
    photos: Boolean(propertyData.photos && propertyData.photos.length > 0),
    videos: Boolean(propertyData.videos && propertyData.videos.length > 0),
    floorPlans: Boolean(
      propertyData.floorPlans && propertyData.floorPlans?.length > 0,
    ),
    virtualTours: Boolean(
      propertyData.virtualTours && propertyData.virtualTours?.length > 0,
    ),
    price: Boolean(propertyData.price && propertyData.price !== "0"),
    bedrooms: Boolean(propertyData.bedrooms && propertyData.bedrooms !== 0),
    bathrooms: Boolean(propertyData.bathrooms && propertyData.bathrooms !== 0),
    size: Boolean(propertyData.size && propertyData.size !== "0"),
    detailedType: Boolean(
      propertyData.detailedType?.typology ||
        propertyData.detailedType?.subTypology,
    ),
    description: Boolean(propertyData.description),
    location: Boolean(propertyData.location),
  };

  const { completedSteps, numberOfSteps } = useMemo(() => {
    const uncheckedSteps = section.steps?.filter(
      (step) => !validationMapping[step.key],
    );

    const numberOfSteps = section.steps.length;
    const completedSteps = numberOfSteps - uncheckedSteps.length;

    return { completedSteps, numberOfSteps };
  }, [propertyData]);

  return (
    <div
      className={`checklist_accordion${isOpen ? " open" : ""}`}
      onClick={onToggle}
    >
      <div className="checklist_accordion_header">
        <div
          className={`checklist_accordion_image${isChecked ? " checked" : ""}`}
        >
          <img src={checkIconWhite} alt="check" />
        </div>
        <div className="checklist_accordion_title">
          <h3>{i18n(section.title)}</h3>
          <p>
            {completedSteps}/{numberOfSteps}
          </p>
        </div>
        <div
          className={`checklist_accordion_arrow-icon${isOpen ? " open" : ""}`}
        >
          <img src={downChevron} alt="arrow" />
        </div>
      </div>

      <div className={`checklist_accordion_steps${isOpen ? " open" : ""}`}>
        {section.steps.map((step, index) => (
          <div
            key={step.key}
            className={`checklist_accordion_step${
              validationMapping[step.key] ? " line-through" : " unchecked"
            }${isOpen ? " visible" : ""}`}
            style={{
              transitionDelay: `${index * 0.05}s`,
            }}
          >
            <img
              src={checkIcon}
              alt="check"
              style={{
                visibility: validationMapping[step.key] ? "visible" : "hidden",
              }}
            />
            <p>{i18n(step.label)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Accordion;
