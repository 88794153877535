import { addSearchToCollection } from "actions/collectionActions";
import { setGlobalLoading, setGlobalLoadingMessage } from "actions/miscActions";
import {
  incrementNumberOfPolygonsFetched,
  setDrawnPolygons,
} from "actions/polygonActions";
import {
  setSaveSearchModalOpen,
  setSearchContext,
  setSearchObject,
} from "actions/saveSearchActions";
import checkIcon from "assets/core/check.svg";
import closeIcon from "assets/core/close.svg";
import rightIconLucid from "assets/core/lucid-arrow_right.svg";
import leftIconLucid from "assets/core/lucide_arrow-left.svg";
import undoIcon from "assets/core/undo.svg";
import SpinWheelAnimation from "components/animations/SpinWheelLoaderAnimation";
import withRouter from "components/core/withRouter";
import { CollectionsContext } from "context/CollectionsContext";
import { getMapObjectById, getStoreName } from "db/mapStore";
import { AnimatePresence, motion } from "framer-motion";
import { i18n } from "i18n/localisation";
import { sendAnalyticsEvent } from "lib/analytics";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { VariableSizeList as List } from "react-window";
import { toast } from "sonner";
import {
  adjustSearchBarAndMapTools,
  getSearchTitle,
  getSerializedSearchObject,
} from "utils/helpers";
import { searchPolygonToGeoJson } from "utils/polygon";
import {
  setPropertyBuildingType,
  setPropertyFilters,
  setPropertySort,
  setSaleType,
  setSort,
} from "../../actions/filterActions";
import "../../animations.css";
import filterIcon from "../../assets/core/outline_filter.svg";
import filterIconHighlighted from "../../assets/core/outline_filter_highlighted.svg";
import likeIcon from "../../assets/core/panel_save.svg";
import {
  BUILDING_TYPE_FILTERS,
  DEVELOPMENT_ONLY_FILTERS,
  FILTER_LABEL_MAPPINGS,
} from "../../lib/filter/filterTypes";
import {
  convertSavedSearchFilterToFrontendFilter,
  getFilteredProperties,
} from "../../lib/filter/filters";
import {
  SORT_LABEL_MAPPINGS,
  SORT_OPTIONS,
  getSortFunction,
  savedSortToFrontendSort,
} from "../../lib/filter/sort";
import Dropdown from "../core/Dropdown";
import PropertyCard from "./PropertyCard";
import FilterPanel from "./filters/FilterPanel";
import "./property.scss";
import { getPropertiesCountText } from "utils/properties";
import SoldPropertyCard from "./SoldPropertyCard";
import { getSubscriptionPlan } from "utils/userHelpers";
import { MOBILE_BREAKPOINT } from "config/constants";

class PropertyPanel extends Component {
  static contextType = CollectionsContext;

  constructor(props) {
    super(props);
    this.state = {
      isActive: getFilteredProperties().length > 0,
      filterMode: false,
      selectedProperty: null,
      expanded: false,
      expandedPanelLoadedPages: 0,
      windowWidth: window.innerWidth,
      listHeight: window.innerHeight - 200,
    };

    this.propertyPanelScrollPlane = React.createRef();
    this.headerDivider = React.createRef();
    this.listRef = React.createRef();
    this.loadedSearch = false;
    this.googleMapsLoadedCheck = null;
    window.propertyPanelScrollToProperty = this.scrollToProperty;
    this.updateHeight = this.updateHeight.bind(this);
  }

  onWindowResize() {
    this.setState({ windowWidth: window.innerWidth }, () => {
      // Force react-window to re-render after updating the height
      if (this.listRef.current) {
        this.listRef.current.resetAfterIndex(0); // Reset after the first item, forcing a full rerender
      }
    });
  }

  updateHeight = () => {
    this.setState({ listHeight: window.innerHeight - 200 });
  };

  componentDidMount() {
    const { urlParams, collections, dispatch } = this.props;

    window.addEventListener("resize", this.onWindowResize.bind(this));
    window.addEventListener("resize", this.updateHeight);
    window.addEventListener("orientationchange", this.updateHeight);

    // if we are in a search context load that search into the state
    if (urlParams.collectionId && urlParams.searchId && !this.loadedSearch) {
      dispatch(setGlobalLoading(true));
      dispatch(setGlobalLoadingMessage(i18n("Loading search parameters...")));
      this.loadedSearch = true;

      // find the collection and search to load
      let collection = collections.find((c) => c.id === urlParams.collectionId);
      if (collection) {
        let search = collection.searches.find(
          (s) => s.id === urlParams.searchId,
        );

        this.props.dispatch(
          setSearchContext({
            collection,
            search,
          }),
        );

        // wait until google maps is loaded
        this.googleMapsLoadedCheck = setInterval(() => {
          if (typeof window.google.maps.LatLng === "function") {
            clearInterval(this.googleMapsLoadedCheck);
            this.googleMapsLoadedCheck = null;
            this.loadSearch(search);
            dispatch(setGlobalLoading(false));
          }
        }, 500);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize.bind(this));
    window.removeEventListener("resize", this.updateHeight);
    window.removeEventListener("orientationchange", this.updateHeight);
  }

  // loads a saved search into the state
  async loadSearch(search) {
    const { dispatch } = this.props;

    // apply sale type before doing any fetching
    if (search.saleType) {
      dispatch(setSaleType(search.saleType));
    }

    // first clear the state of polygons
    dispatch(setDrawnPolygons([]));
    dispatch(incrementNumberOfPolygonsFetched(0));
    window.drawnPolygons.forEach((p) => p.setMap(null));
    window.drawnPolygons = [];

    // now draw polygons from save search
    let polygons = await Promise.all(
      search.polygons.map(async (p) => {
        // if its a ref, get it from the indexed mapdata
        if (p.ref) {
          let object = await getMapObjectById(
            getStoreName(p.country, p.type),
            p.ref,
          );
          return object;
        }

        return searchPolygonToGeoJson(p);
      }),
    );

    this.props.multiAreaDraw(polygons, {
      fetchProperties: true,
      drawExistingArea: true,
    });
    this.props.zoomToDrawnPolygons(polygons);

    // now apply filters/sort
    if (search.buildingType) {
      dispatch(setPropertyBuildingType(search.buildingType));
    }

    let filters = search.filters.map((f) =>
      convertSavedSearchFilterToFrontendFilter(f),
    );
    dispatch(setSort(savedSortToFrontendSort(search.sort)));
    dispatch(setPropertyFilters(filters));
  }

  componentDidUpdate(prevProps) {
    // scroll to top on filter change
    if (prevProps.filters.length !== this.props.filters.length) {
      this.scrollToProperty(null);
    }

    if (prevProps.urlParams !== this.props.urlParams) {
      this.props.dispatch(setSearchContext(null));
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Check if the 'properties' prop has changed.
    // Assuming 'properties' is an array, you can compare their lengths as a simple check.
    // For a deeper comparison, you might need to implement or use a deep comparison function.
    // This example uses a shallow comparison for simplicity.
    if (this.props.properties.length !== nextProps.properties.length) {
      return true; // Re-render if 'properties' has changed.
    }

    // Optionally, add any other conditions for props or state that should trigger a re-render.
    // For example, if you want to re-render when 'filterMode' state changes:
    if (this.props.filters !== nextProps.filters) {
      return true;
    }

    if (this.props.invisibleFilters !== nextProps.invisibleFilters) {
      return true;
    }

    if (this.props.sort !== nextProps.sort) {
      return true;
    }

    if (this.props.collections !== nextProps.collections) {
      return true;
    }

    if (this.state.filterMode !== nextState.filterMode) {
      return true;
    }

    if (this.props.buildingType !== nextProps.buildingType) {
      return true;
    }

    if (this.props.drawnPolygons.length !== nextProps.drawnPolygons.length) {
      return true;
    }

    if (this.props.fetchingProperties !== nextProps.fetchingProperties) {
      return true;
    }

    if (this.state.expanded !== nextState.expanded) {
      return true;
    }

    if (this.state.windowWidth !== nextState.windowWidth) {
      return true;
    }

    if (
      (!this.props.searchContext && nextProps.searchContext) ||
      (!nextProps.searchContext && this.props.searchContext)
    ) {
      return true;
    }

    if (
      this.props.mapReducer.areaSelectionMode !==
      nextProps.mapReducer.areaSelectionMode
    ) {
      return true;
    }

    if (
      this.state.expandedPanelLoadedPages !== nextState.expandedPanelLoadedPages
    ) {
      return true;
    }

    // Return false to prevent re-render if 'properties' and other checked values haven't changed.
    return false;
  }

  toggleFilterMode() {
    sendAnalyticsEvent("Property Panel Click", {
      type: "filter button",
      details: this.state.filterMode ? "close" : "open",
    });
    this.setState({
      filterMode: !this.state.filterMode,
    });
  }

  onPropertyPanelScroll(e) {
    if (e.target.scrollTop > 0) {
      this.headerDivider.current.classList.add("scrolled");
    } else {
      this.headerDivider.current.classList.remove("scrolled");
    }
  }

  canShowPropertyPanel() {
    const { fetchingProperties, numberOfPolygonsFetched, drawnPolygons } =
      this.props;
    const properties = getFilteredProperties();

    if (drawnPolygons.length === 0) {
      return false;
    }

    // return true if we have fetched properties or are fetching properties
    // or all the polygons have been fetched
    return (
      numberOfPolygonsFetched > 0 || fetchingProperties || properties.length > 0
    );
  }

  // change sort option and scroll property panel to the top
  onSort(option) {
    this.props.dispatch(setPropertySort(option));
    this.scrollToProperty(null);
  }

  isLoadingProperties() {
    return this.props.fetchingProperties;
  }

  propertyCount() {
    return getFilteredProperties().length;
  }

  getItemSize(index) {
    let size = 474;

    if (this.props.saleType === "sold" && getSubscriptionPlan() !== null) {
      size = 461;
    }

    if (this.props.saleType === "sold" && getSubscriptionPlan() === null) {
      size = 385;
    }

    const properties = getFilteredProperties();
    if (index === properties.length - 1) return size + 24;
    return size;
  }

  renderProperty({ index, style }) {
    const { searchContext, saleType } = this.props;
    const properties = getFilteredProperties();
    const property = properties[index];

    return (
      <motion.div
        key={index}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className={
          index === properties.length - 1 && searchContext
            ? "property-animate-content_extra-margin"
            : "property-animate-content"
        }
      >
        <div style={style}>
          {saleType === "sold" ? (
            <SoldPropertyCard
              property={property}
              selectPropertyMarker={this.props.selectPropertyMarker}
            />
          ) : (
            <PropertyCard
              property={property}
              selectPropertyMarker={this.props.selectPropertyMarker}
            />
          )}
        </div>
      </motion.div>
    );
  }

  scrollToProperty = (id) => {
    if (!id) {
      if (this.state.expanded) {
        document.querySelector(".property-panel_container").scrollTop = 0;
      } else {
        if (this.listRef.current) {
          this.listRef.current.scrollToItem(0, "start");
        }
      }

      return;
    }

    if (this.listRef.current) {
      let properties = getFilteredProperties();
      const index = properties.findIndex((p) => p.id === id);
      this.listRef.current.scrollToItem(index, "start");
    }
  };

  // save the search to a collection
  async onSaveSearch() {
    this.props.dispatch(setGlobalLoading(true));
    this.props.dispatch(
      setGlobalLoadingMessage(i18n("Creating search object...")),
    );

    const searchObject = await getSerializedSearchObject();
    this.props.dispatch(setGlobalLoading(false));
    this.props.dispatch(setSearchObject(searchObject));
    this.props.dispatch(setSaveSearchModalOpen(true));
  }

  // overwrite saved search
  async overwriteSaveSearch() {
    this.props.dispatch(setGlobalLoading(true));
    this.props.dispatch(setGlobalLoadingMessage(i18n("Saving search...")));

    const { dispatch } = this.props;
    const { searchContext } = this.props;
    let searchObject = await getSerializedSearchObject(searchContext.search.id);
    searchObject.creationDate = searchContext.search.creationDate;
    searchObject.title = searchContext.search.title;
    searchObject.notifications_enabled = parseInt(
      searchContext.search.notifications_enabled,
    );

    dispatch(addSearchToCollection(searchContext.collection, searchObject));
    toast(i18n("Search has been updated"), { duration: 2000 });
    this.props.dispatch(setGlobalLoading(false));
  }

  closeSearchContext() {
    this.props.dispatch(setSearchContext(null));
    this.props.navigate("/");
  }

  renderSearchContextPane() {
    const { searchContext } = this.props;
    const { expanded } = this.state;

    return (
      <div className={"search-context-pane" + (expanded ? " expanded" : "")}>
        <Helmet>
          <title>{`${searchContext.search.title} — ${searchContext.collection.title}`}</title>
        </Helmet>
        <h2>{searchContext.collection.title}</h2>
        <span className="search-context-pane_search-title">
          {getSearchTitle(searchContext.search)}
        </span>
        <div className="search-context-pane_controls">
          <div
            onClick={() => this.closeSearchContext()}
            className="search-context-pane_controls_control"
          >
            <img alt="Close icon" src={closeIcon} />
          </div>
          <div
            onClick={() => this.loadSearch(searchContext.search)}
            className="search-context-pane_controls_control reset"
          >
            <img alt="Undo icon" src={undoIcon} />
            <span>{i18n("Reset")}</span>
          </div>
          <div
            onClick={() => this.overwriteSaveSearch()}
            className="search-context-pane_controls_control"
          >
            <img alt="Check icon" src={checkIcon} />
            <span>{i18n("Save")}</span>
          </div>
        </div>
      </div>
    );
  }

  // handle infinite scroll on expanded panel
  // so we don't load them all in at once and crash the browser
  onExpandedPanelScroll(e) {
    const { expandedPanelLoadedPages } = this.state;

    if (
      e.target.scrollHeight - e.target.scrollTop - 100 <=
        e.target.clientHeight &&
      !this.props.fetchingProperties
    ) {
      this.setState({
        expandedPanelLoadedPages: expandedPanelLoadedPages + 1,
      });
    }
  }

  renderMobilePropertyPanelHeader() {
    const { filters, sort, searchContext } = this.props;

    let filtersClassName = "property-panel-header_filters_btn filters-btn";
    let filtersIcon = filterIcon;

    if (filters.length > 0) {
      filtersClassName += " active-filters";
      filtersIcon = filterIconHighlighted;
    }

    return (
      <div className="property-panel-header expanded gap-4">
        <div className="property-panel-header_mobile-left flex flex-1 flex-col gap-2">
          <Dropdown
            className="sort-dropdown"
            items={SORT_OPTIONS.map((option, index) => ({
              label: SORT_LABEL_MAPPINGS[option.label](),
              value: index,
              onSelect: () => this.onSort(option),
            }))}
            label={SORT_LABEL_MAPPINGS[sort.label]()}
          />
          <Dropdown
            className="sort-dropdown"
            items={BUILDING_TYPE_FILTERS.map((filter, index) => ({
              label: FILTER_LABEL_MAPPINGS[filter.label](),
              value: index,
              selectedIds: [this.props.buildingType.id],
              onSelect: () => {
                this.scrollToProperty(null);
                this.props.dispatch(setPropertyBuildingType(filter));
              },
            }))}
            label={FILTER_LABEL_MAPPINGS[this.props.buildingType.label]()}
          />
        </div>
        <div className="property-panel-header_mobile-right flex flex-1 flex-col gap-2">
          <div
            onClick={() => this.toggleFilterMode()}
            className={filtersClassName}
          >
            {filters.length > 0 && (
              <div className="property-panel-header_filters_bubble">
                <span>{filters.length}</span>
              </div>
            )}
            <img src={filtersIcon} alt="Filter Icon" />
            <span>{i18n("Filters")}</span>
          </div>
          {searchContext ? (
            <div className="property-panel-header_filters_btn inactive">
              <img src={likeIcon} alt="Save search icon" />
              <span>{i18n("Saved")}</span>
            </div>
          ) : (
            <div
              onClick={() => this.onSaveSearch()}
              className="property-panel-header_filters_btn"
            >
              <img src={likeIcon} alt="Save search icon" />
              <span>{i18n("Save")}</span>
            </div>
          )}
        </div>
        <div
          onClick={() => this.closeSearch()}
          className="property-panel-header_mobile-close"
        >
          <img src={closeIcon} alt="Close" />
        </div>
        <div ref={this.headerDivider} className="divider"></div>
      </div>
    );
  }

  // for cancelling the search on the property view
  closeSearch() {
    this.props.dispatch(setSearchContext(null));
    this.props.navigate("/");
  }

  renderExpandedPanelHeader() {
    const { filters, sort, searchContext, saleType } = this.props;
    const propertiesCount = getPropertiesCountText();
    const buildingTypesItems =
      saleType === "rent"
        ? BUILDING_TYPE_FILTERS.filter(
            (filter) => filter.value === "property" || filter.value === "room",
          )
        : BUILDING_TYPE_FILTERS.filter((filter) => filter.value !== "room");

    let filtersClassName = "property-panel-header_filters_btn filters-btn";
    let filtersIcon = filterIcon;

    if (filters.length > 0) {
      filtersClassName += " active-filters";
      filtersIcon = filterIconHighlighted;
    }

    return (
      <div className="property-panel-header expanded">
        <p className="property-panel-header_count flex-1">{propertiesCount}</p>
        <div className="property-panel-header_right flex-3 flex gap-4">
          <Dropdown
            className="sort-dropdown"
            items={SORT_OPTIONS.map((option, index) => ({
              label: SORT_LABEL_MAPPINGS[option.label](),
              value: index,
              onSelect: () => this.onSort(option),
            }))}
            label={SORT_LABEL_MAPPINGS[sort.label]()}
          />
          <Dropdown
            className="sort-dropdown"
            items={buildingTypesItems.map((filter, index) => ({
              label: FILTER_LABEL_MAPPINGS[filter.label](),
              value: index,
              selectedIds: [this.props.buildingType.id],
              onSelect: () => {
                this.scrollToProperty(null);
                this.props.dispatch(setPropertyBuildingType(filter));
              },
            }))}
            label={FILTER_LABEL_MAPPINGS[this.props.buildingType.label]()}
          />
          <div
            onClick={() => this.toggleFilterMode()}
            className={filtersClassName}
          >
            {filters.length > 0 && (
              <div className="property-panel-header_filters_bubble">
                <span>{filters.length}</span>
              </div>
            )}
            <img src={filtersIcon} alt="Filter Icon" />
            <span>{i18n("Filters")}</span>
          </div>
          {searchContext ? (
            <div className="property-panel-header_filters_btn inactive">
              <img src={likeIcon} alt="Save search icon" />
              <span>{i18n("Saved")}</span>
            </div>
          ) : (
            <div
              onClick={() => this.onSaveSearch()}
              className="property-panel-header_filters_btn"
            >
              <img src={likeIcon} alt="Save search icon" />
              <span>{i18n("Save")}</span>
            </div>
          )}
          <div
            onClick={() =>
              this.setState({ expanded: false, expandedPanelLoadedPages: 0 })
            }
            className="property-panel_collapse"
          >
            <div>
              <img alt="Collapse icon" src={rightIconLucid} />
              <span>{i18n("Collapse")}</span>
            </div>
          </div>
        </div>
        <div ref={this.headerDivider} className="divider"></div>
      </div>
    );
  }

  // render the expanded property panel
  renderExpandedPanel() {
    const { searchContext, saleType } = this.props;
    const { expandedPanelLoadedPages, windowWidth } = this.state;
    const perPage = 50;
    let properties = getFilteredProperties().slice(
      0,
      perPage * (expandedPanelLoadedPages + 1),
    );

    // if we are on tablet, only show 8 properties at a time
    if (window.innerWidth < MOBILE_BREAKPOINT) {
      properties = properties.slice(0, 8 * (expandedPanelLoadedPages + 1));
    }

    // if we are on mobile, only show 3 properties at a time
    if (window.innerWidth < 500) {
      properties = properties.slice(0, 4 * (expandedPanelLoadedPages + 1));
    }

    // if we are on mobile, only show 3 properties at a time
    if (window.innerWidth < 400) {
      properties = properties.slice(0, 2 * (expandedPanelLoadedPages + 1));
    }

    return (
      <div id="property-panel" className={`property-panel expanded`}>
        {searchContext && this.renderSearchContextPane()}

        {windowWidth < MOBILE_BREAKPOINT
          ? this.renderMobilePropertyPanelHeader()
          : this.renderExpandedPanelHeader()}

        <div
          className="property-panel_container expanded"
          onScroll={this.onExpandedPanelScroll.bind(this)}
        >
          {this.propertyCount() == 0 ? (
            <div className="property-panel_non-supported-region">
              {this.isLoadingProperties() ? (
                <div className="flex flex-col justify-center self-center">
                  <p className="property-panel_container_loading-text flex-1">
                    {i18n("Loading properties...")}
                  </p>
                  <SpinWheelAnimation />
                </div>
              ) : (
                <>
                  <p>{i18n("No results matching your criteria")}</p>
                </>
              )}
            </div>
          ) : (
            <div className="property-panel_property-grid">
              {saleType === "sold"
                ? properties.map((property, index) => (
                    <SoldPropertyCard
                      key={index}
                      property={property}
                      selectPropertyMarker={this.props.selectPropertyMarker}
                    />
                  ))
                : properties.map((property, index) => (
                    <PropertyCard
                      key={index}
                      property={property}
                      selectPropertyMarker={this.props.selectPropertyMarker}
                      disablePropertyMarkerInteraction={true}
                    />
                  ))}
            </div>
          )}
        </div>
      </div>
    );
  }

  // render the collapsed property panel
  renderCollapsedPanel() {
    const { filters, sort, saleType, buildingType } = this.props;
    const { searchContext } = this.props;
    const { listHeight } = this.state;
    const properties = getFilteredProperties();
    const propertiesCount = getPropertiesCountText();
    const buildingTypesItems =
      saleType === "rent"
        ? BUILDING_TYPE_FILTERS.filter(
            (filter) => filter.value === "property" || filter.value === "room",
          )
        : BUILDING_TYPE_FILTERS.filter((filter) => filter.value !== "room");

    let isActive =
      this.canShowPropertyPanel() || this.props.mapReducer.areaSelectionMode;
    adjustSearchBarAndMapTools(isActive);

    let filtersClassName = "property-panel-header_filters_btn filters-btn";
    let filtersIcon = filterIcon;

    if (filters.length > 0) {
      filtersClassName += " active-filters";
      filtersIcon = filterIconHighlighted;
    }

    return (
      <div
        id="property-panel"
        className={`property-panel fade-in ${isActive ? "active" : ""}`}
      >
        {searchContext && this.renderSearchContextPane()}
        <div
          className={`property-panel-header fade-in ${isActive ? "active" : ""}`}
        >
          <div className="property-panel-header_filters">
            <div className="property-panel-header_sort_column">
              <Dropdown
                className="sort-dropdown"
                items={buildingTypesItems.map((filter, index) => ({
                  label: FILTER_LABEL_MAPPINGS[filter.label](),
                  value: index,
                  selectedIds: [buildingType.id],
                  onSelect: () => {
                    this.scrollToProperty(null);
                    this.props.dispatch(setPropertyBuildingType(filter));
                  },
                }))}
                label={FILTER_LABEL_MAPPINGS[this.props.buildingType.label]()}
                placeholder={i18n("Type:")}
              />
              <Dropdown
                className="sort-dropdown"
                items={SORT_OPTIONS.map((option, index) => ({
                  label: SORT_LABEL_MAPPINGS[option.label](),
                  value: index,
                  onSelect: () => this.onSort(option),
                }))}
                label={SORT_LABEL_MAPPINGS[sort.label]()}
                placeholder={i18n("Sort:")}
              />
            </div>
            <div className="property-panel-header_filters_column">
              <div
                onClick={() => this.toggleFilterMode()}
                className={filtersClassName}
              >
                {filters.length > 0 && (
                  <div className="property-panel-header_filters_bubble">
                    <span>{filters.length}</span>
                  </div>
                )}
                <img src={filtersIcon} alt="Filter Icon" />
                <span>{i18n("Filters")}</span>
              </div>
              {searchContext ? (
                <div className="property-panel-header_filters_btn inactive">
                  <img src={likeIcon} alt="Save search icon" />
                  <span>{i18n("Saved")}</span>
                </div>
              ) : (
                <div
                  onClick={() => this.onSaveSearch()}
                  className="property-panel-header_filters_btn"
                >
                  <img src={likeIcon} alt="Save search icon" />
                  <span>{i18n("Save")}</span>
                </div>
              )}
            </div>
          </div>
          {!this.props.fetchingProperties && (
            <p className="property-panel-header_count">{propertiesCount}</p>
          )}
          <div ref={this.headerDivider} className="divider"></div>
        </div>
        <div className="property-panel_container">
          {this.propertyCount() == 0 ? (
            <div className="property-panel_non-supported-region">
              {this.isLoadingProperties() ? (
                <div className="flex flex-col justify-center self-center">
                  <p className="property-panel_container_loading-text">
                    {i18n("Loading properties...")}
                  </p>
                  <SpinWheelAnimation />
                </div>
              ) : (
                <>
                  <p>{i18n("No results matching your criteria")}</p>
                </>
              )}
            </div>
          ) : (
            <AnimatePresence>
              <List
                height={listHeight}
                width="100%"
                itemSize={this.getItemSize.bind(this)}
                itemCount={properties.length}
                ref={this.listRef}
              >
                {({ index, style }) => {
                  const itemStyle = {
                    ...style,
                    padding: "24px",
                  };
                  return this.renderProperty({ index, style: itemStyle });
                }}
              </List>
            </AnimatePresence>
          )}
        </div>
        <div
          onClick={() => this.setState({ expanded: true })}
          className="property-panel_expand"
        >
          <div>
            <img alt="Expand icon" src={leftIconLucid} />
            <span>{i18n("Expand")}</span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { filterMode, expanded } = this.state;
    let _expanded =
      expanded || (window.innerWidth < MOBILE_BREAKPOINT && this.canShowPropertyPanel());

    return (
      <>
        {_expanded ? this.renderExpandedPanel() : this.renderCollapsedPanel()}
        <CSSTransition in={filterMode} timeout={200} classNames="slide-in">
          <FilterPanel toggleFilterMode={this.toggleFilterMode.bind(this)} />
        </CSSTransition>
      </>
    );
  }
}

export default connect((state) => ({
  filters: state.filters.filters,
  invisibleFilters: state.filters.invisibleFilters,
  buildingType: state.filters.buildingType,
  saleType: state.filters.saleType,
  sort: state.filters.sort,
  drawnPolygons: state.polygon.drawnPolygons,
  fetchingProperties: state.property.fetchingProperties,
  collections: state.collections.collections,
  properties: state.property.properties,
  filteredProperties: state.property.filteredProperties,
  searchObject: state.saveSearch.searchObject,
  searchContext: state.saveSearch.searchContext,
  numberOfPolygonsFetched: state.polygon.numberOfPolygonsFetched,
  fetchProperties: state.property.fetchProperties,
  mapReducer: state.map,
}))(withRouter(PropertyPanel));
