import { setMediaByType } from "actions/myPropertiesActions";
import { savePropertyRequest } from "api/crm";
import plusIcon from "assets/core/plus.svg";
import LoadingSpinner from "components/ui/LoadingSpinner/LoadingSpinner";
import { i18n } from "i18n/localisation";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import PUBLIC_MEDIA_BASE_URL, {
  base64ToBlob,
  getMimeType,
  uploadFile,
} from "utils/file";
import { v4 as uuidv4 } from "uuid";
import EmptySection from "../EmptySection";
import IconComponent from "../IconComponent";
import SectionWrapper from "../SectionWrapper";
import {
  ICON_CONFIG,
  MEDIA_TABS,
  NO_MEDIA_CONTAINER,
  SECTION_WRAPPER_CONTENT,
} from "../uploadPropertyPageConfig";
import { MediaGrid } from "./MediaGrid";
import { generateVideoThumbnail, getAcceptedFileTypes } from "./utils";

const MediaUpload = () => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(MEDIA_TABS[0]);
  const [content, setContent] = useState(SECTION_WRAPPER_CONTENT.EMPTY_CONTENT);
  const [isLoading, setIsLoading] = useState(false);
  const [unsavedMedia, setUnsavedMedia] = useState();

  const { propertyId } = useParams();

  const media = useSelector((state) => ({
    photos: state.myProperties.photos,
    videos: state.myProperties.videos,
    floorPlans: state.myProperties.floorPlans,
    virtualTours: state.myProperties.virtualTours,
  }));

  const mediaRef = useRef();
  const fileInputRef = useRef(null);

  const hasMedia = Object.values(media).some((tab) => tab && tab.length > 0);

  const triggerFileSelect = () => fileInputRef.current?.click();

  const handleFileSelect = useCallback(
    async (files) => {
      try {
        const validFiles = files.filter((file) => {
          const maxSize =
            selectedTab.key === "videos" ? 100 * 1024 * 1024 : 32 * 1024 * 1024;
          return file.size <= maxSize;
        });

        if (validFiles.length === 0) {
          toast.error(i18n("Files exceed maximum size limit"));
          return;
        }

        const serializableFiles = await Promise.all(
          validFiles.map(async (file) => {
            const id = uuidv4();
            const localUrl = URL.createObjectURL(file);

            const serializableFile = {
              id,
              name: file.name,
              size: file.size,
              type: file.type,
              lastModified: file.lastModified,
              localUrl,
            };

            if (selectedTab.key === "videos") {
              const thumbnail = await generateVideoThumbnail(file);
              return {
                ...serializableFile,
                thumbnail: {
                  localUrl: thumbnail.localUrl,
                  type: "thumbnail",
                },
                mediaType: "video",
                isUnsaved: true,
              };
            }

            return {
              ...serializableFile,
              mediaType: "image",
              isUnsaved: true,
            };
          }),
        );

        if (!mediaRef.current) {
          mediaRef.current = {
            [selectedTab.key]: serializableFiles,
          };
        } else {
          mediaRef.current = {
            ...mediaRef.current,
            [selectedTab.key]: serializableFiles.concat(
              mediaRef.current[selectedTab.key],
            ),
          };
        }

        setUnsavedMedia(mediaRef.current);
      } catch (error) {
        toast.error(i18n("Error uploading files: ") + error.message);
        console.error("Upload failed:", error);
      }
    },
    [selectedTab],
  );

  const handleSaveMedia = async () => {
    setIsLoading(true);
    setContent(SECTION_WRAPPER_CONTENT.EMPTY_CONTENT);
    try {
      const mediaTypes = ["photos", "videos", "floorPlans", "virtualTours"];
      const updates = {
        photos: [],
        videos: [],
        floorPlans: [],
        virtualTours: [],
      };

      for (const type of mediaTypes) {
        if (!mediaRef.current[type]?.length) continue;

        const unsaved = mediaRef.current[type].filter((item) => item.isUnsaved);
        const savedMedia = mediaRef.current[type].filter(
          (item) => !item.isUnsaved,
        );

        if (unsaved.length === 0) {
          updates[type] = savedMedia;
          continue;
        }

        const uploadedItems = await Promise.all(
          unsaved.map(async (item) => {
            try {
              if (item.mediaType === "video") {
                // Convert base64 to Blobs using existing utility
                const thumbnailBase64 = item.thumbnail.localUrl.split(",")[1];

                const videoBlob = item.localUrl;
                const thumbnailBlob = base64ToBlob(
                  thumbnailBase64,
                  getMimeType("png"),
                );

                const [videoResult, thumbnailResult] = await Promise.all([
                  uploadFile(
                    videoBlob,
                    `properties/${propertyId}/${item.id}.mp4`,
                    getMimeType("mp4"),
                  ),
                  uploadFile(
                    thumbnailBlob,
                    `properties/${propertyId}/${item.id}.png`,
                    getMimeType("png"),
                  ),
                ]);

                return {
                  id: item.id,
                  name: item.id,
                  type: item.type,
                  mediaType: item.mediaType,
                  url: {
                    video: `${PUBLIC_MEDIA_BASE_URL.PUBLIC_MEDIA_BASE_URL}${videoResult.key}`,
                    thumbnail: `${PUBLIC_MEDIA_BASE_URL.PUBLIC_MEDIA_BASE_URL}${thumbnailResult.key}`,
                  },
                };
              }

              const blob = await fetch(item.localUrl).then((r) => r.blob());
              const extension = item.name.split(".").pop();
              const result = await uploadFile(
                blob,
                `properties/${propertyId}/${item.id}.${extension}`,
                getMimeType(extension),
              );

              return {
                id: item.id,
                name: item.id,
                type: item.type,
                mediaType: item.mediaType,
                url: `${PUBLIC_MEDIA_BASE_URL.PUBLIC_MEDIA_BASE_URL}${result.key}`,
              };
            } catch (error) {
              console.error("Error processing item:", item, error);
              throw error;
            }
          }),
        );

        updates[type] = [...savedMedia, ...uploadedItems];
      }

      Object.entries(updates).forEach(([type, items]) => {
        dispatch(setMediaByType({ type, items }));
      });

      await savePropertyRequest({
        property_id: propertyId,
        update_fields: {
          multimedia: {
            images: updates.photos,
            videos: updates.videos,
            plans: updates.floorPlans,
            virtualTour: updates.virtualTours,
          },
          thumbnail: updates.photos[0]?.url || "",
        },
      });

      toast.success(i18n("Media has been saved"));
    } catch (error) {
      console.error("Error uploading media:", error);
      toast.error(i18n("Error saving media"));
    } finally {
      setIsLoading(false);
      setContent(
        media[selectedTab.key].length === 0
          ? SECTION_WRAPPER_CONTENT.EMPTY_CONTENT
          : SECTION_WRAPPER_CONTENT.SAVED_CONTENT,
      );
    }
  };

  useEffect(() => {
    if (hasMedia) {
      setContent(SECTION_WRAPPER_CONTENT.SAVED_CONTENT);
    }
  }, []);

  useEffect(() => {
    if (!mediaRef.current) {
      mediaRef.current = media;
    }
  }, [media]);

  useEffect(() => {
    return () => {
      Object.values(media)?.forEach((mediaArray) => {
        mediaArray?.forEach((item) => {
          if (item.url) URL.revokeObjectURL(item.url);
          if (item.thumbnail) URL.revokeObjectURL(item.thumbnail);
        });
      });
    };
  }, [media]);

  const renderHeaderButton = () => {
    if (content === SECTION_WRAPPER_CONTENT.INPUT_CONTENT) {
      return (
        <div
          className="flex cursor-pointer gap-[2px]"
          onClick={triggerFileSelect}
        >
          <img src={plusIcon} alt="add media" />
          <p>{i18n(NO_MEDIA_CONTAINER[selectedTab.key]?.label)}</p>
        </div>
      );
    }
  };

  const renderContent = () => {
    if (
      content === SECTION_WRAPPER_CONTENT.SAVED_CONTENT &&
      mediaRef.current[selectedTab.key].length > 0
    ) {
      return (
        <MediaGrid
          images={mediaRef.current}
          tab={selectedTab}
          onFileSelect={triggerFileSelect}
          isSavedGrid
        />
      );
    }

    if (
      content === SECTION_WRAPPER_CONTENT.INPUT_CONTENT &&
      mediaRef.current?.[selectedTab.key]?.length > 0
    ) {
      return (
        <MediaGrid
          images={mediaRef.current}
          setImages={setUnsavedMedia}
          tab={selectedTab}
          onFileSelect={triggerFileSelect}
          isSavedGrid={false}
        />
      );
    }

    return (
      <EmptySection
        setContent={() => {
          triggerFileSelect();
          setContent(SECTION_WRAPPER_CONTENT.INPUT_CONTENT);
        }}
        buttonLabel={i18n(NO_MEDIA_CONTAINER[selectedTab?.key]?.label)}
        helperText={i18n(NO_MEDIA_CONTAINER[selectedTab?.key]?.text)}
        icon={{
          icon: selectedTab.icon,
          parameter: ICON_CONFIG[selectedTab.key].parameter,
        }}
      />
    );
  };

  return (
    <SectionWrapper
      onSave={handleSaveMedia}
      onEdit={() => setContent(SECTION_WRAPPER_CONTENT.INPUT_CONTENT)}
      onCancel={() => setContent(SECTION_WRAPPER_CONTENT.SAVED_CONTENT)}
      content={content}
      title={"Property Media"}
      headerButton={renderHeaderButton}
      isCompleted={media.photos.length > 0}
    >
      {isLoading ? (
        <div className="flex h-auto min-h-[267px] items-center justify-center">
          <LoadingSpinner overlay={false} />
        </div>
      ) : (
        <>
          <div className="flex w-full p-4">
            <input
              type="file"
              ref={fileInputRef}
              className="hidden"
              accept={getAcceptedFileTypes(selectedTab)}
              multiple
              onChange={(e) => {
                handleFileSelect(Array.from(e.target.files));
                e.target.value = "";
              }}
            />
            {renderContent()}
          </div>
          <div className="media-upload_footer">
            <div className="media-upload_footer_tabs">
              {MEDIA_TABS.map((tab) => (
                <div
                  key={tab.key}
                  className={`media-upload_footer_tab 
         ${tab === selectedTab ? "border-t-0" : "border-t-[1px] bg-[#FAFBFC]"} 
         ${tab === MEDIA_TABS[0] ? "rounded-bl-2xl border-l-0" : "rounded-none border-l-[1px]"}
         ${tab === MEDIA_TABS[3] ? "rounded-br-2xl" : "rounded-none"}`}
                  onClick={() => setSelectedTab(tab)}
                >
                  <div className="h-[20px] w-[20px]">
                    <IconComponent
                      icon={tab.icon}
                      color={tab === selectedTab ? "#222222" : "#717171"}
                      parameter={ICON_CONFIG[tab.key].parameter}
                    />
                  </div>
                  <p
                    className={
                      tab === selectedTab
                        ? "font-medium text-[#222222]"
                        : "font-medium text-[#717171]"
                    }
                  >
                    {`${i18n(tab.label)} (${mediaRef.current?.[tab.key]?.length || 0})`}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </SectionWrapper>
  );
};

export default MediaUpload;
