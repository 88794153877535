export function cacheProvinceBoundaries(country, data) {
  localStorage.setItem(country + "-" + "provinces", JSON.stringify(data));
}

export function getCachedProvinceBoundaries(country) {
  return JSON.parse(localStorage.getItem(country + "-" + "provinces"));
}

// Simple LRU Cache implementation for frontend
export class LRUCache {
  constructor(maxSize = 20) {
    this.maxSize = maxSize;
    this.cache = new Map();
  }

  get(key) {
    if (!this.cache.has(key)) return undefined;

    // Get the value and refresh its position
    const value = this.cache.get(key);
    this.cache.delete(key);
    this.cache.set(key, value);
    return value;
  }

  set(key, value) {
    if (this.cache.has(key)) {
      this.cache.delete(key);
    } else if (this.cache.size >= this.maxSize) {
      // Remove the first (oldest) item
      this.cache.delete(this.cache.keys().next().value);
    }
    this.cache.set(key, value);
  }
}
