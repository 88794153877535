import {
  setAddress,
  setMunicipality,
  setProvince,
} from "actions/myPropertiesActions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CatastroLocation from "./CatastroLocation";
import SectionWrapper from "./SectionWrapper";
import { SECTION_WRAPPER_CONTENT } from "./uploadPropertyPageConfig";

const Location = () => {
  const dispatch = useDispatch();
  const [mapImage, setMapImage] = useState("");
  const [content, setContent] = useState(SECTION_WRAPPER_CONTENT.SAVED_CONTENT);
  const municipality = useSelector((state) => state.myProperties.municipality);
  const province = useSelector((state) => state.myProperties.province);
  const address = useSelector((state) => state.myProperties.address);
  const coordinates = useSelector((state) => state.myProperties.coordinates);
  const catastroProperty = useSelector(
    (state) => state.myProperties.catastroProperty,
  );

  useEffect(() => {
    if (catastroProperty) {
      setMapImage(
        `https://maps.googleapis.com/maps/api/staticmap?center=${catastroProperty.latitude},${catastroProperty.longitude}&zoom=15&size=600x400&key=AIzaSyCR06oB8bOqasfKGEU5WIOm6R2Llf9mi9o&scale=2&markers=color:red%7C${coordinates?.latitude},${coordinates?.longitude}`,
      );
    }
  }, [catastroProperty]);

  const handleEdit = () => setContent(SECTION_WRAPPER_CONTENT.INPUT_CONTENT);
  const handleSaveLocation = () => {
    dispatch(setMunicipality(catastroProperty.municipality));
    dispatch(setProvince(catastroProperty.province));
    dispatch(setAddress(catastroProperty.address));
    setContent(SECTION_WRAPPER_CONTENT.SAVED_CONTENT);
  };

  return (
    <SectionWrapper
      title={"Location"}
      content={content}
      onSave={handleSaveLocation}
      onEdit={handleEdit}
      onCancel={() => setContent(SECTION_WRAPPER_CONTENT.SAVED_CONTENT)}
      isCompleted={!!coordinates}
    >
      {content === SECTION_WRAPPER_CONTENT.SAVED_CONTENT && (
        <>
          <div className="h-auto w-full px-[24px] py-[16px]">
            <div className="h-auto min-h-[120]">
              <p className="text-[16px] text-[#717171]">{address}</p>
            </div>
            <div className="h-auto min-h-[120]">
              <p className="text-[16px] text-[#717171]">{municipality}</p>
            </div>
            <div className="h-auto min-h-[120]">
              <p className="text-[16px] text-[#717171]">{province}</p>
            </div>
          </div>
          <div className="w-full overflow-hidden">
            <img src={mapImage} alt="Property location map" />
          </div>
        </>
      )}

      {content === SECTION_WRAPPER_CONTENT.INPUT_CONTENT && (
        <div className="max-h-[800px]">
          <CatastroLocation variant="page" />
        </div>
      )}
    </SectionWrapper>
  );
};

export default Location;
