import { i18n } from "i18n/localisation";
import { useContext, useEffect, useState } from "react";
import { CollectionsContext } from "../../../context/CollectionsContext";
import Button from "../../ui/Button/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";
import { CollectionTile } from "../CollectionTile";
import { sortByCreatedDate } from "../utils";

function toggleItemInArray(array, item) {
  const existsInArray = array.some((arrayItem) => arrayItem.id === item.id);

  if (existsInArray) {
    return array.filter((arrayItem) => arrayItem.id !== item.id);
  } else {
    return [...array, item];
  }
}

// gets collections that contain the object be it saved search or property
function getCollectionsWithObject(collections, object) {
  if (!object) return [];

  if (object.objectType === "search") {
    return collections.filter((collection) =>
      collection.searches.some((item) => item.id === object.id),
    );
  }

  return collections.filter((collection) =>
    collection.properties.some((item) => item.id === object.id),
  );
}

function getMissingObjects(array1, array2) {
  return array1.filter(
    (array1Item) =>
      !array2.some((array2Item) => array2Item.id === array1Item.id),
  );
}

const AddToCollectionModal = ({
  onSubmit,
  collections,
  onCreateCollectionFlow,
}) => {
  const { activeAddToCollectionObject, closeAddToCollectionModal } =
    useContext(CollectionsContext);

  const [sortedCollections, setSortedCollections] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [activeCollectionIndex, setActiveCollectionIndex] = useState(null);
  const [searchCollection, setSearchCollection] = useState("");

  // if the search collection has a space, check against whole collection title
  // if the search collection is a compound name, split it into parts
  // and check against each part of the collection title
  const filteredCollections = collections.filter((collection) =>
    collection.title
      .split(/[\s-]+/) // split by space or hyphen
      .some((word) =>
        word.toLowerCase().startsWith(searchCollection.toLowerCase()),
      ),
  );

  useEffect(() => {
    const copy = [...filteredCollections].sort(sortByCreatedDate).reverse();
    setSortedCollections([...copy].sort(sortByCreatedDate).reverse());
    setActiveCollectionIndex(null);
  }, [collections, searchCollection]);

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [activeAddToCollectionObject, selectedCollections, sortedCollections]);

  useEffect(() => {
    if (!activeAddToCollectionObject) return;
    setCollectionsWithObject();
  }, [activeAddToCollectionObject, collections]);

  const onKeyDown = (e) => {
    const tileElements = Array.from(document.querySelectorAll(".tile"));
    const activeElement = document.querySelector(".active-element");
    const currentIndex = tileElements.indexOf(activeElement);
    const currentTileElementRect =
      tileElements[currentIndex]?.getBoundingClientRect();

    switch (e.key) {
      case "ArrowRight":
        if (currentIndex === tileElements.length - 1) return;

        const nextIndex = currentIndex < 0 ? 0 : currentIndex + 1;
        const nextTileElement = tileElements[nextIndex];

        if (
          nextTileElement?.getBoundingClientRect()?.y !==
          currentTileElementRect?.y
        ) {
          nextTileElement?.scrollIntoView({ block: "end" });
        }

        setActiveCollectionIndex(nextIndex);
        break;
      case "ArrowLeft":
        if (currentIndex === 0) return;

        const prevIndex = currentIndex < 0 ? 0 : currentIndex - 1;
        const prevTileElement = tileElements[prevIndex];

        if (
          prevTileElement?.getBoundingClientRect()?.y !==
          currentTileElementRect?.y
        ) {
          prevTileElement?.scrollIntoView({ block: "start" });
        }

        setActiveCollectionIndex(prevIndex);
        break;
      case "ArrowDown":
        if (currentIndex === tileElements.length - 1) return;

        if (currentIndex < 0) {
          setActiveCollectionIndex(0);
          return;
        }

        // get the tile positioned below the current tile
        // if there is no tile below, return the current tile
        const tileBellow =
          tileElements.find(
            (tile) =>
              tile.getBoundingClientRect().x === currentTileElementRect.x &&
              tile.getBoundingClientRect().y > currentTileElementRect.y,
          ) || tileElements[currentIndex];

        setActiveCollectionIndex(tileElements.indexOf(tileBellow));
        tileBellow.scrollIntoView({ block: "end" });
        break;
      case "ArrowUp":
        if (currentIndex === 0) return;

        if (currentIndex < 0) {
          setActiveCollectionIndex(0);
          return;
        }

        // get the tile positioned above the current tile
        // if there is no tile above, return the current tile
        const tileAbove =
          tileElements.findLast(
            (tile) =>
              tile.getBoundingClientRect().x === currentTileElementRect.x &&
              tile.getBoundingClientRect().y < currentTileElementRect.y,
          ) || tileElements[currentIndex];

        setActiveCollectionIndex(tileElements.indexOf(tileAbove));
        tileAbove.scrollIntoView({ block: "start" });
        break;
      case "Enter":
        e.preventDefault();

        //without this check, the modal would close when pressing enter with no collections selected
        if (currentIndex < 0) {
          return;
        }

        if (e.metaKey && e.key === "Enter") {
          window.removeEventListener("keydown", onKeyDown);
          handleOnSubmit();
          return;
        }

        handleCollectionToggle(sortedCollections[currentIndex]);
        break;
      case "Escape":
        closeAddToCollectionModal();
        window.removeEventListener("keydown", onKeyDown);
        break;
      default:
        break;
    }
  };

  const setCollectionsWithObject = () => {
    const collectionsWithObject = getCollectionsWithObject(
      collections,
      activeAddToCollectionObject,
    );
    setSelectedCollections([...collectionsWithObject]);
  };

  const handleCollectionToggle = (collection) => {
    const nextCollections = collections.find(
      (item) => item.id === collection.id,
    );
    setSelectedCollections((prevCollections) =>
      toggleItemInArray(prevCollections, nextCollections),
    );
  };

  const handleOnSubmit = () => {
    const initialSelectedCollections = getCollectionsWithObject(
      collections,
      activeAddToCollectionObject,
    );

    const addToCollections = getMissingObjects(
      selectedCollections,
      initialSelectedCollections,
    );

    const removeFromCollections = getMissingObjects(
      initialSelectedCollections,
      selectedCollections,
    );

    onSubmit(
      activeAddToCollectionObject,
      addToCollections,
      removeFromCollections,
    );
  };

  return (
    <Dialog
      open={activeAddToCollectionObject}
      onOpenChange={(open) => {
        closeAddToCollectionModal();
        setSelectedCollections([]);
        setSearchCollection("");
      }}
    >
      <DialogContent className="w-full md:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{i18n("Add to collection")}</DialogTitle>
          <div className="search-input">
            <input
              id="search-collections-input"
              type="text"
              placeholder={i18n("Search Collections")}
              value={searchCollection}
              onChange={(e) => setSearchCollection(e.target.value)}
              className="mt-3"
            />
          </div>
        </DialogHeader>
        <div className="flex h-full max-h-[450px] min-h-[365px] flex-wrap justify-center overflow-scroll">
          <div className="flex flex-wrap justify-center p-2 sm:justify-between">
            {sortedCollections.length === 0 && (
              <div className="no-collections flex w-full justify-center">
                <p className="text-gray-500">{i18n("No collections found")}</p>
              </div>
            )}
            {sortedCollections.map((collection) => (
              <CollectionTile
                active={
                  activeCollectionIndex ===
                  sortedCollections.indexOf(collection)
                }
                key={collection.id}
                collection={collection}
                className=""
                isCheckbox
                handleOnClick={() => handleCollectionToggle(collection)}
                checked={selectedCollections.some(
                  (item) => item.id === collection.id,
                )}
              />
            ))}
            {sortedCollections.length % 2 ? (
              <div className="flex h-[268px] w-[268px]"></div>
            ) : null}
          </div>
        </div>
        <DialogFooter>
          <div className="flex w-full justify-end">
            <Button variant="text" onClick={() => setCollectionsWithObject()}>
              {i18n("Deselect")}
            </Button>
            <div className="flex-1"></div>
            <div className="flex justify-end">
              <Button
                onClick={() =>
                  onCreateCollectionFlow(activeAddToCollectionObject)
                }
                className="ml-auto"
                variant="secondary"
              >
                {i18n("Create collection")}
              </Button>
              <Button
                disabled={
                  selectedCollections.length === 0 &&
                  getCollectionsWithObject(
                    collections,
                    activeAddToCollectionObject,
                  ).length === 0
                }
                onClick={() => handleOnSubmit()}
                className="ml-4"
              >
                {i18n("Save")}
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddToCollectionModal;
