import { i18n } from "i18n/localisation";
import checkIconWhite from "../../assets/core/check_white.svg";
import Button from "../ui/Button/Button";
import { SECTION_WRAPPER_CONTENT } from "./uploadPropertyPageConfig";

const SectionWrapper = ({
  children,
  title,
  onSave,
  onEdit,
  onCancel,
  content,
  headerButton = () => {},
  isCompleted,
}) => {
  return (
    <div className="relative flex h-fit w-full flex-col items-center rounded-2xl bg-white">
      <div className="flex h-[56px] w-full items-center justify-between border-b border-[#e1e4eb80] px-6">
        <p className="text-[20px] font-semibold text-[#212121]">
          {i18n(title)}
        </p>
        {headerButton()}
      </div>

      <div className="w-full">{children}</div>

      <div className="h-fit w-full">
        {content === SECTION_WRAPPER_CONTENT.INPUT_CONTENT && (
          <div className="flex justify-between border-t border-[#e1e4eb] px-6 py-4">
            <Button variant="secondary" onClick={onCancel}>
              <span>{i18n("Cancel")}</span>
            </Button>
            <Button onClick={onSave}>
              <span>{i18n("Save")}</span>
            </Button>
          </div>
        )}
        {content === SECTION_WRAPPER_CONTENT.SAVED_CONTENT && (
          <div
            className={`flex items-center border-t border-[#e1e4eb] px-6 py-4 ${isCompleted ? "justify-between" : "justify-end"}`}
          >
            {isCompleted && (
              <div className="flex h-[33px] items-center gap-2 rounded-full bg-[#12B76A] px-[16px]">
                <img src={checkIconWhite} alt="check" className="w-4" />
                <p className="text-[14px] text-white">{i18n("Complete")}</p>
              </div>
            )}
            <Button variant="secondary" onClick={onEdit}>
              <span>{i18n("Edit")}</span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionWrapper;
