const readFileAsDataURL = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

export const generateVideoThumbnail = async (videoFile) => {
  return new Promise(async (resolve, reject) => {
    try {
      const video = document.createElement("video");
      const dataUrl = await readFileAsDataURL(videoFile);
      video.src = dataUrl;

      video.load();

      video.addEventListener("error", (e) => {
        console.error("Video error:", e.target.error);
        reject(
          new Error(
            `Video loading failed: ${e.target.error?.message || "Unknown error"}`,
          ),
        );
      });

      video.addEventListener("loadeddata", () => {
        try {
          video.currentTime = 2;
        } catch (error) {
          reject(error);
        }
      });

      video.addEventListener("seeked", () => {
        try {
          const canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          const ctx = canvas.getContext("2d");
          // Draw the video frame
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

          // // Draw play button overlay
          const centerX = canvas.width / 2;
          const centerY = canvas.height / 2;
          const radius = Math.min(canvas.width, canvas.height) * 0.1; // Size of play button

          // Semi-transparent dark background
          ctx.fillStyle = "rgba(0, 0, 0, 0.5)";
          ctx.beginPath();
          ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
          ctx.fill();

          // White play triangle
          ctx.fillStyle = "white";
          ctx.beginPath();
          ctx.moveTo(centerX - radius / 4, centerY - radius / 3);
          ctx.lineTo(centerX + radius / 3, centerY);
          ctx.lineTo(centerX - radius / 4, centerY + radius / 3);
          ctx.closePath();
          ctx.fill();

          const thumbnailUrl = canvas.toDataURL("image/png");

          resolve({
            localUrl: thumbnailUrl,
            file: canvas,
          });
        } catch (error) {
          console.error("Error in seeked event:", error);
          reject(error);
        }
      });
    } catch (error) {
      console.error("Error in generateVideoThumbnail:", error);
      reject(error);
    }
  });
};

export const getAcceptedFileTypes = (tab) => {
  switch (tab?.key) {
    case "photos":
    case "floorPlans":
    case "virtualTours":
      return "image/jpeg,image/png";
    case "videos":
      return "video/mp4";
    default:
      return "";
  }
};
