import deleteIcon from "assets/core/delete.svg";
import duplicateIcon from "assets/core/duplicate.svg";
import editIcon from "assets/core/edit.svg";
import clsx from "clsx";
import HamburgerMenu from "components/core/HamburgerMenu";
import { i18n } from "i18n/localisation";
import { getSearchTitle, unwatermarkImg } from "utils/helpers";
import Checkbox from "../ui/Checkbox/Checkbox";
import { getInitialsFromString } from "./utils";

export const CollectionTile = ({
  collection,
  handleOnClick,
  className,
  isCheckbox = false,
  checked = false,
  showHamburger = false,
  onEditTitle,
  onDuplicateCollection,
  onDeleteCollection,
  ...props
}) => {
  const handleClick = () => {
    handleOnClick();
  };

  const objects = [].concat(collection.properties, collection.searches);

  const menuItems = [
    {
      text: i18n("Edit title"),
      icon: editIcon,
      onClick: () => onEditTitle(collection),
    },
    {
      text: i18n("Duplicate"),
      icon: duplicateIcon,
      onClick: () => onDuplicateCollection(collection),
    },
    {
      text: i18n("Delete"),
      icon: deleteIcon,
      onClick: () => onDeleteCollection(collection),
    },
  ];

  const renderObjectImage = (object) => {
    if (object.objectType == "search") {
      return (
        <img
          src={object.thumbnail}
          alt={getSearchTitle(object)}
          className="absolute h-full w-full rounded-xl object-cover"
        />
      );
    }

    let imageUrl = null;
    let tag = null;

    if (
      object.multimedia &&
      object.multimedia.images &&
      object.multimedia.images.length > 0
    ) {
      imageUrl = unwatermarkImg(object.multimedia.images[0].url);
      tag = object.multimedia.images[0].tag;
    }

    return (
      <img
        src={imageUrl}
        alt={tag}
        className="absolute h-full w-full rounded-xl object-cover"
      />
    );
  };

  const renderInitials = () => (
    <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
      <span className="text-5xl font-bold uppercase text-gray-300">
        {getInitialsFromString(collection.title)}
      </span>
    </div>
  );

  const renderSingleObject = (object) => (
    <div className="flex h-full w-full items-center justify-center ">
      {renderObjectImage(object)}
    </div>
  );

  const renderMultiObjectImages = (objects) => (
    <div className="grid h-full w-full grid-cols-2 gap-2">
      <div className="relative h-full w-full items-center justify-center">
        {objects.length >= 1 ? renderObjectImage(objects[0]) : null}
      </div>
      <div className="relative h-full w-full items-center justify-center">
        {objects.length >= 2 ? (
          renderObjectImage(objects[1])
        ) : (
          <div className="h-full w-full rounded-xl bg-gray-100"></div>
        )}
      </div>
      <div className="relative h-full w-full items-center justify-center">
        {objects.length >= 3 ? (
          renderObjectImage(objects[2])
        ) : (
          <div className="h-full w-full rounded-xl bg-gray-100"></div>
        )}
      </div>
      <div className="relative h-full w-full items-center justify-center">
        {objects.length >= 4 ? (
          renderObjectImage(objects[3])
        ) : (
          <div className="h-full w-full rounded-xl bg-gray-100"></div>
        )}
      </div>
    </div>
  );

  return (
    <div
      className={clsx(
        className,
        "tile flex flex-col",
        props.active ? "active-element" : "",
      )}
      {...props}
    >
      <div
        className={clsx(
          "rounded-[20px] border-4  p-[2px]",
          props.active ? "border-[#005BD3]" : "border-transparent",
        )}
      >
        <div
          className={clsx(
            "relative h-[268px] w-[268px] cursor-pointer rounded-2xl border-4 bg-gray-200 transition-all duration-200 ease-in-out",
            checked ? "border-gray-dark" : "border-white",
          )}
          id={collection.id}
          onClick={handleClick}
        >
          {objects.length === 0 ? renderInitials() : null}
          {objects.length === 1 ? renderSingleObject(objects[0]) : null}
          {objects.length > 1 ? renderMultiObjectImages(objects) : null}

          {isCheckbox && (
            <Checkbox checked={checked} className={"absolute right-4 top-4"} />
          )}
        </div>
      </div>
      <div className="flex w-[268px] flex-col px-1 py-3">
        <p className="text-lg font-medium">{collection.title}</p>
        <p className="mb-3 text-sm text-gray-500">
          {objects.length} {i18n("saved")}
        </p>
      </div>
      {showHamburger && <HamburgerMenu items={menuItems} />}
    </div>
  );
};
