import { createReducer } from "@reduxjs/toolkit";
import {
  addCondition,
  removeCondition,
  resetConditions,
} from "../actions/valuationDetailsActions";

const initialState = {
  conditions: [],
};

const valuationDetailsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addCondition, (state, action) => {
      state.conditions = state.conditions.filter(
        (condition) => condition.id !== action.payload.id,
      );
      state.conditions.push(action.payload);
    })
    .addCase(removeCondition, (state, action) => {
      state.conditions = state.conditions.filter(
        (condition) => condition.id !== action.payload.id,
      );
    })
    .addCase(resetConditions, (state, action) => {
      state.conditions = [];
    });
});

export default valuationDetailsReducer;
