import Button from "../ui/Button/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { connect } from "react-redux";
import { i18n } from "i18n/localisation";
import { useEffect, useState } from "react";
import PrimaryInput from "components/core/PrimaryInput";
import { Auth } from "aws-amplify";
import { toast } from "sonner";
import {
  addValuationMetaCondition,
  editValuationMetaCondition,
  deleteValuationMetaCondition,
} from "actions/metaValuationConditionsActions";
import { VALUATION_CONDITION_TYPES } from "config/valuationConfig";
import { saveValuationCondition } from "api/valuationConditions";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

const CustomConditionsModal = ({
  closeModal,
  open,
  onHandleComplete,
  condition,
  dispatch,
  typology,
  buildingType,
}) => {
  const [conditionTitle, setConditionTitle] = useState(condition?.label || "");
  const [conditionType, setConditionType] = useState(
    condition?.type || VALUATION_CONDITION_TYPES.YES_NO,
  ); // yesno or dropdown
  const [yesPercentage, setYesPercentage] = useState(
    condition?.yesPercentage || 0,
  );
  const [noPercentage, setNoPercentage] = useState(
    condition?.noPercentage || 0,
  );
  const [dropdownItems, setDropdownItems] = useState(
    condition?.dropdownItems || [{ title: "", percentage: 0 }],
  );

  useEffect(() => {
    if (condition) {
      setConditionTitle(condition.label);
      setConditionType(condition.type);
      setYesPercentage(condition.yesPercentage * 100);
      setNoPercentage(condition.noPercentage * 100);
      setDropdownItems(
        condition.dropdownItems?.map((item) => ({
          label: item.label,
          value: item.value * 100,
        })),
      );
    } else {
      setConditionTitle("");
      setConditionType(VALUATION_CONDITION_TYPES.YES_NO);
      setYesPercentage(0);
      setNoPercentage(0);
      setDropdownItems([{ label: "", value: 0 }]);
    }
  }, [condition, open]);

  const handleDropdownItemChange = (index, field, value) => {
    const newItems = [...dropdownItems];
    newItems[index][field] = value;
    setDropdownItems(newItems);
  };

  const addDropdownItem = () => {
    setDropdownItems([...dropdownItems, { title: "", value: 0 }]);
  };

  const removeDropdownItem = (index) => {
    const newItems = dropdownItems.filter((_, i) => i !== index);
    setDropdownItems(newItems);
  };

  const handleSave = () => {
    const roundToTwoDecimals = (num) => {
      const value = parseFloat(num) / 100;
      return Number(value.toFixed(4));
    };

    const newCondition = {
      id: condition?.id || uuidv4(),
      label: conditionTitle,
      type: conditionType,
      typology: typology,
      buildingType: buildingType,
      timestamp: condition ? parseFloat(condition.timestamp) : moment().unix(),
      yesPercentage:
        conditionType === VALUATION_CONDITION_TYPES.YES_NO
          ? roundToTwoDecimals(yesPercentage)
          : null,
      noPercentage:
        conditionType === VALUATION_CONDITION_TYPES.YES_NO
          ? roundToTwoDecimals(noPercentage)
          : null,
      dropdownItems:
        conditionType === VALUATION_CONDITION_TYPES.YES_NO
          ? null
          : dropdownItems.map((item) => ({
              label: item.label,
              value: roundToTwoDecimals(item.value),
            })),
    };

    if (condition?.id) {
      dispatch(editValuationMetaCondition(newCondition));
    } else {
      dispatch(addValuationMetaCondition(newCondition));
    }

    saveValuationCondition(newCondition).then(() => {
      toast.success(i18n("Condition saved successfully"));
    });

    onHandleComplete(newCondition);
    closeModal();
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        closeModal();
      }}
    >
      <DialogContent className="custom-conditions-modal sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{i18n("Edit custom condition")}</DialogTitle>
        </DialogHeader>
        <div className="custom-conditions-modal_content space-y-4 p-4 text-sm">
          <div className="flex flex-col gap-2">
            <label className="text-sm font-medium">
              {i18n("Condition title")}
            </label>
            <PrimaryInput
              className="primary-input-bordered"
              value={conditionTitle}
              onChange={(e) => setConditionTitle(e.target.value)}
            />
          </div>

          <div className="flex flex-col gap-2">
            <label className="text-sm font-medium">
              {i18n("Condition type")}
            </label>
            <select
              className="rounded-md border p-2"
              value={conditionType}
              onChange={(e) => setConditionType(e.target.value)}
            >
              <option value="yesno">{i18n("Yes/No")}</option>
              <option value="dropdown">{i18n("Dropdown")}</option>
            </select>
          </div>

          {conditionType === "yesno" && (
            <div className="flex gap-4">
              <div className="flex flex-1 flex-col gap-2">
                <label className="text-sm font-medium">
                  {i18n("Yes percentage")}
                </label>
                <PrimaryInput
                  className="primary-input-bordered"
                  type="number"
                  min="0"
                  max="100"
                  value={yesPercentage}
                  onChange={(e) => setYesPercentage(e.target.value)}
                />
              </div>
              <div className="flex flex-1 flex-col gap-2">
                <label className="text-sm font-medium">
                  {i18n("No percentage")}
                </label>
                <PrimaryInput
                  className="primary-input-bordered"
                  type="number"
                  min="0"
                  max="100"
                  value={noPercentage}
                  onChange={(e) => setNoPercentage(e.target.value)}
                />
              </div>
            </div>
          )}

          {conditionType === "dropdown" && (
            <div className="flex flex-col gap-4">
              {dropdownItems.map((item, index) => (
                <div key={index} className="flex items-end gap-4">
                  <div className="flex flex-1 flex-col gap-2">
                    <label className="text-sm font-medium">
                      {i18n("Option title")}
                    </label>
                    <PrimaryInput
                      className="primary-input-bordered"
                      value={item.label}
                      onChange={(e) =>
                        handleDropdownItemChange(index, "label", e.target.value)
                      }
                    />
                  </div>
                  <div className="flex flex-1 flex-col gap-2">
                    <label className="text-sm font-medium">
                      {i18n("Percentage")}
                    </label>
                    <PrimaryInput
                      className="primary-input-bordered"
                      type="number"
                      min="0"
                      max="100"
                      value={item.value}
                      onChange={(e) =>
                        handleDropdownItemChange(index, "value", e.target.value)
                      }
                    />
                  </div>
                  <Button
                    variant="secondary"
                    className="mb-0.5"
                    onClick={() => removeDropdownItem(index)}
                  >
                    {i18n("Remove")}
                  </Button>
                </div>
              ))}
              <Button variant="secondary" onClick={addDropdownItem}>
                {i18n("Add Option")}
              </Button>
            </div>
          )}
        </div>
        <DialogFooter>
          <div className="flex w-full justify-end">
            <Button variant="primary" onClick={handleSave}>
              {i18n("Save")}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default connect()(CustomConditionsModal);
