import downChevron from "assets/core/down_chevron.svg";
import Button from "components/ui/Button/Button";
import { i18n } from "i18n/localisation";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import MediaEmptyTile from "./MediaEmptyTile";

export const MediaGrid = memo(
  ({ images, setImages, tab, onFileSelect, isSavedGrid }) => {
    const [draggedItem, setDraggedItem] = useState(null);
    const [dropTarget, setDropTarget] = useState(null);
    const [showFullGrid, setShowFullGrid] = useState(false);
    const [hasOverflow, setHasOverflow] = useState(false);
    const gridRef = useRef(null);

    useEffect(() => {
      if (isSavedGrid) {
        const checkOverflow = () => {
          if (gridRef.current) {
            const hasVerticalOverflow = gridRef.current.scrollHeight > 390;
            setHasOverflow(hasVerticalOverflow);
          }
        };

        checkOverflow();
        window.addEventListener("resize", checkOverflow);
        return () => window.removeEventListener("resize", checkOverflow);
      }
    }, [images]);

    const handleDragStart = useCallback((e, index) => {
      setDraggedItem(index);
      e.dataTransfer.effectAllowed = "move";
      e.dataTransfer.setData("text/plain", index.toString());
    }, []);

    const handleDragEnter = (e, index) => {
      e.preventDefault();
      if (index !== draggedItem) {
        setDropTarget(index);
      }
    };

    const handleDragOver = (e, index) => {
      e.preventDefault();
      e.dataTransfer.dropEffect = "move";
      if (index !== draggedItem) {
        setDropTarget(index);
      }
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      setDropTarget(null);
    };

    const handleDrop = (e, dropIndex) => {
      e.preventDefault();

      if (draggedItem === null || draggedItem === dropIndex) {
        return;
      }

      const newImages = images[tab.key];
      const [draggedImage] = newImages.splice(draggedItem, 1);
      newImages.splice(dropIndex, 0, draggedImage);

      images = { [tab.key]: newImages, ...images };

      setDraggedItem(null);
      setDropTarget(null);
    };

    const handleDragEnd = () => {
      setDraggedItem(null);
      setDropTarget(null);
    };

    const handleDelete = (index) => {
      const newImages = images[tab.key].filter((_, i) => i !== index);
      images[tab.key] = newImages;
      setImages(images);
    };

    const getImageUrl = useCallback((image) => {
      const isVideo = Boolean(image.thumbnail || image.url?.thumbnail);

      if (isVideo) {
        return image.url?.thumbnail || image.thumbnail.localUrl;
      }

      return image.localUrl || image.url;
    }, []);

    return (
      <>
        <div
          ref={gridRef}
          className={`relative flex w-full flex-wrap justify-center gap-4 ${
            !showFullGrid && isSavedGrid ? "max-h-[388px] overflow-hidden" : ""
          }`}
        >
          {images?.[tab.key]?.map((image, index) => (
            <div
              key={image.id}
              className="relative"
              draggable
              onDragStart={(e) => handleDragStart(e, index)}
              onDragEnter={(e) => handleDragEnter(e, index)}
              onDragOver={(e) => handleDragOver(e, index)}
              onDragLeave={handleDragLeave}
              onDrop={(e) => handleDrop(e, index)}
              onDragEnd={handleDragEnd}
            >
              {dropTarget === index && draggedItem !== index && (
                <div className="absolute inset-0 z-10 rounded-lg border-2 border-blue-500" />
              )}
              <div
                className={`relative aspect-square h-[187px] w-[238px] cursor-grab rounded-lg border border-gray-200 bg-white 
              shadow-sm active:cursor-grabbing
              ${draggedItem === index ? "opacity-50" : "opacity-100"}
              ${draggedItem !== null && draggedItem !== index ? "hover:border-blue-500" : ""}`}
              >
                <img
                  src={getImageUrl(image)}
                  alt=""
                  className="h-full w-full rounded-lg object-cover"
                  draggable={false}
                />
                {Boolean(image?.thumbnail) && (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div className="rounded-full bg-black bg-opacity-50 p-2">
                      <svg
                        className="h-6 w-6 text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                        />
                      </svg>
                    </div>
                  </div>
                )}
                {!isSavedGrid && (
                  <div className="absolute right-2 top-2 flex gap-2">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(index);
                      }}
                      className="rounded-full bg-white p-1 shadow-md hover:bg-red-50"
                    >
                      <svg
                        className="h-4 w-4 text-red-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
          {!isSavedGrid && (
            <MediaEmptyTile
              tab={tab}
              size="small"
              onFileSelect={onFileSelect}
            />
          )}
        </div>
        {hasOverflow && isSavedGrid && (
          <div className="absolute bottom-[150px] right-[24px]">
            <Button
              variant="secondary"
              onClick={() => setShowFullGrid(!showFullGrid)}
            >
              <img
                src={downChevron}
                alt="toggle view"
                className={`mr-1 transition-transform duration-300 ${
                  showFullGrid ? "rotate-180" : ""
                }`}
              />
              <span>{i18n(showFullGrid ? "Show less" : "Show all")}</span>
            </Button>
          </div>
        )}
      </>
    );
  },
);
