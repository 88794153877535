export const VALUATION_CONDITION_TYPES = {
  YES_NO: "yesno",
  DROPDOWN: "dropdown",
};

export const DEFAULT_FLAT_CONDITIONS = [
  {
    id: "1",
    label: "Is it exterior facing?",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: 0.03,
    noPercentage: -0.05,
  },
  {
    id: "2",
    label: "Are there any sports facilities in the building?",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: 0.01,
    noPercentage: 0,
  },
  {
    id: "3",
    label: "Choose type of heating",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: 0.01,
    noPercentage: 0,
    yesLabel: "Central",
    noLabel: "Individual",
  },
  {
    id: "4",
    label: "Does it have alarm system?",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: 0.005,
    noPercentage: 0,
  },
  {
    id: "5",
    label: "Does it have a parking space?",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: 0.03,
    noPercentage: 0,
  },
  {
    id: "6",
    label: "Does the building have a concierge?",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: 0.05,
    noPercentage: 0,
  },
  {
    id: "7",
    label: "Is the building adapted for disabled people?",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: 0.05,
    noPercentage: -0.01,
  },
  {
    id: "8",
    label: "Rate the views",
    type: VALUATION_CONDITION_TYPES.DROPDOWN,
    dropdownItems: [
      {
        label: "1",
        value: -0.01,
      },
      {
        label: "2",
        value: -0.01,
      },
      {
        label: "3",
        value: -0.01,
      },
      {
        label: "4",
        value: -0.01,
      },
      {
        label: "5",
        value: 0,
      },
      {
        label: "6",
        value: 0,
      },
      {
        label: "7",
        value: 0,
      },
      {
        label: "8",
        value: 0,
      },
      {
        label: "9",
        value: 0.01,
      },
      {
        label: "10",
        value: 0.01,
      },
    ],
  },
  {
    id: "9",
    label: "Rate the brightness",
    type: VALUATION_CONDITION_TYPES.DROPDOWN,
    dropdownItems: [
      {
        label: "Dark",
        value: -0.02,
      },
      {
        label: "Average",
        value: 0,
      },
      {
        label: "Bright",
        value: 0.01,
      },
    ],
  },
  {
    id: "10",
    label: "Rate the ceiling height",
    type: VALUATION_CONDITION_TYPES.DROPDOWN,
    dropdownItems: [
      {
        label: "Low",
        value: -0.02,
      },
      {
        label: "Average",
        value: 0,
      },
      {
        label: "High",
        value: 0.005,
      },
    ],
  },
  {
    id: "11",
    label: "Rate the noise level",
    type: VALUATION_CONDITION_TYPES.DROPDOWN,
    dropdownItems: [
      {
        label: "Quiet",
        value: 0,
      },
      {
        label: "Only noisy with the window open",
        value: -0.03,
      },
      {
        label: "Noisy with the window closed",
        value: -0.05,
      },
    ],
  },
  {
    id: "12",
    label: "Energy rating",
    type: VALUATION_CONDITION_TYPES.DROPDOWN,
    dropdownItems: [
      {
        label: "A",
        value: 0.005,
      },
      {
        label: "B",
        value: 0.005,
      },
      {
        label: "C",
        value: 0,
      },
      {
        label: "D",
        value: 0,
      },
      {
        label: "E",
        value: 0,
      },
      {
        label: "F",
        value: -0.01,
      },
      {
        label: "G",
        value: -0.01,
      },
    ],
  },
  {
    id: "13",
    label: "Rate the building",
    type: VALUATION_CONDITION_TYPES.DROPDOWN,
    dropdownItems: [
      {
        label: "Below average",
        value: -0.04,
      },
      {
        label: "Average",
        value: 0,
      },
      {
        label: "Above average",
        value: 0.01,
      },
      {
        label: "Exceptional",
        value: 0.04,
      },
    ],
  },
  {
    id: "14",
    label: "Desired time to sell",
    type: VALUATION_CONDITION_TYPES.DROPDOWN,
    dropdownItems: [
      {
        label: "1 month or less",
        value: -0.1,
      },
      {
        label: "2-4 months",
        value: 0,
      },
      {
        label: "More than 4 months",
        value: 0.03,
      },
    ],
  },
];

export const DEFAULT_HOUSE_CONDITIONS = [
  {
    id: "15",
    label: "Does it have floor heating?",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: 0.01,
    noPercentage: 0,
  },
  {
    id: "16",
    label: "Does it have an exterior heated pool?",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: 0.01,
    noPercentage: 0,
  },
  {
    id: "17",
    label: "Does it have an indoor pool?",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: 0.02,
    noPercentage: 0,
  },
  {
    id: "18",
    label: "Does it have solar panels?",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: 0.01,
    noPercentage: 0,
  },
  {
    id: "19",
    label: "Does it have a cinema room?",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: 0.01,
    noPercentage: 0,
  },
  {
    id: "20",
    label: "Rate the views",
    type: VALUATION_CONDITION_TYPES.DROPDOWN,
    dropdownItems: [
      {
        label: "1",
        value: -0.01,
      },
      {
        label: "2",
        value: -0.01,
      },
      {
        label: "3",
        value: -0.01,
      },
      {
        label: "4",
        value: -0.01,
      },
      {
        label: "5",
        value: 0,
      },
      {
        label: "6",
        value: 0,
      },
      {
        label: "7",
        value: 0,
      },
      {
        label: "8",
        value: 0,
      },
      {
        label: "9",
        value: 0.01,
      },
      {
        label: "10",
        value: 0.01,
      },
    ],
  },
  {
    id: "12",
    label: "Energy rating",
    type: VALUATION_CONDITION_TYPES.DROPDOWN,
    dropdownItems: [
      {
        label: "A",
        value: 0.005,
      },
      {
        label: "B",
        value: 0.005,
      },
      {
        label: "C",
        value: 0,
      },
      {
        label: "D",
        value: 0,
      },
      {
        label: "E",
        value: 0,
      },
      {
        label: "F",
        value: -0.01,
      },
      {
        label: "G",
        value: -0.01,
      },
    ],
  },
  {
    id: "22",
    label: "Desired time to sell",
    type: VALUATION_CONDITION_TYPES.DROPDOWN,
    dropdownItems: [
      {
        label: "1 month or less",
        value: -0.1,
      },
      {
        label: "2-4 months",
        value: 0,
      },
      {
        label: "More than 4 months",
        value: 0.03,
      },
    ],
  },
];

export const DEFAULT_LAND_CONDITIONS = [
  {
    id: "23",
    label: "Does it have a building permit?",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: 0.1,
    noPercentage: 0,
  },
  {
    id: "24",
    label: "Is there an existing building on the land?",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: 0.05,
    noPercentage: 0,
  },
  {
    id: "25",
    label: "Any planned developments nearby?",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: 0.02,
    noPercentage: 0,
  },
  {
    id: "26",
    label: "Is there private security in the area?",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: 0.02,
    noPercentage: 0,
  },
  {
    id: "27",
    label: "Is it possible to build more than one unit?",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: 0.02,
    noPercentage: 0,
  },
  {
    id: "28",
    label: "Is it close to any public services?",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: 0,
    noPercentage: -0.02,
  },
  {
    id: "29",
    label: "Is it close to any public services?",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: 0,
    noPercentage: -0.02,
  },
  {
    id: "30",
    label: "Rate the views",
    type: VALUATION_CONDITION_TYPES.DROPDOWN,
    dropdownItems: [
      {
        label: "1",
        value: -0.01,
      },
      {
        label: "2",
        value: -0.01,
      },
      {
        label: "3",
        value: -0.01,
      },
      {
        label: "4",
        value: -0.01,
      },
      {
        label: "5",
        value: 0,
      },
      {
        label: "6",
        value: 0,
      },
      {
        label: "7",
        value: 0,
      },
      {
        label: "8",
        value: 0,
      },
      {
        label: "9",
        value: 0.01,
      },
      {
        label: "10",
        value: 0.01,
      },
    ],
  },
  {
    id: "31",
    label: "What is the inclination of the land?",
    type: VALUATION_CONDITION_TYPES.DROPDOWN,
    dropdownItems: [
      {
        label: "Uniform",
        value: 0,
      },
      {
        label: "Slightly sloped",
        value: -0.05,
      },
      {
        label: "Sloped",
        value: -0.1,
      },
      {
        label: "Steep",
        value: -0.15,
      },
      {
        label: "Irregular",
        value: -0.1,
      },
    ],
  },
  {
    id: "32",
    label: "Building typology",
    type: VALUATION_CONDITION_TYPES.DROPDOWN,
    dropdownItems: [
      {
        label: "Single-family dwelling",
        value: 0,
      },
      {
        label: "Multi-family dwelling",
        value: 0,
      },
    ],
  },
  {
    id: "33",
    label: "Rate the noise level",
    type: VALUATION_CONDITION_TYPES.DROPDOWN,
    dropdownItems: [
      {
        label: "Low",
        value: 0,
      },
      {
        label: "Average",
        value: 0,
      },
      {
        label: "Noisy",
        value: -0.01,
      },
    ],
  },
  {
    id: "34",
    label: "Rate the development potential",
    type: VALUATION_CONDITION_TYPES.DROPDOWN,
    dropdownItems: [
      {
        label: "1",
        value: -0.02,
      },
      {
        label: "2",
        value: -0.02,
      },
      {
        label: "3",
        value: -0.02,
      },
      {
        label: "4",
        value: -0.02,
      },
      {
        label: "5",
        value: 0,
      },
      {
        label: "6",
        value: 0,
      },
      {
        label: "7",
        value: 0,
      },
      {
        label: "8",
        value: 0,
      },
      {
        label: "9",
        value: 0.02,
      },
      {
        label: "10",
        value: 0.02,
      },
    ],
  },
  {
    id: "35",
    label: "Rate the area",
    type: VALUATION_CONDITION_TYPES.DROPDOWN,
    dropdownItems: [
      {
        label: "Low profile",
        value: 0,
      },
      {
        label: "Normal",
        value: 0,
      },
      {
        label: "High class",
        value: 0.01,
      },
      {
        label: "Prime/Exclusive area",
        value: 0.03,
      },
    ],
  },
  {
    id: "36",
    label: "Desired time to sell",
    type: VALUATION_CONDITION_TYPES.DROPDOWN,
    dropdownItems: [
      {
        label: "1 month or less",
        value: -0.1,
      },
      {
        label: "2-4 months",
        value: 0,
      },
      {
        label: "More than 4 months",
        value: 0.03,
      },
    ],
  },
];

export const DEFAULT_COMMERCIAL_CONDITIONS = [
  {
    id: "37",
    label: "Does it have direct access to the street?",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: 0,
    noPercentage: -0.01,
  },
  {
    id: "38",
    label: "Is it currently operating?",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: 0,
    noPercentage: 0,
  },
  {
    id: "39",
    label: "If yes, is it for sale with rental return?",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: 0,
    noPercentage: 0,
  },
  {
    id: "40",
    label: "Does the property have a business license?",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: 0,
    noPercentage: 0,
  },
  {
    id: "41",
    label: "Is the premises unfinished?",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: -0.1,
    noPercentage: 0,
  },
  {
    id: "42",
    label: "Are there parking facilities nearby?",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: 0,
    noPercentage: -0.02,
  },
  {
    id: "43",
    label: "Is it located in an active commercial area?",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: 0.01,
    noPercentage: 0,
  },
  {
    id: "44",
    label: "Is it close to public transport?",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: 0.01,
    noPercentage: -0.02,
  },
  {
    id: "45",
    label: "Is the property adapted for disabled people?",
    type: VALUATION_CONDITION_TYPES.YES_NO,
    yesPercentage: 0,
    noPercentage: -0.02,
  },
  {
    id: "46",
    label: "Rate the commercial potential of the area",
    type: VALUATION_CONDITION_TYPES.DROPDOWN,
    dropdownItems: [
      {
        label: "Low",
        value: -0.03,
      },
      {
        label: "Normal",
        value: 0,
      },
      {
        label: "High",
        value: 0.02,
      },
    ],
  },
  {
    id: "47",
    label: "Rate the ceiling height",
    type: VALUATION_CONDITION_TYPES.DROPDOWN,
    dropdownItems: [
      {
        label: "Low",
        value: -0.02,
      },
      {
        label: "Average",
        value: 0,
      },
      {
        label: "High",
        value: 0.005,
      },
    ],
  },
  {
    id: "48",
    label: "Energy rating",
    type: VALUATION_CONDITION_TYPES.DROPDOWN,
    dropdownItems: [
      {
        label: "A",
        value: 0.005,
      },
      {
        label: "B",
        value: 0.005,
      },
      {
        label: "C",
        value: 0,
      },
      {
        label: "D",
        value: 0,
      },
      {
        label: "E",
        value: 0,
      },
      {
        label: "F",
        value: -0.01,
      },
      {
        label: "G",
        value: -0.01,
      },
    ],
  },
  {
    id: "49",
    label: "Rate the area",
    type: VALUATION_CONDITION_TYPES.DROPDOWN,
    dropdownItems: [
      {
        label: "Low profile",
        value: 0,
      },
      {
        label: "Normal",
        value: 0,
      },
      {
        label: "High class",
        value: 0.01,
      },
      {
        label: "Prime/Exclusive area",
        value: 0.03,
      },
    ],
  },
  {
    id: "50",
    label: "Desired time to sell",
    type: VALUATION_CONDITION_TYPES.DROPDOWN,
    dropdownItems: [
      {
        label: "1 month or less",
        value: -0.1,
      },
      {
        label: "2-4 months",
        value: 0,
      },
      {
        label: "More than 4 months",
        value: 0.03,
      },
    ],
  },
];
