import * as turf from "@turf/turf";
import { MAP_INDEXES_CACHE_KEY } from "../actions/geoActions";
import { getAllMapObjectsByType } from "db/mapStore";
import store from "store";
import { getLocationsInRangeFromDb } from "./map";

/**
 * converts google maps path array to lat long polygon
 */
export function gMapPathToPolygon(paths) {
  let polygon = [];

  paths.forEach((coord) => {
    polygon.push({
      latitude: coord.lat(),
      longitude: coord.lng(),
    });
  });

  return polygon;
}

/**
 * converts google maps path array to lat long polygon
 */
export function geoJsonFeatureToPolygon(feature) {
  let polygon = [];
  let coordinates = feature.geometry.coordinates[0];

  if (feature.geometry.type === "MultiPolygon") {
    coordinates = getLargestPolygonFromGeoJsonFeature(feature);
  }

  coordinates.forEach((coord) => {
    polygon.push({
      latitude: parseFloat(coord[1]),
      longitude: parseFloat(coord[0]),
    });
  });

  return polygon;
}

/**
 * converts geojson coordinates to lat long polygon
 */
export function geoJsonCoordinatesToPolygon(coordinates) {
  let polygon = [];

  coordinates.forEach((coord) => {
    polygon.push({
      latitude: coord[1],
      longitude: coord[0],
    });
  });

  return polygon;
}

export function searchPolygonToGeoJson(polygon) {
  // editable polygons are those with no metadata as they are drawn
  let newPolygon = { ...polygon, editable: !polygon.metadata };
  newPolygon.geometry = {
    coordinates: [
      polygon.geometry.map((coords) => [
        parseFloat(coords.longitude),
        parseFloat(coords.latitude),
      ]),
    ],
  };
  return newPolygon;
}

export function gMapPolygonToGeojson(polygon) {
  let newPolygon = {};
  newPolygon.geometry = {
    type: "Polygon",
    coordinates: [
      polygon.geometry.coordinates[0].map((coords) => [
        parseFloat(coords[0]),
        parseFloat(coords[1]),
      ]),
    ],
  };
  newPolygon.type = "Feature";
  newPolygon.properties = {};
  return newPolygon;
}

/**
 * Ray casting algorithm to check if a coordinate is within a set of vertices
 */
export function isInPolygon(vertices, point) {
  let x = point.longitude,
    y = point.latitude;
  let inside = false;

  for (let i = 0, j = vertices.length - 1; i < vertices.length; j = i++) {
    let xi = vertices[i].longitude,
      yi = vertices[i].latitude;
    let xj = vertices[j].longitude,
      yj = vertices[j].latitude;

    let intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
}

/**
 * checks whether polygonA at any moment intersects with polygonB with
 * one of its vertices
 */
export function polygonAIntersectWithPolygonB(polygonA, polygonB) {
  // Check each vertex of the polygon
  for (const vertex of polygonA) {
    if (isInPolygon(polygonB, vertex)) {
      return true;
    }
  }

  return false;
}

/**
 * Gets the bounding rectangle of a polygon
 * @param {Polygon} vertices
 * @returns
 */
export function getBoundingRectangleFromPolygon(vertices) {
  let minLat = vertices[0].latitude,
    maxLat = vertices[0].latitude;
  let minLong = vertices[0].longitude,
    maxLong = vertices[0].longitude;

  for (let i = 1; i < vertices.length; i++) {
    let vertex = vertices[i];

    if (vertex.latitude < minLat) {
      minLat = vertex.latitude;
    } else if (vertex.latitude > maxLat) {
      maxLat = vertex.latitude;
    }

    if (vertex.longitude < minLong) {
      minLong = vertex.longitude;
    } else if (vertex.longitude > maxLong) {
      maxLong = vertex.longitude;
    }
  }

  return {
    minLat: minLat,
    maxLat: maxLat,
    minLong: minLong,
    maxLong: maxLong,
    coords: [
      {
        latitude: minLat,
        longitude: minLong,
      },
      {
        latitude: minLat,
        longitude: maxLong,
      },
      {
        latitude: maxLat,
        longitude: maxLong,
      },
      {
        latitude: maxLat,
        longitude: minLong,
      },
    ],
  };
}

// generates a lat lng square from mapbox bounds
export function generateSquareFromMapBounds(mapBounds) {
  let minLat = mapBounds[0][1];
  let maxLat = mapBounds[1][1];
  let minLng = mapBounds[0][0];
  let maxLng = mapBounds[1][0];

  return [
    {
      latitude: minLat,
      longitude: minLng,
    },
    {
      latitude: minLat,
      longitude: maxLng,
    },
    {
      latitude: maxLat,
      longitude: maxLng,
    },
    {
      latitude: maxLat,
      longitude: minLng,
    },
  ];
}

// Function to check if a point is inside a square
export function isPointInsideSquare(point, square) {
  const x = point.longitude,
    y = point.latitude;
  const sx1 = square[0].longitude,
    sy1 = square[0].latitude; // Top-left corner of the square
  const sx2 = square[2].longitude,
    sy2 = square[2].latitude; // Bottom-right corner of the square
  return x >= sx1 && x <= sx2 && y >= sy1 && y <= sy2;
}

// Function to check if any of the polygon's vertices are within the square's bounds
export function polygonIntersectsWithinSquare(polygon, square) {
  // Check each vertex of the polygon
  for (const vertex of polygon) {
    if (isPointInsideSquare(vertex, square)) {
      return true;
    }
  }

  return false;
}

//converts a rectangle object as defined in getBoundingRectangleFromPolygon to an array based rectangle
export function convertRectangleBoundsToIterable(rectangle) {
  return [
    [rectangle.minLong, rectangle.minLat],
    [rectangle.maxLong, rectangle.minLat],
    [rectangle.minLong, rectangle.maxLat],
    [rectangle.maxLong, rectangle.maxLat],
  ];
}

// gets the center point of a rectangle
export function getRectangleCenter(rectangle) {
  let sumLat = 0;
  let sumLng = 0;
  let rectangleIterable = convertRectangleBoundsToIterable(rectangle);

  // Assuming 'rectangle' is an array of [latitude, longitude] coordinates
  for (const corner of rectangleIterable) {
    sumLat += corner[0];
    sumLng += corner[1];
  }

  const centerLat = sumLat / rectangleIterable.length;
  const centerLng = sumLng / rectangleIterable.length;

  return [centerLat, centerLng];
}

// Check if a polygon is within a square defined by two diagonal points
export function isPolygonInSquare(polygon, square) {
  // Extract the square's coordinates
  const pointA = [parseFloat(square.min_lng), parseFloat(square.min_lat)];
  const pointB = [parseFloat(square.max_lng), parseFloat(square.max_lat)];

  // Check each vertex of the polygon
  for (const vertex of polygon) {
    if (
      vertex.longitude < pointA[0] ||
      vertex.longitude > pointB[0] ||
      vertex.latitude < pointA[1] ||
      vertex.latitude > pointB[1]
    ) {
      // If any vertex is outside the square, the polygon is not completely within
      return false;
    }
  }

  // All polygon vertices are inside the square
  return true;
}

export function propertyToPoint(property) {
  return {
    latitude: property.latitude,
    longitude: property.longitude,
  };
}

// convert a polygon to a rectangle
export function polygonToRectangle(coordinates, padding = 0) {
  let maxLat = -90;
  let minLat = 90;
  let maxLng = -180;
  let minLng = 180;

  for (const coord of coordinates) {
    maxLat = Math.max(maxLat, coord.latitude + padding);
    minLat = Math.min(minLat, coord.latitude - padding);
    maxLng = Math.max(maxLng, coord.longitude + padding);
    minLng = Math.min(minLng, coord.longitude - padding);
  }

  // Create a rectangle using the maximum and minimum values
  return [
    { latitude: maxLat, longitude: minLng }, // Top left
    { latitude: maxLat, longitude: maxLng }, // Top right
    { latitude: minLat, longitude: maxLng }, // Bottom right
    { latitude: minLat, longitude: minLng }, // Bottom left
    { latitude: maxLat, longitude: minLng }, // Close the rectangle
  ];
}

// serializes a google maps polygon so it can be used in the redux store
export function serializePolygon(polygon) {
  return {
    irealtyId: polygon.irealtyId,
    geometry: gMapPathToPolygon(polygon.getPath().getArray()),
    metadata: polygon.metadata,
    colourCode: polygon.colourCode,
  };
}

/**
 * Get the polygon with the largest area from a GeoJSON MultiPolygon feature
 * @param {object} feature - The GeoJSON feature
 * @returns {array} - The coordinates of the largest polygon
 */
export function getLargestPolygonFromGeoJsonFeature(feature) {
  // Initialize variables to track the largest polygon
  let largestPolygon = feature.geometry.coordinates[0][0];
  let largestArea = 0;
  // Iterate through the polygons in the MultiPolygon
  for (const polygonCoordinates of feature.geometry.coordinates) {
    const polygon = turf.polygon(polygonCoordinates);
    const area = turf.area(polygon);
    // Check if this polygon has a larger area than the previous largest
    if (area > largestArea) {
      largestArea = area;
      largestPolygon = polygon.geometry.coordinates[0];
    }
  }
  return largestPolygon;
}

// calculate the area of a polygon in square meters
export function calculatePolygonArea(polygon) {
  const coordinates = polygon.getPath().getArray();
  const area = window.google.maps.geometry.spherical.computeArea(coordinates);
  return area;
}

/**
 * Check if a polygon is larger than 10 square miles
 * @param {google.maps.Polygon} polygon - The polygon to check
 * @returns {boolean} - True if the polygon is larger than 10 square miles, false otherwise
 */
export function polygonIsLargerThanMilesSquared(polygon, milesSquared) {
  const area = calculatePolygonArea(polygon);
  const squareMiles = area * 0.000000386102159;
  return squareMiles > milesSquared;
}

// convert google polygon to geometry coordinates
export function googlePolygonToGeometryCoordinates(polygon) {
  return polygon
    .getPath()
    .getArray()
    .map((coord) => {
      return [coord.lng(), coord.lat()];
    });
}

/**
 * Get the district that contains the given point or the nearest one if not contained
 */
export async function getNearestNeighbourGeoboundary(
  lat,
  lng,
  geoboundary,
  prioritise = "district",
) {
  let boundaries = await getAllMapObjectsByType("es", geoboundary);

  // sort by the smallest subdivision so they get caught first
  boundaries.sort((a, b) => (a.type == prioritise ? -1 : 1));

  let point = turf.point([parseFloat(lng), parseFloat(lat)]);
  let nearestBoundary = null;
  let shortestDistance = Infinity;

  for (let boundary of boundaries) {
    if (!boundary.geometry) continue;
    const normalized = normalizeGeoJsonCoordsToFloat(boundary.geometry);

    if (normalized.type === "MultiPolygon") {
      // Iterate through each polygon in the MultiPolygon
      for (let polygonCoords of normalized.coordinates) {
        let polygon = turf.polygon(polygonCoords);
        // First check if point is contained
        if (turf.booleanContains(polygon, point)) {
          return boundary;
        }
        // If not contained, calculate distance to nearest point on polygon
        const polygonPoints = turf.explode(polygon);
        const nearest = turf.nearestPoint(point, polygonPoints);
        const distance = turf.distance(point, nearest);

        if (distance < shortestDistance && distance < 1) {
          shortestDistance = distance;
          nearestBoundary = boundary;
        }
      }
    } else {
      let polygon = turf.polygon(normalized.coordinates);
      // First check if point is contained
      if (turf.booleanContains(polygon, point)) {
        return boundary;
      }
      // If not contained, calculate distance to nearest point on polygon
      const polygonPoints = turf.explode(polygon);
      const nearest = turf.nearestPoint(point, polygonPoints);
      const distance = turf.distance(point, nearest);

      if (distance < shortestDistance && distance < 1) {
        shortestDistance = distance;
        nearestBoundary = boundary;
      }
    }
  }

  return nearestBoundary;
}

// gets a drawn polygon by id
export function getPolygonById(polygonId) {
  return store
    .getState()
    .polygon.drawnPolygons.find((polygon) => polygon.irealtyId === polygonId);
}

/**
 * Given two locations, returns all municipalities that are within the circle
 */
export async function getAllMunicipalitiesBetweenAandB(locationA, locationB) {
  const locationANormalized = normalizeGeoJsonCoordsToFloat(locationA.geometry);
  const locationBNormalized = normalizeGeoJsonCoordsToFloat(locationB.geometry);

  let polygonA = null;

  if (locationANormalized.type === "MultiPolygon") {
    polygonA = turf.multiPolygon(locationANormalized.coordinates);
  } else {
    polygonA = turf.polygon(locationANormalized.coordinates);
  }

  let polygonB = null;

  if (locationBNormalized.type === "MultiPolygon") {
    polygonB = turf.multiPolygon(locationBNormalized.coordinates);
  } else {
    polygonB = turf.polygon(locationBNormalized.coordinates);
  }

  // Calculate the centroid of each polygon
  const centroid1 = turf.centroid(polygonA);
  const centroid2 = turf.centroid(polygonB);

  // Calculate the midpoint between the two centroids
  const midpoint = turf.midpoint(centroid1, centroid2);

  // Calculate the distance between the centroids
  const distance = turf.distance(centroid1, midpoint);

  // Create a circle using the midpoint as the center and the distance as the radius
  const circle = turf.circle(midpoint, distance, { units: "kilometers" });

  // Get the bounding box of the circle
  const circleBounds = turf.bbox(circle);

  const boundsToScan = {
    min_lat: circleBounds[1],
    min_lng: circleBounds[0],
    max_lat: circleBounds[3],
    max_lng: circleBounds[2],
  };

  // get locations in range from the database
  const municipalities = await getLocationsInRangeFromDb(boundsToScan);

  // Find all municipalities that intersect with the circle
  const intersectingMunicipalities = municipalities.filter((municipality) => {
    let municipalityPolygon = null;

    if (municipality.geometry.type === "MultiPolygon") {
      municipalityPolygon = turf.multiPolygon(
        municipality.geometry.coordinates,
      );
    } else {
      municipalityPolygon = turf.polygon(municipality.geometry.coordinates);
    }

    try {
      const contains = turf.booleanContains(circle, municipalityPolygon);
      return contains;
    } catch (error) {
      console.error("Error processing polygon:", error);
      return false;
    }
  });

  return intersectingMunicipalities.concat([locationA, locationB]);
}

// normalize geojson coordinates to float
export function normalizeGeoJsonCoordsToFloat(geoJson) {
  if (geoJson.type === "Polygon") {
    geoJson.coordinates = geoJson.coordinates.map((ring) =>
      ring.map((coord) => [parseFloat(coord[0]), parseFloat(coord[1])]),
    );
  } else if (geoJson.type === "MultiPolygon") {
    geoJson.coordinates = geoJson.coordinates.map((polygon) =>
      polygon.map((ring) =>
        ring.map((coord) => [parseFloat(coord[0]), parseFloat(coord[1])]),
      ),
    );
  }
  return geoJson;
}
