import { setDescription } from "actions/myPropertiesActions";
import { savePropertyRequest } from "api/crm";
import { i18n } from "i18n/localisation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import EmptySection from "./EmptySection";
import SectionWrapper from "./SectionWrapper";
import { SECTION_WRAPPER_CONTENT } from "./uploadPropertyPageConfig";

const InputDescription = ({ value, setValue }) => {
  return (
    <div>
      <label className="text-[14px] text-[#717171]">
        {i18n(
          "Add a description including details on features, condition, amenities, surroundings etc.",
        )}
      </label>
      <textarea
        id="description"
        className="mt-[16px] min-h-[200px] w-full rounded-lg border-[1px] border-[#BABABA] p-[10px]"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
};

const DescriptionText = ({ description }) => {
  return (
    <div className="h-auto min-h-[200] w-full">
      <p className="text-[16px] text-[#717171]">{description}</p>
    </div>
  );
};

const Description = () => {
  const dispatch = useDispatch();
  const description = useSelector((state) => state.myProperties.description);

  const { propertyId } = useParams();
  const [textInInputDescription, setTextInInputDescription] = useState("");
  const [content, setContent] = useState(SECTION_WRAPPER_CONTENT.EMPTY_CONTENT);

  useEffect(() => {
    if (description) {
      setTextInInputDescription(description);
      setContent(SECTION_WRAPPER_CONTENT.SAVED_CONTENT);
    }
  }, [description]);

  const handleSaveDescription = async () => {
    dispatch(setDescription(textInInputDescription));
    try {
      await savePropertyRequest({
        property_id: propertyId,
        update_fields: { description: textInInputDescription },
      });
      toast.success(i18n("Description has been saved"));
    } catch (e) {
      console.log(e);
      toast.error(i18n("Error saving description"));
    }

    setContent(
      textInInputDescription
        ? SECTION_WRAPPER_CONTENT.SAVED_CONTENT
        : SECTION_WRAPPER_CONTENT.EMPTY_CONTENT,
    );
  };

  const handleCancel = () => {
    setTextInInputDescription(description);
    setContent(
      description
        ? SECTION_WRAPPER_CONTENT.SAVED_CONTENT
        : SECTION_WRAPPER_CONTENT.EMPTY_CONTENT,
    );
  };

  const handleEdit = () => {
    setContent(SECTION_WRAPPER_CONTENT.INPUT_CONTENT);
  };

  return (
    <SectionWrapper
      title={"Description"}
      onSave={handleSaveDescription}
      onEdit={handleEdit}
      onCancel={handleCancel}
      content={content}
      isCompleted={content === SECTION_WRAPPER_CONTENT.SAVED_CONTENT}
    >
      <div className="p-6">
        {content === SECTION_WRAPPER_CONTENT.EMPTY_CONTENT && (
          <EmptySection
            setContent={handleEdit}
            buttonLabel={"Add property description"}
            helperText={
              "Add a description including details on features, condition, amenities, surroundings etc."
            }
          />
        )}
        {content === SECTION_WRAPPER_CONTENT.INPUT_CONTENT && (
          <InputDescription
            value={textInInputDescription}
            setValue={setTextInInputDescription}
          />
        )}
        {content === SECTION_WRAPPER_CONTENT.SAVED_CONTENT && (
          <DescriptionText description={textInInputDescription} />
        )}
      </div>
    </SectionWrapper>
  );
};

export default Description;
