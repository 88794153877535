import { getLang, i18n } from "i18n/localisation";
import {
  fetchCachedProperties,
  getLocalePropertyPrice,
  getPropertyDistrictByName,
  getPropertyGeoboundary,
  isPropertyFromDataSource,
  // getAverageTimeOnMarket,
  // getPropertyTimeOnMarket
} from "./properties";
import { getAllMapObjectsByType } from "db/mapStore";
import moment from "moment";
import { fetchAnalyticsRequest } from "api/analytics";

// Extract price calculations into separate utility functions
const calculatePricePerSqm = (price, size) => {
  if (!price || !size || isNaN(price) || isNaN(size) || price <= 0 || size <= 0)
    return null;
  return parseFloat(price) / parseFloat(size);
};

const formatPriceDifference = (property, currentPrice, averagePrice) => {
  const difference = currentPrice - averagePrice;
  const prefix = difference > 0 ? "+" : "";
  return {
    value: `${prefix}${getLocalePropertyPrice(property, Math.round(difference))}`,
    positive: difference > 0,
    negative: difference < 0,
  };
};

const formatPriceDifferencePercentage = (currentPrice, averagePrice) => {
  const percentageDiff = ((currentPrice - averagePrice) / averagePrice) * 100;
  const prefix = percentageDiff > 0 ? "+" : "";
  return {
    value: `${prefix}${Math.round(percentageDiff)}%`,
    positive: percentageDiff > 0,
    negative: percentageDiff < 0,
  };
};

// Extract property filtering logic
const filterPropertiesByTypology = (properties, referenceProperty) => {
  return properties.filter(
    (p) =>
      p.detailedType.typology === referenceProperty.detailedType.typology &&
      p.detailedType.subTypology ===
        referenceProperty.detailedType.subTypology &&
      p.buildingType === referenceProperty.buildingType,
  );
};

const calculateStatistics = (
  property,
  currentProperty,
  mean,
  averagePrice,
  averageRentalYield,
  averageTimeOnMarket,
) => {
  if (property.saleType === "rent") {
    return calculateRentalStatistics(property, currentProperty, averagePrice);
  }

  return calculateSaleStatistics(
    property,
    currentProperty,
    mean,
    averagePrice,
    averageRentalYield,
    averageTimeOnMarket,
  );
};

const calculateSaleStatistics = (
  property,
  currentProperty,
  mean,
  averagePrice,
  averageRentalYield,
  averageTimeOnMarket,
) => {
  const statistics = [
    {
      label: i18n("Average price/m²:"),
      value: getLocalePropertyPrice(property, Math.round(mean)),
    },
    {
      label: i18n("Property price/m²:"),
      value: getLocalePropertyPrice(
        property,
        Math.round(parseFloat(property.price) / parseFloat(property.size)),
      ),
    },
    {
      ...formatPriceDifference(
        property,
        parseFloat(property.price) / parseFloat(property.size),
        mean,
      ),
      label: i18n("Price/m² difference:"),
    },
    {
      ...formatPriceDifferencePercentage(
        parseFloat(property.price) / parseFloat(property.size),
        mean,
      ),
      label: i18n("Price/m² difference %:"),
    },
    {
      label: i18n("Average price:"),
      value: getLocalePropertyPrice(property, Math.round(averagePrice)),
    },
    {
      ...formatPriceDifference(
        property,
        currentProperty.originalPrice,
        averagePrice,
      ),
      label: i18n("Price difference:"),
    },
  ];

  if (averageRentalYield) {
    statistics.push({
      label: i18n("Average long term rental yield"),
      value: `${getLocalePropertyPrice(property, Math.round(averageRentalYield))} / ${i18n("month")}`,
    });
  }

  statistics.push({
    label: i18n("Average time on market"),
    value: `${averageTimeOnMarket} ${i18n("days")}`,
  });

  return statistics;
};

const calculateRentalStatistics = (property, currentProperty, averagePrice) => {
  const suffix = property.rental_isShortTerm
    ? i18n(" / day")
    : i18n(" / month");

  return [
    {
      label: i18n("Average price:"),
      value:
        getLocalePropertyPrice(property, Math.round(averagePrice)) + suffix,
    },
    {
      label: i18n("Property price:"),
      value: getLocalePropertyPrice(property, property.price) + suffix,
    },
    {
      ...formatPriceDifference(
        property,
        currentProperty.originalPrice,
        averagePrice,
      ),
      label: i18n("Price difference:"),
    },
    {
      ...formatPriceDifferencePercentage(
        currentProperty.originalPrice,
        averagePrice,
      ),
      label: i18n("Price difference %:"),
    },
  ];
};

const calculateBins = (prices, currentProperty, property, binCount = 16) => {
  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);
  const binSize = (maxPrice - minPrice) / binCount;

  return Array.from({ length: binCount }, (_, i) => {
    const lowerBound = minPrice + i * binSize;
    const upperBound = i === binCount - 1 ? maxPrice : lowerBound + binSize;

    const count = prices.filter((p) =>
      i === 0
        ? p >= lowerBound && p <= upperBound
        : i === binCount - 1
          ? p >= lowerBound && p <= upperBound
          : p >= lowerBound && p < upperBound,
    ).length;

    const isCurrentPropertyBin =
      property.saleType === "rent"
        ? currentProperty.originalPrice >= lowerBound &&
          currentProperty.originalPrice < upperBound
        : currentProperty.price >= lowerBound &&
          currentProperty.price <= upperBound;

    return {
      label: `${getLocalePropertyPrice(property, Math.round(lowerBound))} - ${getLocalePropertyPrice(property, Math.round(upperBound))}`,
      count,
      isCurrentPropertyBin,
    };
  });
};

const MIN_PROPERTIES_FOR_STATISTICS = 50;

// gets the properties for processing (we pass in the property and the district/zone/municipality)
// the optimal area will be returned along with the properties
// thanks to LRUCache, we cache the properties so this function can be called multiple times
export async function getPropertiesForProcessing(
  property,
  propertyDistrict,
  propertyZone,
  propertyMunicipality,
) {
  if (!propertyDistrict && !propertyZone)
    return {
      properties: null,
      rentalProperties: null,
      resolvedDistrict: null,
    };

  // Fetch and filter properties
  const [
    districtProperties,
    zoneProperties,
    municipalityProperties,
    districtRentalProperties,
    zoneRentalProperties,
    municipalityRentalProperties,
  ] = await Promise.all([
    fetchCachedProperties(
      propertyDistrict?.id ?? propertyZone?.id,
      property.saleType ?? "sale",
    ),
    fetchCachedProperties(propertyZone?.id, property.saleType ?? "sale"),
    fetchCachedProperties(
      propertyMunicipality?.id,
      property.saleType ?? "sale",
    ),
    fetchCachedProperties(propertyDistrict?.id ?? propertyZone?.id, "rent"),
    fetchCachedProperties(propertyZone?.id, "rent"),
    fetchCachedProperties(propertyMunicipality?.id, "rent"),
  ]);

  const filteredDistrictProperties = filterPropertiesByTypology(
    districtProperties,
    property,
  );
  const filteredZoneProperties = filterPropertiesByTypology(
    zoneProperties,
    property,
  );
  const filteredMunicipalityProperties = filterPropertiesByTypology(
    municipalityProperties,
    property,
  );
  const filteredDistrictRentalProperties = filterPropertiesByTypology(
    districtRentalProperties,
    property,
  );
  const filteredZoneRentalProperties = filterPropertiesByTypology(
    zoneRentalProperties,
    property,
  );
  const filteredMunicipalityRentalProperties = filterPropertiesByTypology(
    municipalityRentalProperties,
    property,
  );

  // Determine which properties to use based on count
  const useDistrict =
    filteredDistrictProperties.length >= MIN_PROPERTIES_FOR_STATISTICS;
  let properties = [];
  let rentalProperties = [];
  let resolvedDistrict = propertyDistrict;

  if (useDistrict) {
    properties = filteredDistrictProperties;
    rentalProperties = filteredDistrictRentalProperties;
  } else if (filteredZoneProperties.length >= MIN_PROPERTIES_FOR_STATISTICS) {
    properties = filteredZoneProperties;
    rentalProperties = filteredZoneRentalProperties;
    resolvedDistrict = propertyZone;
  } else if (
    filteredMunicipalityProperties.length >= MIN_PROPERTIES_FOR_STATISTICS
  ) {
    properties = filteredMunicipalityProperties;
    rentalProperties = filteredMunicipalityRentalProperties;
    resolvedDistrict = propertyMunicipality;
  }

  if (properties.length < MIN_PROPERTIES_FOR_STATISTICS)
    return {
      properties: null,
      rentalProperties: null,
      resolvedDistrict: null,
    };

  return {
    properties,
    rentalProperties,
    resolvedDistrict,
  };
}

// calculates the prices per sqm for the properties
const calculatePricesPerSqm = (properties, property) => {
  return isPropertyFromDataSource(property, "airbnb")
    ? properties.map((p) => ({
        id: p.id,
        price: parseFloat(p.price),
        originalPrice: parseFloat(p.price),
        size: 1,
        isCurrentProperty: property.id === p.id,
      }))
    : properties
        .map((p) => ({
          id: p.id,
          price: calculatePricePerSqm(p.price, p.size),
          originalPrice: parseFloat(p.price),
          size: parseFloat(p.size),
          isCurrentProperty: property.id === p.id,
        }))
        .filter((p) => p.price !== null);
};

// calculates the average rental yield for the properties
const calculateAverageRentalYield = (rentalProperties) => {
  return rentalProperties.length >= 5
    ? rentalProperties.reduce((a, b) => a + parseFloat(b.price), 0) /
        rentalProperties.length
    : null;
};

// calculates the price statistics for the properties
const calculatePriceStatistics = (pricesPerSqm, property) => {
  const prices =
    property.saleType === "rent"
      ? pricesPerSqm.map((p) => p.originalPrice)
      : pricesPerSqm.map((p) => p.price);

  const mean = prices.reduce((a, b) => a + b, 0) / prices.length;
  const averagePrice =
    pricesPerSqm.reduce((a, b) => a + b.originalPrice, 0) / pricesPerSqm.length;

  return { mean, averagePrice };
};

// calculates the average time on market for the properties
const calculateAverageTimeOnMarket = (properties) => {
  const timeOnMarketData = properties.map((p) => {
    const importTime = moment.unix(parseFloat(p.initialImportTime));
    return moment().diff(moment(importTime), "days");
  });
  return Math.round(
    timeOnMarketData.reduce((a, b) => a + b, 0) / timeOnMarketData.length,
  );
};

// gets the stats data for the property
export async function getStatsData(property, properties, rentalProperties) {
  if (!properties) return null;

  const pricesPerSqm = calculatePricesPerSqm(properties, property);
  const currentProperty = pricesPerSqm.find((p) => p.isCurrentProperty);
  const { mean, averagePrice } = calculatePriceStatistics(
    pricesPerSqm,
    property,
  );
  const averageRentalYield = calculateAverageRentalYield(rentalProperties);
  const averageTimeOnMarket = calculateAverageTimeOnMarket(properties);

  return calculateStatistics(
    property,
    currentProperty,
    mean,
    averagePrice,
    averageRentalYield,
    averageTimeOnMarket,
  );
}

// gets the price distribution chart data for the properties compatible with chart.js
export async function getPricePerSqmDistributionChartData(
  property,
  properties,
) {
  if (!properties) return null;

  const pricesPerSqm = calculatePricesPerSqm(properties, property);
  const currentProperty = pricesPerSqm.find((p) => p.isCurrentProperty);
  const prices =
    property.saleType === "rent"
      ? pricesPerSqm.map((p) => p.originalPrice)
      : pricesPerSqm.map((p) => p.price);

  const bins = calculateBins(prices, currentProperty, property);

  return {
    chartData: {
      labels: bins.map((bin) => bin.label),
      datasets: [
        {
          label: i18n("Number of Properties"),
          data: bins.map((bin) => bin.count),
          backgroundColor: bins.map((bin) =>
            bin.isCurrentPropertyBin ? "#ee3943" : "#8884",
          ),
          borderColor: bins.map((bin) =>
            bin.isCurrentPropertyBin ? "#ee3943" : "#888",
          ),
          borderWidth: 1,
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: true,
          position: "top",
          labels: {
            generateLabels: () => [
              {
                text: i18n("Other Properties"),
                fillStyle: "#8884",
                strokeStyle: "#888",
                lineWidth: 1,
              },
              {
                text: i18n("Current Property"),
                fillStyle: "#ee3943",
                strokeStyle: "#ee3943",
                lineWidth: 1,
              },
            ],
          },
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: i18n("Price Range"),
            font: { size: 14 },
          },
        },
        y: {
          ticks: { stepSize: 1 },
          title: {
            display: true,
            text: i18n("Number of Properties"),
            font: { size: 14 },
          },
        },
      },
    },
  };
}

export async function getPriceDistributionChartData(property, properties) {
  if (!properties?.length) return null;

  // Filter properties by same typology
  const filteredProperties = filterPropertiesByTypology(properties, property);

  // Get all prices
  const prices = filteredProperties.map((p) => parseFloat(p.price));
  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);
  const priceRange = maxPrice - minPrice;
  const binSize = priceRange / 16;

  // Initialize bins
  const bins = Array(16).fill(0);

  // Current property's price
  const currentPropertyPrice = parseFloat(property.price);
  const currentPropertyBin = Math.min(
    Math.floor((currentPropertyPrice - minPrice) / binSize),
    15,
  );

  // Count properties in each bin
  filteredProperties.forEach((p) => {
    const price = parseFloat(p.price);
    const binIndex = Math.min(Math.floor((price - minPrice) / binSize), 15);
    bins[binIndex]++;
  });

  // Create price range labels
  const labels = Array(16)
    .fill()
    .map((_, i) => {
      const start = Math.round(minPrice + i * binSize);
      const end = Math.round(minPrice + (i + 1) * binSize);
      return `${getLocalePropertyPrice(property, start)} - ${getLocalePropertyPrice(property, end)}`;
    });

  // Create dataset with highlighted bin for current property
  const backgroundColor = Array(16).fill("#8884");
  backgroundColor[currentPropertyBin] = "#ee3943";

  return {
    chartData: {
      labels,
      datasets: [
        {
          data: bins,
          backgroundColor,
          borderColor: backgroundColor.map((c) =>
            c === "#8884" ? "#888" : "#ee3943",
          ),
          borderWidth: 1,
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: true,
          position: "top",
          labels: {
            generateLabels: () => [
              {
                text: i18n("Other Properties"),
                fillStyle: "#8884",
                strokeStyle: "#888",
                lineWidth: 1,
              },
              {
                text: i18n("Current Property"),
                fillStyle: "#ee3943",
                strokeStyle: "#ee3943",
                lineWidth: 1,
              },
            ],
          },
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: i18n("Price Range"),
            font: { size: 14 },
          },
        },
        y: {
          ticks: { stepSize: 1 },
          title: {
            display: true,
            text: i18n("Number of Properties"),
            font: { size: 14 },
          },
        },
      },
    },
  };
}

export async function getAverageTimeOnMarketChartData(property, properties) {
  if (!properties?.length) return null;

  // Filter properties by same typology
  const filteredProperties = filterPropertiesByTypology(properties, property);

  // Calculate price ranges
  const prices = filteredProperties.map((p) => parseFloat(p.price));
  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);
  const priceRange = maxPrice - minPrice;
  const binSize = priceRange / 16;

  // Initialize bins with arrays to store time on market values
  const timeBins = Array(16)
    .fill()
    .map(() => []);

  // Current property's price and time on market
  const currentPropertyPrice = parseFloat(property.price);

  // Sort properties into price bins and calculate their time on market
  filteredProperties.forEach((p) => {
    const price = parseFloat(p.price);
    const binIndex = Math.min(Math.floor((price - minPrice) / binSize), 15);

    const timeOnMarket = moment().diff(
      moment.unix(parseFloat(p.initialImportTime)),
      "days",
    );

    timeBins[binIndex].push(timeOnMarket);
  });

  // Calculate average time for each bin
  const averageTimes = timeBins.map((bin) =>
    bin.length ? Math.round(bin.reduce((a, b) => a + b, 0) / bin.length) : 0,
  );

  // Find which bin contains current property
  const currentPropertyBin = Math.min(
    Math.floor((currentPropertyPrice - minPrice) / binSize),
    15,
  );

  // Create price range labels
  const labels = Array(16)
    .fill()
    .map((_, i) => {
      const start = Math.round(minPrice + i * binSize);
      const end = Math.round(minPrice + (i + 1) * binSize);
      return `${getLocalePropertyPrice(property, start)} - ${getLocalePropertyPrice(property, end)}`;
    });

  return {
    chartData: {
      labels,
      datasets: [
        {
          data: averageTimes,
          backgroundColor: averageTimes.map((_, i) =>
            i === currentPropertyBin ? "#ee3943" : "#8884",
          ),
          borderColor: averageTimes.map((_, i) =>
            i === currentPropertyBin ? "#ee3943" : "#888",
          ),
          borderWidth: 1,
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: true,
          position: "top",
          labels: {
            generateLabels: () => [
              {
                text: i18n("Other Price Ranges"),
                fillStyle: "#8884",
                strokeStyle: "#888",
                lineWidth: 1,
              },
              {
                text: i18n("Current Property's Price Range"),
                fillStyle: "#ee3943",
                strokeStyle: "#ee3943",
                lineWidth: 1,
              },
            ],
          },
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: i18n("Price Range"),
            font: { size: 14 },
          },
        },
        y: {
          ticks: { stepSize: 1 },
          title: {
            display: true,
            text: i18n("Average Days on Market"),
            font: { size: 14 },
          },
        },
      },
    },
  };
}

// Historic data function remains largely the same
export async function getAveragePricePerSqmHistoricChartData(areaId) {
  if (!areaId) return null;

  const results = await fetchAnalyticsRequest("av_price_per_sqm", areaId);
  if (!results?.length) return null;

  const sortedResults = results.sort(
    (a, b) => parseFloat(a.time_taken_from) - parseFloat(b.time_taken_from),
  );

  return {
    chartData: {
      labels: sortedResults.map((r) =>
        moment.unix(parseFloat(r.time_taken_from)).format("YYYY"),
      ),
      datasets: [
        {
          label: i18n("Average Price per m²"),
          data: sortedResults.map((r) => parseFloat(r.value)),
          fill: false,
          backgroundColor: "#ee3943",
          borderColor: "#ee3943",
          borderWidth: 2,
          pointRadius: 0,
        },
      ],
    },
    options: {
      scales: {
        x: { title: { display: true, text: i18n("Time") } },
        y: { title: { display: true, text: i18n("Price per m²") } },
      },
    },
  };
}
