import store from "store";
import fireApiRequest from "./core";

var GET_VALUATION_CONDITIONS_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/get_valuation_conditions";
var SAVE_VALUATION_CONDITION_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/save_valuation_condition";
var DELETE_VALUATION_CONDITION_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/delete_valuation_condition";

if (process.env.REACT_APP_NODE_ENV === "production") {
  GET_VALUATION_CONDITIONS_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/get_valuation_conditions";
  SAVE_VALUATION_CONDITION_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/save_valuation_condition";
  DELETE_VALUATION_CONDITION_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/delete_valuation_condition";
}

export async function saveValuationCondition(condition) {
  const state = store.getState();
  const { user } = state;

  if (user.userData.teamId) {
    await fireApiRequest(SAVE_VALUATION_CONDITION_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({
        condition: {
          ...condition,
          team_id: user.userData.teamId,
        },
      }),
    });
  }
}

export async function getValuationConditions() {
  const state = store.getState();
  const { user } = state;

  if (user.userData.teamId) {
    const data = await fireApiRequest(GET_VALUATION_CONDITIONS_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({
        team_id: user.userData.teamId,
      }),
    });

    return data.conditions;
  }

  return [];
}

export async function deleteValuationCondition(conditionId) {
  await fireApiRequest(DELETE_VALUATION_CONDITION_ENDPOINT, {
    method: "POST",
    body: JSON.stringify({
      condition_id: conditionId,
    }),
  });
}
