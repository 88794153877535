import { createReducer } from "@reduxjs/toolkit";
import {
  addMedia,
  clearUploadProperty,
  removeMedia,
  setAddress,
  setAgencyReference,
  setAvailability,
  setBathrooms,
  setBedrooms,
  setCatastroPlots,
  setCatastroProperty,
  setCatastroReference,
  setCoordinates,
  setDescription,
  setDetailedType,
  setEnergyCertificate,
  setFeatures,
  setFloorPlans,
  setIsApproximateLocation,
  setIsPrivate,
  setIsPublished,
  setIsRequiredStepsCompleted,
  setListingAgent,
  setListingType,
  setLocation,
  setMediaByType,
  setMediaUrls,
  setMunicipality,
  setPhotos,
  setPlotSize,
  setPrice,
  setPropertyId,
  setPropertyType,
  setProvince,
  setSize,
  setStatus,
  setTitle,
  setUsableSize,
  setVideos,
  setVirtualTours,
  setYearConstructed,
} from "actions/myPropertiesActions";

const initialState = {
  propertyId: null,
  listingType: null,
  propertyType: null,
  listingAgent: null,
  isApproximateLocation: null,
  catastroProperty: null,
  catastroPlots: [],
  photos: [],
  videos: [],
  floorPlans: [],
  virtualTours: [],
  price: null,
  bedrooms: null,
  bathrooms: null,
  size: null,
  usableSize: null,
  plotSize: null,
  features: [],
  detailedType: null,
  location: null,
  description: null,
  isPublished: false,
  title: null,
  mediaUrls: null,
  coordinates: null,
  municipality: null,
  province: null,
  catastroReference: null,
  isPrivate: null,
  availability: null,
  energyCertificate: null,
  status: null,
  yearConstructed: null,
  address: null,
  isRequiredStepsCompleted: false,
  agencyReference: null,
};

const myPropertiesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setCatastroProperty, (state, action) => {
      state.catastroProperty = action.payload;
    })
    .addCase(setListingType, (state, action) => {
      state.listingType = action.payload;
    })
    .addCase(setPropertyType, (state, action) => {
      state.propertyType = action.payload;
    })
    .addCase(setListingAgent, (state, action) => {
      state.listingAgent = action.payload;
    })
    .addCase(setIsApproximateLocation, (state, action) => {
      state.isApproximateLocation = action.payload;
    })
    .addCase(setDescription, (state, action) => {
      state.description = action.payload;
    })
    .addCase(setLocation, (state, action) => {
      state.location = action.payload;
    })
    .addCase(setPhotos, (state, action) => {
      state.photos = action.payload || [];
    })
    .addCase(setVideos, (state, action) => {
      state.videos = action.payload || [];
    })
    .addCase(setFloorPlans, (state, action) => {
      state.floorPlans = action.payload || [];
    })
    .addCase(setVirtualTours, (state, action) => {
      state.virtualTours = action.payload || [];
    })
    .addCase(setPrice, (state, action) => {
      state.price = action.payload;
    })
    .addCase(setBedrooms, (state, action) => {
      state.bedrooms = action.payload;
    })
    .addCase(setBathrooms, (state, action) => {
      state.bathrooms = action.payload;
    })
    .addCase(setSize, (state, action) => {
      state.size = action.payload;
    })
    .addCase(setFeatures, (state, action) => {
      state.features = action.payload || [];
    })
    .addCase(setDetailedType, (state, action) => {
      state.detailedType = action.payload;
    })
    .addCase(setIsPublished, (state, action) => {
      state.isPublished = action.payload;
    })
    .addCase(setTitle, (state, action) => {
      state.title = action.payload;
    })
    .addCase(clearUploadProperty, () => initialState)
    .addCase(addMedia, (state, action) => {
      const { type, media } = action.payload;
      state[type] = [...state[type], ...media];
    })
    .addCase(removeMedia, (state, action) => {
      const { type, media } = action.payload;
      state[type] = state[type].filter((item) => item !== media);
    })
    .addCase(setMediaUrls, (state, action) => {
      state.mediaUrls = action.payload;
    })
    .addCase(setMediaByType, (state, action) => {
      const { type, items } = action.payload;
      state[type] = items;
    })
    .addCase(setPropertyId, (state, action) => {
      state.propertyId = action.payload;
    })
    .addCase(setCoordinates, (state, action) => {
      state.coordinates = action.payload;
    })
    .addCase(setMunicipality, (state, action) => {
      state.municipality = action.payload;
    })
    .addCase(setProvince, (state, action) => {
      state.province = action.payload;
    })
    .addCase(setCatastroReference, (state, action) => {
      state.catastroReference = action.payload;
    })
    .addCase(setIsPrivate, (state, action) => {
      state.isPrivate = action.payload;
    })
    .addCase(setAvailability, (state, action) => {
      state.availability = action.payload;
    })
    .addCase(setCatastroPlots, (state, action) => {
      state.catastroPlots = action.payload;
    })
    .addCase(setStatus, (state, action) => {
      state.status = action.payload;
    })
    .addCase(setEnergyCertificate, (state, action) => {
      state.energyCertificate = action.payload;
    })
    .addCase(setUsableSize, (state, action) => {
      state.usableSize = action.payload;
    })
    .addCase(setPlotSize, (state, action) => {
      state.plotSize = action.payload;
    })
    .addCase(setAddress, (state, action) => {
      state.address = action.payload;
    })
    .addCase(setYearConstructed, (state, action) => {
      state.yearConstructed = action.payload;
    })
    .addCase(setIsRequiredStepsCompleted, (state, action) => {
      state.isRequiredStepsCompleted = action.payload;
    })
    .addCase(setAgencyReference, (state, action) => {
      state.agencyReference = action.payload;
    });
});

export default myPropertiesReducer;
