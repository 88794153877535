import { createReducer } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import { getDefaultLocalisation } from "i18n/localisation";
import {
  finishFetchingUserData,
  setUserData,
  startFetchingUserData,
} from "../actions/userActions";

const initialState = {
  userData: {
    personalDetails: {},
    agencyDetails: {},
    preferences: {
      lang: getDefaultLocalisation(),
    },
    permissions: {},
    creationDate: null,
    trialEndDate: null,
    initialLaunch: false,
    teamRole: null,
    teamId: null,
    invitationStatus: null,
    roles: [],
    valuationConditionsPrefs: {
      property: false,
      commercial: false,
      land: false,
    },
  },
  // flags to indicate if user data has been fetched
  userDataFetched: false,
  userDataFetching: false,
};

const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(startFetchingUserData, (state) => {
      // Set fetching flag to true when fetching starts
      state.userDataFetching = true;
    })
    .addCase(finishFetchingUserData, (state) => {
      // Set fetching flag to false when fetching finishes
      state.userDataFetching = false;
    })
    .addCase(setUserData, (state, action) => {
      state.userData = {
        // Merge new user data with existing state to ensure completeness
        ...state.userData,
        ...action.payload,
      };

      // Set flags to indicate that user data has been fetched
      state.userDataFetched = true;
      state.userDataFetching = false;

      // force sign out if user is blacklisted
      if (action.payload.blacklisted) {
        (async () => {
          await Auth.signOut();
          window.location.href = "/";
        })();
      }
    });
});

export default userReducer;
