import { i18n } from "i18n/localisation";
import { useState } from "react";

export const RadioGroup = ({
  items,
  onSelect,
  direction = "row",
  selected = "",
}) => {
  const [selectedItem, setSelectedItem] = useState(selected);

  return (
    <div
      className={
        "flex h-[48px] gap-[8px] " +
        (direction === "column" ? "flex-col" : "flex-row")
      }
    >
      {items.map((item) =>
        selectedItem === item ? (
          <div
            className="flex h-auto w-full min-w-[128px] cursor-pointer items-center space-x-2 text-wrap rounded-lg border-[1.5px] border-[#EE3943] bg-[#EE39431A] p-[12px] sm:h-[48px]"
            onClick={() => {
              setSelectedItem(item);
              onSelect(item);
            }}
            key={item}
          >
            <div className="flex h-[24px] w-[24px] items-center justify-center rounded-xl border-[1.5px] border-[#EE3943]">
              <div className="h-[12px] w-[12px] rounded-xl bg-[#EE3943]" />
            </div>
            <p className="cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap text-sm">
              {i18n(item)}
            </p>
          </div>
        ) : (
          <div
            className="flex h-auto w-full min-w-[128px] items-center space-x-2 text-wrap rounded-lg border-[1px] border-[#BABABA] p-[12px]"
            onClick={() => {
              setSelectedItem(item);
              onSelect(item);
            }}
            key={item}
          >
            <div className="flex h-[24px] w-[24px] items-center justify-center rounded-xl border-[1.5px] border-[#BABABA]" />
            <p className="cursor-pointer overflow-hidden text-sm text-[#717171]">
              {i18n(item)}
            </p>
          </div>
        ),
      )}
    </div>
  );
};

export default RadioGroup;
