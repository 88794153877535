import plusIcon from "assets/core/plus.svg";
import { i18n } from "i18n/localisation";
import Button from "../../ui/Button/Button";
import IconComponent from "../IconComponent";
import { ICON_CONFIG, NO_MEDIA_CONTAINER } from "../uploadPropertyPageConfig";

const MediaEmptyTile = ({ tab, size, onFileSelect }) => {
  return (
    <div
      className={`${"media-empty-tile"} ${
        size === "small" ? "h-[187px] max-w-[238px]" : "h-[336px]"
      }`}
    >
      <div className="media-empty-tile_wrapper">
        {size === "standard" && (
          <div className="h-[40px] w-[40px]">
            <IconComponent
              icon={tab.icon}
              color={"#717171"}
              parameter={ICON_CONFIG[tab.key].parameter}
            />
          </div>
        )}
        <Button
          variant="secondary"
          className="flex h-[44px] justify-around gap-[8px]"
          onClick={onFileSelect}
        >
          <img src={plusIcon} alt="add media" />
          <span>{i18n(NO_MEDIA_CONTAINER[tab?.key]?.label)}</span>
        </Button>
        <p>{i18n(NO_MEDIA_CONTAINER[tab?.key]?.text)}</p>
      </div>
    </div>
  );
};

export default MediaEmptyTile;
