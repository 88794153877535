import { setHideMapTools } from "actions/mapActions";
import {
  setAddress,
  setCatastroProperty,
  setCatastroReference,
  setIsApproximateLocation,
  setMunicipality,
  setProvince,
} from "actions/myPropertiesActions";
import RadioGroup from "components/ui/RadioGroup/RadioGroup";
import { i18n } from "i18n/localisation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatNumber } from "utils/helpers";
import { setCanDrawGeographicPolygons } from "../../actions/mapActions";
import rightChevron from "../../assets/core/right_chevron.svg";
import PropertyMap from "../map/PropertyMap";
import { LOCATION_PREFERENCE_ITEMS } from "./uploadPropertyPageConfig";
import { MOBILE_BREAKPOINT } from "config/constants";

const CatastroPlotContainer = ({ property, size }) => {
  return (
    <div className="py-[24px]">
      <div className="mb-[8px] flex w-full justify-between text-[16px] font-[500] text-[#222222]">
        <p>{property.ref}</p>
        {property.url && (
          <a
            target={property.ref}
            className="flex"
            href={property.url}
            onClick={(e) => e.stopPropagation()}
          >
            <span>Catastro</span>
            <img src={rightChevron} alt={"chevron icon"} />
          </a>
        )}
      </div>
      <p className="w-full sm:w-[400px]">{property.address}</p>
      <p className="mb-[12px]">{property.zip_code}</p>
      <div className="flex gap-[8px]">
        <span>{property.type}</span>
        <span>{size}</span>
      </div>
    </div>
  );
};

const CatastroLocation = ({ variant = "modal" }) => {
  const [isLastStep, setIsLastStep] = useState(false);
  const dispatch = useDispatch();
  const catastroProperty = useSelector(
    (state) => state.myProperties.catastroProperty,
  );
  const catastroPlots = useSelector(
    (state) => state.myProperties.catastroPlots,
  );

  dispatch(setHideMapTools(true));
  dispatch(setCanDrawGeographicPolygons(false));

  useEffect(() => {
    if (catastroPlots.length === 1) {
      setIsLastStep(true);
    } else {
      setIsLastStep(false);
    }
  }, [catastroPlots]);

  const getSize = () => {
    if (!catastroProperty) {
      return;
    }
    if (isNaN(catastroProperty.size) && isNaN(catastroProperty.plotSize)) {
      return;
    }

    if (catastroProperty.buildingType === "land") {
      return `${isNaN(catastroProperty.plotSize) ? formatNumber(catastroProperty.size) : formatNumber(catastroProperty.plotSize)}  ㎡`;
    }

    return `${isNaN(catastroProperty.size) ? formatNumber(catastroProperty.plotSize) : formatNumber(catastroProperty.size)}  ㎡`;
  };

  const handleClick = (property) => {
    setIsLastStep(true);
    dispatch(setCatastroProperty(property));
    dispatch(setCatastroReference(property.ref));
    dispatch(setProvince(property.province));
    dispatch(setMunicipality(property.municipality));
    dispatch(setAddress(property.address));
  };

  const renderSubPlots = () => {
    return (
      <div
        className={`${variant === "modal" ? "max-h-[calc(100svh-550px)]" : "max-h-[380px]"} w-screen overflow-scroll px-[24px] sm:w-full`}
      >
        {catastroPlots.map((property) => (
          <div onClick={() => handleClick(property)}>
            <CatastroPlotContainer property={property} size={getSize()} />
          </div>
        ))}
        <div className="h-[1px] w-full bg-[#E1E4EB]"></div>
      </div>
    );
  };

  const renderLastStep = () => {
    return (
      <div
        className={`${variant === "modal" ? "max-h-[calc(100svh-550px)]" : "h-fit"} w-screen overflow-scroll px-[24px] sm:w-full`}
      >
        <CatastroPlotContainer property={catastroPlots[0]} size={getSize()} />
        <div className="h-[1px] w-full bg-[#E1E4EB]"></div>
        <div className="flex flex-col gap-[8px] py-[24px]">
          <p>{i18n("Location preferences")}</p>
          <p className="text-base font-normal text-[#717171]">
            {i18n(
              "Showing exact location increases sale rate by an average of X amount",
            )}
          </p>
          <RadioGroup
            direction={window.innerWidth < MOBILE_BREAKPOINT ? "column" : "row"}
            items={LOCATION_PREFERENCE_ITEMS.map((item) => item.label)}
            onSelect={(selected) =>
              dispatch(
                setIsApproximateLocation(
                  LOCATION_PREFERENCE_ITEMS.find(
                    (item) => item.label === selected,
                  ).value,
                ),
              )
            }
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <PropertyMap page="myProperties" hideSearchBar />
      {isLastStep ? renderLastStep() : renderSubPlots()}
    </div>
  );
};

export default CatastroLocation;
