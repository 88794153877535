import { combineReducers } from "redux";
import adminReducer from "./adminReducer";
import authReducer from "./authReducer";
import cmaReducer from "./cmaReducer";
import collectionsReducer from "./collectionsReducer";
import filterReducer from "./filterReducer";
import geoReducer from "./geoReducer";
import mapReducer from "./mapReducer";
import miscReducer from "./miscReducer";
import myPropertiesReducer from "./myPropertiesReducer";
import notificationsReducer from "./notificationsReducer";
import plotReducer from "./plotsReducer";
import polygonReducer from "./polygonReducer";
import propertyReducer from "./propertyReducer";
import saveSearchReducer from "./saveSearchReducer";
import teamReducer from "./teamReducer";
import userReducer from "./userReducer";
import valuationDetailsReducer from "./valuationDetailsReducer";
import metaValuationConditionsReducer from "./metaValuationConditionsReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  property: propertyReducer,
  geo: geoReducer,
  polygon: polygonReducer,
  collections: collectionsReducer,
  filters: filterReducer,
  misc: miscReducer,
  user: userReducer,
  admin: adminReducer,
  plots: plotReducer,
  cma: cmaReducer,
  valuationDetails: valuationDetailsReducer,
  map: mapReducer,
  notifications: notificationsReducer,
  team: teamReducer,
  saveSearch: saveSearchReducer,
  metaValuationConditions: metaValuationConditionsReducer,
  myProperties: myPropertiesReducer,
});

export default rootReducer;
