import { setIsRequiredStepsCompleted } from "actions/myPropertiesActions";
import padlockCloseIcon from "assets/core/padlock_close.svg";
import padlockOpenIcon from "assets/core/padlock_open.svg";
import rightArrow from "assets/core/right_chevron.svg";
import { i18n } from "i18n/localisation";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import StatusChip from "../StatusChip";
import { ACCORDION_SECTIONS } from "../uploadPropertyPageConfig";
import Accordion from "./Accordion";

const selectMyProperties = (state) => state.myProperties;

const selectUploadPropertyData = createSelector(
  [selectMyProperties],
  (myProperties) => ({
    photos: myProperties.photos,
    videos: myProperties.videos,
    floorPlans: myProperties.floorPlans,
    virtualTours: myProperties.virtualTours,
    price: myProperties.price,
    bedrooms: myProperties.bedrooms,
    bathrooms: myProperties.bathrooms,
    size: myProperties.size,
    detailedType: myProperties.detailedType,
    description: myProperties.description,
    location: myProperties.municipality,
    isPublished: myProperties.isPublished,
  }),
);

const selectSettingsData = createSelector(
  [selectMyProperties],
  (myProperties) => ({
    isPublished: myProperties.isPublished,
    isPrivate: myProperties.isPrivate,
    availability: myProperties.availability,
  }),
);

const Checklist = ({
  onClickPublishButton,
  onClickUnpublishButton,
  onClickAvalabilityButton,
  onClickVisibilityButton,
  onClickListingAgentButton,
}) => {
  const dispatch = useDispatch();
  const [openedSections, setOpenedSections] = useState(new Set());

  const uploadPropertyData = useSelector(selectUploadPropertyData);
  const settingsData = useSelector(selectSettingsData);

  const listingAgent = useSelector((state) => state.myProperties.listingAgent);
  const validationMapping = {
    photos: Boolean(
      uploadPropertyData.photos && uploadPropertyData.photos.length > 0,
    ),
    videos: Boolean(
      uploadPropertyData.videos && uploadPropertyData.videos.length > 0,
    ),
    floorPlans: Boolean(
      uploadPropertyData.floorPlans &&
        uploadPropertyData.floorPlans?.length > 0,
    ),
    virtualTours: Boolean(
      uploadPropertyData.virtualTours &&
        uploadPropertyData.virtualTours?.length > 0,
    ),
    price: Boolean(
      uploadPropertyData.price && uploadPropertyData.price !== "0",
    ),
    bedrooms: Boolean(
      uploadPropertyData.bedrooms && uploadPropertyData.bedrooms !== 0,
    ),
    bathrooms: Boolean(
      uploadPropertyData.bathrooms && uploadPropertyData.bathrooms !== 0,
    ),
    size: Boolean(uploadPropertyData.size && uploadPropertyData.size !== "0"),
    detailedType: Boolean(
      uploadPropertyData.detailedType?.typology ||
        uploadPropertyData.detailedType?.subTypology,
    ),
    description: Boolean(uploadPropertyData.description),
    location: Boolean(uploadPropertyData.location),
  };

  const isSectionCompleted = useMemo(
    () => (section) => {
      const requiredSteps = section.steps.filter((step) => step.isRequired);
      const isStepsChecked = (steps) =>
        steps.every((step) => validationMapping[step.key]);

      if (requiredSteps.length > 0) {
        return isStepsChecked(requiredSteps);
      }

      return isStepsChecked(section.steps);
    },
    [uploadPropertyData],
  );

  const calculateProgress = useMemo(
    () => (sections) => {
      const completedSections = sections.reduce((acc, section) => {
        return acc + isSectionCompleted(section);
      }, 0);

      const progress = (completedSections / sections.length) * 100;
      dispatch(setIsRequiredStepsCompleted(progress === 100));

      return progress;
    },
    [isSectionCompleted],
  );

  const progressPercentage = useMemo(
    () => calculateProgress(ACCORDION_SECTIONS),
    [calculateProgress],
  );

  const handleAccordionToggle = (sectionKey) => {
    setOpenedSections((prevState) => {
      const newState = new Set(prevState);
      if (newState.has(sectionKey)) {
        newState.delete(sectionKey);
      } else {
        newState.add(sectionKey);
      }
      return newState;
    });
  };

  return (
    <div className="checklist">
      <div className="checklist_header">
        <h2>{i18n("Listing Checklist")}</h2>
      </div>

      <div className="checklist_progress">
        <div className="checklist_progress_percentage">
          <p>{Math.round(progressPercentage)}%</p>
        </div>
        <div className="checklist_progress_bar">
          <div
            className="checklist_progress_bar_fill"
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>
      </div>

      <div className="checklist_accordion-container">
        {ACCORDION_SECTIONS?.map(
          (section) =>
            section && (
              <Accordion
                key={section.key}
                section={section}
                isChecked={isSectionCompleted(section)}
                isOpen={openedSections.has(section.key)}
                onToggle={() => handleAccordionToggle(section.key)}
              />
            ),
        )}
      </div>

      <div className="checklist_footer">
        <h2>{i18n("Property Settings")}</h2>

        <div className="checklist_footer_settings">
          <div className="titles">
            <div>{i18n("Published status:")}</div>
            {settingsData.isPublished && (
              <>
                <div>{i18n("Availability status:")}</div>
                <div>{i18n("Visibility status:")}</div>
              </>
            )}
            <div>{i18n("Listing agent:")}</div>
          </div>

          <div className="options">
            <div
              className="flex w-full justify-between"
              onClick={
                settingsData.isPublished
                  ? onClickUnpublishButton
                  : onClickPublishButton
              }
            >
              <StatusChip
                status={settingsData.isPublished ? "published" : "unpublished"}
              />
              <img src={rightArrow} alt="arrow icon" />
            </div>
            {settingsData.isPublished && (
              <>
                <div
                  className="flex w-full cursor-pointer justify-between"
                  onClick={onClickAvalabilityButton}
                >
                  <StatusChip
                    status={settingsData.availability || "available"}
                  />
                  <img src={rightArrow} alt="arrow icon" />
                </div>
                <div
                  className="flex h-8 w-full cursor-pointer items-center justify-between"
                  onClick={onClickVisibilityButton}
                >
                  <img
                    src={
                      settingsData.isPrivate
                        ? padlockCloseIcon
                        : padlockOpenIcon
                    }
                    alt="padlock icon"
                  />
                  <p className="ml-3 grow">
                    {i18n(
                      settingsData.isPrivate
                        ? "Private Listing"
                        : "Public Listing",
                    )}
                  </p>
                  <img src={rightArrow} alt="arrow icon" />
                </div>
              </>
            )}
            <div
              className="flex h-8 w-full max-w-[190px] items-center justify-between"
              onClick={onClickListingAgentButton}
            >
              <p class="truncate">
                {listingAgent?.personalDetails
                  ? `${listingAgent?.personalDetails?.firstName} ${listingAgent?.personalDetails?.lastName}`
                  : listingAgent?.email}
              </p>
              <img src={rightArrow} alt="right arrow" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checklist;
