import { setIsPrivate, setIsPublished } from "actions/myPropertiesActions";
import { savePropertyRequest } from "api/crm";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { i18n } from "i18n/localisation";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import eyeIcon from "../../assets/core/eye.svg";
import Button from "../ui/Button/Button";

const PublishModal = ({ isOpen, closeModal }) => {
  const { propertyId, isPrivate } = useSelector((state) => state.myProperties);
  const isRequiredStepsCompleted = useSelector(
    (state) => state.myProperties.isRequiredStepsCompleted,
  );

  const dispatch = useDispatch();

  const handlePublish = async () => {
    dispatch(setIsPublished(true));
    closeModal();
    try {
      await savePropertyRequest({
        property_id: propertyId,
        update_fields: { published: 1, isPrivate },
      });
    } catch (e) {
      console.log(e);
      dispatch(setIsPublished(false));
      toast.error(i18n("Failed to publish property. Please try again."), {
        duration: 5000,
      });
    }

    toast.success(i18n("Property published"), { duration: 5000 });
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        closeModal();
      }}
    >
      <DialogContent className="max-h-[calc(100svh-10px)] gap-0 sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{i18n("Publish your property")}</DialogTitle>
        </DialogHeader>
        <div className="mt-6 flex w-full flex-col gap-6 p-6 pt-[0px]">
          <p className="text-[#717171]">
            {i18n(
              "Publishing your property will make it accessible for others to see.",
            )}
          </p>
          <div
            className={
              "flex h-auto w-full rounded-[8px] border border-[#E1E4EB] bg-[#F2F4F8] p-[16px]"
            }
          >
            <p className="text-[16px]">
              <span className="font-bold">
                {i18n("Preview the published view")}
              </span>
              {i18n(" to see what your property would look like.")}
            </p>
            <Button
              onClick={() =>
                window.open(
                  `/property/${propertyId}`,
                  "_blank",
                  "noopener,noreferrer",
                )
              }
              variant="secondary"
              className="h-[44px] min-w-fit"
            >
              <img src={eyeIcon} alt="share icon" className="w-5" />
              <span className="ml-[8px] text-base font-normal">
                {i18n("See as a Viewer")}
              </span>
            </Button>
          </div>

          <div className="flex w-full flex-col gap-3 border-t">
            <p className="mt-6 text-base font-medium">{i18n("Visibility")}</p>
            <p className="text-base font-normal text-[#717171]">
              {i18n("Select your property listing visibility.")}
            </p>
            <div className="flex justify-between gap-4">
              <div
                onClick={() => dispatch(setIsPrivate(false))}
                className={`flex w-1/2 gap-3 rounded-lg border p-4 ${
                  isPrivate === false
                    ? "border-black bg-[#2222221A]"
                    : "border-[#BABABA]"
                }`}
              >
                <div
                  className={`flex h-6 w-6 min-w-6 items-center justify-center rounded-full border ${
                    isPrivate === false ? "border-black" : "border-[#BABABA]"
                  }`}
                >
                  {isPrivate === false && (
                    <div className="h-3 w-3 rounded-full bg-black"></div>
                  )}
                </div>
                <div className="flex flex-col gap-1">
                  <p
                    className={`text-base font-semibold ${
                      isPrivate === false ? "text-black" : "text-[#717171]"
                    }`}
                  >
                    {i18n("Public Listing")}
                  </p>
                  <p
                    className={`text-sm ${
                      isPrivate === false ? "text-black" : "text-[#717171]"
                    }`}
                  >
                    {i18n(
                      "Can be found by searching and will be visible to others.",
                    )}
                  </p>
                </div>
              </div>
              <div
                onClick={() => dispatch(setIsPrivate(true))}
                className={`flex w-1/2 gap-3 rounded-lg border p-4 ${
                  isPrivate ? "border-black bg-[#2222221A]" : "border-[#BABABA]"
                }`}
              >
                <div
                  className={`flex h-6 w-6 min-w-6 items-center justify-center rounded-full border ${
                    isPrivate ? "border-black" : "border-[#BABABA]"
                  }`}
                >
                  {isPrivate && (
                    <div className="h-3 w-3 rounded-full bg-black"></div>
                  )}
                </div>
                <div className="flex flex-col gap-1">
                  <p
                    className={`text-base font-semibold ${
                      isPrivate ? "text-black" : "text-[#717171]"
                    }`}
                  >
                    {i18n("Private Listing")}
                  </p>
                  <p
                    className={`text-sm ${
                      isPrivate ? "text-black" : "text-[#717171]"
                    }`}
                  >
                    {i18n("Hidden from search and only accessible if shared.")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DialogFooter>
          <div className="flex w-full justify-end">
            <Button
              onClick={handlePublish}
              disabled={isPrivate === null || !isRequiredStepsCompleted}
            >
              <div>{i18n("Publish")}</div>
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PublishModal;
