import { i18n } from "i18n/localisation";

export default function YesNoOption({
  question,
  value,
  onYes,
  onNo,
  noLabel = i18n("No"),
  yesLabel = i18n("Yes"),
}) {
  return (
    <div className="yes-no-option">
      <div className="yes-no-option_question">{question}</div>
      <div className="yes-no-option_switch">
        <div
          onClick={onYes}
          className={
            "yes-no-option_switch_option" + (value === "yes" ? " selected" : "")
          }
        >
          <span className="yes-no-option_switch_option_text">{i18n(yesLabel)}</span>
        </div>
        <div
          onClick={onNo}
          className={
            "yes-no-option_switch_option" + (value === "no" ? " selected" : "")
          }
        >
          <span className="yes-no-option_switch_option_text">{i18n(noLabel)}</span>
        </div>
      </div>
    </div>
  );
}
