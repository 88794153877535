import { createAction } from "@reduxjs/toolkit";
import { getValuationConditions } from "api/valuationConditions";

// these are the conditions that are customised by the user that can be used in the valuation
export const addValuationMetaCondition = createAction(
  "valuationConditions/addValuationMetaCondition",
);
export const editValuationMetaCondition = createAction(
  "valuationConditions/editValuationMetaCondition",
);
export const deleteValuationMetaCondition = createAction(
  "valuationConditions/deleteValuationMetaCondition",
);
export const setValuationMetaConditions = createAction(
  "valuationConditions/setValuationMetaConditions",
);

// fetches custom meta valuation conditions for the team
// and then consumes into state
export function fetchValuationMetaConditions() {
  return async (dispatch) => {
    const conditions = await getValuationConditions();
    let conditionsCleaned = conditions.map((c) => ({
      ...c,
      yesPercentage: parseFloat(c.yesPercentage),
      noPercentage: parseFloat(c.noPercentage),
      timestamp: parseFloat(c.timestamp),
      dropdownItems: c.dropdownItems?.map((di) => ({
        label: di.label,
        value: parseFloat(di.value),
      })),
    }));
    dispatch(setValuationMetaConditions(conditionsCleaned));
  };
}
