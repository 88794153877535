import { createAction } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import { getDefaultLocalisation } from "i18n/localisation";
import { initialiseUserTracking } from "lib/analytics";
import { initialiseCanny } from "lib/canny";
import { fetchSubscriptionRequest } from "../api/subscriptions";
import { fetchUserDataRequest, saveUserDataRequest } from "../api/user";

const setUser = createAction("user/setUser");
const setSubscription = createAction("subscription/setSubscription");
const setUserData = createAction("user/setUserData");

// Action creators for starting and finishing the fetching process
export const startFetchingUserData = createAction("startFetchingUserData");
export const finishFetchingUserData = createAction("finishFetchingUserData");

// fetches subscription and updates the redux store
function fetchSubscription() {
  return async (dispatch) => {
    try {
      let subscription = await fetchSubscriptionRequest();

      dispatch(
        setSubscription({
          data: subscription,
          subscriptionDataFetched: true,
        }),
      );
    } catch (e) {
      dispatch(
        setSubscription({
          data: null,
          subscriptionDataFetched: true,
        }),
      );
    }
  };
}

// Function to fetch user data with a check to prevent multiple fetches
const fetchUserData =
  (cb = null) =>
  async (dispatch, getState) => {
    const { user } = getState();

    // Check if data is already being fetched
    if (user.userDataFetching) {
      return;
    }

    // Dispatch the action to start fetching user data
    dispatch(startFetchingUserData());
    try {
      // Make the request to fetch user data
      const userData = await fetchUserDataRequest();

      // force sign out if user doesn't exist anymore
      if (userData.userNotFound) {
        Auth.signOut();
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
        return;
      }

      // mixpanel user tracking
      initialiseUserTracking();

      initialiseCanny(userData);

      // we need to check if they have lang set, if not we need to set it
      // to the default browser language (for backend email notifications etc)
      if (!userData.preferences.lang) {
        const lang = getDefaultLocalisation();
        dispatch(
          saveUserData({ preferences: { ...userData.preferences, lang } }),
        );
        userData.preferences.lang = lang;
      }

      dispatch(setUserData(userData));
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      // Dispatch action to indicate fetching has finished
      dispatch(finishFetchingUserData());

      // call callback if provided
      if (cb) cb();
    }
  };

function saveUserData(data, cb = null) {
  return async (dispatch, getState) => {
    let dataToUpdateState = { ...getState().user.userData };

    if (data.agencyDetails) {
      dataToUpdateState.agencyDetails = data.agencyDetails;
    }

    if (data.personalDetails) {
      dataToUpdateState.personalDetails = data.personalDetails;
    }

    if (data.preferences) {
      dataToUpdateState.preferences = data.preferences;
    }

    if (data.miscDetails) {
      dataToUpdateState.miscDetails = data.miscDetails;
    }

    if (data.userType) {
      dataToUpdateState.userType = data.userType;
    }

    if (data.valuationConditionsPrefs) {
      dataToUpdateState.valuationConditionsPrefs =
        data.valuationConditionsPrefs;
    }

    dispatch(setUserData(dataToUpdateState));
    await saveUserDataRequest(data);

    // call callback if provided
    if (cb) cb();
  };
}

export {
  fetchSubscription,
  fetchUserData,
  saveUserData,
  setSubscription,
  setUser,
  setUserData,
};
