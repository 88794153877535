import {
  setAddress,
  setListingType,
  setMunicipality,
  setPropertyType,
  setProvince,
} from "actions/myPropertiesActions";
import { savePropertyRequest } from "api/crm";
import Dropdown from "components/core/Dropdown";
import Button from "components/ui/Button/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import RadioGroup from "components/ui/RadioGroup/RadioGroup";
import { i18n } from "i18n/localisation";
import {
  BUILDING_TYPE_FILTERS,
  FILTER_LABEL_MAPPINGS,
} from "lib/filter/filterTypes";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import CatastroLocation from "./CatastroLocation";
import ListingAgent from "./ListingAgent";
import { MODAL_STEPS, MODAL_STEPS_CONFIG } from "./uploadPropertyPageConfig";

const UploadPropertyModal = ({ isOpen, closeModal }) => {
  const [currentStep, setCurrentStep] = useState(MODAL_STEPS.PROPERTY_DETAILS);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    team,
    user,
    myProperties,
    listingType,
    catastroProperty,
    propertyType,
    catastroReference,
    isApproximateLocation,
    listingAgent,
  } = useSelector((state) => ({
    team: state.team,
    user: state.auth,
    userData: state.user.userData,
    myProperties: state.myProperties,
    listingType: state.myProperties.listingType,
    catastroProperty: state.myProperties.catastroProperty,
    propertyType: state.myProperties.propertyType,
    catastroReference: state.myProperties.catastroReference,
    isApproximateLocation: state.myProperties.isApproximateLocation,
    listingAgent: state.myProperties.listingAgent,
  }));

  const generatePropertyId = () => {
    // Generate a secure UUID and convert it to a number
    const uuid = uuidv4();
    // Take first 10 digits of the UUID's numeric hash
    const numericHash = Array.from(uuid)
      .map((char) => char.charCodeAt(0))
      .join("")
      .slice(0, 10);

    return numericHash;
  };

  const handleNext = async () => {
    if (currentStep === MODAL_STEPS.PROPERTY_DETAILS) {
      setCurrentStep(MODAL_STEPS.CATASTRO);
    } else {
      const propertyId = generatePropertyId();
      dispatch(setMunicipality(catastroProperty.municipality));
      dispatch(setProvince(catastroProperty.province));
      dispatch(setAddress(catastroProperty.address));

      await savePropertyRequest({
        property_id: propertyId,
        update_fields: {
          latitude: Number(catastroProperty.latitude),
          longitude: Number(catastroProperty.longitude),
          url: propertyId,
          buildingType: catastroProperty.buildingType,
          parentDuplicatedId: propertyId,
          municipality: catastroProperty.municipality,
          title: `${propertyType.label} in ${catastroProperty.municipality}`,
          refcat: catastroReference,
          saleType: listingType,
          isApproximateLocation,
          user_id: listingAgent.id
        },
      });
      navigate(`/upload/${propertyId}`);
    }
  };

  const handleBack = () => {
    setCurrentStep(MODAL_STEPS.PROPERTY_DETAILS);
  };

  const isNextButtonDisabled = () => {
    return !MODAL_STEPS_CONFIG[currentStep].validate(myProperties);
  };

  const renderStep = useCallback(() => {
    switch (currentStep) {
      case MODAL_STEPS.PROPERTY_DETAILS:
        return renderStepOne();
      case MODAL_STEPS.CATASTRO:
        return renderStepTwo();
      default:
        return null;
    }
  }, [currentStep, myProperties, team, user]);

  const renderStepOne = () => {
    return (
      <div className="mt-6 flex max-h-[calc(100svh-200px)] w-full flex-col flex-wrap gap-[24px] overflow-y-auto p-[24px] pt-[0px]">
        <span className="text-base font-normal text-[#717171]">
          {i18n(
            "Select the listing type and property type to get started with your listing",
          )}
        </span>
        <div className="flex flex-col gap-[16px] sm:flex-row">
          <div className="flex-col">
            <p className="mb-2 text-sm font-medium text-gray-700">
              {i18n("Listing Type")}
            </p>
            <RadioGroup
              items={["Sale", "Rent"]}
              onSelect={(item) => dispatch(setListingType(item))}
              selected={listingType}
            />
          </div>
          <div className="w-full">
            <p className="mb-2 text-sm font-medium text-gray-700">
              {i18n("Property Type")}
            </p>
            <Dropdown
              className="upload-modal-dropdown"
              placeholder={propertyType ? "" : i18n("Select property type")}
              items={BUILDING_TYPE_FILTERS.map((filter, index) => ({
                label: FILTER_LABEL_MAPPINGS[filter.label](),
                value: index,
                onSelect: () => dispatch(setPropertyType(filter)),
              }))}
              label={
                propertyType ? FILTER_LABEL_MAPPINGS[propertyType.label]() : ""
              }
            />
          </div>
        </div>
        <div className="h-[1px] w-full bg-[#E1E4EB]"></div>
        <ListingAgent styleClass="upload-modal" />
      </div>
    );
  };

  const renderStepTwo = () => {
    return <CatastroLocation />;
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        closeModal();
        setCurrentStep(MODAL_STEPS.PROPERTY_DETAILS);
      }}
    >
      <DialogContent className="max-h-[calc(100svh-10px)] gap-0 sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            {i18n(MODAL_STEPS_CONFIG[currentStep].title)}
          </DialogTitle>
        </DialogHeader>
        {renderStep()}
        <DialogFooter>
          <div className="flex w-full justify-between">
            <Button
              variant="ghost"
              className={
                currentStep === MODAL_STEPS.PROPERTY_DETAILS
                  ? "invisible"
                  : "visible"
              }
              onClick={handleBack}
            >
              <div>{i18n("Back")}</div>
            </Button>
            <div className="flex items-center gap-[6px]">
              <div
                className={`h-[6px] w-[6px] rounded-full ${currentStep === MODAL_STEPS.PROPERTY_DETAILS ? "bg-[#717171]/40" : "bg-[#717171]"}`}
              />
              <div
                className={`h-[6px] w-[6px] rounded-full ${currentStep === MODAL_STEPS.CATASTRO ? "bg-[#717171]/40" : "bg-[#717171]"}`}
              />
            </div>
            <Button onClick={handleNext} disabled={isNextButtonDisabled()}>
              <div>
                {currentStep === MODAL_STEPS.PROPERTY_DETAILS
                  ? i18n("Next")
                  : i18n("Start")}
              </div>
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default UploadPropertyModal;
