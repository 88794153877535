import { i18n } from "i18n/localisation";
import { STEPPER_CONFIG } from "../uploadPropertyPageConfig";

const StepWrapper = ({ step, text, isCurrentStep, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`${isCurrentStep ? "border-[#717171] bg-black" : "border-[#E1E4EB] bg-transparent"} relative flex h-11 w-11 cursor-pointer items-center justify-center rounded-full border-2`}
    >
      <p
        className={`${isCurrentStep ? "text-white" : "text-[#717171]"} font-medium`}
      >
        {step}
      </p>
      <p className="absolute top-14">{i18n(text)}</p>
    </div>
  );
};

const Stepper = ({ currentStep, setCurrentStep }) => {
  return (
    <div className="h-24">
      <div className="flex items-center justify-center gap-3">
        {STEPPER_CONFIG.map((step) => (
          <div key={step.key} className="flex items-center gap-3">
            <StepWrapper
              step={step.step}
              text={step.text}
              isCurrentStep={currentStep === step.key}
              onClick={() => setCurrentStep(step.key)}
            />
            {step.key !== 3 && (
              <div className="h-[1.5px] w-[50px] bg-[#BABABA]" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stepper;
