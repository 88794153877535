import PropertyFeatureChip from "components/property/PropertyFeatureChip";
import { i18n } from "i18n/localisation";
import { useSelector } from "react-redux";
import { formatNumber } from "utils/helpers";
import { FEATURE_CHIP_MAPPING } from "../uploadPropertyPageConfig";

const SavedDetails = () => {
  const propertyDetails = useSelector((state) => ({
    bathrooms: state.myProperties.bathrooms,
    detailedType: state.myProperties.detailedType,
    features: state.myProperties.features,
    price: state.myProperties.price,
    bedrooms: state.myProperties.bedrooms,
    size: state.myProperties.size,
    usableSize: state.myProperties.usableSize,
    plotSize: state.myProperties.plotSize,
    status: state.myProperties.status,
    yearConstructed: state.myProperties.yearConstructed,
    energyCertificate: state.myProperties.energyCertificate,
    title: state.myProperties.title,
    listingType: state.myProperties.listingType,
  }));

  const renderPropertySize = () => {
    return (
      <>
        <span className="font-inter text-[14px] font-normal leading-normal text-[#717171]">
          {formatNumber(propertyDetails.size)}
          {i18n("m² built")}
        </span>
        {propertyDetails.plotSize && propertyDetails.plotSize > 0 && (
          <>
            <div className="mx-2 mt-[1px] h-4 w-[1px] bg-[rgba(225,228,235,0.5)]"></div>
            <span className="font-inter text-[14px] font-normal leading-normal text-[#717171]">
              {formatNumber(propertyDetails.plotSize)}
              {i18n("m² total plot")}
            </span>
          </>
        )}
      </>
    );
  };

  const getFeaturesToChips = () => {
    let features = Object.keys(propertyDetails.features).filter(
      (feature) =>
        propertyDetails.features[feature] && FEATURE_CHIP_MAPPING[feature],
    );

    if (propertyDetails.bathrooms > 0) {
      features = ["bathrooms", ...features];
    }

    if (propertyDetails.bedrooms > 0) {
      features = ["bedrooms", ...features];
    }

    return features;
  };

  const getFeature = (feature) => {
    if (feature === "bathrooms" || feature === "bedrooms") {
      return {
        ...FEATURE_CHIP_MAPPING[feature],
        value: propertyDetails[feature],
      };
    }

    return FEATURE_CHIP_MAPPING[feature];
  };

  return (
    <div>
      <div>
        <p className="text-2xl font-medium">{propertyDetails.title}</p>
        <div className="my-3">
          <div className="flex justify-between text-base font-normal">
            <span className="text-[28px] font-semibold">
              {parseFloat(propertyDetails.price) > 0 ? (
                <>{propertyDetails.price} €</>
              ) : (
                i18n("Price on request")
              )}
            </span>

            {propertyDetails.saleType !== "rent" &&
              parseFloat(propertyDetails.price) > 0 &&
              parseFloat(propertyDetails.size) > 0 && (
                <span className="text-base font-normal">
                  {Math.round(
                    parseFloat(propertyDetails.price) /
                      parseFloat(propertyDetails.size),
                  )}{" "}
                  €/㎡
                </span>
              )}
          </div>
        </div>
      </div>
      <div className="h-[1px] w-full bg-[rgba(225,228,235,0.5)]"></div>
      <div className="flex flex-wrap gap-2 py-4">
        {getFeaturesToChips().map((feature, index) => (
          <PropertyFeatureChip key={index} feature={getFeature(feature)} />
        ))}
      </div>
      <div className="flex">{renderPropertySize()}</div>
    </div>
  );
};

export default SavedDetails;
