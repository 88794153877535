import { setSaleType } from "actions/filterActions";
import { i18n } from "i18n/localisation";
import { connect } from "react-redux";

function SaleTypeToggle(props) {
  const { saleType, dispatch, onSaleTypeChange, cmaMode } = props;

  const _onSaleTypeChange = (saleType) => {
    if (onSaleTypeChange) {
      onSaleTypeChange(saleType);
    } else {
      dispatch(setSaleType(saleType));
    }
  };

  return (
    <div className={`search-bar_toggle-content ${cmaMode ? "cma-mode" : ""}`}>
      <div
        onClick={() => _onSaleTypeChange("sale")}
        className={`search-bar_toggle-btn sale ${saleType === "sale" && "selected"}`}
      >
        <span>{i18n("Sale")}</span>
      </div>
      <div className="vertical-divider"></div>
      <div
        onClick={() => _onSaleTypeChange("rent")}
        className={`search-bar_toggle-btn rent ${saleType === "rent" && "selected"}`}
      >
        <span>{i18n("Rent")}</span>
      </div>
      <div className="vertical-divider"></div>
      {!cmaMode && (
        <div
          onClick={() => _onSaleTypeChange("sold")}
          className={`search-bar_toggle-btn sold ${saleType === "sold" && "selected"}`}
        >
          <span>{i18n("Sold")}</span>
        </div>
      )}
    </div>
  );
}

export default connect((state) => ({
  saleType: state.filters.saleType,
}))(SaleTypeToggle);
