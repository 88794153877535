var GOOGLE_MAPS_API_KEY = "AIzaSyCR06oB8bOqasfKGEU5WIOm6R2Llf9mi9o";
export const IS_LOCALHOST = window.location.hostname === "localhost";
export const GOOGLE_MAPS_LIBRARIES = ["drawing", "geometry", "places"];

if (IS_LOCALHOST) {
  GOOGLE_MAPS_API_KEY = "";
}

export { GOOGLE_MAPS_API_KEY };

export const SUPPORTED_LANGUAGES = [
  {
    code: "es",
    name: "Español",
  },
  {
    code: "en",
    name: "English",
  },
];

var MEDIA_BUCKET_URL = "https://irealty-media-dev.s3.eu-west-2.amazonaws.com/";
let MIXPANEL_PROJECT_TOKEN = "01b9c2e854b79c48904f090c2e487689";

if (process.env.REACT_APP_NODE_ENV === "production") {
  MEDIA_BUCKET_URL = "https://irealty-media-prod.s3.eu-west-2.amazonaws.com/";
  MIXPANEL_PROJECT_TOKEN = "4963f86c0464cee651f67974dcc5f597";
}

const CORS_PROXY = "https://cors.irealty.app/";
const ZOOM_LEVEL_TO_SHOW_PLOTS = 15;
const ZOOM_LEVEL_TO_SHOW_MUNICIPALITIES = 9;
const ZOOM_LEVEL_TO_HIDE_MUNICIPALITIES = 13;
const MAX_ALLOWED_AREAS = 10;
const CANNY_APP_ID = "66ebf0f062f49469447fc24a";
const MOBILE_BREAKPOINT = 1100;

export {
  CANNY_APP_ID,
  CORS_PROXY,
  MAX_ALLOWED_AREAS,
  MEDIA_BUCKET_URL,
  MIXPANEL_PROJECT_TOKEN,
  MOBILE_BREAKPOINT,
  ZOOM_LEVEL_TO_HIDE_MUNICIPALITIES,
  ZOOM_LEVEL_TO_SHOW_MUNICIPALITIES,
  ZOOM_LEVEL_TO_SHOW_PLOTS,
};
