import { i18n } from "i18n/localisation";
import { STATUS_CONFIG } from "./uploadPropertyPageConfig";

const StatusChip = ({ status }) => {
  console.log(STATUS_CONFIG[status]?.label);
  return (
    <div
      className={`flex h-8 w-fit items-center justify-center rounded-full px-3 ${STATUS_CONFIG[status]?.style}`}
    >
      <p className="text-4 font-semibold">
        {i18n(STATUS_CONFIG[status]?.label)}
      </p>
    </div>
  );
};

export default StatusChip;
