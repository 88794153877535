import { setCmaProperty } from "actions/cmaActions";
import { setSaleType, setSort } from "actions/filterActions";
import { setHideMapTools } from "actions/mapActions";
import {
  setGlobalLoading,
  setGlobalLoadingMessage,
  setPricingModal,
} from "actions/miscActions";
import { setCanDrawPlots, setSelectedPlot } from "actions/plotsActions";
import { setProperties } from "actions/propertiesActions";
import {
  fetchPropertyByUrlRequest,
  fetchPropertyRequest,
} from "api/properties";
import AreaSelectionPanelContent from "components/property/AreaSelectionPanelContent";
import Button from "components/ui/Button/Button";
import SearchInput from "components/ui/SearchInput/SearchInput";
import { ZOOM_LEVEL_TO_SHOW_PLOTS } from "config/constants";
import { getMapObjectById } from "db/mapStore";
import { i18n } from "i18n/localisation";
import {
  filterByRelevantTypology,
  getFeatureFilterArrayFromProperty,
} from "lib/filter/filters";
import { SORT_OPTIONS, cmaSort } from "lib/filter/sort";
import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { toast } from "sonner";
import {
  catastroToCmaProperty,
  clearAllButSelectedPlot,
  clearDrawnPlots,
  fetchPlotsByMapBounds,
  getGlobalMapInstance,
  searchMapForReferences,
} from "utils/map";
import { getNearestNeighbourGeoboundary } from "utils/polygon";
import { getPropertyTitle, getPropertyTypologyHuman } from "utils/properties";
import { trialEnded, userHasRole } from "utils/userHelpers";
import { getCatastralByRefDirect, valuatePropertyRequest } from "../../api/cma";
import {
  COMMERCIAL_FEATURES_FILTERS,
  COMMERCIAL_TYPE_FILTERS,
  FILTER_LABEL_MAPPINGS,
  LAND_FEATURES_FILTERS,
  LAND_TYPE_FILTERS,
  PROPERTY_CONDITTION_FILTERS,
  PROPERTY_FEATURES_FILTERS,
  PROPERTY_TYPE_FILTERS,
} from "../../lib/filter/filterTypes";
import {
  getDistrictsForMunicipality,
  isMobile,
  normalizeString,
  resetUrlSearchQueries,
} from "../../utils/helpers";
import Dropdown from "../core/Dropdown";
import PrimaryInput from "../core/PrimaryInput";
import Checkbox from "../input/Checkbox";
import CmaModal from "./CmaModal";
import CmaPropertyCard from "./CmaPropertyCard";
import CmaPropertyPanel from "./CmaPropertyPanel";
import SaleTypeToggle from "components/search/SaleTypeToggle";
import ValuationDetails from "./ValuationDetails";

const CMA_STAGE_SEARCH_ADDRESS = 0;
const CMA_STAGE_STUDY_AREA = 1;
const CMA_STAGE_REFERENCES = 2;
const CMA_STAGE_SOLD_REFERENCES = 3;
const BEDROOM_BATHROOM_RANGE = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 26, 27, 28, 29, 30,
];
const PROPERTY_FEATURES_FIRST_COL = [
  PROPERTY_FEATURES_FILTERS.find((f) => f.path === "features.hasGarage"),
  PROPERTY_FEATURES_FILTERS.find((f) => f.path === "features.hasGarden"),
  PROPERTY_FEATURES_FILTERS.find((f) => f.path === "features.hasSwimmingPool"),
  PROPERTY_FEATURES_FILTERS.find((f) => f.path === "features.hasSeaView"),
];
const PROPERTY_FEATURES_SECOND_COL = [
  PROPERTY_FEATURES_FILTERS.find(
    (f) => f.path === "features.hasAirConditioning",
  ),
  PROPERTY_FEATURES_FILTERS.find((f) => f.path === "features.hasLift"),
  PROPERTY_FEATURES_FILTERS.find((f) => f.path === "features.hasTerrace"),
  PROPERTY_FEATURES_FILTERS.find((f) => f.path === "features.hasStorage"),
];

class CmaPanel extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      stage: CMA_STAGE_SEARCH_ADDRESS,
      searchResults: [],
      selectedDistricts: [],
      districts: [],
      selectedProperties: [],
      selectedPropertiesObjects: [],
      sort: SORT_OPTIONS[0],
      valuationModalOpen: false,
      valuationValue: 0,
      loadingCatastral: false,
      topMatchesSelected: false,
      topMatchesSelectedSold: false,
      tempFullRef: null,
      searchTerm: null,
      relatedProperties: [],
      noActiveProperties: false,
    };

    this.tick = 0;
    this.selectTopMatches = this.selectTopMatches.bind(this);
    this.onSelectProperty = this.onSelectProperty.bind(this);
    this.onSort = this.onSort.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.tick > 0) {
        return;
      }

      // ticking prevents componentDidMount from multiplying
      this.tick++;

      // if url params contain a property id then fetch the property
      // and set it as the selected result
      const urlParams = new URLSearchParams(window.location.search);
      const propertyId = urlParams.get("id");
      const saleType = urlParams.get("sale_type") ?? "sale";

      if (propertyId) {
        fetchPropertyRequest(propertyId, saleType).then((data) => {
          if (data && data.property) {
            // we dont want to draw plots when a property is selected
            this.props.dispatch(setCanDrawPlots(false));

            // switch context to rent if we are valuating rentals
            if (data.property.saleType == "rent") {
              this.props.dispatch(setSaleType("rent"));
            }

            this.onInternalPropertyDataRetrieved(
              this.internalPropertyToCmaProperty(
                data.property,
                `/property/${propertyId}`,
              ),
            );
          }
        });
      }
    }, 500);
  }

  // listen for any districts deleted from map
  componentDidUpdate(prevProps, prevState) {
    const { selectedDistricts } = this.state;
    const { drawnPolygons, properties } = this.props;

    if (prevProps.drawnPolygons.length != this.props.drawnPolygons.length) {
      let newSelectedDistricts = [];

      selectedDistricts.forEach((district) => {
        if (
          drawnPolygons.find((p) => p.metadata && p.metadata.id === district.id)
        ) {
          newSelectedDistricts.push(district);
        }
      });

      this.setState({
        selectedDistricts: newSelectedDistricts,
      });
    }

    // trigger catastro reference extraction on selected plot change
    if (
      prevProps.selectedPlot !== this.props.selectedPlot &&
      this.props.selectedPlot
    ) {
      this.findCatastralByRef(this.props.selectedPlot.refcat);
    } else if (!this.props.selectedPlot && prevProps.selectedPlot) {
      this.setState({
        searchResults: [],
      });

      this.props.dispatch(setCmaProperty(null));
    }
  }

  getSurfaceTotalFromInternalProperty(property) {
    if (property.buildingType == "land") {
      return parseFloat(property.size);
    }

    if (property.plotSize) {
      return parseFloat(property.plotSize);
    }

    return parseFloat(property.size);
  }

  // gets the subtypology if exists, else the base typology
  // for the cma form
  getTypologyForForm(property) {
    if (!property.detailedType.subTypology) {
      return property.detailedType.typology;
    }

    return property.detailedType.subTypology;
  }

  // converts an internal property to cma compatible property
  internalPropertyToCmaProperty(property, url) {
    return {
      id: property.id,
      ref: getPropertyTitle(property),
      address: property.address,
      zip_code: property.zip_code,
      plotSize: this.getSurfaceTotalFromInternalProperty(property),
      url: url,
      typology: this.getTypologyForForm(property),
      type: getPropertyTypologyHuman(property),
      latitude: property.latitude,
      longitude: property.longitude,
      isCmaProperty: true,
      province: property.province,
      municipality: property.municipality,
      price: property.price,
      detailedType: property.detailedType,
      rooms: property.rooms,
      bathrooms: property.bathrooms,
      status: property.status,
      features: getFeatureFilterArrayFromProperty(property),
      size: property.size,
      images: property.multimedia.images,
      buildingType: property.buildingType,
      land_type: property.land_type,
      commercial_type: property.commercial_type,
      land_buildableSize: property.land_buildableSize,
      country: property.country,
    };
  }

  // internal properties are properties on our system and not catastro
  onInternalPropertyDataRetrieved(property) {
    let compatibleProperty = {
      ...property,
      latitude: parseFloat(property.latitude),
      longitude: parseFloat(property.longitude),
    };

    // get the district for the property, this allows
    // us to prefill the municipality districts for the district selection panel
    this.resolvePropertyDistricts(compatibleProperty);

    this.setState({
      searchResults: [compatibleProperty],
    });

    this.props.dispatch(setCmaProperty(compatibleProperty));
    this.props.dispatch(setProperties([compatibleProperty]));

    window.googleMapsInstance.setZoom(18);
    window.googleMapsInstance.panTo({
      lat: compatibleProperty.latitude,
      lng: compatibleProperty.longitude,
    });

    this.onShiftMapForCmaPanel();
  }

  // search google places for for the user query
  onAddressSearch = (query) => {
    return new Promise((resolve, reject) => {
      // Create a PlacesService instance
      const placesService = new window.google.maps.places.PlacesService(
        window.googleMapsInstance,
      );

      // Perform the text search
      placesService.textSearch(
        {
          query: query,
          region: "es",
        },
        async (results, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            results &&
            results[0]
          ) {
            if (results && results.length > 0) {
              // Get the first result
              let firstResult = results[0];

              // Get the latitude and longitude from the first result
              let lat = firstResult.geometry.location.lat();
              let lng = firstResult.geometry.location.lng();

              window.googleMapsInstance.setZoom(ZOOM_LEVEL_TO_SHOW_PLOTS + 1);
              window.googleMapsInstance.panTo({
                lat: lat,
                lng: lng,
              });

              this.onShiftMapForCmaPanel();
              resolve({ lat, lng });

              // show map if mobile
              if (isMobile()) {
                this.setPanelHidden(true);
              }
            }
          } else {
            console.error("Text search failed:", status);
          }
        },
      );
    });
  };

  // account for the cma panel so the areas are centered correctly
  onShiftMapForCmaPanel() {
    if (isMobile()) {
      return;
    }

    let cmaPanel = document.getElementById("cma-panel");
    let cmaPanelWidth = cmaPanel.offsetWidth;
    let navbar = document.querySelector(".navbar");
    let navbarWidth = navbar.offsetWidth;
    window.googleMapsInstance.panBy((cmaPanelWidth + navbarWidth) / 2, 0);
  }

  // gets the area the property is based on and draw it automatically
  // onto the map as a default study area
  async resolvePropertyDistricts(property) {
    this.setState({
      loadingPropertyDistricts: true,
    });

    let propertyDistrict = await getNearestNeighbourGeoboundary(
      property.latitude,
      property.longitude,
      "district",
    );

    let propertyMunicipality = await getNearestNeighbourGeoboundary(
      property.latitude,
      property.longitude,
      "municipality",
    );

    // fallback to municipality if no district present
    if (!propertyDistrict) {
      propertyDistrict = propertyMunicipality;
    }

    if (propertyDistrict) {
      let districts = await getDistrictsForMunicipality(
        propertyDistrict.mun_code,
      );

      this.setState({
        loadingPropertyDistricts: false,
      });

      // no districts tied to municipality so just render municipality
      if (districts.length === 0) {
        let areas = [propertyMunicipality];
        areas = areas.sort((a, b) => a.name.localeCompare(b.name));
        this.setState(
          {
            selectedDistricts: areas,
            districts: areas,
            propertyDistrict: areas[0],
          },
          () => {
            // auto draw area of property district
            const { stage, propertyDistrict } = this.state;
            if (stage == CMA_STAGE_STUDY_AREA && propertyDistrict) {
              this.props.drawArea(propertyMunicipality, {
                fetchProperties: true,
                type: "CMA",
              });
            }
          },
        );
      } else {
        // place property district first
        districts = [
          propertyDistrict,
          ...districts
            .filter((d) => d.id !== propertyDistrict.id)
            .sort((a, b) => a.name.localeCompare(b.name)),
        ];

        this.setState(
          {
            selectedDistricts: [propertyDistrict],
            districts,
            propertyDistrict: propertyDistrict,
          },
          () => {
            // auto draw area of property district
            const { stage, propertyDistrict } = this.state;
            if (stage == CMA_STAGE_STUDY_AREA && propertyDistrict) {
              this.props.drawArea(propertyDistrict, {
                fetchProperties: true,
                type: "CMA",
              });
            }
          },
        );
      }
    }
  }

  // receive catastro data and convert to cma compatible property
  // which is then rendered as a reference pin on the map
  onCatastralDataRetrieved(data) {
    // if no selected plot then we cant do anything
    if (!this.props.selectedPlot) {
      return;
    }

    const { selectedPlot, saleType } = this.props;

    // if no data or refs then we cant do anything
    if (!data || data.length === 0) {
      return toast(i18n("No properties found at this location."), {
        duration: 5000,
      });
    }

    // show panel if mobile as we have received results
    if (isMobile()) {
      this.setPanelHidden(false);
    }

    let properties = [];
    let property = null;

    // catastro refs can be multi refs so in this case we make multiple properties
    if (data instanceof Array) {
      properties = data.map((d) =>
        catastroToCmaProperty(
          d,
          selectedPlot.refcat,
          selectedPlot.center_y,
          selectedPlot.center_x,
          selectedPlot,
        ),
      );
      property = properties[0];

      // if we have a temp full ref then auto select the property
      let autoSelectProperty = properties.find(
        (p) => p.ref == this.state.tempFullRef,
      );
      if (autoSelectProperty) {
        this.onResultClick(autoSelectProperty);
      }
    } else {
      property = catastroToCmaProperty(
        data,
        selectedPlot.refcat,
        selectedPlot.center_y,
        selectedPlot.center_x,
        selectedPlot,
      );
      properties = [property];

      // if we have a temp full ref then auto select the property
      if (property.ref == this.state.tempFullRef) {
        this.onResultClick(property);
      }
    }

    // set search results to the properties extracted
    this.setState(
      {
        searchResults: properties,
        tempFullRef: null,
      },
      () => {
        // scroll to the top of the search results panel
        document.querySelector(".cma-form_search-results")?.scrollTo(0, 0);
      },
    );

    // already start loading the districts for this catastro property
    this.resolvePropertyDistricts(property);
  }

  // get the catastral data by ref
  async findCatastralByRef(ref) {
    this.setState({
      loadingCatastral: true,
    });

    let promise = getCatastralByRefDirect(ref);
    toast.promise(promise, {
      loading: i18n("Extracting catastral reference..."),
      success: i18n("Catastral extracted"),
      error: i18n("Failed to extract catastral"),
      duration: 1000,
    });

    promise
      .then((data) => {
        this.onCatastralDataRetrieved(data);
      })
      .finally(() => {
        this.setState({
          loadingCatastral: false,
        });
      });
  }

  // clicking a card in the cma result list
  // prevent further drawing of plots
  // and keep selected plot drawn
  onResultClick(property) {
    this.props.dispatch(setCmaProperty(property));
    clearAllButSelectedPlot();
    this.props.dispatch(setCanDrawPlots(false));
  }

  renderSearch() {
    return (
      <div className="cma-form_search">
        <SearchInput
          placeholder={i18n("Search...")}
          onEnter={(query) =>
            searchMapForReferences(
              query,
              "cma",
              this.onAddressSearch.bind(this),
            )
          }
        />
      </div>
    );
  }

  renderHelpDialog() {
    return (
      <>
        {this.renderSearch()}
        <div className="cma-form_help-container">
          <div className="cma-form_help-dialog">
            <p>{i18n("Find a property by:")}</p>
            <ul>
              <li>
                <div className="cma-form_help-dialog_num">
                  <span>1</span>
                </div>
                <span className="cma-form_help-dialog_desc">
                  {i18n("Pasting the cadastral reference")}
                </span>
              </li>
              <li>
                <div className="cma-form_help-dialog_num">
                  <span>2</span>
                </div>
                <span className="cma-form_help-dialog_desc">
                  {i18n("Clicking a property on the map")}
                </span>
              </li>
              <li>
                <div className="cma-form_help-dialog_num">
                  <span>3</span>
                </div>
                <span className="cma-form_help-dialog_desc">
                  {i18n("Searching by property address")} (
                  <i>{i18n("less reliable")}</i>)
                </span>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }

  renderSearchResults() {
    const { searchResults } = this.state;
    const { saleType } = this.props;

    // filter out non residential properties if we are looking for rent
    const results = searchResults.filter((p) => {
      if (saleType == "rent") {
        return p.buildingType == "property";
      }

      return true;
    });

    return (
      <>
        {this.renderSearch()}
        <div className="cma-form_search-results">
          {results.map((property, index) => (
            <CmaPropertyCard
              onClick={() => this.onResultClick(property)}
              key={index}
              property={property}
            />
          ))}
        </div>
      </>
    );
  }

  // commercial properties have different filters
  renderCommercialSelectedFlow() {
    const { cmaProperty } = this.props.cma;

    let commercialTypeFilter = COMMERCIAL_TYPE_FILTERS.find(
      (f) => f.value == cmaProperty.commercial_type,
    );

    let conditionFilter = PROPERTY_CONDITTION_FILTERS.find(
      (f) => f.value == cmaProperty.status,
    );

    return (
      <div className="cma-form_selected-flow">
        <Helmet>
          <title>{`${i18n("CMA")}: ${cmaProperty.ref}`}</title>
        </Helmet>
        <CmaPropertyCard selected property={cmaProperty} />
        <div className="cma-form_selected-flow_categories">
          <div className="cma-form_selected-flow_row">
            <div className="cma-form_selected-flow_col">
              <p>{i18n("Commercial Type")}*</p>
              <Dropdown
                className="sort-dropdown"
                items={COMMERCIAL_TYPE_FILTERS.map((filter, index) => ({
                  label: FILTER_LABEL_MAPPINGS[filter.label](),
                  value: index,
                  onSelect: () => {
                    let property = { ...cmaProperty };
                    property.commercial_type = filter.value;
                    this.props.dispatch(setCmaProperty(property));
                  },
                }))}
                label={
                  commercialTypeFilter
                    ? FILTER_LABEL_MAPPINGS[commercialTypeFilter.label]()
                    : i18n("Select")
                }
              />
            </div>
          </div>
          <div className="cma-form_selected-flow_row">
            <div className="cma-form_selected-flow_col">
              <p>{i18n("Area built m²")}*</p>
              <PrimaryInput
                type="number"
                placeholder="m²"
                value={cmaProperty.size}
                onChange={(e) => {
                  let inputVal = e.target.value;
                  let property = { ...cmaProperty };
                  property.size = parseInt(inputVal);
                  this.props.dispatch(setCmaProperty(property));
                }}
              />
            </div>
            <div className="cma-form_selected-flow_col">
              <p>{i18n("Floors")}</p>
              <PrimaryInput
                type="number"
                placeholder={i18n("Floors")}
                value={cmaProperty.numFloors}
                onChange={(e) => {
                  let inputVal = e.target.value;
                  let property = { ...cmaProperty };
                  property.numFloors = parseInt(inputVal);
                  this.props.dispatch(setCmaProperty(property));
                }}
              />
            </div>
          </div>
          <div className="cma-form_selected-flow_row">
            <div className="cma-form_selected-flow_col">
              <p>{i18n("Rooms")}</p>
              <PrimaryInput
                type="number"
                placeholder={i18n("Rooms")}
                value={cmaProperty.rooms}
                onChange={(e) => {
                  let inputVal = e.target.value;
                  let property = { ...cmaProperty };
                  property.rooms = parseInt(inputVal);
                  this.props.dispatch(setCmaProperty(property));
                }}
              />
            </div>
            <div className="cma-form_selected-flow_col">
              <p>{i18n("Toilets")}</p>
              <PrimaryInput
                type="number"
                placeholder={i18n("Toilets")}
                value={cmaProperty.bathrooms}
                onChange={(e) => {
                  let inputVal = e.target.value;
                  let property = { ...cmaProperty };
                  property.bathrooms = parseInt(inputVal);
                  this.props.dispatch(setCmaProperty(property));
                }}
              />
            </div>
          </div>
          <div className="cma-form_selected-flow_row">
            <div className="cma-form_selected-flow_col">
              <p>{i18n("Condition")}*</p>
              <Dropdown
                className="sort-dropdown"
                items={PROPERTY_CONDITTION_FILTERS.map((filter, index) => ({
                  label: FILTER_LABEL_MAPPINGS[filter.label](),
                  value: index,
                  onSelect: () => {
                    let property = { ...cmaProperty };
                    property.status = filter.value;
                    this.props.dispatch(setCmaProperty(property));
                  },
                }))}
                label={
                  conditionFilter
                    ? FILTER_LABEL_MAPPINGS[conditionFilter.label]()
                    : i18n("Select")
                }
              />
            </div>
          </div>
        </div>
        <div className="cma-form_selected-flow_features">
          <h2>{i18n("Features")}</h2>
          <p>
            {i18n(
              "Select property features to reference within your study area and include in the valuation.",
            )}
          </p>
          <div className="cma-form_selected-flow_row feature_set">
            <div className="cma-form_selected-flow_col">
              {COMMERCIAL_FEATURES_FILTERS.slice(0, 7).map((feature, index) => (
                <div
                  className="cma-form_selected-flow_col_checkbox"
                  key={index}
                  onClick={() => this.onFeatureSelect(feature)}
                >
                  <Checkbox
                    label={i18n(feature.label)}
                    checked={this.isFeatureSelected(feature)}
                  />
                </div>
              ))}
            </div>
            <div className="cma-form_selected-flow_col">
              {COMMERCIAL_FEATURES_FILTERS.slice(7).map((feature, index) => (
                <div
                  className="cma-form_selected-flow_col_checkbox"
                  key={index}
                  onClick={() => this.onFeatureSelect(feature)}
                >
                  <Checkbox
                    label={i18n(feature.label)}
                    checked={this.isFeatureSelected(feature)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <ValuationDetails property={cmaProperty} />
      </div>
    );
  }

  // lands have different filters to properties
  renderLandSelectedFlow() {
    let cmaProperty = this.props.cma.cmaProperty;
    let landTypeFilter = LAND_TYPE_FILTERS.find(
      (f) => f.value == cmaProperty.land_type,
    );

    return (
      <div className="cma-form_selected-flow">
        <CmaPropertyCard selected property={this.props.cma.cmaProperty} />
        <div className="cma-form_selected-flow_categories">
          <div className="cma-form_selected-flow_row">
            <div className="cma-form_selected-flow_col">
              <p>{i18n("Land Type")}*</p>
              <Dropdown
                className="sort-dropdown"
                items={LAND_TYPE_FILTERS.map((filter, index) => ({
                  label: FILTER_LABEL_MAPPINGS[filter.label](),
                  value: index,
                  onSelect: () => {
                    let property = { ...cmaProperty };
                    property.land_type = filter.value;
                    this.props.dispatch(setCmaProperty(property));
                  },
                }))}
                label={
                  landTypeFilter
                    ? FILTER_LABEL_MAPPINGS[landTypeFilter.label]()
                    : i18n("Select")
                }
              />
            </div>
          </div>
          <div className="cma-form_selected-flow_row">
            <div className="cma-form_selected-flow_col">
              <p>{i18n("Surface m²")}*</p>
              <PrimaryInput
                type="number"
                placeholder="m²"
                value={this.props.cma.cmaProperty.plotSize}
                onChange={(e) => {
                  let inputVal = e.target.value;
                  let property = { ...cmaProperty };
                  property.plotSize = parseInt(inputVal);
                  property.size = parseInt(inputVal);
                  this.props.dispatch(setCmaProperty(property));
                }}
              />
            </div>
            <div className="cma-form_selected-flow_col">
              <p>{i18n("Buildable m²")}</p>
              <PrimaryInput
                type="number"
                placeholder="m²"
                value={cmaProperty.land_buildableSize}
                onChange={(e) => {
                  let inputVal = e.target.value;
                  let property = { ...cmaProperty };
                  property.land_buildableSize = parseInt(inputVal);
                  this.props.dispatch(setCmaProperty(property));
                }}
              />
            </div>
          </div>
          <div className="cma-form_selected-flow_row">
            <div className="cma-form_selected-flow_col">
              <p>{i18n("Buildable floors")}</p>
              <PrimaryInput
                type="number"
                placeholder={i18n("Floors")}
                value={cmaProperty.land_buildableFloors}
                onChange={(e) => {
                  let inputVal = e.target.value;
                  let property = { ...cmaProperty };
                  property.land_buildableFloors = parseInt(inputVal);
                  this.props.dispatch(setCmaProperty(property));
                }}
              />
            </div>
          </div>
        </div>
        <div className="cma-form_selected-flow_features">
          <h2>{i18n("Features")}</h2>
          <p>
            {i18n(
              "Select property features to reference within your study area and include in the valuation.",
            )}
          </p>
          <div className="cma-form_selected-flow_row feature_set">
            <div className="cma-form_selected-flow_col">
              {LAND_FEATURES_FILTERS.slice(0, 4).map((feature, index) => (
                <div
                  className="cma-form_selected-flow_col_checkbox"
                  key={index}
                  onClick={() => this.onFeatureSelect(feature)}
                >
                  <Checkbox
                    label={i18n(feature.label)}
                    checked={this.isFeatureSelected(feature)}
                  />
                </div>
              ))}
            </div>
            <div className="cma-form_selected-flow_col">
              {LAND_FEATURES_FILTERS.slice(4).map((feature, index) => (
                <div
                  className="cma-form_selected-flow_col_checkbox"
                  key={index}
                  onClick={() => this.onFeatureSelect(feature)}
                >
                  <Checkbox
                    label={i18n(feature.label)}
                    checked={this.isFeatureSelected(feature)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <ValuationDetails property={cmaProperty} />
      </div>
    );
  }

  // property selection flow
  // this includes the filters that get added to the state
  renderSelectedFlow() {
    const { cmaProperty } = this.props.cma;

    if (cmaProperty.buildingType == "land") {
      return this.renderLandSelectedFlow();
    }

    if (cmaProperty.buildingType == "commercial") {
      return this.renderCommercialSelectedFlow();
    }

    let propertyTypeFilter = PROPERTY_TYPE_FILTERS.find(
      (f) => f.code == cmaProperty.typology,
    );
    let conditionFilter = PROPERTY_CONDITTION_FILTERS.find(
      (f) => f.value == cmaProperty.status,
    );

    let isFlatTypology =
      cmaProperty.typology == "flat" ||
      cmaProperty.typology == "penthouse" ||
      cmaProperty.typology == "duplex";

    return (
      <div className="cma-form_selected-flow">
        <CmaPropertyCard selected property={cmaProperty} />
        <div className="cma-form_selected-flow_categories">
          <div className="cma-form_selected-flow_row">
            <div className="cma-form_selected-flow_col">
              <p>{i18n("Property Type")}*</p>
              <Dropdown
                className="sort-dropdown"
                items={PROPERTY_TYPE_FILTERS.map((filter, index) => ({
                  label: FILTER_LABEL_MAPPINGS[filter.label](),
                  value: index,
                  onSelect: () => {
                    let property = { ...cmaProperty };
                    let typology = filter.code;
                    property.typology = typology;

                    // set plot size to null if typology is flat, penthouse or duplex
                    if (
                      typology == "flat" ||
                      typology == "penthouse" ||
                      typology == "duplex"
                    ) {
                      property.plotSize = 0;
                    }

                    this.props.dispatch(setCmaProperty(property));
                  },
                }))}
                label={
                  propertyTypeFilter
                    ? FILTER_LABEL_MAPPINGS[propertyTypeFilter.label]()
                    : i18n("Select")
                }
              />
            </div>
          </div>
          <div className="cma-form_selected-flow_row">
            <div className="cma-form_selected-flow_col">
              <p>{i18n("Bedrooms")}*</p>
              <Dropdown
                className="sort-dropdown"
                items={BEDROOM_BATHROOM_RANGE.map((filter, index) => ({
                  label: filter,
                  value: index,
                  onSelect: () => {
                    // attach the rooms to the property object
                    let property = { ...cmaProperty };
                    property.rooms = filter;
                    this.props.dispatch(setCmaProperty(property));
                  },
                }))}
                label={cmaProperty.rooms ?? i18n("Select")}
              />
            </div>
            <div className="cma-form_selected-flow_col">
              <p>{i18n("Bathrooms")}*</p>
              <Dropdown
                className="sort-dropdown"
                items={BEDROOM_BATHROOM_RANGE.map((filter, index) => ({
                  label: filter,
                  value: index,
                  onSelect: () => {
                    // attach bathrooms to the property object
                    let property = { ...cmaProperty };
                    property.bathrooms = filter;
                    this.props.dispatch(setCmaProperty(property));
                  },
                }))}
                label={cmaProperty.bathrooms ?? i18n("Select")}
              />
            </div>
          </div>
          <div className="cma-form_selected-flow_row">
            <div className="cma-form_selected-flow_col">
              <p>{i18n("Area built m²")}*</p>
              <PrimaryInput
                type="number"
                placeholder="m²"
                value={cmaProperty.size}
                onChange={(e) => {
                  let inputVal = e.target.value;
                  let property = { ...cmaProperty };
                  property.size = parseInt(inputVal);
                  this.props.dispatch(setCmaProperty(property));
                }}
              />
            </div>
            <div
              className={
                "cma-form_selected-flow_col" +
                (isFlatTypology ? " disabled" : "")
              }
            >
              <p>{i18n("Surface m²")}</p>
              <PrimaryInput
                type="number"
                placeholder="m²"
                value={cmaProperty.plotSize}
                onChange={(e) => {
                  let inputVal = e.target.value;
                  let property = { ...cmaProperty };
                  property.plotSize = parseInt(inputVal);
                  this.props.dispatch(setCmaProperty(property));
                }}
                disabled={isFlatTypology}
              />
            </div>
          </div>
          <div className="cma-form_selected-flow_row">
            <div className="cma-form_selected-flow_col">
              <p>{i18n("Condition")}*</p>
              <Dropdown
                className="sort-dropdown"
                items={PROPERTY_CONDITTION_FILTERS.map((filter, index) => ({
                  label: FILTER_LABEL_MAPPINGS[filter.label](),
                  value: index,
                  onSelect: () => {
                    let property = { ...cmaProperty };
                    property.status = filter.value;
                    this.props.dispatch(setCmaProperty(property));
                  },
                }))}
                label={
                  conditionFilter
                    ? FILTER_LABEL_MAPPINGS[conditionFilter.label]()
                    : i18n("Select")
                }
              />
            </div>
          </div>
        </div>
        <div className="cma-form_selected-flow_features">
          <h2>{i18n("Features")}</h2>
          <p>
            {i18n(
              "Select property features to reference within your study area and include in the valuation.",
            )}
          </p>
          <div className="cma-form_selected-flow_row feature_set">
            <div className="cma-form_selected-flow_col">
              {PROPERTY_FEATURES_FIRST_COL.map((feature, index) => (
                <div
                  className="cma-form_selected-flow_col_checkbox"
                  key={index}
                  onClick={() => this.onFeatureSelect(feature)}
                >
                  <Checkbox
                    label={FILTER_LABEL_MAPPINGS[feature.label]()}
                    checked={this.isFeatureSelected(feature)}
                  />
                </div>
              ))}
            </div>
            <div className="cma-form_selected-flow_col">
              {PROPERTY_FEATURES_SECOND_COL.map((feature, index) => (
                <div
                  className="cma-form_selected-flow_col_checkbox"
                  key={index}
                  onClick={() => this.onFeatureSelect(feature)}
                >
                  <Checkbox
                    label={FILTER_LABEL_MAPPINGS[feature.label]()}
                    checked={this.isFeatureSelected(feature)}
                  />
                </div>
              ))}
            </div>
          </div>
          <ValuationDetails property={cmaProperty} />
        </div>
      </div>
    );
  }

  isFeatureSelected(feature) {
    const { cmaProperty } = this.props.cma;
    return cmaProperty.features.find((f) => f.id === feature.id);
  }

  // toggle feature filter
  onFeatureSelect(feature) {
    let cmaProperty = { ...this.props.cma.cmaProperty };
    let features = cmaProperty.features.filter((f) => f.id !== feature.id);

    if (!this.isFeatureSelected(feature)) {
      features.push(feature);
    }

    // interpolate the features onto the property object
    cmaProperty.features = features;
    this.props.dispatch(setCmaProperty(cmaProperty));
  }

  renderCatastralLoader() {
    return (
      <>
        {this.renderSearch()}
        <div className="cma-form_loader mt-8 flex flex-1 justify-center">
          <p>{i18n("Loading records...")}</p>
        </div>
      </>
    );
  }

  // selection and parameter stage
  renderStageOne() {
    const { cmaProperty } = this.props.cma;
    const { searchResults, loadingCatastral } = this.state;

    if (loadingCatastral) {
      return this.renderCatastralLoader();
    } else if (cmaProperty) {
      return this.renderSelectedFlow();
    } else if (searchResults.length > 0) {
      return this.renderSearchResults();
    } else {
      return this.renderHelpDialog();
    }
  }

  // when a district is selected we draw it on the map
  // by sending a drawArea to our ancestor
  // if its already drawn we delete it from the map
  onDistrictSelect(district) {
    const { selectedDistricts } = this.state;
    const { drawnPolygons } = this.props;
    let newSelectedDistricts = [...selectedDistricts];

    if (selectedDistricts.find((d) => d.id == district.id)) {
      newSelectedDistricts = newSelectedDistricts.filter(
        (d) => d.id !== district.id,
      );
    } else {
      newSelectedDistricts.push(district);
    }

    this.setState(
      {
        selectedDistricts: newSelectedDistricts,
      },
      () => {
        const { selectedDistricts } = this.state;

        if (selectedDistricts.find((d) => d.id == district.id)) {
          getMapObjectById("es-districts", district.id).then((geoBoundary) => {
            this.props.drawArea(geoBoundary, {
              fetchProperties: true,
              saleType: "sale",
            });

            this.props.zoomToDrawnPolygons([geoBoundary]);
            this.onShiftMapForCmaPanel();
          });
        } else {
          let polygon = drawnPolygons.find(
            (p) => p.metadata && p.metadata.id === district.id,
          );
          if (polygon) {
            this.props.onPolygonDelete(polygon.irealtyId);
          }
        }
      },
    );
  }

  // study area stage
  renderStageTwo() {
    const { selectedDistricts, propertyDistrict, loadingPropertyDistricts } =
      this.state;
    const { districts, searchTerm } = this.state;

    function getFilteredDistricts() {
      if (searchTerm) {
        return (
          searchTerm &&
          districts.filter((district) =>
            normalizeString(district.name).includes(searchTerm.toLowerCase()),
          )
        );
      }

      return districts;
    }

    return (
      <div className="cma-form_stage-2">
        <div className="cma-search-input">
          <input
            type="text"
            placeholder={i18n("Search Areas")}
            value={searchTerm}
            onChange={(e) => this.setState({ searchTerm: e.target.value })}
          />
        </div>
        <AreaSelectionPanelContent
          filteredAreas={getFilteredDistricts()}
          propertyDistrict={propertyDistrict}
          selectedAreas={selectedDistricts}
          isActive
          listMode="flat"
          loading={loadingPropertyDistricts}
          handleCheckboxChange={(district) => this.onDistrictSelect(district)}
        />
      </div>
    );
  }

  // change sort option and scroll property panel to the top
  onSort(option) {
    this.props.dispatch(setSort(option));
    window.propertyPanelScrollToProperty(null);
  }

  onSelectProperty(property) {
    const { selectedProperties, selectedPropertiesObjects } = this.state;
    if (selectedProperties.includes(property.id)) {
      this.setState({
        selectedProperties: selectedProperties.filter((p) => p !== property.id),

        selectedPropertiesObjects: selectedPropertiesObjects.filter(
          (p) => p.id !== property.id,
        ),
      });
    } else {
      this.setState({
        selectedProperties: [...selectedProperties, property.id],
        selectedPropertiesObjects: [...selectedPropertiesObjects, property],
      });
    }
  }

  // filter properties for the references stage
  filterProperties(type) {
    const { cmaProperty } = this.props.cma;
    const { properties } = this.props;

    // get saleType from props
    const { saleType } = this.props;
    if (!type) {
      type = saleType;
    }

    let filteredByType;

    if (type == "sold") {
      // Filter properties that have a saleType of sold
      filteredByType = properties.filter((p) => p.saleType == "sold");
    } else {
      // Filter properties that have a saleType of sale
      filteredByType = properties.filter(
        (p) => p.saleType == "sale" || p.saleType == type,
      );
    }

    let filteredProperties = filterByRelevantTypology(
      cmaProperty,
      filteredByType,
    );
    // remove the cma property from the list so we dont reference it

    filteredProperties = filteredProperties.filter(
      (p) => !p.isCmaProperty || cmaProperty.id !== p.id,
    );

    // filter out properties that were sold more than 2 years ago
    filteredProperties = filteredProperties.filter((p) => {
      if (p.saleType == "sold") {
        // convert timestamp string unix timestamp
        const timestamp = parseInt(p.timestamp);

        // get unix timestamp in seconds for 2 years ago
        const twoYearsAgoUnix = Math.floor(
          (Date.now() - 2 * 365 * 24 * 60 * 60 * 1000) / 1000,
        );
        return timestamp > twoYearsAgoUnix;
      } else {
        return true;
      }
    });

    // sort the properties by similarity score
    filteredProperties = cmaSort(cmaProperty, filteredProperties);

    // return a max of 100 references
    filteredProperties = filteredProperties.slice(0, 100);

    return filteredProperties;
  }

  // select top matches based on similarity score
  selectTopMatches() {
    const {
      selectedProperties,
      selectedPropertiesObjects,
      topMatchesSelected,
      topMatchesSelectedSold,
      stage,
    } = this.state;
    let filteredProperties = this.filterProperties(
      stage === CMA_STAGE_SOLD_REFERENCES ? "sold" : this.props.saleType,
    );
    filteredProperties = cmaSort(
      this.props.cma.cmaProperty,
      filteredProperties,
    ).filter((p) => p.similarityScore > 0.9);

    if (stage === CMA_STAGE_SOLD_REFERENCES) {
      // Handle sold references stage
      if (
        selectedProperties.length === filteredProperties.length ||
        topMatchesSelectedSold
      ) {
        // Only remove sold properties that were added via top matches
        const remainingProperties = selectedProperties.filter((id) => {
          const property = selectedPropertiesObjects.find((p) => p.id === id);
          return !filteredProperties.find((fp) => fp.id === id);
        });
        const remainingPropertiesObjects = selectedPropertiesObjects.filter(
          (p) => {
            return !filteredProperties.find((fp) => fp.id === p.id);
          },
        );

        this.setState({
          selectedProperties: remainingProperties,
          selectedPropertiesObjects: remainingPropertiesObjects,
          topMatchesSelectedSold: false,
        });
      } else {
        // Add new properties to existing selection for sold properties
        const newSelectedProperties = [
          ...selectedProperties,
          ...filteredProperties
            .map((p) => p.id)
            .filter((id) => !selectedProperties.includes(id)),
        ];
        const newSelectedPropertiesObjects = [
          ...selectedPropertiesObjects,
          ...filteredProperties.filter(
            (p) => !selectedPropertiesObjects.map((sp) => sp.id).includes(p.id),
          ),
        ];

        this.setState({
          selectedProperties: newSelectedProperties,
          selectedPropertiesObjects: newSelectedPropertiesObjects,
          topMatchesSelectedSold: true,
        });
      }
    } else {
      // Handle regular references stage
      if (
        selectedProperties.length === filteredProperties.length ||
        topMatchesSelected
      ) {
        this.setState({
          selectedProperties: [],
          selectedPropertiesObjects: [],
          topMatchesSelected: false,
        });
      } else {
        this.setState({
          selectedProperties: filteredProperties.map((p) => p.id),
          selectedPropertiesObjects: filteredProperties,
          topMatchesSelected: true,
        });
      }
    }
  }

  // references stage
  renderStageThree() {
    const { selectedProperties, topMatchesSelected } = this.state;
    let filteredProperties = this.filterProperties(this.props.saleType);

    // if 0 check if there are sold properties
    if (filteredProperties.length == 0) {
      filteredProperties = this.filterProperties("sold");
      if (filteredProperties.length > 0) {
        this.setState({
          stage: CMA_STAGE_SOLD_REFERENCES,
          noActiveProperties: true,
        });
      } else {
        this.setState({
          stage: CMA_STAGE_SEARCH_ADDRESS,
        });

        // show toast
        toast.error(
          i18n(
            "No references properties found, please try again with a different search",
          ),
        );
      }
    }

    return (
      <CmaPropertyPanel
        selectedProperties={selectedProperties}
        selectTopMatches={this.selectTopMatches}
        topMatchesSelected={topMatchesSelected}
        onSelectProperty={this.onSelectProperty}
        properties={filteredProperties}
        onSort={this.onSort}
      />
    );
  }

  renderStageFour() {
    const { selectedProperties, topMatchesSelectedSold } = this.state;
    let filteredProperties = this.filterProperties("sold");

    return (
      <CmaPropertyPanel
        selectedProperties={selectedProperties}
        selectTopMatches={this.selectTopMatches}
        topMatchesSelected={topMatchesSelectedSold}
        onSelectProperty={this.onSelectProperty}
        properties={filteredProperties}
        onSort={this.onSort}
      />
    );
  }

  renderStage() {
    const { stage } = this.state;

    switch (stage) {
      case CMA_STAGE_SEARCH_ADDRESS:
        return this.renderStageOne();
      case CMA_STAGE_STUDY_AREA:
        return this.renderStageTwo();
      case CMA_STAGE_REFERENCES:
        return this.renderStageThree();
      case CMA_STAGE_SOLD_REFERENCES:
        return this.renderStageFour();
      default:
        return null;
    }
  }

  onBackClick() {
    if (this.state.stage === CMA_STAGE_SEARCH_ADDRESS) {
      // backing from this stage is essentially
      // the same as restarting the cma process
      this.setState({
        stage: CMA_STAGE_SEARCH_ADDRESS,
        propertyDistrict: null,
        searchResults: [],
      });

      // clear all properties
      // and selected plot and selected cma property
      this.props.dispatch(setProperties([]));
      this.props.dispatch(setSelectedPlot(null));
      this.props.dispatch(setCmaProperty(null));
      resetUrlSearchQueries();

      // re-enable drawing of plots
      clearDrawnPlots();
      this.props.dispatch(setCanDrawPlots(true));
      if (getGlobalMapInstance().zoom >= ZOOM_LEVEL_TO_SHOW_PLOTS) {
        fetchPlotsByMapBounds();
      }
    } else if (this.state.stage === CMA_STAGE_STUDY_AREA) {
      this.setState(
        {
          stage: CMA_STAGE_SEARCH_ADDRESS,
        },
        () => {
          for (let polygon of this.props.drawnPolygons) {
            this.props.onPolygonDelete(polygon.irealtyId);
          }
        },
      );
      this.props.dispatch(setHideMapTools(true));
    } else if (this.state.stage === CMA_STAGE_REFERENCES) {
      this.setState({
        stage: CMA_STAGE_STUDY_AREA,
        selectedProperties: [],
        selectedPropertiesObjects: [],
        topMatchesSelected: false,
        noActiveProperties: false,
      });

      this.props.dispatch(setHideMapTools(false));
    } else if (this.state.stage === CMA_STAGE_SOLD_REFERENCES) {
      // if there are no active properties but there are sold properties, on the back button go back to the study area stage
      if (this.state.noActiveProperties) {
        this.setState({
          stage: CMA_STAGE_STUDY_AREA,
          selectedProperties: [],
          selectedPropertiesObjects: [],
          topMatchesSelected: false,
          noActiveProperties: false,
        });
      } else {
        this.setState({
          stage: CMA_STAGE_REFERENCES,
          selectedPropertiesObjects:
            this.state.selectedPropertiesObjects.filter(
              (p) =>
                p.saleType === "sale" || p.saleType === this.props.saleType,
            ),
          selectedProperties: this.state.selectedProperties.filter((id) =>
            this.state.selectedPropertiesObjects.find(
              (p) =>
                p.id === id &&
                (p.saleType === "sale" || p.saleType === this.props.saleType),
            ),
          ),
          topMatchesSelectedSold: false,
        });
      }
      // // re-render the stage
      // // re-draw the polygons and re fetch the properties
      // this.props.drawArea(this.state.propertyDistrict, {
      //   fetchProperties: true,
      //   saleType: 'sale',
      // });
      this.renderStage();
    }
  }

  onNextClick() {
    const { stage } = this.state;

    // check if user has cma role
    // if not show pricing modal
    if (!userHasRole("cma") && trialEnded()) {
      this.props.dispatch(setPricingModal(true));
      return;
    }

    // filtered sold properties
    const filteredSoldProperties = this.filterProperties("sold");

    if (
      stage === CMA_STAGE_SOLD_REFERENCES ||
      (stage === CMA_STAGE_REFERENCES && filteredSoldProperties.length == 0) ||
      (stage === CMA_STAGE_REFERENCES && this.props.saleType == "rent")
    ) {
      return this.onValuate();
    }

    this.setState(
      {
        stage: this.state.stage + 1,
      },
      () => {
        // unhide map tools for this stage
        // and draw the polygon for the property district
        if (this.state.stage == CMA_STAGE_STUDY_AREA) {
          this.props.dispatch(setHideMapTools(false));

          const { propertyDistrict } = this.state;
          if (propertyDistrict) {
            this.props.drawArea(propertyDistrict, {
              fetchProperties: true,
              saleType: this.props.saleType || "sale",
              type: "cma",
            });

            this.props.zoomToDrawnPolygons([propertyDistrict]);
            this.onShiftMapForCmaPanel();

            this.setState({
              selectedDistricts: [propertyDistrict],
            });
          }
        } else {
          this.props.dispatch(setHideMapTools(true));
          if (
            stage === CMA_STAGE_REFERENCES &&
            this.props.properties.filter(
              (p) => p.saleType == this.props.saleType,
            ).length == 0 &&
            this.props.properties.filter((p) => p.saleType == "sold").length > 0
          ) {
            this.setState({
              stage: CMA_STAGE_SOLD_REFERENCES,
            });
          }
        }
      },
    );
  }

  // gets property size for valuation based on if land or property
  getPropertySize(property) {
    if (property.buildingType == "land") {
      return parseFloat(property.plotSize);
    }

    return parseFloat(property.size);
  }

  // valuate the property after collating the required data points
  onValuate() {
    const { selectedPropertiesObjects } = this.state;
    const { cmaProperty } = this.props.cma;

    // global app loading state
    this.props.dispatch(setGlobalLoading(true));
    this.props.dispatch(
      setGlobalLoadingMessage(
        i18n("Valuating property with") +
          " " +
          selectedPropertiesObjects.length +
          " " +
          i18n("references"),
      ),
    );

    // construct a backend ready-to-valuate
    // property object
    const propertyObj = {
      id: cmaProperty.id,
      ref: cmaProperty.isCatastro ? cmaProperty.ref : null,
      size: this.getPropertySize(cmaProperty),
    };

    // convert selectedPropertiesObjects to an array with price and size
    const selectedPropertiesFull = selectedPropertiesObjects.map((p) => {
      return {
        ...p,
        price: parseFloat(p.price),
        size: parseFloat(p.size),
      };
    });

    valuatePropertyRequest(
      propertyObj,
      selectedPropertiesFull,
      propertyObj.ref,
    ).then((data) => {
      this.props.dispatch(setGlobalLoading(false));
      if (data) {
        // apply any conditions to the property value
        const propertyValue = data.property_value;
        const propertyValueWithConditions = this.applyConditions(
          propertyValue,
          cmaProperty,
        );
        this.setState({
          valuationModalOpen: true,
          valuationValue: propertyValueWithConditions,
        });
      }
    });
  }

  applyConditions(propertyValue, property) {
    const { conditions } = this.props;

    let influencedValue = propertyValue;

    for (let condition of conditions) {
      influencedValue += influencedValue * condition.value;
    }

    return influencedValue;
  }

  isNextButtonEnabled() {
    const { drawnPolygons, properties } = this.props;
    const { cmaProperty } = this.props.cma;
    const { stage, selectedProperties } = this.state;

    // make sure required fields are filled
    if (stage === CMA_STAGE_SEARCH_ADDRESS) {
      if (cmaProperty.buildingType == "property") {
        return (
          cmaProperty.size > 0 &&
          cmaProperty.typology &&
          cmaProperty.status &&
          cmaProperty.rooms &&
          cmaProperty.bathrooms
        );
      }
      if (cmaProperty.buildingType == "land") {
        return cmaProperty.plotSize > 0 && cmaProperty.land_type;
      }
      if (cmaProperty.buildingType == "commercial") {
        return (
          cmaProperty.size > 0 &&
          cmaProperty.commercial_type &&
          cmaProperty.status
        );
      }
    }

    if (stage === CMA_STAGE_STUDY_AREA) {
      return drawnPolygons.length > 0;
    }

    if (stage === CMA_STAGE_REFERENCES) {
      return selectedProperties.length > 0;
    }

    if (stage === CMA_STAGE_SOLD_REFERENCES) {
      return selectedProperties.length > 0;
    }

    return false;
  }

  renderFooter() {
    const { stage, selectedProperties } = this.state;
    const { cmaProperty } = this.props.cma;

    if (!cmaProperty) {
      return null;
    }

    let propertyText = i18n("properties");
    if (selectedProperties.length === 1) {
      propertyText = i18n("property");
    }

    let doneText =
      i18n("Done") +
      " (" +
      selectedProperties.length +
      " " +
      propertyText +
      ")";

    return (
      <div className="cma-form_footer">
        <Button
          className="cma-footer-btn"
          onClick={() => this.onBackClick()}
          variant="text"
        >
          <span>{i18n("Back")}</span>
        </Button>
        <Button
          className="cma-footer-btn"
          onClick={() => this.isNextButtonEnabled() && this.onNextClick()}
          variant="primary"
          disabled={!this.isNextButtonEnabled()}
        >
          <span>
            {stage === CMA_STAGE_SOLD_REFERENCES ? doneText : i18n("Next")}
          </span>
        </Button>
      </div>
    );
  }

  renderHeader() {
    const { stage } = this.state;

    if (stage === CMA_STAGE_SEARCH_ADDRESS) {
      return (
        <div className="cma-form_header">
          <h2>{i18n("Comparative Market Analysis")}</h2>
          <p>
            {i18n(
              "Conduct a valuation on a selected property against market specific data.",
            )}
          </p>
        </div>
      );
    }

    if (stage === CMA_STAGE_STUDY_AREA) {
      return (
        <div className="cma-form_header">
          <h2>{i18n("Select Districts")}</h2>
          <p>
            {i18n(
              "Optionally select surrounding districts to include within study area of the valuation.",
            )}
          </p>
          <p className="or-draw-txt">
            {i18n("Or draw your own areas on the map using the map tools")}
          </p>
        </div>
      );
    }

    if (stage === CMA_STAGE_REFERENCES) {
      return (
        <div className="cma-form_header">
          <h2>{i18n("Reference Properties")}</h2>
          <p>
            {i18n(
              "Confirm the properties from the study area to include within the valuation.",
            )}
          </p>
        </div>
      );
    }

    if (stage === CMA_STAGE_SOLD_REFERENCES) {
      return (
        <div className="cma-form_header">
          <h2>{i18n("Reference Sold Properties")}</h2>
          <p>
            {i18n(
              "Add sold property data from the study area to the valuation.",
            )}
          </p>
        </div>
      );
    }
  }

  setPanelHidden(status) {
    this.setState({ panelHidden: status });

    if (status) {
      document.getElementById("cma-panel").classList.add("panel-hidden");
    } else {
      document.getElementById("cma-panel").classList.remove("panel-hidden");
    }
  }

  render() {
    const { stage, propertyDistrict, panelHidden } = this.state;
    const { cmaProperty } = this.props.cma;
    let rooms = null;
    let bathrooms = null;
    let features = null;

    return (
      <>
        {panelHidden && (
          <div className="cma-show-panel">
            <Button
              variant="secondary"
              onClick={() => this.setPanelHidden(false)}
            >
              {i18n("Show Panel")}
            </Button>
          </div>
        )}
        <div id="cma-panel" className="cma-panel">
          <div className="cma-form">
            <div className="cma-show-map">
              <Button variant="text" onClick={() => this.setPanelHidden(true)}>
                {i18n("Show Map")}
              </Button>
            </div>
            {this.renderHeader()}
            <div className="cma-form_stage">
              <div
                className={
                  "cma-form_stage_item cma-form_stage_item" +
                  (stage === CMA_STAGE_SEARCH_ADDRESS
                    ? " cma-stage-active"
                    : "")
                }
              >
                <div className="cma-form_stage_item_num">
                  <span>1</span>
                </div>
                <span>{i18n("Property")}</span>
              </div>
              <div className="divider"></div>
              <div
                className={
                  "cma-form_stage_item cma-form_stage_item" +
                  (stage === CMA_STAGE_STUDY_AREA ? " cma-stage-active" : "")
                }
              >
                <div className="cma-form_stage_item_num">
                  <span>2</span>
                </div>
                <span>{i18n("Study area")}</span>
              </div>
              <div className="divider"></div>
              <div
                className={
                  "cma-form_stage_item cma-form_stage_item" +
                  (stage === CMA_STAGE_REFERENCES ||
                  stage === CMA_STAGE_SOLD_REFERENCES
                    ? " cma-stage-active"
                    : "")
                }
              >
                <div className="cma-form_stage_item_num">
                  <span>3</span>
                </div>
                <span>{i18n("References")}</span>
              </div>
            </div>
            {this.renderStage()}
            {this.renderFooter()}
            {cmaProperty && (
              <CmaModal
                property={cmaProperty}
                valuationValue={this.state.valuationValue}
                isValutionModalOpen={this.state.valuationModalOpen}
                closeModal={() => this.setState({ valuationModalOpen: false })}
                district={propertyDistrict ? propertyDistrict.name : ""}
                municipality={
                  propertyDistrict ? propertyDistrict.municipality : ""
                }
                province={propertyDistrict ? propertyDistrict.province : ""}
                studyAreas={this.props.drawnPolygons}
                rooms={rooms}
                bathrooms={bathrooms}
                features={features}
                selectedProperties={this.state.selectedPropertiesObjects}
              />
            )}
          </div>
        </div>

        {stage === CMA_STAGE_SEARCH_ADDRESS && (
          <SaleTypeToggle cmaMode={true} />
        )}
      </>
    );
  }
}

export default connect((state) => ({
  drawnPolygons: state.polygon.drawnPolygons,
  properties: state.property.properties,
  sort: state.filters.sort,
  selectedPlot: state.plots.selectedPlot,
  cma: state.cma,
  user: state.user,
  saleType: state.filters.saleType,
  conditions: state.valuationDetails.conditions,
}))(React.memo(CmaPanel));
