import Dropdown from "components/core/Dropdown";
import { i18n } from "i18n/localisation";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setListingAgent } from "../../actions/myPropertiesActions";

const ListingAgent = ({ styleClass }) => {
  const { team, user, listingAgent, userDetails } = useSelector((state) => ({
    team: state.team,
    user: state.auth.user,
    listingAgent: state.myProperties.listingAgent,
    userDetails: state.user.userData,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!listingAgent) {
      dispatch(
        setListingAgent({ id: userDetails.sub, ...userDetails, ...user }),
      );
    }
  }, [listingAgent]);

  const getAgentFullName = (agent) => {
    if (!agent?.personalDetails?.firstName) {
      return agent?.email;
    }

    return `${agent?.personalDetails?.firstName} ${agent?.personalDetails?.lastName}`;
  };

  return (
    <div className="flex flex-col gap-[12px]">
      <p className="mb-2 text-sm font-medium text-gray-700">
        {i18n("Listing Agent")}
      </p>
      <span className="text-base font-normal text-[#717171]">
        {i18n(
          "Select an agent from your team to manage this property listing.",
        )}
      </span>
      <Dropdown
        className={`upload-modal-dropdown listing-agent-dropdown ${styleClass}`}
        items={team.users?.map((teamMember) => ({
          label: getAgentFullName(teamMember),
          value: teamMember.id,
          onSelect: () => {
            dispatch(setListingAgent(teamMember));
          },
        }))}
        label={getAgentFullName(listingAgent)}
        selected={listingAgent?.id}
      />
    </div>
  );
};

export default ListingAgent;
