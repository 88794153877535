import React, { useEffect, useState, useRef } from "react";
import { Page, Text, View, Document, Image, usePDF } from "@react-pdf/renderer";
import {
  getLang,
  getLargeTextLocalisation,
  getLocalisation,
  i18n,
} from "i18n/localisation";
import { CORS_PROXY } from "config/constants";
import { PDF_STYLES } from "config/pdf";
import { getLocalePropertyPrice } from "utils/properties";
import { PDFDocument, degrees } from "pdf-lib";

import BedIcon from "../../assets/property/bed.png";
import ShowerIcon from "../../assets/property/shower.png";
import { capitalizeWord } from "utils/helpers";

export const CmaDocument = (props) => {
  const propertyImageUrl =
    CORS_PROXY + encodeURIComponent(props.propertyImageUrl);
  const agencyLogoUrl = CORS_PROXY + encodeURIComponent(props.agencyLogoUrl);
  const lang = props.lang;
  const pieChartUrl =
    CORS_PROXY +
    encodeURIComponent(
      `https://dashboard.irealty.app/assets/cma/${lang}_pie_chart_spain_cma.png`,
    );

  let studyAreaText = props.studyAreas
    .filter((s) => s.metadata)
    .map((s) => s.metadata.name)
    .join(", ");

  if (studyAreaText.length === 0) {
    studyAreaText = getLocalisation("Custom area", lang);
  }

  const chunkArray = (array, chunkSize) => {
    return array.reduce((result, item, index) => {
      const chunkIndex = Math.floor(index / chunkSize);
      if (!result[chunkIndex]) result[chunkIndex] = [];
      result[chunkIndex].push(item);
      return result;
    }, []);
  };

  // const propertiesForSale = props.selectedProperties.filter(p => p.saleType === 'sale');
  // const propertiesSold = props.selectedProperties.filter(p => p.saleType === 'sold');
  const allProperties = props.selectedProperties;

  const renderPropertyPages = (properties, lang) => {
    // Split into chunks of 4, but first page has 3 properties
    const firstPage = properties.slice(0, 3);
    const remainingProperties = properties.slice(3);
    const propertyChunks = [firstPage, ...chunkArray(remainingProperties, 4)];

    return propertyChunks.map((chunk, pageIndex) => (
      <Page key={pageIndex} style={PDF_STYLES.page}>
        <View style={PDF_STYLES.section}>
          {/* Show title only on the first page */}
          {pageIndex === 0 && (
            <>
              <Text style={PDF_STYLES.title2}>
                {getLocalisation("Comparables", lang)}
              </Text>
              <View style={{ marginTop: 16 }} />
              <View
                style={{
                  backgroundColor: "#f5f7fa",
                  borderRadius: 8,
                  padding: 12,
                  marginBottom: 0,
                }}
              >
                <Text style={{ fontSize: 10, color: "#6c757d" }}>
                  {getLargeTextLocalisation("cma_pdf_comparables_text_1", lang)}
                </Text>
              </View>
            </>
          )}

          {chunk.map((property, index) => (
            <React.Fragment key={index}>
              <View
                style={{
                  marginBottom: 16,
                  height: 1,
                  backgroundColor: "#ddd",
                  marginTop: 16,
                }}
              />
              <View
                style={{
                  padding: 0,
                  flexDirection: "row",
                  alignItems: "flex-start",
                  backgroundColor: "#fff",
                }}
              >
                {/* Property Image */}
                <View
                  style={{
                    position: "relative",
                    width: 200,
                    height: 133.33,
                    marginRight: 16,
                  }}
                >
                  <Image
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: 8,
                      objectFit: "cover",
                      zIndex: -1,
                    }}
                    src={
                      property.saleType === "sale" ||
                      property.saleType === "rent"
                        ? CORS_PROXY +
                          encodeURIComponent(property.multimedia.images[0].url)
                        : CORS_PROXY +
                          encodeURIComponent(
                            `https://dev-catastro-images.s3.amazonaws.com/${property.refcat}.jpg`,
                          )
                    }
                    onError={(e) => {
                      e.target.src = require("../../assets/property/sold-properties-placeholder.png");
                    }}
                  />
                  <View
                    style={{
                      position: "absolute",
                      top: 8,
                      left: 8,
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: "#333",
                        borderRadius: 10,
                        padding: "4px 8px",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: 8,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 10,
                          color: "#fff",
                          fontWeight: "normal",
                        }}
                      >
                        {index +
                          1 +
                          (pageIndex === 0 ? 0 : (pageIndex - 1) * 4 + 3)}
                      </Text>
                    </View>
                    <View
                      style={{
                        backgroundColor:
                          property.saleType === "sale"
                            ? "#4CAF50"
                            : property.saleType === "rent"
                              ? "#4CAF50"
                              : "#CF4343",
                        borderRadius: 100,
                        padding: "4px 12px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 10,
                          color: "#fff",
                          fontWeight: "500",
                        }}
                      >
                        {property.saleType === "sale" ? (
                          getLocalisation("Active", lang)
                        ) : property.saleType === "rent" ? (
                          getLocalisation("Active", lang)
                        ) : (
                          <>
                            <Text style={{ fontWeight: "bold" }}>
                              {getLocalisation("Sold: ", lang)}
                            </Text>
                            {getSoldText(property)}
                          </>
                        )}
                      </Text>
                    </View>
                  </View>
                </View>

                <View style={{ flex: 1 }}>
                  <Text
                    style={{ fontWeight: "500", fontSize: 12, marginBottom: 8 }}
                  >
                    {getPropertyTitle(property)}
                  </Text>
                  {/* Price and Price per square meter */}
                  <Text
                    style={{
                      fontSize: 16,
                      color: "#222",
                      marginBottom: 8,
                      fontWeight: "bold",
                    }}
                  >
                    {getLocalePropertyPrice(property, property.price)}
                    {property.saleType === "rent" && (
                      <Text
                        style={{
                          color: "#222",
                          fontSize: 12,
                          fontWeight: "normal",
                        }}
                      >
                        {getLocalisation("/ month", lang)}
                      </Text>
                    )}
                    <Text
                      style={{
                        color: "#222",
                        fontSize: 12,
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      (€{Math.round(property.price / property.size)}/m²)
                    </Text>
                  </Text>

                  {/* Features */}
                  <View style={{ flexDirection: "row", marginBottom: 8 }}>
                    {property.saleType === "sale" ||
                      (property.saleType === "rent" && (
                        <>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              backgroundColor: "#f5f5f5",
                              padding: "3px 8px",
                              borderRadius: 100,
                              marginRight: 8,
                            }}
                          >
                            <Image
                              style={{ width: 10, height: 10, marginRight: 4 }}
                              src={require("../../assets/property/bed.png")}
                            />
                            <Text
                              style={{
                                fontSize: 8.75,
                                color: "#222",
                                fontWeight: "400",
                              }}
                            >
                              {property.rooms || 0}
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              backgroundColor: "#f5f5f5",
                              padding: "3px 8px",
                              borderRadius: 100,
                              marginRight: 8,
                            }}
                          >
                            <Image
                              style={{ width: 10, height: 10, marginRight: 4 }}
                              src={require("../../assets/property/shower.png")}
                            />
                            <Text
                              style={{
                                fontSize: 8.75,
                                color: "#222",
                                fontWeight: "400",
                              }}
                            >
                              {property.bathrooms || 0}
                            </Text>
                          </View>
                        </>
                      ))}
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        backgroundColor: "#f5f5f5",
                        padding: "3px 8px",
                        borderRadius: 100,
                        alignSelf: "flex-start",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 8.75,
                          color: "#222",
                          fontWeight: "400",
                        }}
                      >
                        {property.size || 0}m²
                      </Text>
                    </View>
                  </View>

                  {/* Days on market & other details */}
                  <View style={{ flexDirection: "row" }}>
                    <View style={{ flex: 1, marginRight: 8 }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "#717171",
                          marginBottom: 8,
                        }}
                      >
                        {getLocalisation("Year of Construction: ", lang)}
                        <Text style={{ fontWeight: "bold", color: "#222" }}>
                          {property.yearConstructed || "-"}
                        </Text>
                      </Text>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "#717171",
                          marginBottom: 8,
                        }}
                      >
                        {getLocalisation("Days on the market: ", lang)}
                        <Text style={{ fontWeight: "bold", color: "#222" }}>
                          {property.saleType === "sale"
                            ? getDaysOnTheMarketSale(property)
                            : property.saleType === "rent"
                              ? getDaysOnTheMarketSale(property)
                              : "-"}
                        </Text>
                      </Text>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "#717171",
                          marginBottom: 8,
                        }}
                      >
                        {getLocalisation("Energy Certificate: ", lang)}
                        <Text style={{ fontWeight: "bold", color: "#222" }}>
                          {property.saleType === "sale"
                            ? getEnergyCertificateText(property)
                            : property.saleType === "rent"
                              ? getEnergyCertificateText(property)
                              : "-"}
                        </Text>
                      </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                      <Text style={{ fontSize: 10, color: "#717171" }}>
                        {getLocalisation("Features: ", lang)}
                        <Text style={{ fontWeight: "bold", color: "#222" }}>
                          {getFeaturesText(property) || "-"}
                        </Text>
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </React.Fragment>
          ))}
        </View>
      </Page>
    ));
  };

  const renderSaleInfoPages = () => {
    return (
      <>
        <Page style={PDF_STYLES.page}>
          <View style={PDF_STYLES.section}>
            <Text style={PDF_STYLES.title2}>
              {getLocalisation(
                "The 3 factors influencing the sale of your property",
                lang,
              )}
            </Text>
            <View style={PDF_STYLES.spacer} />
            <View style={PDF_STYLES.greyBox}>
              <Text style={PDF_STYLES.text2}>
                {getLargeTextLocalisation("cma_pdf_page_2_text_1", lang)}
              </Text>
            </View>
            <View style={PDF_STYLES.spacer2} />
            <View style={PDF_STYLES.row}>
              <View style={PDF_STYLES.circle}>
                <Text style={PDF_STYLES.numText}>1</Text>
              </View>
              <Text style={{ ...PDF_STYLES.heading2, flex: 1 }}>
                {getLocalisation("The State of Conservation", lang)}
              </Text>
            </View>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_2_text_2", lang)}
            </Text>

            <View style={PDF_STYLES.spacer2} />
            <View style={PDF_STYLES.row}>
              <View style={PDF_STYLES.circle}>
                <Text style={PDF_STYLES.numText}>2</Text>
              </View>
              <Text style={{ ...PDF_STYLES.heading2, flex: 1 }}>
                {getLocalisation("The Price", lang)}
              </Text>
            </View>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_2_text_3", lang)}
            </Text>

            <View style={PDF_STYLES.spacer2} />
            <View style={PDF_STYLES.row}>
              <View style={PDF_STYLES.circle}>
                <Text style={PDF_STYLES.numText}>3</Text>
              </View>
              <Text style={{ ...PDF_STYLES.heading2, flex: 1 }}>
                {getLocalisation("Marketing", lang)}
              </Text>
            </View>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_2_text_4", lang)}
            </Text>
            <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
          </View>
        </Page>

        <Page style={PDF_STYLES.page}>
          <View style={PDF_STYLES.section}>
            <Text style={PDF_STYLES.title2}>
              {getLocalisation("Explanation of the report", lang)}
            </Text>
            <View style={PDF_STYLES.spacer} />
            <View style={PDF_STYLES.greyBox}>
              <Text style={PDF_STYLES.text2}>
                {getLargeTextLocalisation("cma_pdf_page_3_text_1", lang)}
              </Text>
            </View>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.faintText}>
              {getLocalisation("This analysis includes:", lang)}
            </Text>

            <View style={PDF_STYLES.spacer2} />
            <View style={PDF_STYLES.row}>
              <View style={PDF_STYLES.circle}>
                <Text style={PDF_STYLES.numText}>1</Text>
              </View>
              <Text style={{ ...PDF_STYLES.heading2, flex: 1 }}>
                {getLocalisation(
                  "Similar properties for sale in your immediate area",
                  lang,
                )}
              </Text>
            </View>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_3_text_2", lang)}
            </Text>

            <View style={PDF_STYLES.spacer2} />
            <View style={PDF_STYLES.row}>
              <View style={PDF_STYLES.circle}>
                <Text style={PDF_STYLES.numText}>2</Text>
              </View>
              <Text style={{ ...PDF_STYLES.heading2, flex: 1 }}>
                {getLocalisation(
                  "Properties that have not been sold for a long time",
                  lang,
                )}
              </Text>
            </View>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_3_text_3", lang)}
            </Text>
            <View style={PDF_STYLES.midSpacer} />
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_3_text_3", lang)}
            </Text>
            <View style={PDF_STYLES.midSpacer} />
            <View style={PDF_STYLES.divider} />
            <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
          </View>
        </Page>

        <Page style={PDF_STYLES.page}>
          <View style={PDF_STYLES.section}>
            <Text style={{ ...PDF_STYLES.heading2 }}>
              {getLocalisation(
                "The valuation of your property is a balancing exercise",
                lang,
              )}
            </Text>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_4_text_1", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />
            <Text style={{ ...PDF_STYLES.heading2 }}>
              {getLocalisation("The true market value of your property", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_4_text_2", lang)}
            </Text>
            <View style={PDF_STYLES.midSpacer} />
            <View style={PDF_STYLES.divider} />
            <View style={PDF_STYLES.midSpacer} />
            <Text style={{ ...PDF_STYLES.heading2, fontWeight: 700 }}>
              {getLocalisation("Main sources buyers use to find homes", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />
            <View style={PDF_STYLES.spacer2} />
            <Image style={PDF_STYLES.propertyImage} src={pieChartUrl} />
            <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
          </View>
        </Page>

        <Page style={PDF_STYLES.page}>
          <View style={PDF_STYLES.section}>
            <Text style={PDF_STYLES.title2}>
              {getLocalisation("Expenses after selling your property", lang)}
            </Text>
            <View style={PDF_STYLES.spacer} />
            <View style={PDF_STYLES.greyBox}>
              <Text style={PDF_STYLES.text2}>
                {getLargeTextLocalisation("cma_pdf_page_5_text_1", lang)}
              </Text>
            </View>

            <View style={PDF_STYLES.spacer2} />
            <Text style={{ ...PDF_STYLES.heading2 }}>
              {getLocalisation("For non-residents in Spain", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_5_text_2", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />

            <Text style={{ ...PDF_STYLES.heading2 }}>
              {getLocalisation("Taxes on your Income Tax Return", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {" "}
              • {getLocalisation("Profits up to 6.000€: 19%", lang)}
            </Text>
            <Text style={PDF_STYLES.text2}>
              {" "}
              • {getLocalisation("Profits between 6.000€ - 50.000€: 21%", lang)}
            </Text>
            <Text style={PDF_STYLES.text2}>
              {" "}
              •{" "}
              {getLocalisation("Profits between 50.000€ - 200.000€: 23%", lang)}
            </Text>
            <Text style={PDF_STYLES.text2}>
              {" "}
              •{" "}
              {getLocalisation(
                "Profits between 200.000€ - 300.000€: 27%",
                lang,
              )}
            </Text>
            <Text style={PDF_STYLES.text2}>
              {" "}
              • {getLocalisation("Profits over €300,000: 28%", lang)}
            </Text>

            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLocalisation(
                "These percentages are applied progressively.",
                lang,
              )}
            </Text>

            <View style={PDF_STYLES.spacer2} />
            <Text style={{ ...PDF_STYLES.heading2 }}>
              {getLocalisation("Municipality Tax", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLocalisation("This is dependent on your municipality.", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />

            <Text style={{ ...PDF_STYLES.heading2 }}>
              {getLocalisation("Proprietors' Community", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_5_text_3", lang)}
            </Text>

            <View style={PDF_STYLES.spacer2} />
            <Text style={{ ...PDF_STYLES.heading2 }}>
              {getLocalisation("IBI and BASURA TAX", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_5_text_4", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />

            <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
          </View>
        </Page>

        <Page style={PDF_STYLES.page}>
          <View style={PDF_STYLES.section}>
            <Text style={{ ...PDF_STYLES.heading2 }}>
              {getLocalisation("Energy Certificate", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_6_text_1", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />

            <Text style={{ ...PDF_STYLES.heading2 }}>
              {getLocalisation("Cancellation of Mortgage", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_6_text_2", lang)}
            </Text>
            <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
          </View>
        </Page>
      </>
    );
  };

  return (
    <Document>
      <Page style={PDF_STYLES.page}>
        <View style={PDF_STYLES.section}>
          <Text
            style={{ ...PDF_STYLES.title, fontSize: lang === "es" ? 38 : 50 }}
          >
            {getLocalisation("Property Valuation Report", lang)}
          </Text>
          <Text style={PDF_STYLES.heading}>
            {props.clientName} {props.clientSurname}
          </Text>
          <View style={PDF_STYLES.divider} />
          <Image
            style={PDF_STYLES.propertyImage}
            src={props.customImage ? props.customImage : propertyImageUrl}
          />
          <Text style={{ ...PDF_STYLES.heading, marginTop: 16 }}>
            {props.propertyName}
          </Text>
          <View style={PDF_STYLES.midSpacer} />
          <Text style={PDF_STYLES.subHeading}>
            {props.personalDetails.firstName} {props.personalDetails.lastName},{" "}
            {props.agencyDetails.name}
          </Text>
          <Text style={PDF_STYLES.subHeading}>
            {props.personalDetails.phone} / {props.personalDetails.email}
          </Text>
          <Text style={PDF_STYLES.subHeading}>
            {props.agencyDetails.address}
          </Text>
          <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
        </View>
      </Page>
      <Page style={PDF_STYLES.page}>
        <View style={PDF_STYLES.section}>
          <View style={PDF_STYLES.row}>
            <View style={PDF_STYLES.col}>
              <Text style={PDF_STYLES.subHeading}>
                {getLocalisation("Valuation estimate", lang)}
              </Text>
              <Text style={PDF_STYLES.valuationPriceText}>
                {getLocalePropertyPrice(props.property, props.valuationValue)}
                {props.saleType == "rent" && (
                  <Text style={PDF_STYLES.subHeading}>
                    {" "}
                    {getLocalisation("/ month", lang)}
                  </Text>
                )}
              </Text>
            </View>
            <View style={PDF_STYLES.col}>
              <Text style={PDF_STYLES.subHeading}>
                {getLocalisation("Estimated range", lang)}
              </Text>
              <Text style={PDF_STYLES.rangePriceText}>
                {getLocalePropertyPrice(props.property, props.lowerRange)} -{" "}
                {getLocalePropertyPrice(props.property, props.upperRange)}
              </Text>
            </View>
          </View>
          <View style={PDF_STYLES.divider} />
          <View style={PDF_STYLES.row}>
            <Text style={PDF_STYLES.faintText}>
              {getLocalisation("Study area:", lang)}{" "}
            </Text>
            <Text style={PDF_STYLES.text}>{studyAreaText}</Text>
            {props.rooms > 0 && (
              <>
                <View style={PDF_STYLES.verticalDivider} />
                <Text style={PDF_STYLES.faintText}>
                  {getLocalisation("Bedrooms:", lang)}{" "}
                </Text>
                <Text style={PDF_STYLES.text}>{props.rooms}</Text>
              </>
            )}
            {props.bathrooms && (
              <>
                <View style={PDF_STYLES.verticalDivider} />
                <Text style={PDF_STYLES.faintText}>
                  {getLocalisation("Bathrooms:", lang)}{" "}
                </Text>
                <Text style={PDF_STYLES.text}>{props.bathrooms}</Text>
              </>
            )}
          </View>
          <View style={PDF_STYLES.row}>
            <Text style={PDF_STYLES.faintText}>
              {getLocalisation("Area built:", lang)}{" "}
            </Text>
            <Text style={PDF_STYLES.text}>{props.property.size}m²</Text>
          </View>
          <View style={PDF_STYLES.spacer} />
          <Image style={PDF_STYLES.propertyImage} src={props.locationUrl} />
          <View style={PDF_STYLES.spacer} />
          <View style={PDF_STYLES.row}>
            <View style={PDF_STYLES.col}>
              <Text style={PDF_STYLES.faintText}>
                {getLocalisation("Address:", lang)}
              </Text>
              {props.property.address && (
                <>
                  <View style={PDF_STYLES.smallSpacer} />
                  {props.property.address.includes("Escalera") ? (
                    <View>
                      <Text style={PDF_STYLES.text}>
                        {props.property.address.split(" Escalera")[0]}
                      </Text>
                      <View style={PDF_STYLES.smallSpacer} />
                      <Text style={PDF_STYLES.text}>
                        Escalera {props.property.address.split(" Escalera")[1]}
                      </Text>
                    </View>
                  ) : (
                    <Text style={PDF_STYLES.text}>
                      {props.property.address}
                    </Text>
                  )}
                  <View style={PDF_STYLES.smallSpacer} />
                </>
              )}
              {!props.property.address && (
                <View style={PDF_STYLES.smallSpacer} />
              )}
              <Text style={PDF_STYLES.text}>{props.district}</Text>
              <View style={PDF_STYLES.smallSpacer} />
              <Text style={PDF_STYLES.text}>{props.municipality}</Text>
              <View style={PDF_STYLES.smallSpacer} />
              <Text style={PDF_STYLES.text}>{props.province}</Text>
            </View>
            {props.property.isCatastro && (
              <View style={PDF_STYLES.col}>
                <Text style={PDF_STYLES.faintText}>
                  {getLocalisation("Catastral reference:", lang)}
                </Text>
                <View style={PDF_STYLES.smallSpacer} />
                <Text style={PDF_STYLES.text}>{props.property.ref}</Text>
              </View>
            )}
          </View>
          <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
        </View>
      </Page>

      {renderPropertyPages(allProperties, lang)}
      {props.saleType !== "rent" && renderSaleInfoPages()}
    </Document>
  );
};

// function to get the days on the market
const getDaysOnTheMarketSale = (property) => {
  const today = new Date();
  const initialImportTime = new Date(
    parseFloat(property.initialImportTime) * 1000,
  );
  const diffTime = Math.abs(today - initialImportTime);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

const getSoldText = (property) => {
  // return feb 2024, or may 2023 depending on the sold timestamp
  const soldDate = new Date(parseFloat(property.timestamp) * 1000);
  const soldMonth = soldDate.getMonth();
  const soldYear = soldDate.getFullYear();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[soldMonth] + " " + soldYear;
};

const getFeaturesText = (property) => {
  // for each true value in the features array, add the feature to the string
  const features = [];
  if (property.features && typeof property.features === "object") {
    Object.keys(property.features).forEach((key) => {
      if (property.features[key]) {
        features.push(key);
      }
    });
  }

  //  convert each from camel case to normal case with spaces and remove "has" from the beginning of the string
  const formattedFeatures = features.map((feature) => {
    return feature
      .replace(/^has/, "")
      .replace(/^is/, "")
      .replace(/([A-Z])/g, " $1")
      .trim();
  });

  return formattedFeatures.join(", ");
};

const getEnergyCertificateText = (property) => {
  if (property.energyCertificate) {
    if (property.energyCertificate.hasEnergyCertificate) {
      // if hasEnergyCertificate is true, return the energy certificate type
      if (property.energyCertificate.consumptionGrade) {
        return property.energyCertificate.consumptionGrade;
      } else {
        return "Has Energy Certificate";
      }
    } else {
      // if inProgress is true, return "In Progress"
      if (property.energyCertificate.inProgress) {
        return "In Progress";
      } else {
        return "No Energy Certificate";
      }
    }
  }
  return "-";
};

export function getPropertyTypologyHuman(
  property,
  plural = false,
  skipRentalPrefix = false,
) {
  if (property.buildingType == "land") {
    if (plural) {
      return i18n("Lands");
    }

    return i18n("Land");
  }

  if (property.buildingType == "building") {
    if (plural) {
      return i18n("Buildings");
    }

    return i18n("Building");
  }

  if (property.buildingType == "office") {
    if (plural) {
      return i18n("Offices");
    }

    return i18n("Office");
  }

  if (property.buildingType == "garage") {
    if (plural) {
      return i18n("Garages");
    }

    return i18n("Garage");
  }

  if (property.buildingType == "storage") {
    if (plural) {
      return i18n("Storages");
    }

    return i18n("Storage");
  }

  if (property.buildingType == "room") {
    if (plural) {
      return i18n("Rooms");
    }

    return i18n("Room");
  }

  if (property.buildingType == "commercial") {
    if (property.commercial_type == "industrial") {
      if (plural) {
        return i18n("Industrial properties");
      }

      return i18n("Industrial property");
    } else {
      if (plural) {
        return i18n("Commercial properties");
      }

      return i18n("Commercial property");
    }
  }

  let title = capitalizeWord(property.propertyType);

  if (property.detailedType && property.detailedType.subTypology) {
    if (property.detailedType.subTypology === "semidetachedHouse") {
      title = i18n("Semi-detached house");

      if (plural) {
        title = i18n("Semi-detached houses");
      }
    } else if (property.detailedType.subTypology === "independantHouse") {
      title = i18n("Detached house");

      if (plural) {
        title = i18n("Detached houses");
      }
    } else if (property.detailedType.subTypology === "terracedHouse") {
      title = i18n("Terrace house");

      if (plural) {
        title = i18n("Terrace houses");
      }
    } else if (property.detailedType.subTypology === "countryHouse") {
      title = i18n("Country house");

      if (plural) {
        title = i18n("Country houses");
      }
    } else if (property.detailedType.subTypology === "studio") {
      title = i18n("Studio");

      if (plural) {
        title = i18n("Studios");
      }
    }
  }

  if (property.detailedType.isVilla) {
    title = "Villa";

    if (plural) {
      title = "Villas";
    }
  }

  if (property.detailedType && property.detailedType.typology && !title) {
    if (property.detailedType.typology === "flat") {
      title = i18n("Flat");

      if (plural) {
        title = i18n("Flats");
      }
    }

    if (property.rooms === 0) {
      title = i18n("Studio");

      if (plural) {
        title = i18n("Studios");
      }
    }

    if (property.detailedType.subTypology) {
      if (property.detailedType.subTypology === "duplex") {
        title = i18n("Duplex");

        if (plural) {
          title = i18n("Duplexes");
        }
      }

      if (property.detailedType.subTypology === "penthouse") {
        title = i18n("Penthouse");

        if (plural) {
          title = i18n("Penthouses");
        }
      }
    }

    if (property.detailedType.typology === "house") {
      title = i18n("House");

      if (plural) {
        title = i18n("Houses");
      }
    }
  }

  if (!title) {
    title = i18n("Property");

    if (plural) {
      title = i18n("Properties");
    }
  }

  if (property.saleType == "rent" && !skipRentalPrefix) {
    if (getLang().code == "es") {
      return "Alquiler de " + title;
    } else {
      return `${title} ${i18n("for rent")}`;
    }
  }

  return title;
}

// transforms data points in from the backend to a more
// human readable title
export function getPropertyTitle(property) {
  let title = getPropertyTypologyHuman(property);
  let location = property.address;

  if (!location || location == "n/a" || location == "none" || location == "") {
    location =
      property.neighborhood !== "n/a" && property.neighborhood !== "none"
        ? property.neighborhood
        : null;
  }

  if (!location || location == "n/a" || location == "none" || location == "") {
    location = property.district;
  }

  if (!location || location == "n/a" || location == "none" || location == "") {
    location = property.municipality;
  }

  return `${title} ${i18n("in")} ${location}`;
}

const CmaPDFProvider = (props) => {
  const [mergedUrl, setMergedUrl] = useState(null);

  const [mergeError, setMergeError] = useState(null);
  const downloadTriggered = useRef(false);

  const { refcat, isInternalValuation } = props;

  // Construct the additional PDF URL with the dynamic refcat and CORS proxy
  const additionalPdfUrl =
    CORS_PROXY +
    encodeURIComponent(
      `https://www1.sedecatastro.gob.es/CYCBienInmueble/SECImprimirCroquisYDatos.aspx?del=1&mun=1&refcat=${refcat}`,
    );

  // Use the usePDF hook to generate the base PDF
  const [basePdfData, basePdfMeta] = usePDF({
    document: <CmaDocument {...props} />,
  });

  useEffect(() => {
    const mergePdfs = async () => {
      setMergeError(null);
      setMergedUrl(null);

      try {
        if (!basePdfData?.blob) {
          return;
        }

        const basePdfBytes = await basePdfData.blob.arrayBuffer();

        if (!basePdfBytes) {
          throw new Error("Failed to generate base PDF bytes.");
        }

        if (refcat && !isInternalValuation) {
          // Load the generated base PDF
          const basePdf = await PDFDocument.load(basePdfBytes);

          // Fetch the additional PDF
          const response = await fetch(additionalPdfUrl);
          if (!response.ok) {
            throw new Error(
              `Failed to fetch additional PDF: ${response.statusText}`,
            );
          }
          const additionalPdfBytes = await response.arrayBuffer();
          const additionalPdf = await PDFDocument.load(additionalPdfBytes);

          // Create a new PDFDocument to merge both PDFs
          const mergedPdf = await PDFDocument.create();

          // Copy pages from the base PDF
          const basePages = await mergedPdf.copyPages(
            basePdf,
            basePdf.getPageIndices(),
          );
          basePages.forEach((page) => mergedPdf.addPage(page));

          // Copy pages from the additional PDF
          const additionalPages = await mergedPdf.copyPages(
            additionalPdf,
            additionalPdf.getPageIndices(),
          );

          // Rotate each additional page by -90 degrees (anticlockwise)
          additionalPages.forEach((page) => {
            // Set rotation to -90 degrees
            page.setRotation(degrees(-90));
            // Add the rotated page to the merged PDF
            mergedPdf.addPage(page);
          });

          // Serialize the merged PDF to bytes
          const mergedPdfBytes = await mergedPdf.save();

          // Create a blob and generate a URL
          const mergedBlob = new Blob([mergedPdfBytes], {
            type: "application/pdf",
          });
          const mergedPdfUrl = URL.createObjectURL(mergedBlob);
          setMergedUrl(mergedPdfUrl);
        } else {
          const mergedBlob = new Blob([basePdfBytes], {
            type: "application/pdf",
          });
          const mergedPdfUrl = URL.createObjectURL(mergedBlob);
          setMergedUrl(mergedPdfUrl);
        }
      } catch (err) {
        console.error("Error merging PDFs:", err);
        setMergeError(err.message);
      }
    };

    if (basePdfData?.blob && !mergeError && !mergedUrl) {
      mergePdfs();
    }
  }, [basePdfData, additionalPdfUrl, mergeError, mergedUrl]);

  useEffect(() => {
    return () => {
      if (mergedUrl) {
        URL.revokeObjectURL(mergedUrl);
      }
    };
  }, [mergedUrl]);

  useEffect(() => {
    if (mergedUrl && !downloadTriggered.current) {
      downloadTriggered.current = true; // Prevent multiple triggers

      // Open the Blob URL in a new tab
      const newTab = window.open(mergedUrl, "_blank");
      if (!newTab) {
        console.error(
          "Failed to open a new tab. Check if pop-ups are blocked.",
        );
      } else {
        console.log("Blob URL opened in a new tab.");
      }

      // Release the trigger
      downloadTriggered.current = false;
    }
  }, [mergedUrl]);

  // Handle loading state
  if (basePdfMeta.loading) {
    return;
  }

  // Handle errors during PDF generation
  if (basePdfMeta.error) {
    return <div>Error generating PDF: {basePdfMeta.error.message}</div>;
  }

  // Handle errors during PDF merging
  if (mergeError) {
    return <div>Error merging PDFs: {mergeError}</div>;
  }

  // Indicate that PDFs are being merged
  return;
};

export default CmaPDFProvider;
