export default function createPolygonDeleteButton(position, onDelete) {
  class PolygonDeleteButton extends window.google.maps.OverlayView {
    constructor(position, onDelete) {
      super();
      this.position = position;
      this.onDelete = onDelete;
      this.div = null;
    }

    onAdd() {
      const div = document.createElement("div");
      div.style.position = "absolute";
      div.style.cursor = "pointer";
      div.style.width = "32px";
      div.style.height = "32px";
      div.style.background = "white";
      div.style.border = "2px solid #000";
      div.style.borderRadius = "50%";
      div.style.display = "flex";
      div.style.alignItems = "center";
      div.style.justifyContent = "center";
      div.style.zIndex = "99999";
      div.innerHTML = "×";
      div.style.fontSize = "24px";
      div.style.fontWeight = "bold";

      div.addEventListener("click", () => {
        this.onDelete();
        this.setMap(null);
      });

      this.div = div;
      const panes = this.getPanes();
      panes.floatPane.appendChild(div);
    }

    draw() {
      const overlayProjection = this.getProjection();
      const position = overlayProjection.fromLatLngToDivPixel(this.position);

      if (this.div) {
        this.div.style.left = position.x - 12 + "px";
        this.div.style.top = position.y - 12 + "px";
      }
    }

    onRemove() {
      if (this.div) {
        this.div.parentNode.removeChild(this.div);
        this.div = null;
      }
    }
  }

  return new PolygonDeleteButton(position, onDelete);
}
