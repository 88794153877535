import { clearUploadProperty } from "actions/myPropertiesActions";
import { deletePropertyRequest } from "api/crm";
import { fetchTeamPortfolioRequest } from "api/properties";
import LoadingSpinner from "components/ui/LoadingSpinner/LoadingSpinner";
import { i18n } from "i18n/localisation";
import { sendAnalyticsEvent } from "lib/analytics";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import "../collections/collections.scss";
import EmptyTileContainer from "../core/EmptyTileContainer";
import Dashboard from "../Dashboard";
import MyPropertyTile from "./MyPropertyTile";
import UploadPropertyModal from "./UploadPropertyModal";

const MyPropertiesPage = (props) => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [myProperties, setMyProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    const fetchTeamPortfolio = async () => {
      try {
        const response = await fetchTeamPortfolioRequest();
        setMyProperties(response);
      } catch (error) {
        console.error("Error fetching user properties", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTeamPortfolio();
  }, []);

  const onClickEmptyProperty = () => {
    dispatch(clearUploadProperty());
    setIsUploadModalOpen(true);
    sendAnalyticsEvent("My Properties", {
      type: "click upload property",
    });
  };

  const onDeleteProperty = async (propertyId) => {
    try {
      await deletePropertyRequest({ property_id: propertyId });
      setMyProperties(
        myProperties.filter((property) => property.id !== propertyId),
      );
      toast.success(i18n("Property has been deleted"), { duration: 2000 });
    } catch (error) {
      console.error("Error deleting property", error);
      toast.error(i18n("Property has not been deleted"), { duration: 2000 });
    }
  };

  const onDuplicateProperty = (property) => {
    toast(i18n("Property has been duplicated"), { duration: 2000 });
    return;
  };

  const onEditTitle = (property, newTitle) => {
    //setEditingObjectTitle(property);
    return;
  };

  return (
    <Dashboard>
      <Helmet>
        <title>{i18n("My Properties")}</title>
      </Helmet>

      <div className="pr-18 collections-page align-center flex min-h-screen w-full flex-col bg-gray-100 p-12 py-16 md:pl-36">
        <h1 className="mb-8 text-3xl font-bold">{i18n("My Properties")}</h1>

        {/* Show loading spinner while fetching properties */}
        {isLoading ? (
          <div className="flex h-full w-full items-center justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="w-full max-w-[1140px] ">
            {myProperties.length === 0 && (
              <>
                <h2 className="mb-8 text-3xl font-bold">
                  {i18n("Create your first property listing")}
                </h2>
                <div className="mb-8 max-w-prose text-base">
                  <p className="mb-2">
                    {i18n(
                      "Upload your property details and add imagery to create a listing.",
                    )}
                  </p>
                  <p>
                    {i18n(
                      "You can choose to make your property listing public so others can find it through search, or keep it private and share it selectively.",
                    )}
                  </p>
                </div>
              </>
            )}

            <div className="mb-4 flex max-w-screen-xl flex-wrap gap-[24px]">
              <EmptyTileContainer
                label={i18n("Upload property")}
                onClick={onClickEmptyProperty}
              />
              {myProperties.length > 0 &&
                myProperties.map((property) => (
                  <MyPropertyTile
                    key={property.id}
                    showHamburger
                    onEditTitle={onEditTitle}
                    onDeleteProperty={() => onDeleteProperty(property.id)}
                    onDuplicateProperty={onDuplicateProperty}
                    handleOnClick={() => navigate(`/upload/${property.id}`)}
                    property={property}
                  />
                ))}
            </div>
          </div>
        )}
      </div>
      <UploadPropertyModal
        isOpen={isUploadModalOpen}
        closeModal={() => setIsUploadModalOpen(false)}
      />
    </Dashboard>
  );
};

export default MyPropertiesPage;
