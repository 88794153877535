import Checkbox from "components/input/Checkbox";
import { i18n } from "i18n/localisation";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  addPropertyFilter,
  removePropertyFilter,
  removePropertyFilterGroup,
  setPropertyFilters,
} from "../../../actions/filterActions";
import closeIcon from "../../../assets/core/close.svg";
import leftChevronIcon from "../../../assets/core/left_chevron.svg";
import { sendAnalyticsEvent } from "../../../lib/analytics";
import {
  ALL_CHIP_FILTERS,
  AREA_RANGE_FILTER_ID,
  BATHROOMS_RANGE_FILTER_ID,
  BEDROOMS_RANGE_FILTER_ID,
  BUILDABLE_AREA_RANGE_FILTER_ID,
  BUILDABLE_FLOORS_RANGE_FILTER_ID,
  BUILDING_ALLOWED_USE_FILTERS,
  BUILDING_CONDITION_STATUS_FILTERS,
  BUILDING_FEATURES_FILTERS,
  COMMERCIAL_BUSINESS_ACTIVITIES_FILTERS,
  COMMERCIAL_FEATURES_FILTERS,
  COMMERCIAL_TYPE_FILTERS,
  FILTER_LABEL_MAPPINGS,
  FILTER_LOWER_BOUND_KEY,
  FILTER_UPPER_BOUND_KEY,
  FLOOR_RANGE_FILTER_ID,
  GARAGE_FEATURES_FILTERS,
  GARAGE_PARKING_SPACES_FILTERS,
  GARAGE_SPACES_RANGE_FILTER_ID,
  GARAGE_SPOT_CAPACITY_FILTERS,
  LAND_CLASSIFICATION_FEATURES,
  LAND_FEATURES_FILTERS,
  LAND_TYPE_FILTERS,
  LIFTS_RANGE_FILTER_ID,
  NUM_FLOORS_RANGE_FILTER_ID,
  OFFICE_DISTRIBUTION_FILTERS,
  OFFICE_EXCLUSIVE_USE_FILTERS,
  OFFICE_FEATURES_FILTERS,
  OFFICE_LOCATION_FILTERS,
  OFFICE_ORIENTATION_FILTERS,
  OFFICE_SECURITY_FILTERS,
  PRICE_RANGE_FILTER_ID,
  PRIVATE_BANK_PROPERTY_FILTERS,
  PROPERTY_CONDITTION_FILTERS,
  PROPERTY_FEATURES_FILTERS,
  PROPERTY_FILTER_OPERATIONS,
  PROPERTY_FILTER_TYPES,
  PROPERTY_MULTIMEDIA_FILTERS,
  PROPERTY_RENTAL_FEATURES_FILTERS,
  PROPERTY_TYPE_FILTERS,
  SELLER_TYPE_FILTERS,
  SHORT_TERM_FILTERS,
  SIZE_RANGE_FILTER_ID,
  STORAGE_FEATURES_FILTERS,
  YEAR_RANGE_FILTER_ID,
  YEAR_SOLD_RANGE_FILTER_ID,
} from "../../../lib/filter/filterTypes";
import Dropdown from "../../core/Dropdown";
import PrimaryInput from "../../core/PrimaryInput";
import "./filters.scss";
import { getPropertiesCountText } from "utils/properties";
import moment from "moment";

const MAX_RANGE_VALUE = 1000000000000;
const MIN_RANGE_VALUE = -1;

class FilterPanel extends Component {
  constructor(props) {
    super(props);
    this.headerDivider = React.createRef();
  }

  renderFilterChip(filter, index) {
    const { filters } = this.props;
    let icon = ALL_CHIP_FILTERS.find((f) => f.id == filter.id)?.icon;
    let className = "property-chip";
    let filterToggled = false;

    if (filters.find((f) => f.id == filter.id)) {
      icon = ALL_CHIP_FILTERS.find((f) => f.id == filter.id)?.highlightedIcon;
      className += " selected";
      filterToggled = true;
    }

    const toggleFilter = () => {
      if (filterToggled) {
        this.removeFilter(filter);
      } else {
        this.addFilter(filter);
      }
    };

    return (
      <div key={index} className={className} onClick={toggleFilter}>
        {icon && <img src={icon} />}
        <span>{FILTER_LABEL_MAPPINGS[filter.label]()}</span>
      </div>
    );
  }

  onFilterPanelScroll(e) {
    let scrollOffset = e.target.scrollTop;

    if (scrollOffset > 0) {
      this.headerDivider.current.classList.add("scrolled");
    } else {
      this.headerDivider.current.classList.remove("scrolled");
    }
  }

  addFilter(filter) {
    this.props.dispatch(addPropertyFilter(filter));
  }

  removeFilter(filter) {
    this.props.dispatch(removePropertyFilter(filter));
  }

  getSelectedValueForRangeFilter(filterId, bound) {
    const { filters } = this.props;
    let filter = filters.find((f) => f.id === filterId);

    if (filter && this.isValidRangeBound(filter[bound])) {
      // do not return a real value if the bounds are at the minimums/maximums
      // this means that bound hasn't been set
      if (
        bound === FILTER_LOWER_BOUND_KEY &&
        filter[bound] === MIN_RANGE_VALUE
      ) {
        return null;
      }

      if (
        bound === FILTER_UPPER_BOUND_KEY &&
        filter[bound] === MAX_RANGE_VALUE
      ) {
        return null;
      }

      if (filter.id === YEAR_SOLD_RANGE_FILTER_ID) {
        return moment.unix(filter[bound]).format("YYYY");
      }

      return filter[bound];
    }

    return null;
  }

  /**
   * Range changes operate on whenever a number input is changed
   * if the complimentary other bound isn't set we then set it automatically
   */
  onRangeChange(value, path, bound, filterId, filterType) {
    let currentRangeFilter = this.props.filters.find((f) => f.id === filterId);
    let numValue = value;

    if (typeof value === "string") {
      numValue = parseFloat(value);
    }

    // remove the filter if each bound is unset
    // else just set it to either the minimum or maximum
    if (value === null || value === undefined || value.length === 0) {
      if (bound === FILTER_LOWER_BOUND_KEY) {
        if (
          currentRangeFilter &&
          currentRangeFilter[FILTER_UPPER_BOUND_KEY] === MAX_RANGE_VALUE
        ) {
          return this.removeFilter(currentRangeFilter);
        } else {
          numValue = MIN_RANGE_VALUE;
        }
      } else if (bound === FILTER_UPPER_BOUND_KEY) {
        if (
          currentRangeFilter &&
          currentRangeFilter[FILTER_LOWER_BOUND_KEY] === MIN_RANGE_VALUE
        ) {
          return this.removeFilter(currentRangeFilter);
        } else {
          numValue = MAX_RANGE_VALUE;
        }
      }
    }

    let filter = {
      id: filterId,
      [bound]: numValue,
      path,
      type: filterType,
      operation: PROPERTY_FILTER_OPERATIONS.range,
    };

    // just set the max value to a crazy high number if the max range isn't set
    if (bound === FILTER_LOWER_BOUND_KEY) {
      filter[FILTER_UPPER_BOUND_KEY] = MAX_RANGE_VALUE;
    }

    // set the lower bound to 0 if it hasnt been set
    if (bound === FILTER_UPPER_BOUND_KEY) {
      filter[FILTER_LOWER_BOUND_KEY] = MIN_RANGE_VALUE;
    }

    // now we check if the range filter has been set and apply the set values to the missing bound
    if (currentRangeFilter) {
      // if the user has changed the lower bound then we check the
      // existing upper bound that has been set and add that to the new filter object
      if (
        currentRangeFilter[FILTER_UPPER_BOUND_KEY] != MAX_RANGE_VALUE &&
        bound === FILTER_LOWER_BOUND_KEY
      ) {
        filter[FILTER_UPPER_BOUND_KEY] =
          currentRangeFilter[FILTER_UPPER_BOUND_KEY];
      }

      // if the user has changed the upper bound then we check the
      // existing lower bound that has been set and add that to the new filter object
      if (
        currentRangeFilter[FILTER_LOWER_BOUND_KEY] != MIN_RANGE_VALUE &&
        bound === FILTER_UPPER_BOUND_KEY
      ) {
        filter[FILTER_LOWER_BOUND_KEY] =
          currentRangeFilter[FILTER_LOWER_BOUND_KEY];
      }
    }

    this.addFilter(filter);
  }

  // handler for multi select dropdown filters
  onMultiSelectFilter(filter) {
    // if its an 'all' or a cancel action then remove the entire multi select group's conditions
    if (filter.cancel) {
      this.props.dispatch(removePropertyFilterGroup(filter.type));
    } else {
      // if the filter already exists then we need to toggle it off
      // else toggle it on
      const { filters } = this.props;
      if (filters.find((f) => f.id === filter.id)) {
        this.removeFilter(filter);
      } else {
        this.addFilter(filter);
      }
    }
  }

  isValidRangeBound(value) {
    return value != null && value > -1;
  }

  // clears all the redux filters and last set filters
  clearFilters() {
    this.props.dispatch(setPropertyFilters([]));
  }

  // closes the filter panel and sends an analytics event
  onBack() {
    this.props.toggleFilterMode();

    sendAnalyticsEvent("Filter Panel Click", {
      type: "back button",
      details: this.props.filters,
    });
  }

  // toggles a filter
  toggleFilter(filter) {
    const { filters } = this.props;
    let filterSet = filters.find((f) => f.id == filter.id);

    if (filterSet) {
      this.props.dispatch(
        setPropertyFilters([].concat(filters.filter((f) => f.id != filter.id))),
      );
    } else {
      this.props.dispatch(
        setPropertyFilters(
          [].concat(
            filters.filter((f) => f.id != filter.id),
            [filter],
          ),
        ),
      );
    }
  }

  // adds a filter thats unique by type
  addUniqueTypeFilter(filter) {
    let filtersCopy = this.props.filters.filter((f) => f.type !== filter.type);

    if (filter.cancel) {
      this.props.dispatch(setPropertyFilters([].concat(filtersCopy, [])));
    } else {
      this.props.dispatch(setPropertyFilters([].concat(filtersCopy, [filter])));
    }
  }

  // helper for getting min price filter
  getMinFilterValue(filterId) {
    const { filters } = this.props;

    let filter = filters.find((f) => f.id === filterId);

    if (filter) {
      return filter.lowerBound > MIN_RANGE_VALUE ? filter.lowerBound : "";
    }

    return "";
  }

  // helper for getting max price filter
  getMaxFilterValue(filterId) {
    const { filters } = this.props;

    let filter = filters.find((f) => f.id === filterId);

    if (filter) {
      return filter.upperBound < MAX_RANGE_VALUE ? filter.upperBound : "";
    }

    return "";
  }

  // renders generated tags
  renderTagFilters() {
    let filters = this.props.filters;
    let tagFilters = filters.filter(
      (f) => f.type == PROPERTY_FILTER_TYPES.tags,
    );

    if (tagFilters.length == 0) {
      return null;
    }

    return (
      <>
        <p className="property-filter-panel_section-header">
          {i18n("Smart tags")}
        </p>
        <div className="property-filter-panel_chip-container">
          {tagFilters.map((filter, index) => (
            <div key={index} className="property-chip">
              <span>{filter.label}</span>
              <img onClick={() => this.removeFilter(filter)} src={closeIcon} />
            </div>
          ))}
        </div>
      </>
    );
  }

  renderRangeInputSection(
    title,
    minPlaceholder,
    maxPlaceholder,
    filterId,
    path,
    filterType,
  ) {
    return (
      <>
        <p className="property-filter-panel_section-header">{i18n(title)}</p>
        <div className="property-filter-panel_section">
          <PrimaryInput
            type="number"
            placeholder={i18n(minPlaceholder)}
            value={this.getMinFilterValue(filterId)}
            onChange={(e) =>
              this.onRangeChange(
                e.target.value,
                path,
                FILTER_LOWER_BOUND_KEY,
                filterId,
                filterType,
              )
            }
          />
          <PrimaryInput
            type="number"
            placeholder={i18n(maxPlaceholder)}
            value={this.getMaxFilterValue(filterId)}
            onChange={(e) =>
              this.onRangeChange(
                e.target.value,
                path,
                FILTER_UPPER_BOUND_KEY,
                filterId,
                filterType,
              )
            }
          />
        </div>
      </>
    );
  }

  renderRangeDropdownSection(
    title,
    minPlaceholder,
    maxPlaceholder,
    filterType,
    path,
    filterId,
    items,
  ) {
    let minValue = this.getSelectedValueForRangeFilter(
      filterId,
      FILTER_LOWER_BOUND_KEY,
    );
    let maxValue = this.getSelectedValueForRangeFilter(
      filterId,
      FILTER_UPPER_BOUND_KEY,
    );
    return (
      <>
        <p className="property-filter-panel_section-header">{i18n(title)}</p>
        <div className="property-filter-panel_section">
          <Dropdown
            label={minValue || i18n(minPlaceholder)}
            defaultValue={minValue}
            cancelButton={minValue}
            onCancel={() =>
              this.onRangeChange(
                null,
                path,
                FILTER_LOWER_BOUND_KEY,
                filterId,
                filterType,
              )
            }
            items={items.map((item) => ({
              label: item.label,
              value: item.value,
              onSelect: () => {
                this.onRangeChange(
                  item.value,
                  path,
                  FILTER_LOWER_BOUND_KEY,
                  filterId,
                  filterType,
                );
              },
            }))}
          />
          <Dropdown
            label={maxValue || i18n(maxPlaceholder)}
            defaultValue={maxValue}
            cancelButton={maxValue}
            onCancel={() =>
              this.onRangeChange(
                null,
                path,
                FILTER_UPPER_BOUND_KEY,
                filterId,
                filterType,
              )
            }
            items={items.map((item) => ({
              label: item.label,
              value: item.value,
              onSelect: () => {
                this.onRangeChange(
                  item.value,
                  path,
                  FILTER_UPPER_BOUND_KEY,
                  filterId,
                  filterType,
                );
              },
            }))}
          />
        </div>
      </>
    );
  }

  renderCheckboxSection(title, items) {
    const { filters } = this.props;
    const sliceIndex = items.length > 3 ? Math.ceil(items.length / 2) : null;
    const items1 = sliceIndex && items.slice(0, sliceIndex);
    const items2 = sliceIndex && items.slice(sliceIndex);
    const itemsCols = sliceIndex ? [items1, items2] : [items];

    return (
      <>
        <p className="property-filter-panel_section-header">{i18n(title)}</p>
        <div className="property-filter-panel_section flex gap-4">
          {itemsCols.map((it, index) => (
            <div key={index} className="flex flex-1 flex-col gap-2">
              {it.map((item, index) => (
                <div
                  key={index}
                  onClick={() => this.toggleFilter(item)}
                  className="property-filter-panel_section_land-features"
                >
                  <Checkbox
                    label={i18n(item.label)}
                    checked={filters.find((f) => f.id === item.id)}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </>
    );
  }

  renderSingleDropdownSection(
    title,
    filterType,
    items,
    isInverted = false,
    isMulti = false,
  ) {
    const { filters } = this.props;

    const selectedFilter = this.props.filters.find(
      (f) => f.type === filterType,
    );
    const deleteFilter = (filter) => {
      const _filters = this.props.filters.filter((f) => f !== selectedFilter);
      this.props.dispatch(setPropertyFilters(_filters));
    };

    const getLabel = () => {
      if (isMulti) {
        const dropdownFilters = filters.filter((f) => f.type === filterType);
        const filtersLabel =
          dropdownFilters.length > 0
            ? dropdownFilters
                .map((f) => FILTER_LABEL_MAPPINGS[f.label]())
                .join(", ")
            : i18n("All");
        return filtersLabel;
      }

      return selectedFilter
        ? FILTER_LABEL_MAPPINGS[selectedFilter.label]()
        : i18n("All");
    };

    const getItems = () => {
      if (isMulti) {
        return items.map((filter) => ({
          ...filter,
          label: FILTER_LABEL_MAPPINGS[filter.label](),
          onSelect: () => {
            this.onMultiSelectFilter(filter);
          },
        }));
      }

      return items
        .map((filter, index) => ({
          label: FILTER_LABEL_MAPPINGS[filter.label](),
          value: index,
          selectedIds: [selectedFilter],
          onSelect: () => this.addUniqueTypeFilter(filter),
        }))
        .concat({
          label: i18n("All"),
          value: null,
          selectedIds: null,
          onSelect: () => deleteFilter(selectedFilter),
        });
    };

    return (
      <>
        <p className="property-filter-panel_section-header">{i18n(title)}</p>
        <div className="property-filter-panel_section">
          <Dropdown
            isInverted={isInverted}
            isMulti={isMulti}
            noMaxHeight
            selectedIds={filters
              .filter((f) => f.type === filterType)
              .map((f) => f.id)}
            items={getItems()}
            label={getLabel()}
          />
        </div>
      </>
    );
  }

  renderBankPropertyDropdownSection(title, filterType, items) {
    const { filters } = this.props;

    const selectedFilter = this.props.filters.find(
      (f) => f.type === filterType,
    );

    const deleteFilter = (filter) => {
      const _filters = this.props.filters.filter((f) => f !== selectedFilter);
      this.props.dispatch(setPropertyFilters(_filters));
    };

    const getLabel = () => {
      return selectedFilter
        ? FILTER_LABEL_MAPPINGS[
            items.find(
              (item) =>
                item.value === selectedFilter.value &&
                item.label === selectedFilter.label,
            ).label
          ]()
        : FILTER_LABEL_MAPPINGS[items[0].label]();
    };

    const getItems = () => {
      return items.map((item, index) => ({
        label: FILTER_LABEL_MAPPINGS[item.label](),
        value: index,
        selectedIds: [selectedFilter],
        onSelect: () => {
          if (item.value === null) {
            deleteFilter(selectedFilter);
          } else {
            this.addUniqueTypeFilter(item);
          }
        },
      }));
    };

    return (
      <>
        {title.length > 0 ? (
          <p className="property-filter-panel_section-header">{i18n(title)}</p>
        ) : (
          <div style={{ marginTop: "12px" }} />
        )}
        <div className="property-filter-panel_section">
          <Dropdown
            noMaxHeight
            selectedIds={filters
              .filter((f) => f.type === filterType)
              .map((f) => f.id)}
            items={getItems()}
            label={getLabel()}
          />
        </div>
      </>
    );
  }

  renderShortTermDropdownSection(title, filterType, items) {
    if (this.props.saleType === "rent") {
      const { filters } = this.props;

      const selectedFilter = this.props.filters.find(
        (f) => f.type === filterType,
      );

      const deleteFilter = (filter) => {
        const _filters = this.props.filters.filter((f) => f !== selectedFilter);
        this.props.dispatch(setPropertyFilters(_filters));
      };

      const getLabel = () => {
        return selectedFilter
          ? FILTER_LABEL_MAPPINGS[
              items.find(
                (item) =>
                  item.value === selectedFilter.value &&
                  item.label === selectedFilter.label,
              ).label
            ]()
          : FILTER_LABEL_MAPPINGS[items[0].label]();
      };

      const getItems = () => {
        return items.map((item, index) => ({
          label: FILTER_LABEL_MAPPINGS[item.label](),
          value: index,
          selectedIds: [selectedFilter],
          onSelect: () => {
            if (item.value === null) {
              deleteFilter(selectedFilter);
            } else {
              this.addUniqueTypeFilter(item);
            }
          },
        }));
      };

      return (
        <>
          {title.length > 0 ? (
            <p className="property-filter-panel_section-header">
              {i18n(title)}
            </p>
          ) : (
            <div style={{ marginTop: "12px" }} />
          )}
          <div className="property-filter-panel_section">
            <Dropdown
              noMaxHeight
              selectedIds={filters
                .filter((f) => f.type === filterType)
                .map((f) => f.id)}
              items={getItems()}
              label={getLabel()}
            />
          </div>
        </>
      );
    } else {
      return null;
    }
  }

  renderChipsSection(title, filterType) {
    return (
      <>
        <p className="property-filter-panel_section-header">{i18n(title)}</p>
        <div className="property-filter-panel_chip-container">
          {filterType.map((filter, index) =>
            this.renderFilterChip(filter, index),
          )}
        </div>
      </>
    );
  }

  // filters that appear only when commercial building type is selected
  renderCommercialFilters() {
    return (
      <>
        {this.renderTagFilters()}
        {this.renderChipsSection("Commercial Type", COMMERCIAL_TYPE_FILTERS)}
        {this.renderBankPropertyDropdownSection(
          "",
          PROPERTY_FILTER_TYPES.isPrivateBankProperty,
          PRIVATE_BANK_PROPERTY_FILTERS,
        )}
        {this.renderSingleDropdownSection(
          "Seller type",
          PROPERTY_FILTER_TYPES.sellerType,
          SELLER_TYPE_FILTERS,
          true,
        )}
        {this.renderRangeInputSection(
          "Price",
          "Min price",
          "Max price",
          PRICE_RANGE_FILTER_ID,
          "price",
          PROPERTY_FILTER_TYPES.price,
        )}
        {this.renderRangeInputSection(
          "Total surface m²",
          "Min m²",
          "Max m²",
          AREA_RANGE_FILTER_ID,
          "size",
          PROPERTY_FILTER_TYPES.area,
        )}
        {this.renderRangeInputSection(
          "Floors",
          "Min floors",
          "Max floors",
          NUM_FLOORS_RANGE_FILTER_ID,
          "numFloors",
          PROPERTY_FILTER_TYPES.numFloors,
        )}
        {this.renderRangeInputSection(
          "Rooms",
          "Min rooms",
          "Max rooms",
          BEDROOMS_RANGE_FILTER_ID,
          "rooms",
          PROPERTY_FILTER_TYPES.bedrooms,
        )}
        {this.renderRangeDropdownSection(
          "Toilets / Bathroooms",
          "Min",
          "Max",
          BATHROOMS_RANGE_FILTER_ID,
          "bathrooms",
          PROPERTY_FILTER_TYPES.bathrooms,
          this.getOneToTenDropdownItems(),
        )}
        {this.renderCheckboxSection("Features", COMMERCIAL_FEATURES_FILTERS)}
        {this.renderChipsSection(
          "Business Activities",
          COMMERCIAL_BUSINESS_ACTIVITIES_FILTERS,
        )}
        {this.renderSingleDropdownSection(
          "Condition",
          PROPERTY_FILTER_TYPES.condition,
          PROPERTY_CONDITTION_FILTERS,
          true,
          true,
        )}
      </>
    );
  }

  // filters that appear only when land building type is selected
  renderLandFilters() {
    return (
      <>
        {this.renderTagFilters()}
        {this.renderChipsSection("Land Type", LAND_TYPE_FILTERS)}
        {this.renderBankPropertyDropdownSection(
          "",
          PROPERTY_FILTER_TYPES.isPrivateBankProperty,
          PRIVATE_BANK_PROPERTY_FILTERS,
        )}
        {this.renderSingleDropdownSection(
          "Seller type",
          PROPERTY_FILTER_TYPES.sellerType,
          SELLER_TYPE_FILTERS,
          true,
        )}
        {this.renderRangeInputSection(
          "Price",
          "Min price",
          "Max price",
          PRICE_RANGE_FILTER_ID,
          "price",
          PROPERTY_FILTER_TYPES.price,
        )}
        {this.renderRangeInputSection(
          "Total surface m²",
          "Min m²",
          "Max m²",
          AREA_RANGE_FILTER_ID,
          "size",
          PROPERTY_FILTER_TYPES.area,
        )}
        {this.renderRangeInputSection(
          "Buildable area m²",
          "Min m²",
          "Max m²",
          BUILDABLE_AREA_RANGE_FILTER_ID,
          "land_buildableSize",
          PROPERTY_FILTER_TYPES.landBuildableSize,
        )}
        {this.renderRangeDropdownSection(
          "Buildable floors",
          "Min",
          "Max",
          PROPERTY_FILTER_TYPES.landBuildableFloors,
          "land_buildableFloors",
          BUILDABLE_FLOORS_RANGE_FILTER_ID,
          this.getOneToTenDropdownItems(),
        )}
        {this.renderCheckboxSection("Features", LAND_FEATURES_FILTERS)}
        {this.renderSingleDropdownSection(
          "Classification",
          PROPERTY_FILTER_TYPES.landClassification,
          LAND_CLASSIFICATION_FEATURES,
          true,
          true,
        )}
      </>
    );
  }

  // gets the last 10 years as numerical values
  getYearSoldDropdownItems() {
    const currentYear = moment().year();
    const items = [];

    for (let i = 0; i < 10; i++) {
      items.push({
        label: currentYear - i,
        value: moment()
          .year(currentYear - i)
          .unix(),
      });
    }

    return items;
  }

  getOneToTenDropdownItems() {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => ({
      label: value === 10 ? "10+" : value.toString(),
      value,
    }));
  }

  renderSoldPropertyFilters() {
    const propertyTypeFilters = PROPERTY_TYPE_FILTERS.filter(
      (f) =>
        f.value === "flat" ||
        f.value === "independantHouse" ||
        f.value === "terracedHouse",
    );

    return (
      <>
        {this.renderChipsSection("Property Type", propertyTypeFilters)}
        {this.renderRangeInputSection(
          "Price",
          "Min price",
          "Max price",
          PRICE_RANGE_FILTER_ID,
          "price",
          PROPERTY_FILTER_TYPES.price,
        )}
        {this.renderRangeInputSection(
          "Area built m²",
          "Min m²",
          "Max m²",
          AREA_RANGE_FILTER_ID,
          "size",
          PROPERTY_FILTER_TYPES.area,
        )}
        {this.renderRangeDropdownSection(
          "Year of sale",
          "Min year",
          "Max year",
          PROPERTY_FILTER_TYPES.yearSold,
          "timestamp",
          YEAR_SOLD_RANGE_FILTER_ID,
          this.getYearSoldDropdownItems(),
        )}
      </>
    );
  }

  renderSoldCommercialFilters() {
    return (
      <>
        {this.renderChipsSection("Commercial Type", COMMERCIAL_TYPE_FILTERS)}
        {this.renderRangeInputSection(
          "Price",
          "Min price",
          "Max price",
          PRICE_RANGE_FILTER_ID,
          "price",
          PROPERTY_FILTER_TYPES.price,
        )}
        {this.renderRangeInputSection(
          "Area built m²",
          "Min m²",
          "Max m²",
          AREA_RANGE_FILTER_ID,
          "size",
          PROPERTY_FILTER_TYPES.area,
        )}
        {this.renderRangeDropdownSection(
          "Year of sale",
          "Min year",
          "Max year",
          PROPERTY_FILTER_TYPES.yearSold,
          "timestamp",
          YEAR_SOLD_RANGE_FILTER_ID,
          this.getYearSoldDropdownItems(),
        )}
      </>
    );
  }

  renderGeneralSoldPropertyFilters() {
    return (
      <>
        {this.renderRangeInputSection(
          "Price",
          "Min price",
          "Max price",
          PRICE_RANGE_FILTER_ID,
          "price",
          PROPERTY_FILTER_TYPES.price,
        )}
        {this.renderRangeInputSection(
          "Area built m²",
          "Min m²",
          "Max m²",
          AREA_RANGE_FILTER_ID,
          "size",
          PROPERTY_FILTER_TYPES.area,
        )}
        {this.renderRangeDropdownSection(
          "Year of sale",
          "Min year",
          "Max year",
          PROPERTY_FILTER_TYPES.yearSold,
          "timestamp",
          YEAR_SOLD_RANGE_FILTER_ID,
          this.getYearSoldDropdownItems(),
        )}
      </>
    );
  }

  renderPropertyFilters() {
    const { saleType } = this.props;

    let features = PROPERTY_FEATURES_FILTERS;
    if (saleType === "rent") {
      features = PROPERTY_RENTAL_FEATURES_FILTERS;
    }

    return (
      <>
        {saleType === "rent" &&
          this.renderShortTermDropdownSection(
            "Term",
            PROPERTY_FILTER_TYPES.isShortTerm,
            SHORT_TERM_FILTERS,
          )}
        {this.renderTagFilters()}
        {this.renderChipsSection("Property Type", PROPERTY_TYPE_FILTERS)}
        {saleType !== "rent" &&
          this.renderBankPropertyDropdownSection(
            "",
            PROPERTY_FILTER_TYPES.isPrivateBankProperty,
            PRIVATE_BANK_PROPERTY_FILTERS,
          )}
        {this.renderSingleDropdownSection(
          "Seller type",
          PROPERTY_FILTER_TYPES.sellerType,
          SELLER_TYPE_FILTERS,
        )}
        {this.renderRangeInputSection(
          "Price",
          "Min price",
          "Max price",
          PRICE_RANGE_FILTER_ID,
          "price",
          PROPERTY_FILTER_TYPES.price,
        )}
        {this.renderRangeDropdownSection(
          "Bedrooms",
          "Min beds",
          "Max beds",
          PROPERTY_FILTER_TYPES.bedrooms,
          "rooms",
          BEDROOMS_RANGE_FILTER_ID,
          this.getOneToTenDropdownItems(),
        )}
        {this.renderRangeDropdownSection(
          "Bathrooms",
          "Min baths",
          "Max baths",
          PROPERTY_FILTER_TYPES.bathrooms,
          "bathrooms",
          BATHROOMS_RANGE_FILTER_ID,
          this.getOneToTenDropdownItems(),
        )}
        {this.renderChipsSection("Features", features)}
        {this.renderRangeInputSection(
          "Area built m²",
          "Min m²",
          "Max m²",
          AREA_RANGE_FILTER_ID,
          "size",
          PROPERTY_FILTER_TYPES.area,
        )}
        {this.renderSingleDropdownSection(
          "Condition",
          PROPERTY_FILTER_TYPES.condition,
          PROPERTY_CONDITTION_FILTERS,
          true,
          true,
        )}
        {this.renderSingleDropdownSection(
          "Multimedia",
          PROPERTY_FILTER_TYPES.multimedia,
          PROPERTY_MULTIMEDIA_FILTERS,
          true,
          true,
        )}
      </>
    );
  }

  renderBuildingFilters() {
    return (
      <>
        {this.renderTagFilters()}
        {this.renderChipsSection(
          "Condition Status",
          BUILDING_CONDITION_STATUS_FILTERS,
        )}
        {this.renderBankPropertyDropdownSection(
          "",
          PROPERTY_FILTER_TYPES.isPrivateBankProperty,
          PRIVATE_BANK_PROPERTY_FILTERS,
        )}
        {this.renderRangeInputSection(
          "Price",
          "Min price",
          "Max price",
          PRICE_RANGE_FILTER_ID,
          "price",
          PROPERTY_FILTER_TYPES.price,
        )}
        {this.renderRangeInputSection(
          "Built size m²",
          "Min m²",
          "Max m²",
          SIZE_RANGE_FILTER_ID,
          "size",
          PROPERTY_FILTER_TYPES.size,
        )}
        {this.renderRangeDropdownSection(
          "Floors",
          "Min floors",
          "Max floors",
          PROPERTY_FILTER_TYPES.numFloors,
          "numFloors",
          NUM_FLOORS_RANGE_FILTER_ID,
          this.getOneToTenDropdownItems(),
        )}
        {this.renderRangeDropdownSection(
          "Lifts",
          "Min lifts",
          "Max lifts",
          PROPERTY_FILTER_TYPES.lifts,
          "building_numLifts",
          LIFTS_RANGE_FILTER_ID,
          this.getOneToTenDropdownItems(),
        )}
        {this.renderCheckboxSection(
          "Type of allowed use",
          BUILDING_ALLOWED_USE_FILTERS,
        )}
        {this.renderCheckboxSection("Features", BUILDING_FEATURES_FILTERS)}
        {this.renderRangeDropdownSection(
          "Garage spaces included in the price",
          "Min spaces",
          "Max spaces",
          PROPERTY_FILTER_TYPES.buildingGarageSpaces,
          "building_garageSpaces",
          GARAGE_SPACES_RANGE_FILTER_ID,
          this.getOneToTenDropdownItems(),
        )}
      </>
    );
  }

  renderGarageFilters() {
    return (
      <>
        {this.renderTagFilters()}
        {this.renderSingleDropdownSection(
          "Spot capacity",
          PROPERTY_FILTER_TYPES.garageSpotCapacity,
          GARAGE_SPOT_CAPACITY_FILTERS,
        )}
        {this.renderBankPropertyDropdownSection(
          "",
          PROPERTY_FILTER_TYPES.isPrivateBankProperty,
          PRIVATE_BANK_PROPERTY_FILTERS,
        )}
        {this.renderRangeInputSection(
          "Price",
          "Min price",
          "Max price",
          PRICE_RANGE_FILTER_ID,
          "price",
          PROPERTY_FILTER_TYPES.price,
        )}
        {this.renderRangeInputSection(
          "Surface m²",
          "Min m²",
          "Max m²",
          AREA_RANGE_FILTER_ID,
          "size",
          PROPERTY_FILTER_TYPES.area,
        )}
        {this.renderChipsSection(
          "Parking spaces",
          GARAGE_PARKING_SPACES_FILTERS,
        )}
        {this.renderCheckboxSection("Features", GARAGE_FEATURES_FILTERS)}
      </>
    );
  }

  renderStorageFilters() {
    return (
      <>
        {this.renderTagFilters()}
        {this.renderRangeInputSection(
          "Price",
          "Min price",
          "Max price",
          PRICE_RANGE_FILTER_ID,
          "price",
          PROPERTY_FILTER_TYPES.price,
        )}
        {this.renderBankPropertyDropdownSection(
          "",
          PROPERTY_FILTER_TYPES.isPrivateBankProperty,
          PRIVATE_BANK_PROPERTY_FILTERS,
        )}
        {this.renderRangeInputSection(
          "Built size m²",
          "Min m²",
          "Max m²",
          AREA_RANGE_FILTER_ID,
          "size",
          PROPERTY_FILTER_TYPES.area,
        )}
        {this.renderCheckboxSection("Features", STORAGE_FEATURES_FILTERS)}
      </>
    );
  }

  renderRoomFilters() {
    return (
      <>
        {this.renderShortTermDropdownSection(
          "Term",
          PROPERTY_FILTER_TYPES.isShortTerm,
          SHORT_TERM_FILTERS,
        )}
        {this.renderTagFilters()}
        {this.renderRangeInputSection(
          "Price",
          "Min price",
          "Max price",
          PRICE_RANGE_FILTER_ID,
          "price",
          PROPERTY_FILTER_TYPES.price,
        )}
        {this.renderRangeDropdownSection(
          "Bedrooms",
          "Min beds",
          "Max beds",
          PROPERTY_FILTER_TYPES.bedrooms,
          "rooms",
          BEDROOMS_RANGE_FILTER_ID,
          this.getOneToTenDropdownItems(),
        )}
        {this.renderRangeDropdownSection(
          "Bathrooms",
          "Min baths",
          "Max baths",
          PROPERTY_FILTER_TYPES.bathrooms,
          "bathrooms",
          BATHROOMS_RANGE_FILTER_ID,
          this.getOneToTenDropdownItems(),
        )}
        {this.renderChipsSection("Features", PROPERTY_RENTAL_FEATURES_FILTERS)}
        {this.renderRangeInputSection(
          "Area built m²",
          "Min m²",
          "Max m²",
          AREA_RANGE_FILTER_ID,
          "size",
          PROPERTY_FILTER_TYPES.area,
        )}
      </>
    );
  }

  renderOfficeFilters() {
    return (
      <>
        {this.renderTagFilters()}
        {this.renderRangeDropdownSection(
          "Floor level within building",
          "Min floor",
          "Max floor",
          PROPERTY_FILTER_TYPES.floor,
          "floor",
          FLOOR_RANGE_FILTER_ID,
          this.getOneToTenDropdownItems(),
        )}
        {this.renderBankPropertyDropdownSection(
          "",
          PROPERTY_FILTER_TYPES.isPrivateBankProperty,
          PRIVATE_BANK_PROPERTY_FILTERS,
        )}
        {this.renderRangeInputSection(
          "Price",
          "Min price",
          "Max price",
          PRICE_RANGE_FILTER_ID,
          "price",
          PROPERTY_FILTER_TYPES.price,
        )}
        {this.renderRangeInputSection(
          "Built size m²",
          "Min m²",
          "Max m²",
          SIZE_RANGE_FILTER_ID,
          "size",
          PROPERTY_FILTER_TYPES.size,
        )}
        {this.renderChipsSection("Building use", OFFICE_EXCLUSIVE_USE_FILTERS)}
        {this.renderRangeInputSection(
          "Surface ㎡",
          "Min m²",
          "Max m²",
          SIZE_RANGE_FILTER_ID,
          "size",
          PROPERTY_FILTER_TYPES.size,
        )}
        {this.renderSingleDropdownSection(
          "International Global Distribution",
          PROPERTY_FILTER_TYPES.officeDistribution,
          OFFICE_DISTRIBUTION_FILTERS,
        )}
        {this.renderCheckboxSection("Features", OFFICE_FEATURES_FILTERS)}
        {this.renderCheckboxSection(
          "Office’s security",
          OFFICE_SECURITY_FILTERS,
        )}
        {this.renderCheckboxSection("Orientation", OFFICE_ORIENTATION_FILTERS)}
        {this.renderCheckboxSection(
          "Interior / Exterior",
          OFFICE_LOCATION_FILTERS,
        )}
        {this.renderRangeInputSection(
          "Construction year",
          "Min year",
          "Max year",
          YEAR_RANGE_FILTER_ID,
          "yearConstructed",
          PROPERTY_FILTER_TYPES.yearConstructed,
        )}
      </>
    );
  }

  renderFilterPanel() {
    const { buildingType, saleType } = this.props;

    // sold properties filters
    if (saleType === "sold") {
      if (buildingType.value === "property") {
        return this.renderSoldPropertyFilters();
      }

      if (buildingType.value === "commercial") {
        return this.renderSoldCommercialFilters();
      }

      return this.renderGeneralSoldPropertyFilters();
    }

    // else, standard filters for sale/rent
    if (buildingType.value === "land") {
      return this.renderLandFilters();
    }

    if (buildingType.value === "commercial") {
      return this.renderCommercialFilters();
    }

    if (buildingType.value === "building") {
      return this.renderBuildingFilters();
    }

    if (buildingType.value === "garage") {
      return this.renderGarageFilters();
    }

    if (buildingType.value === "storage") {
      return this.renderStorageFilters();
    }

    if (buildingType.value === "office") {
      return this.renderOfficeFilters();
    }

    if (buildingType.value === "room") {
      return this.renderRoomFilters();
    }

    return this.renderPropertyFilters();
  }

  render() {
    const { className } = this.props;

    return (
      <div className="property-filter-panel-container">
        <div className="property-filter-panel-header">
          <div className="flex w-full">
            <div onClick={() => this.onBack()} className="primary-btn">
              <img alt="Back icon" src={leftChevronIcon} />
            </div>
            <span className="property-filter-panel-header_property-count">
              {getPropertiesCountText(true)}
            </span>
            <div
              onClick={() => this.clearFilters()}
              className="primary-btn ml-2"
            >
              <img src={closeIcon} alt="Close icon" />
            </div>
          </div>
          <div className="spacer"></div>
          <div ref={this.headerDivider} className="divider"></div>
        </div>
        <div
          onScroll={(e) => this.onFilterPanelScroll(e)}
          className={
            "property-filter-panel" + (className ? ` ${className}` : "")
          }
        >
          {this.renderFilterPanel()}
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  filters: state.filters.filters,
  buildingType: state.filters.buildingType,
  saleType: state.filters.saleType,
}))(FilterPanel);
