import { useJsApiLoader } from "@react-google-maps/api";
import {
  setMarkLocationMode,
  setMarkLocationProperty,
} from "actions/adminActions";
import {
  setAreaSelectionMode,
  setAvailableAreas,
  setCanDrawGeographicPolygons,
  toggleSelectedArea,
} from "actions/mapActions";
import { setSaveSearchModalOpen } from "actions/saveSearchActions";
import { markPropertyLocation } from "api/admin";
import AreaSelectionPanel from "components/property/AreaSelectionPanel";
import SearchSaveModal from "components/search/SearchSaveModal";
import { i18n } from "i18n/localisation";
import { sendAnalyticsEvent } from "lib/analytics";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { toast } from "sonner";
import { v4 as uuidv4 } from "uuid";
import {
  incrementPolygonColourCode,
  setDrawnPolygons,
  setSelectedPolygons,
} from "../../actions/polygonActions";
import {
  deleteProperties,
  updateProperty,
} from "../../actions/propertiesActions";
import {
  GOOGLE_MAPS_API_KEY,
  GOOGLE_MAPS_LIBRARIES,
  MAX_ALLOWED_AREAS,
  MOBILE_BREAKPOINT,
} from "../../config/constants";
import { polygonColourLadder, polygonColours } from "../../config/map";
import {
  filterByRelevantTypology,
  getFilteredProperties,
} from "../../lib/filter/filters";
import store from "../../store";
import { getDistrictsForMunicipality } from "../../utils/helpers";
import {
  clearAllActiveGeographicPolygons,
  clearAllDrawnGeographicPolygons,
  drawHoverDistricts,
  enableAreaClickability,
  getGlobalMapInstance,
  onCreatePolygon,
  onDeletePolygon,
  renderPolygonDeleteButton,
  selectActiveGeographicPolygon,
  shouldDrawGeographicBounds,
} from "../../utils/map";
import {
  gMapPathToPolygon,
  getLargestPolygonFromGeoJsonFeature,
  googlePolygonToGeometryCoordinates,
  polygonIsLargerThanMilesSquared,
  serializePolygon,
} from "../../utils/polygon";
import CmaPanel from "../cma/CmaPanel";
import UploadPropertySearchBar from "../myProperties/UploadPropertySearchBar";
import PropertyPanel from "../property/PropertyPanel";
import SearchBar from "../search/SearchBar";
import GoogleMapWrapper from "./GoogleMapWrapper";
import MapToolbar from "./MapToolbar";
import MarketInsightsTray from "./MarketInsightsTray";
import ShowPlotsToggle from "./ShowPlotsToggle";
import SaleTypeToggle from "components/search/SaleTypeToggle";

// need these vars to exist out of the react state as lower level
// google maps functionality is self contained and doesn't access the parent React state
window.drawnPolygons = [];
var isDraggingPolygon = false;
var scopedProperties = [];

/**
 * Map of world leveraging Google Maps which visualises property locations
 * and allows for searching for property locations through tools like Polygon drawing
 */
const PropertyMap = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: GOOGLE_MAPS_LIBRARIES,
  });

  // store filters in a ref so we can check prop updates
  const filters = props.filters;
  const invisibleFilters = props.invisibleFilters;
  const allProperties = props.properties;
  const prevPropsRef = useRef({
    filters,
    properties: allProperties,
    invisibleFilters,
    polygon: props.polygon,
  });

  const { selectedPolygons } = props.polygon;
  const [map, setMap] = useState(null);
  const [drawingMode, setDrawingMode] = useState(null);
  const [drawingManager, setDrawingManager] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const searchObject = props.searchObject;
  const { cmaProperty } = props.cma;

  // if in cma mode we use the cma filter else we use the
  // global filter
  const [filteredProperties, setFilteredProperties] = useState(
    props.page === "cma" || props.page === "myProperties"
      ? filterByRelevantTypology(cmaProperty, props.properties)
      : getFilteredProperties(props.properties),
  );

  scopedProperties = props.properties;

  const [activeDistrictMunicipality, setActiveDistrictMunicipality] =
    useState(null);

  // treat as componentDidUpdate and listen for filter changes
  // so we can update the filtered properties state
  // we end up firing getFilteredProperties on each render
  // and this component renders many times over, so it is a performance
  // issue to call getFilteredProperties on each render for this component
  useEffect(() => {
    let prevProps = prevPropsRef.current;

    // compare filter change, if so update filtered properties state var
    if (
      prevProps.filters.length != filters.length ||
      allProperties.length != prevProps.properties.length ||
      prevProps.invisibleFilters.length != invisibleFilters.length
    ) {
      setFilteredProperties(
        props.page === "cma" || props.page === "myProperties"
          ? filterByRelevantTypology(cmaProperty, props.properties)
          : getFilteredProperties(props.properties),
      );
    }

    if (allProperties.length === 0) {
      setFilteredProperties([]);
    }
  }, [filters, invisibleFilters, allProperties]);

  // function that deselects the supplied polygon
  const onPolygonBlur = (polygon) => {
    polygon.setOptions({
      strokeOpacity: 1,
      strokeColor: polygon.colourCode,
      fillColor: polygon.colourCode,
      draggable: false,
      editable: false,
    });

    // remove the delete button if it exists
    if (polygon.deleteButton) {
      polygon.deleteButton.setMap(null);
    }
  };

  // deselects all polygons
  const deselectAllPolygons = (updateReduxState = true) => {
    for (let polygon of window.drawnPolygons) {
      onPolygonBlur(polygon);
    }

    if (updateReduxState) {
      store.dispatch(setSelectedPolygons([]));
    }
  };

  // function that deselects other polygons
  const deselectOtherPolygons = (
    polygonToKeepSelected,
    updateReduxState = true,
  ) => {
    for (let otherPolygon of window.drawnPolygons) {
      if (polygonToKeepSelected.irealtyId != otherPolygon.irealtyId) {
        onPolygonBlur(otherPolygon);
      }
    }

    // update redux store with selected polygon id
    if (updateReduxState) {
      store.dispatch(setSelectedPolygons([polygonToKeepSelected.irealtyId]));
    }
  };

  // handler for selecting polygons
  const onPolygonSelect = (polygon, editable) => {
    // update redux store with selected polygon id
    store.dispatch(setSelectedPolygons([polygon.irealtyId]));

    // deselect other polygons without updating the redux store
    // because we have done it above
    deselectOtherPolygons(polygon, false);
    renderPolygonDeleteButton(polygon, onPolygonDelete);

    polygon.setOptions({
      fillColor: polygonColours.primary,
      strokeColor: polygonColours.selected,
      strokeOpacity: 1,
    });

    if (editable) {
      polygon.setOptions({
        draggable: false,
        editable: true,
      });
    }
  };

  // handler for deleting polygons
  const onPolygonDelete = useCallback(
    (polygonId) => {
      // get the handle to the polygon
      let polygon = window.drawnPolygons.find((p) => p.irealtyId === polygonId);
      let polygonCoords = gMapPathToPolygon(polygon.getPath().getArray());

      // not to be confused with onPolygonDelete which is the caller
      // this deletes the properties/markers from the redux state associated with the polygon
      let propertiesDeleted = onDeletePolygon(polygonCoords, polygonId);

      // remove the selected property pin state if it has been deleted
      if (propertiesDeleted.map((p) => p.id).includes(selectedProperty)) {
        setSelectedProperty(null);
      }

      // delete polygon from view
      polygon.setMap(null);

      // then remove it as a selected polygon from the store
      // and update the drawn polygons on the redux store
      window.drawnPolygons = window.drawnPolygons.filter(
        (p) => p.irealtyId != polygonId,
      );

      // allow the redrawing of municipalities if there are no drawn polygons
      if (window.drawnPolygons.length === 0) {
        store.dispatch(setCanDrawGeographicPolygons(true));

        // redraw municipality bounds if conditions are okay
        setTimeout(() => {
          shouldDrawGeographicBounds(handleMunicipalityClick);
        }, 100);
      }

      store.dispatch(setSelectedPolygons([]));
      store.dispatch(
        setDrawnPolygons(
          window.drawnPolygons.map((polygon) => serializePolygon(polygon)),
        ),
      );
    },
    [props.page],
  );

  // fired whenever we draw or change a polygon
  const handlePolygonChange = (
    polygon,
    event,
    saleType = null,
    type = null,
  ) => {
    // prevent users from drawing polygons that are too large
    if (!polygon.metadata && polygonIsLargerThanMilesSquared(polygon, 10)) {
      toast.error(i18n("Polygon is too large, please draw a smaller polygon"), {
        duration: 3000,
      });
      return;
    }

    const paths = polygon.getPath().getArray();
    const polygonCoords = gMapPathToPolygon(paths);

    // creation event
    if (event === "change") {
      // else its a polygon update and we need to wipe the properties associated with
      // the polygon, they will get re-added when onCreatePolygon promise resolves
      let propertiesToClear = scopedProperties.filter(
        (p) => p.polygonId === polygon.irealtyId,
      );
      props.dispatch(deleteProperties(propertiesToClear));
    }

    // fire a request to server to fetch properties within
    // the polygon's bounds
    onCreatePolygon(polygonCoords, polygon.irealtyId, saleType, type);

    // update redux store with updated polygons
    store.dispatch(
      setDrawnPolygons(
        [].concat(window.drawnPolygons.map((p) => serializePolygon(p))),
      ),
    );
  };

  // fired when a municipality is selected from the search bar
  // open district selection panel if sub areas are available
  const handleMunicipalitySelection = (areas, munCode, munId) => {
    props.dispatch(setAreaSelectionMode("district"));
    props.dispatch(setAvailableAreas(areas));
    setActiveDistrictMunicipality(munCode);

    // clear all other active municipality polygons
    clearAllActiveGeographicPolygons();

    // select the municipality on the map
    selectActiveGeographicPolygon(munId);

    // only make zones hoverable if municipality has both zones and districts
    let hasZonesAndDistricts =
      areas.find((a) => a.type === "zone") &&
      areas.find((a) => a.type === "district");
    let areasToDraw = areas.map((a) => {
      return {
        ...a,
        hoverable: hasZonesAndDistricts ? a.type === "zone" : true,
      };
    });

    // filter out hoverable municipalities
    drawHoverDistricts(areasToDraw.filter((d) => d.type !== "municipality"));
  };

  // fired when a municipality is clicked on the map
  const handleMunicipalityClick = async (municipality) => {
    sendAnalyticsEvent("Municipality Click", {
      type: "municipality",
      details: municipality.name,
    });

    const areaSelectionMode = props.mapReducer.areaSelectionMode;
    let areas = await getDistrictsForMunicipality(municipality.mun_code);

    // new: include municipality in district array so entire municipalities can
    // also be fetched
    areas = [municipality].concat(areas);
    let selectAreas = false;

    // if we are in area selection mode then we just toggle the area as selected
    // as we are in the process of selecting areas rather than opening districts
    // for that area
    if (areaSelectionMode === "area") {
      selectAreas = true;
    } else {
      // else open the area selection panel for that municipality
      handleMunicipalitySelection(
        areas,
        municipality.mun_code,
        municipality.id,
      );
      zoomToDrawnPolygons([municipality]);
    }

    // if there is only a single area then select it by default
    if (areas.length == 1 || selectAreas) {
      if (
        props.mapReducer.selectedAreas.length == MAX_ALLOWED_AREAS &&
        !props.mapReducer.selectedAreas.find((a) => a.id == areas[0].id)
      ) {
        toast.error(i18n("Max number of areas reached"));
      } else {
        props.dispatch(toggleSelectedArea(areas[0]));
      }
    }
  };

  // removes all polygons from the map
  const removeAllMunicipalityPolygons = () => {
    for (let polygon of window.drawnPolygons) {
      if (polygon.metadata && polygon.metadata.type === "municipality") {
        onPolygonDelete(polygon.irealtyId);
      }
    }
  };

  // fired when a districts are selected + applied from the district selection panel
  const handleAreasSelected = async (areas) => {
    props.dispatch(setAreaSelectionMode(null));
    setActiveDistrictMunicipality(null);
    removeAllMunicipalityPolygons();

    if (areas.length === 0) return;

    store.dispatch(setCanDrawGeographicPolygons(false));
    multiAreaDraw(areas, {
      fetchProperties: true,
    });
    zoomToDrawnPolygons(areas);
  };

  // account for the property panel so the areas are centered correctly
  const onShiftMapForPropertyPanel = () => {
    if (window.innerWidth <= MOBILE_BREAKPOINT) {
      return;
    }

    let propertyPanel = document.getElementById("property-panel");
    let propertyPanelWidth = propertyPanel.offsetWidth;
    getGlobalMapInstance().panBy(propertyPanelWidth / 2, 0);
  };

  const zoomToDrawnPolygons = (areas) => {
    let polygons = areas.map((r) => r.geometry);

    // Create a LatLngBounds object to encapsulate all polygon coordinates
    const bounds = new window.google.maps.LatLngBounds();
    polygons.forEach((polygon) => {
      let coordinates = [];

      if (polygon.type === "MultiPolygon") {
        coordinates = polygon.coordinates.flat(2);
      } else {
        coordinates = polygon.coordinates[0];
      }

      coordinates.forEach((point) => {
        bounds.extend(
          new window.google.maps.LatLng(
            parseFloat(point[1]),
            parseFloat(point[0]),
          ),
        );
      });
    });

    getGlobalMapInstance().fitBounds(bounds);

    if (props.page === "search") {
      onShiftMapForPropertyPanel();
    }
  };

  // when a polygon is finished being drawn this function is called
  const handlePolygonComplete = useCallback(
    (
      polygon,
      fetchProperties,
      editable,
      administrativeArea = null,
      saleType = null,
      type = null,
    ) => {
      // we generate an id tied to this polygon
      // so we can attribute it to any markers on the map rendered through this polygon
      polygon.irealtyId = uuidv4();
      polygon.metadata = null;

      // prevent nesting of metadata when creating administrative area polygons
      if (administrativeArea && administrativeArea.metadata) {
        polygon.metadata = administrativeArea.metadata;
        polygon.irealtyId = administrativeArea.metadata.id;
      } else if (administrativeArea && administrativeArea.mun_code) {
        polygon.metadata = administrativeArea;
        polygon.irealtyId = administrativeArea.id;
      }

      // if we have drawn a custom area in district selection mode
      // then add it as a selected district and bail
      if (!administrativeArea && props.mapReducer.areaSelectionMode) {
        let customDistrict = {
          id: uuidv4(),
          name: i18n("Custom area"),
          type: "district",
          geometry: {
            coordinates: [googlePolygonToGeometryCoordinates(polygon)],
          },
        };

        // treat polygon as if it were a district
        if (polygonIsLargerThanMilesSquared(polygon, 10)) {
          toast.error(
            i18n("Polygon is too large, please draw a smaller polygon"),
            {
              duration: 3000,
            },
          );
        } else {
          if (props.mapReducer.selectedAreas.length == MAX_ALLOWED_AREAS) {
            toast.error(i18n("Max number of areas reached"));
          } else {
            props.dispatch(toggleSelectedArea(customDistrict));
          }
        }

        enableAreaClickability();
        polygon.setMap(null); // remove transient polygon
        setDrawingMode(null);
        window.googleMapsDrawingManager.setDrawingMode(null);
        return;
      }

      // also apply and increment the colour code so we can map polygons to certain colours
      let colourCode =
        polygonColourLadder[store.getState().polygon.polygonColourIndex];
      polygon.colourCode = colourCode;
      store.dispatch(incrementPolygonColourCode());

      // make sure to clear out drawn municipalities/provinces
      clearAllDrawnGeographicPolygons();

      // save the polygon because a scoping issue prevents us from
      // accessing it in the onLoad of the DrawingManager
      window.drawnPolygons.push(polygon);

      // reset stroke weight as it is larger when drawing a polygon
      polygon.setOptions({
        strokeWeight: 3,
      });

      // attach a shaping listeners to listen for any locational changes
      // drag
      polygon.addListener("dragstart", function (event) {
        isDraggingPolygon = true;
      });

      polygon.addListener("dragend", function (event) {
        isDraggingPolygon = false;
        handlePolygonChange(this, "change");
      });

      polygon.addListener("click", function (event) {
        if (handleMarkLocation(event)) {
          return;
        }

        onPolygonSelect(polygon, editable);
      });

      // move polygon point
      polygon.getPath().addListener("set_at", function (event) {
        if (!isDraggingPolygon) {
          handlePolygonChange(polygon, "change");
        }
      });

      // insert polygon point
      polygon.getPath().addListener("insert_at", function (event) {
        handlePolygonChange(polygon, "change");
      });

      // deselect all polygons
      deselectAllPolygons();

      // fetch properties based on polygon
      if (fetchProperties) {
        handlePolygonChange(polygon, "create", saleType, type);
      }

      // update redux store with drawn polygons
      store.dispatch(
        setDrawnPolygons(
          [].concat(window.drawnPolygons.map((p) => serializePolygon(p))),
        ),
      );

      // we set the drawing mode to null so that we force Google Maps to stop drawing
      // and to go back to pan mode
      setDrawingMode(null);
      window.googleMapsDrawingManager.setDrawingMode(null);
    },
  );

  const handleMarkLocation = (event) => {
    const admin = store.getState().admin;

    // if we are in mark location mode we mark the location of the property
    if (admin.markLocationMode) {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      markPropertyLocation(admin.markLocationProperty.id, lat, lng).then(() => {
        let updatedProperty = { ...admin.markLocationProperty };
        updatedProperty.latitude = lat;
        updatedProperty.longitude = lng;
        props.dispatch(updateProperty(updatedProperty));
        toast.message("Property location marked successfully");
      });
      props.dispatch(setMarkLocationProperty(null));
      props.dispatch(setMarkLocationMode(false));
      return true;
    }

    return false;
  };

  // map click handler
  const onMapClick = useCallback((event) => {
    if (handleMarkLocation(event)) {
      return;
    }

    for (let polygon of window.drawnPolygons) {
      // check if the click target is not the polygon or any of its paths
      if (
        !window.google.maps.geometry.poly.containsLocation(
          event.latLng,
          polygon,
        )
      ) {
        onPolygonBlur(polygon);
      }
    }

    // update redux store
    store.dispatch(setSelectedPolygons([]));
  });

  // zoom by positive or negative value
  const zoomBy = (val) => {
    var currentZoom = map.getZoom();
    var newZoom = currentZoom + val;
    map.setZoom(newZoom);
  };

  // draws multiple polygons
  const multiAreaDraw = (
    areas,
    options = {
      fetchProperties: false,
      type: null,
    },
  ) => {
    for (let area of areas) {
      drawArea(area, options);
    }
  };

  // draws supplied area into a polygon on map
  const drawArea = useCallback(
    (
      area,
      options = {
        fetchProperties: false,
        saleType: options.saleType,
        type: options.type,
      },
    ) => {
      // first check if an administrative polygon has been drawn already
      let drawnPolygons = store.getState().polygon.drawnPolygons;

      let existingAdministrativePolygon = drawnPolygons.find((p) => {
        return (
          p.metadata && p.metadata.id && area.id && p.metadata.id === area.id
        );
      });

      if (existingAdministrativePolygon) {
        // If polygon exists but saleType is different, delete existing and redraw
        if (
          options.saleType &&
          existingAdministrativePolygon.saleType !== options.saleType
        ) {
          onPolygonDelete(existingAdministrativePolygon.irealtyId);
        } else {
          console.log("Polygon already drawn with same sale type");
          return;
        }
      }

      let coords = area.geometry.coordinates[0];

      // if multi polygon then we take the polygon with the largest area
      if (area.geometry.type === "MultiPolygon") {
        coords = getLargestPolygonFromGeoJsonFeature(area);
      }

      let polygonPath = [];

      // loop through the coordinates and convert them to LatLng objects
      for (const coord of coords) {
        const latLng = new window.google.maps.LatLng(
          parseFloat(coord[1]),
          parseFloat(coord[0]),
        );
        polygonPath.push(latLng);
      }

      createPolygon(
        polygonPath,
        area,
        options.fetchProperties,
        options.saleType,
        options.type,
      );
    },
    [props.page, drawingManager, map],
  );

  const createPolygon = async (
    polygonPath,
    area,
    fetchProperties = true,
    saleType = null,
    type = null,
  ) => {
    let editable = area ? area.editable : false;
    const strokeColour =
      polygonColourLadder[store.getState().polygon.polygonColourIndex];

    const polygon = new window.google.maps.Polygon({
      paths: polygonPath,
      strokeColor: strokeColour,
      strokeOpacity: 1,
      fillColor: strokeColour,
      fillOpacity: polygonColours.fillOpacity,
      draggable: false,
      editable,
    });

    polygon.setMap(getGlobalMapInstance());

    // if its a district we fetch properties on polygon draw
    handlePolygonComplete(
      polygon,
      fetchProperties,
      editable,
      area,
      saleType,
      type,
    );
  };

  return (
    <div>
      {props.page === "search" && (
        <SearchBar
          hidden={props.hideSearchBar}
          map={map}
          handlePolygonComplete={handlePolygonComplete}
          handleMunicipalitySelection={handleMunicipalitySelection}
          drawArea={drawArea}
          multiAreaDraw={multiAreaDraw}
          onShiftMapForPropertyPanel={onShiftMapForPropertyPanel}
          zoomToDrawnPolygons={zoomToDrawnPolygons}
        />
      )}

      {props.page === "myProperties" && <UploadPropertySearchBar />}
      {isLoaded && (
        <GoogleMapWrapper
          setMap={setMap}
          map={map}
          setDrawingManager={setDrawingManager}
          onMapClick={onMapClick}
          filteredProperties={filteredProperties}
          handlePolygonComplete={handlePolygonComplete}
          setSelectedProperty={setSelectedProperty}
          selectedProperty={selectedProperty}
          page={props.page}
          plotMode={props.page === "cma" || props.page === "myProperties"}
          drawingMode={drawingMode}
          handleMunicipalityClick={handleMunicipalityClick}
        />
      )}
      {!props.mapReducer.hideMapTools && (
        <MapToolbar
          page={props.page}
          onMapTypeChange={(newMapType) => map.setMapTypeId(newMapType)}
          drawingMode={drawingMode}
          setDrawingMode={setDrawingMode}
          canClearSelection={selectedPolygons.length > 0}
          onClearSelection={() =>
            selectedPolygons.length > 0 && onPolygonDelete(selectedPolygons[0])
          }
          zoomBy={(val) => zoomBy(val)}
          shouldDrawGeographicBounds={shouldDrawGeographicBounds}
        />
      )}
      <ShowPlotsToggle />
      {props.page === "cma" && (
        <CmaPanel
          onPolygonDelete={onPolygonDelete}
          drawArea={drawArea}
          zoomToDrawnPolygons={zoomToDrawnPolygons}
        />
      )}
      {props.page === "search" && props.collectionsFetched && (
        <PropertyPanel
          selectPropertyMarker={setSelectedProperty}
          multiAreaDraw={multiAreaDraw}
          zoomToDrawnPolygons={zoomToDrawnPolygons}
        />
      )}
      <MarketInsightsTray
        zoomToDrawnPolygons={zoomToDrawnPolygons}
        deletePolygon={onPolygonDelete}
        activeDistrictMunicipality={activeDistrictMunicipality}
      />
      <AreaSelectionPanel
        handleAreasSelected={(areas) => handleAreasSelected(areas)}
      />
      <SearchSaveModal
        isOpen={props.saveSearchModalOpen}
        closeModal={() => props.dispatch(setSaveSearchModalOpen(false))}
        searchObject={searchObject}
      />
    </div>
  );
};

export default connect((state) => ({
  properties: state.property.properties,
  polygon: state.polygon,
  collections: state.collections.collections,
  collectionsFetched: state.collections.collectionsFetched,
  filters: state.filters.filters,
  invisibleFilters: state.filters.invisibleFilters,
  user: state.user,
  admin: state.admin,
  cma: state.cma,
  mapReducer: state.map,
  saveSearchModalOpen: state.saveSearch.saveSearchModalOpen,
  searchObject: state.saveSearch.searchObject,
}))(React.memo(PropertyMap));
