import { setHighlightProIcon, setChangePlanModal } from "actions/miscActions";
import { fetchSubscription, fetchUserData } from "actions/userActions";
import { modifySubscriptionRequest } from "api/subscriptions";
import pricingModalFeatureCheck from "assets/core/pricing_modal_feature_check.svg";
import pricingModalFeatureCross from "assets/core/pricing_modal_feature_cross.svg";
import enPricingModelHeaderImage_1 from "assets/billing/en_pricing_banner_1.png";
import esPricingModelHeaderImage_1 from "assets/billing/es_pricing_banner_1.png";
import pricingModelHeaderImage_2 from "assets/core/pricing_modal_header_image_2.svg";
import StarIcon from "assets/core/star.svg";
import LeftChevronIcon from "assets/core/left_chevron.svg";
import { Auth } from "aws-amplify";
import { getLang, i18n } from "i18n/localisation";
import { sendAnalyticsEvent } from "lib/analytics";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { toast } from "sonner";
import { isMobile, subscribeToPlatform } from "utils/helpers";
import {
  getBillingCycle,
  getSubscriptionPlan,
  getTotalSeats,
  getUsedSeats,
} from "utils/userHelpers";
import Dropdown from "../../core/Dropdown";
import Button from "../../ui/Button/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";

export const SEAT_OPTIONS = [
  {
    numSeats: 1,
    label: () => i18n("Single Seat"),
  },
  {
    numSeats: 2,
    label: () => i18n("Up to 2"),
  },
  {
    numSeats: 5,
    label: () => i18n("Up to 5"),
  },
  {
    numSeats: 19,
    label: () => i18n("Up to 19"),
  },
  {
    numSeats: 40,
    label: () => i18n("Up to 40"),
  },
];

const LOCALISED_IMAGES = {
  en: {
    header1: enPricingModelHeaderImage_1,
  },
  es: {
    header1: esPricingModelHeaderImage_1,
  },
};

const PricingModal = ({
  isOpen,
  user,
  closeModal,
  trialEnded,
  changePlanModal,
  ...props
}) => {
  const [currentPage, setCurrentPage] = useState(
    trialEnded || changePlanModal ? 3 : 1,
  );
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [numSeats, setNumSeats] = useState(1);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const modalContentRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);

  const pricing = {
    monthly: [
      { numSeats: 1, values: { search: 69, country: 89, pro: 149 } },
      { numSeats: 2, values: { search: 89, country: 149, pro: 258 } },
      { numSeats: 5, values: { search: 147, country: 240, pro: 387 } },
      { numSeats: 19, values: { search: 504, country: 900, pro: 1308 } },
      { numSeats: 40, values: { search: 1170, country: 2070, pro: 2970 } },
    ],
    yearly: [
      { numSeats: 1, values: { search: 690, country: 890, pro: 1490 } },
      { numSeats: 2, values: { search: 890, country: 1490, pro: 2580 } },
      { numSeats: 5, values: { search: 1470, country: 2400, pro: 3870 } },
      { numSeats: 19, values: { search: 5040, country: 9000, pro: 13080 } },
      { numSeats: 40, values: { search: 11700, country: 20700, pro: 29700 } },
    ],
  };

  const selectedPricing = pricing[billingCycle].find(
    (option) => option.numSeats === numSeats,
  );

  const onSignOut = () => {
    Auth.signOut().then(() => {
      window.location.href = "/";
    });
  };

  const handleNext = () => {
    sendAnalyticsEvent("Pricing Modal", {
      type: "next",
      details: `current page: ${currentPage}`,
    });

    if (currentPage < 3) {
      setCurrentPage(currentPage + 1);
    } else {
      closeModal();
    }
  };

  const checkScrollable = () => {
    if (modalContentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = modalContentRef.current;
      setIsScrollable(scrollTop + clientHeight < scrollHeight);
      setIsAtBottom(scrollTop + clientHeight >= scrollHeight - 1);
    }
  };
  // on this component mount, fetch the subscription data
  useEffect(() => {
    props.dispatch(fetchSubscription());

    if (trialEnded) {
      setCurrentPage(3);
    }
  }, [trialEnded]);

  useEffect(() => {
    if (changePlanModal) {
      setCurrentPage(3);
    }
  }, [changePlanModal]);

  // remove the event listener on component unmount
  useEffect(() => {
    return () => {
      window.removeEventListener("resize", checkScrollable);
    };
  }, []);

  useEffect(() => {
    // Run checkScrollable only if the modal is open
    if (isOpen) {
      setCurrentSubscription();
      const timeoutId = setTimeout(() => {
        checkScrollable();

        // at the point of opening the model grab the element with the role of dialog and add custom styles
        const dialogElement = document.querySelector('[role="dialog"]');

        console.log(dialogElement);

        if (dialogElement) {
          // add class to the dialog element
          dialogElement.classList.add("pricing-modal-mobile-height");
        }
      }, 100); // Small delay to ensure content has rendered

      // Cleanup timeout on unmount or if modal closes
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isOpen]);

  const setCurrentSubscription = () => {
    let currentPlan = getSubscriptionPlan();

    if (currentPlan === "standard") {
      currentPlan = "search";
    } else if (currentPlan === "pro") {
      currentPlan = "country";
    } else if (currentPlan === "ultra") {
      currentPlan = "pro";
    }

    let currentBillingCycle = getBillingCycle();

    if (currentBillingCycle === "year") {
      currentBillingCycle = "yearly";
    } else {
      currentBillingCycle = "monthly";
    }

    // get current seats and billing cycle
    const currentSeats = getTotalSeats();
    setNumSeats(currentSeats);
    setBillingCycle(currentBillingCycle);

    setSelectedPlan(currentPlan);

    return currentPlan;
  };

  // check if the upgrade button should be disabled
  const isDisabled = () => {
    // Ensure selectedPlan is not empty and contains valid values
    if (!selectedPlan) {
      return true;
    }

    // Get the current plan and total seats from the helper
    let currentPlan = getSubscriptionPlan();
    const currentSeats = getTotalSeats();
    let currentBillingCycle = getBillingCycle();

    if (currentBillingCycle === "year") {
      currentBillingCycle = "yearly";
    } else if (currentBillingCycle === "month") {
      currentBillingCycle = "monthly";
    }

    if (currentPlan === "standard") {
      currentPlan = "search";
    } else if (currentPlan === "pro") {
      currentPlan = "country";
    } else if (currentPlan === "ultra") {
      currentPlan = "pro";
    }

    // Check if the selected plan is the same as the current one
    const isSamePlan = currentPlan === selectedPlan;
    const isSameSeats = currentSeats === numSeats;
    const isSameBillingCycle = billingCycle === currentBillingCycle;

    // Return true to disable if the plan and seats are the same or selectedPlan is empty
    return isSamePlan && isSameSeats && isSameBillingCycle;
  };

  const getPlanName = (plan) => {
    switch (plan) {
      case "search":
        return "";
      case "country":
        return "Pro";
      case "pro":
        return "Ultra";
    }
  };

  // on close reset the current page
  const handleClose = () => {
    if (trialEnded) {
      setCurrentPage(3);
      return;
    }

    if (changePlanModal) {
      props.dispatch(setChangePlanModal(false));
    }

    setCurrentPage(1);
    setBillingCycle("monthly");
    setNumSeats(1);
    setConfirmLoading(false);
    setSelectedPlan("");
    setErrorMessages([]);
    // set highlight pro icon to false
    props.dispatch(setHighlightProIcon(false));
    closeModal();
  };

  const formatPricing = (price) => {
    // if price is greater than 1000 then format it to have a comma
    if (price >= 1000) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    return price;
  };

  const handlePlanSelection = (plan) => {
    let newSelectedPlan = plan;

    // if the selected plan is the same as the current selected plan then unselect it
    if (selectedPlan === newSelectedPlan) {
      setSelectedPlan("");
      // set num seats and billing cycle to default
      setErrorMessages([]);
      return;
    }

    setSelectedPlan(newSelectedPlan);
  };

  const upgradeButtonClicked = () => {
    // if is disabled then return
    if (isDisabled()) {
      return;
    }
    // loading state for the button
    setConfirmLoading(true);

    // remove any error messages
    setErrorMessages([]);

    let selectedPlanType = selectedPlan;
    let selectedBillingCycle = billingCycle;
    let selectedNumSeats = numSeats;

    if (selectedPlanType === "search") {
      selectedPlanType = "standard";
    } else if (selectedPlanType === "country") {
      selectedPlanType = "pro";
    } else if (selectedPlanType === "pro") {
      selectedPlanType = "ultra";
    }

    sendAnalyticsEvent("Pricing Modal", {
      type: "confirm",
      details: selectedPlanType,
    });

    // if the user is already subscribed to the selected plan then return
    if (
      getSubscriptionPlan() === selectedPlanType &&
      getTotalSeats() === selectedNumSeats
    ) {
      // return error message that user is already subscribed to the selected plan
      setConfirmLoading(false);
      return alert("You are already subscribed to this plan");
    }

    // if user is on a plan and is trying to upgrade to a higher plan
    if (getSubscriptionPlan()) {
      // if they have more seats than the selected plan then return an error message
      if (parseInt(selectedNumSeats) < parseInt(getUsedSeats())) {
        setErrorMessages([
          "Remove seats from your team before selecting less seats for your plan.",
        ]);
        setConfirmLoading(false);
      }
      // if they have more seats than the selected plan then update the subscription
      updateSubscription(
        selectedPlanType,
        selectedBillingCycle,
        selectedNumSeats,
      );
    } else {
      // if user is not subscribed to any plan then subscribe to the selected plan
      subscribeToPlatform(
        selectedPlanType,
        selectedBillingCycle,
        selectedNumSeats,
      );
      setConfirmLoading(false);
    }

    // set the loading state to false
  };

  const updateSubscription = async (plan, interval, seats) => {
    try {
      const response = await modifySubscriptionRequest(
        user.sub,
        plan,
        interval,
        seats,
      );

      toast.promise(Promise.resolve(response), {
        loading: i18n("Updating subscription..."),
        success: i18n("Your subscription has been updated successfully"),
        error: i18n("Failed to update subscription"),
        duration: 1000,
      });
      // if response 200 status then update the subscription data
      if (response.status === 200) {
        setConfirmLoading(false);
        closeModal();
        // wait for 1 second before fetching the subscription data + new user role data
        setTimeout(() => {
          props.dispatch(fetchSubscription());
          props.dispatch(fetchUserData());
        }, 1000);
      } else {
        // show an error message
        console.log("Error updating subscription", response);
        // set the loading state to false
        setConfirmLoading(false);
      }
    } catch (error) {
      console.log("Error updating subscription", error);
      // set the loading state to false
      setConfirmLoading(false);
      // show an error message
    }
  };

  const getSelectedPlanPrice = () => {
    if (selectedPlan) {
      let plan = selectedPlan;
      let numSelectedSeats = numSeats;
      let selectedBillingCycle = billingCycle;
      let planPricing = pricing[selectedBillingCycle].find(
        (option) => option.numSeats === parseInt(numSelectedSeats),
      );
      return formatPricing(planPricing.values[plan]);
    }
    return "";
  };

  const handleBack = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const renderPageContent = () => {
    const lang = getLang();
    const pricingModelHeaderImage_1 = LOCALISED_IMAGES[lang.code].header1;

    switch (currentPage) {
      case 1:
        return (
          <div className="px-6 py-4">
            <div className="free-trial-modal_img">
              <img src={pricingModelHeaderImage_1} />
            </div>
            <p
              className="my-6 text-center font-medium"
              style={{ color: "rgba(34, 34, 34, 1)" }}
            >
              {i18n(
                "Get ahead of your competitors by generating accurate property valuations that will save you hours.",
              )}
            </p>
            <div className="free-trial-modal_divider flex items-center gap-4">
              <hr className="flex-1" />
              <span className="text-sm text-gray-700">
                {i18n("BENEFIT FROM")}
              </span>
              <hr className="flex-1" />
            </div>
            <div className="pricing-modal_features flex flex-col">
              <div className="flex gap-4">
                <div className="pricing-modal_features_feature flex flex-row">
                  <div className="pricing-modal_features_feature_icon">
                    <img src={pricingModalFeatureCheck} />
                  </div>
                  <div className="pricing-modal_features_feature_wrapper flex flex-col">
                    <div className="pricing-modal_features_feature_heading flex">
                      <span>{i18n("AI-Vetted Data Accuracy")}</span>
                    </div>
                    <span className="text-gray pricing-modal__features_feature_desc text-sm">
                      {i18n(
                        "Eliminates duplicates and cross-references government sources.",
                      )}
                    </span>
                  </div>
                </div>
                <div className="pricing-modal_features_feature flex flex-row">
                  <div className="pricing-modal_features_feature_icon">
                    <img src={pricingModalFeatureCheck} />
                  </div>
                  <div className="pricing-modal_features_feature_wrapper flex flex-col">
                    <div className="pricing-modal_features_feature_heading flex">
                      <span>{i18n("Customisable Reports")}</span>
                    </div>
                    <span className="text-gray pricing-modal__features_feature_desc text-sm">
                      {i18n(
                        "Agency branded reports for professional presentations.",
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex gap-4">
                <div className="pricing-modal_features_feature flex flex-row">
                  <div className="pricing-modal_features_feature_icon">
                    <img src={pricingModalFeatureCheck} />
                  </div>
                  <div className="pricing-modal_features_feature_wrapper flex flex-col">
                    <div className="pricing-modal_features_feature_heading flex">
                      <span>{i18n("Historical Sold Properties Data")}</span>
                    </div>
                    <span className="text-gray pricing-modal__features_feature_desc text-sm">
                      {i18n(
                        "Access to the real prices of properties sold since 2014.",
                      )}
                    </span>
                  </div>
                </div>
                <div className="pricing-modal_features_feature flex flex-row">
                  <div className="pricing-modal_features_feature_icon">
                    <img src={pricingModalFeatureCheck} />
                  </div>
                  <div className="pricing-modal_features_feature_wrapper flex flex-col">
                    <div className="pricing-modal_features_feature_heading flex">
                      <span>{i18n("Market Analysis")}</span>
                    </div>
                    <span className="text-gray pricing-modal__features_feature_desc text-sm">
                      {i18n(
                        "Get all the insights of the market per property and areas.",
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex gap-4">
                <div className="pricing-modal_features_feature flex flex-row">
                  <div className="pricing-modal_features_feature_icon">
                    <img src={pricingModalFeatureCheck} />
                  </div>
                  <div className="pricing-modal_features_feature_wrapper flex flex-col">
                    <div className="pricing-modal_features_feature_heading flex">
                      <span>{i18n("Access to Catastro Data")}</span>
                    </div>
                    <span className="text-gray pricing-modal__features_feature_desc text-sm">
                      {i18n(
                        "Detailed and precise Catastral information for accurate valuations.",
                      )}
                    </span>
                  </div>
                </div>
                <div className="pricing-modal_features_feature flex flex-row">
                  <div className="pricing-modal_features_feature_icon">
                    <img src={pricingModalFeatureCheck} />
                  </div>
                  <div className="pricing-modal_features_feature_wrapper flex flex-col">
                    <div className="pricing-modal_features_feature_heading flex">
                      <span>{i18n("District-Level Valuations")}</span>
                    </div>
                    <span className="text-gray pricing-modal__features_feature_desc text-sm">
                      {i18n(
                        "Enables localised valuations by using district-specific data.",
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="px-6 py-4">
            <div className="free-trial-modal_img">
              <img src={pricingModelHeaderImage_2} />
            </div>
            <p
              className="my-6 text-center font-medium"
              style={{ color: "rgba(34, 34, 34, 1)" }}
            >
              {i18n(
                "Gain an advantage against your competitors by unlocking the ability to view bank owned properties.",
              )}
            </p>
            <div className="free-trial-modal_divider flex items-center gap-4">
              <hr className="flex-1" />
              <span className="text-sm text-gray-700">
                {i18n("BENEFIT FROM")}
              </span>
              <hr className="flex-1" />
            </div>
            <div className="pricing-modal_features flex flex-col">
              <div className="flex gap-4">
                <div className="pricing-modal_features_feature flex flex-row">
                  <div className="pricing-modal_features_feature_icon">
                    <img src={pricingModalFeatureCheck} />
                  </div>
                  <div className="pricing-modal_features_feature_wrapper flex flex-col">
                    <div className="pricing-modal_features_feature_heading flex">
                      <span>{i18n("Exclusive Access")}</span>
                    </div>
                    <span className="text-gray pricing-modal__features_feature_desc text-sm">
                      {i18n(
                        "First access to unique properties not available elsewhere.",
                      )}
                    </span>
                  </div>
                </div>
                <div className="pricing-modal_features_feature flex flex-row">
                  <div className="pricing-modal_features_feature_icon">
                    <img src={pricingModalFeatureCheck} />
                  </div>
                  <div className="pricing-modal_features_feature_wrapper flex flex-col">
                    <div className="pricing-modal_features_feature_heading flex">
                      <span>{i18n("Diverse Investments")}</span>
                    </div>
                    <span className="text-gray pricing-modal__features_feature_desc text-sm">
                      {i18n(
                        "Variety of properties for short and long-term investments.",
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex gap-4">
                <div className="pricing-modal_features_feature flex flex-row">
                  <div className="pricing-modal_features_feature_icon">
                    <img src={pricingModalFeatureCheck} />
                  </div>
                  <div className="pricing-modal_features_feature_wrapper flex flex-col">
                    <div className="pricing-modal_features_feature_heading flex">
                      <span>{i18n("Direct Bank Contacts")}</span>
                    </div>
                    <span className="text-gray pricing-modal__features_feature_desc text-sm">
                      {i18n(
                        "Direct connections with banks and funds for smoother transactions.",
                      )}
                    </span>
                  </div>
                </div>
                <div className="pricing-modal_features_feature flex flex-row">
                  <div className="pricing-modal_features_feature_icon">
                    <img src={pricingModalFeatureCheck} />
                  </div>
                  <div className="pricing-modal_features_feature_wrapper flex flex-col">
                    <div className="pricing-modal_features_feature_heading flex">
                      <span>{i18n("Competitive Pricing")}</span>
                    </div>
                    <span className="text-gray pricing-modal__features_feature_desc text-sm">
                      {i18n(
                        "Below market value properties, verified for authenticity.",
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex gap-4">
                <div className="pricing-modal_features_feature flex flex-row">
                  <div className="pricing-modal_features_feature_icon">
                    <img src={pricingModalFeatureCheck} />
                  </div>
                  <div className="pricing-modal_features_feature_wrapper flex flex-col">
                    <div className="pricing-modal_features_feature_heading flex">
                      <span>{i18n("Enhanced Experience")}</span>
                    </div>
                    <span className="text-gray pricing-modal__features_feature_desc text-sm">
                      {i18n(
                        "Direct connections with banks and funds for smoother transactions.",
                      )}
                    </span>
                  </div>
                </div>
                <div className="pricing-modal_features_feature flex flex-row">
                  <div className="pricing-modal_features_feature_icon">
                    <img src={pricingModalFeatureCheck} />
                  </div>
                  <div className="pricing-modal_features_feature_wrapper flex flex-col">
                    <div className="pricing-modal_features_feature_heading flex">
                      <span>{i18n("Risk Mitigation")}</span>
                    </div>
                    <span className="text-gray pricing-modal__features_feature_desc text-sm">
                      {i18n(
                        "Below market value properties, verified for authenticity.",
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="pricingModalContainer my-2 px-0 py-4 md:px-6">
            {/* if is mobile */}
            {isMobile() && (
              <>
                <div className="seat-dropdown-mobile">
                  <p className="seat-dropdown-label">
                    {i18n("Number of seats")}
                  </p>
                  <Dropdown
                    className="seat-dropdown"
                    items={SEAT_OPTIONS.map((option, index) => ({
                      label: option.label(),
                      value: numSeats === option.numSeats,
                      onSelect: () => {
                        setNumSeats(option.numSeats);
                      },
                    }))}
                    label={SEAT_OPTIONS.find(
                      (option) => option.numSeats === numSeats,
                    ).label()}
                  />
                </div>

                <div className="toggle-button">
                  <span
                    className={billingCycle === "monthly" ? "active" : ""}
                    onClick={() => setBillingCycle("monthly")}
                  >
                    {i18n("Monthly")}
                  </span>
                  <span
                    className={billingCycle === "yearly" ? "active" : ""}
                    onClick={() => setBillingCycle("yearly")}
                  >
                    {i18n("Yearly")}
                  </span>
                </div>
              </>
            )}
            <div className="pricing-table">
              <div className="pricing-table__header grid grid-cols-4 text-center font-medium">
                {/* if is mobile is false */}
                {!isMobile() ? (
                  <>
                    <div>
                      <p className="seat-dropdown-label">
                        {i18n("Number of seats")}
                      </p>
                      <Dropdown
                        className="seat-dropdown"
                        items={SEAT_OPTIONS.map((option, index) => ({
                          label: option.label(),
                          value: numSeats === option.numSeats,
                          onSelect: () => {
                            setNumSeats(option.numSeats);
                          },
                        }))}
                        label={SEAT_OPTIONS.find(
                          (option) => option.numSeats === numSeats,
                        ).label()}
                      />
                    </div>
                  </>
                ) : (
                  <div></div>
                )}
                <div className="tableHeading">{i18n("iRealty")}</div>
                <div className="tableHeading">{i18n("Pro")}</div>
                <div className="tableHeading">
                  {i18n("Ultra")}
                  {/* img of star.svg */}
                  <img src={StarIcon} />
                </div>
              </div>
              <div className="pricing-table__pricing grid grid-cols-4 text-center font-medium">
                {!isMobile() ? (
                  <div className="toggle-button">
                    <span
                      className={billingCycle === "monthly" ? "active" : ""}
                      onClick={() => setBillingCycle("monthly")}
                    >
                      {i18n("Monthly")}
                    </span>
                    <span
                      className={billingCycle === "yearly" ? "active" : ""}
                      onClick={() => setBillingCycle("yearly")}
                    >
                      {i18n("Yearly")}
                    </span>
                  </div>
                ) : (
                  <div></div>
                )}
                <div>
                  {selectedPricing &&
                    formatPricing(selectedPricing.values.search)}
                  €{" "}
                  <span className="priceLabel">
                    {billingCycle === "yearly" ? "p/y" : "p/m"}
                  </span>
                </div>
                <div>
                  {selectedPricing &&
                    formatPricing(selectedPricing.values.country)}
                  €{" "}
                  <span className="priceLabel">
                    {billingCycle === "yearly" ? "p/y" : "p/m"}
                  </span>
                </div>
                <div>
                  {selectedPricing && formatPricing(selectedPricing.values.pro)}
                  €{" "}
                  <span className="priceLabel">
                    {billingCycle === "yearly" ? "p/y" : "p/m"}
                  </span>
                </div>
              </div>
              <div className="pricing-table__features grid grid-cols-4 text-center">
                <div>{i18n("5M+ Properties")}</div>
                <div>
                  <img src={pricingModalFeatureCheck} />
                </div>
                <div>
                  <img src={pricingModalFeatureCheck} />
                </div>
                <div>
                  <img src={pricingModalFeatureCheck} />
                </div>

                <div>{i18n("AI Search Engine")}</div>
                <div>
                  <img src={pricingModalFeatureCheck} />
                </div>
                <div>
                  <img src={pricingModalFeatureCheck} />
                </div>
                <div>
                  <img src={pricingModalFeatureCheck} />
                </div>

                <div>{i18n("AI Cleaned Data")}</div>
                <div>
                  <img src={pricingModalFeatureCheck} />
                </div>
                <div>
                  <img src={pricingModalFeatureCheck} />
                </div>
                <div>
                  <img src={pricingModalFeatureCheck} />
                </div>

                <div>{i18n("Collections")}</div>
                <div>
                  <img src={pricingModalFeatureCheck} />
                </div>
                <div>
                  <img src={pricingModalFeatureCheck} />
                </div>
                <div>
                  <img src={pricingModalFeatureCheck} />
                </div>

                <div>{i18n("Notifications / Alerts")}</div>
                <div>
                  <img src={pricingModalFeatureCheck} />
                </div>
                <div>
                  <img src={pricingModalFeatureCheck} />
                </div>
                <div>
                  <img src={pricingModalFeatureCheck} />
                </div>

                <div>{i18n("Access to all of Spain")}</div>
                <div>
                  <img src={pricingModalFeatureCheck} />
                </div>
                <div>
                  <img src={pricingModalFeatureCheck} />
                </div>
                <div>
                  <img src={pricingModalFeatureCheck} />
                </div>

                <div>{i18n("Sold Property Data")}</div>
                <div>
                  <img src={pricingModalFeatureCheck} />
                </div>
                <div>
                  <img src={pricingModalFeatureCheck} />
                </div>
                <div>
                  <img src={pricingModalFeatureCheck} />
                </div>

                <div className="font-semibold">{i18n("Valuation Tool")}</div>
                <div>
                  <img src={pricingModalFeatureCross} />
                </div>
                <div>
                  <img src={pricingModalFeatureCheck} />
                </div>
                <div>
                  <img src={pricingModalFeatureCheck} />
                </div>

                <div className="font-semibold">
                  {i18n("Custom Valuation Conditions")}
                </div>
                <div>
                  <img src={pricingModalFeatureCross} />
                </div>
                <div>
                  <img src={pricingModalFeatureCross} />
                </div>
                <div>
                  <img src={pricingModalFeatureCheck} />
                </div>

                <div className="font-semibold">
                  {i18n("Off-market Properties")}
                </div>
                <div>
                  <img src={pricingModalFeatureCross} />
                </div>
                <div>
                  <img src={pricingModalFeatureCross} />
                </div>
                <div>
                  <img src={pricingModalFeatureCheck} />
                </div>

                <div className="font-semibold">
                  {i18n("Access to Exclusive tools")}
                </div>
                <div>
                  <img src={pricingModalFeatureCross} />
                </div>
                <div>
                  <img src={pricingModalFeatureCross} />
                </div>
                <div>
                  <img src={pricingModalFeatureCheck} />
                </div>

                <div className="font-semibold">{i18n("Priority Support")}</div>
                <div>
                  <img src={pricingModalFeatureCross} />
                </div>
                <div>
                  <img src={pricingModalFeatureCross} />
                </div>
                <div>
                  <img src={pricingModalFeatureCheck} />
                </div>

                <div>{i18n("Select Plan")}</div>
                <div>
                  {/* checkbox - radio button */}
                  <input
                    type="radio"
                    id={"search"}
                    name={"plan"}
                    value={"search"}
                    onClick={() => handlePlanSelection("search")}
                    onChange={() => {}}
                    checked={selectedPlan === "search"}
                    className="custom-radio"
                  />
                </div>
                <div>
                  <input
                    type="radio"
                    id={"country"}
                    name={"plan"}
                    value={"country"}
                    onClick={() => handlePlanSelection("country")}
                    onChange={() => {}}
                    checked={selectedPlan === "country"}
                    className="custom-radio"
                  />
                </div>
                <div>
                  <input
                    type="radio"
                    id={"pro"}
                    name={"plan"}
                    value={"pro"}
                    onClick={() => handlePlanSelection("pro")}
                    onChange={() => {}}
                    checked={selectedPlan === "pro"}
                    className="custom-radio"
                  />
                </div>
              </div>
            </div>

            {errorMessages.length > 0 && (
              <div className="error-messages">
                {errorMessages.map((message, index) => (
                  <div key={index} className="error-message">
                    {message}
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => handleClose()}
      className="pricing-modal"
    >
      <DialogContent
        className={`sm:max-w-[425px] ${trialEnded ? "subscription-end-modal" : ""} gap-0`}
      >
        <DialogHeader>
          <DialogTitle>
            <span className="header-title">
              {currentPage === 1 &&
                i18n("The Most Accurate Valuation Tool on the Market")}
              {currentPage === 2 && i18n("Unlock Bank Owned Properties")}
              {currentPage === 3 && (
                <>
                  {trialEnded
                    ? i18n("Your trial has expired")
                    : i18n("Select your plan")}
                </>
              )}
            </span>
          </DialogTitle>

          {currentPage > 1 && isMobile() && (
            <div className="dialog-back-button" onClick={handleBack}>
              <img src={LeftChevronIcon} />
            </div>
          )}
        </DialogHeader>
        <div
          className="overflow-y-scroll md:max-h-[65vh]"
          onScroll={checkScrollable}
          ref={modalContentRef}
        >
          {renderPageContent()}
          <div
            className={`scrollableOverlay pointer-events-none absolute left-0 right-0 h-[150px] bg-gradient-to-b from-transparent via-90% to-white transition-opacity ${
              isScrollable && !isAtBottom ? "" : "hidden"
            } ${
              isMobile() && currentPage === 3
                ? selectedPlan !== ""
                  ? "scrollableOverlayMobileSelected"
                  : "scrollableOverlayPage3"
                : ""
            }`}
          >
            {/* if currentPage is 3 add the select plan */}
            {currentPage === 3 && (
              <div className="pricing-table-overflow-plan-selection grid grid-cols-4 text-center">
                <div>{i18n("Select Plan")}</div>
                <div>
                  {/* checkbox - radio button */}
                  <input
                    type="radio"
                    id={"search"}
                    name={"plan-overflow"}
                    value={"search"}
                    onClick={() => handlePlanSelection("search")}
                    onChange={() => {}}
                    checked={selectedPlan === "search"}
                    className="custom-radio"
                  />
                </div>
                <div>
                  <input
                    type="radio"
                    id={"country"}
                    name={"plan-overflow"}
                    value={"country"}
                    onClick={() => handlePlanSelection("country")}
                    onChange={() => {}}
                    checked={selectedPlan === "country"}
                    className="custom-radio"
                  />
                </div>
                <div>
                  <input
                    type="radio"
                    id={"pro"}
                    name={"plan-overflow"}
                    value={"pro"}
                    onClick={() => handlePlanSelection("pro")}
                    onChange={() => {}}
                    checked={selectedPlan === "pro"}
                    className="custom-radio"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <DialogFooter className="pricing-modal_footer">
          {currentPage === 1 && (
            <div className="align-center pricing-modal_footer_icons flex justify-center gap-2">
              <div className="pricing-modal_footer_icon">
                <div className="pricing-modal_footer_icon_active"></div>
              </div>
              <div className="pricing-modal_footer_icon">
                <div className="pricing-modal_footer_icon_inactive"></div>
              </div>
              <div className="pricing-modal_footer_icon">
                <div className="pricing-modal_footer_icon_inactive"></div>
              </div>
            </div>
          )}
          {currentPage === 2 && (
            <div className="align-center pricing-modal_footer_icons flex justify-center gap-2">
              <div className="pricing-modal_footer_icon">
                <div className="pricing-modal_footer_icon_inactive"></div>
              </div>
              <div className="pricing-modal_footer_icon">
                <div className="pricing-modal_footer_icon_active"></div>
              </div>
              <div className="pricing-modal_footer_icon">
                <div className="pricing-modal_footer_icon_inactive"></div>
              </div>
            </div>
          )}
          <div className="flex w-full justify-between">
            {currentPage > 1 && !trialEnded && !isMobile() && (
              <Button variant="text" onClick={handleBack}>
                {i18n("Back")}
              </Button>
            )}
            {currentPage < 3 && (
              <div className="ml-auto flex gap-2">
                <Button variant="primary" onClick={handleNext}>
                  {i18n("Next")}
                </Button>
              </div>
            )}
            {currentPage === 3 && (
              <>
                {trialEnded && (
                  <Button variant="text" onClick={() => onSignOut()}>
                    {i18n("Log out")}
                  </Button>
                )}
                <div className="ml-auto flex w-full flex-wrap justify-end md:w-[65%]">
                  {selectedPlan && (
                    <div className="pricing-summary">
                      <div className="selected-plan-container">
                        <div className="selected-plan-body">
                          <div className="plan-title">
                            <strong>iRealty {getPlanName(selectedPlan)}</strong>
                          </div>
                          {!isMobile() && (
                            <div className="plan-subtitle">
                              {selectedPlan &&
                                SEAT_OPTIONS.find(
                                  (option) =>
                                    option.numSeats === parseInt(numSeats),
                                ).label()}
                            </div>
                          )}
                        </div>
                        {!isMobile() && <div className="dividers"></div>}
                        <div className="plan-details">
                          {isMobile() && (
                            <div className="plan-subtitle">
                              {selectedPlan &&
                                SEAT_OPTIONS.find(
                                  (option) =>
                                    option.numSeats === parseInt(numSeats),
                                ).label()}
                            </div>
                          )}
                          {isMobile() && <div className="dividers"></div>}
                          <div className="plan-billing-cycle">
                            <span>
                              {billingCycle === "monthly"
                                ? i18n("Monthly")
                                : i18n("Yearly")}
                            </span>
                          </div>
                          {isMobile() && <div className="dividers"></div>}
                          <div className="plan-price">
                            <span>
                              {getSelectedPlanPrice()}€{" "}
                              {billingCycle === "yearly"
                                ? i18n("p/y")
                                : i18n("p/m")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <Button
                    variant="primary"
                    onClick={() => {
                      upgradeButtonClicked();
                    }}
                    loading={confirmLoading}
                    disabled={isDisabled()}
                    className="confirm-button"
                  >
                    {i18n("Confirm")}
                  </Button>
                </div>
              </>
            )}
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default connect((state) => ({
  lang: state.user.userData.preferences.lang,
  user: state.auth.user,
  subscription: state.auth.subscription,
}))(PricingModal);
