import closeIcon from "assets/core/close.svg";
import { i18n } from "i18n/localisation";
import { connect } from "react-redux";
import { Tooltip } from "react-tooltip";
import { searchPolygonToGeoJson } from "utils/polygon";
import { getFilteredProperties } from "../../lib/filter/filters";
import { useState } from "react";
import moment from "moment";
import { formatPrice, isMobile } from "utils/helpers";
import { SHORT_TERM_FILTERS } from "lib/filter/filterTypes";

function MarketInsightsTray({
  drawnPolygons,
  zoomToDrawnPolygons,
  deletePolygon,
  activeDistrictMunicipality,
  properties,
  saleType,
  filters,
  ...props
}) {
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });
  const [isExpanded, setIsExpanded] = useState(false);

  // don't show on mobile on valuation page
  if (isMobile() && window.location.pathname.startsWith("/valuation")) {
    return null;
  }

  let allowedPolygons = drawnPolygons.filter(
    (dp) =>
      !dp.metadata ||
      !dp.metadata.mun_code ||
      dp.metadata.mun_code !== activeDistrictMunicipality,
  );

  if (allowedPolygons.length === 0 || props.mapReducer.hideMapTools) {
    return null;
  }

  const isShortTermRentalMode = filters.some(
    (f) => f.path === "rental_isShortTerm" && f.value === true,
  );

  const propertiesCountInPolygon = (polygon) => {
    return getFilteredProperties().filter(
      (property) => property.polygonId === polygon.irealtyId,
    ).length;
  };

  const onDeletePolygon = (e, polygon) => {
    e.stopPropagation();
    deletePolygon(polygon.irealtyId);
  };

  const goToPolygon = (polygon) => {
    zoomToDrawnPolygons([searchPolygonToGeoJson(polygon)]);
  };

  const calculateAveragePricePerSqm = (polygon) => {
    const propertiesInPolygon = getFilteredProperties().filter(
      (property) => property.polygonId === polygon.irealtyId,
    );

    if (propertiesInPolygon.length === 0) return 0;

    const validProperties = propertiesInPolygon.filter(
      (property) => parseFloat(property.price) && parseFloat(property.size),
    );

    if (validProperties.length === 0) return 0;

    const totalPricePerSqm = validProperties.reduce(
      (sum, property) =>
        sum + parseFloat(property.price) / parseFloat(property.size),
      0,
    );

    return Math.round(totalPricePerSqm / validProperties.length);
  };

  const calculateAveragePrice = (polygon) => {
    const propertiesInPolygon = getFilteredProperties().filter(
      (property) => property.polygonId === polygon.irealtyId,
    );

    if (propertiesInPolygon.length === 0) return 0;

    const validProperties = propertiesInPolygon.filter((property) =>
      parseFloat(property.price),
    );

    if (validProperties.length === 0) return 0;

    const totalPrice = validProperties.reduce(
      (sum, property) => sum + parseFloat(property.price),
      0,
    );

    return Math.round(totalPrice / validProperties.length);
  };

  const calculateAverageTimeOnMarket = (polygon) => {
    const propertiesInPolygon = getFilteredProperties().filter(
      (property) => property.polygonId === polygon.irealtyId,
    );

    if (propertiesInPolygon.length === 0) return 0;

    const now = moment().unix();
    const avgDays =
      propertiesInPolygon.reduce((sum, property) => {
        const daysOnMarket =
          (now - parseFloat(property.initialImportTime)) / (60 * 60 * 24);
        return sum + daysOnMarket;
      }, 0) / propertiesInPolygon.length;

    return Math.round(avgDays);
  };

  const onSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const getSortedPolygons = () => {
    if (!sortConfig.key) return allowedPolygons;

    return [...allowedPolygons].sort((a, b) => {
      let aValue, bValue;

      switch (sortConfig.key) {
        case "name":
          aValue = a.metadata?.name || i18n("Custom area");
          bValue = b.metadata?.name || i18n("Custom area");
          break;
        case "properties":
          aValue = propertiesCountInPolygon(a);
          bValue = propertiesCountInPolygon(b);
          break;
        case "pricePerSqm":
          aValue = calculateAveragePricePerSqm(a);
          bValue = calculateAveragePricePerSqm(b);
          break;
        case "avgPrice":
          aValue = calculateAveragePrice(a);
          bValue = calculateAveragePrice(b);
          break;
        case "avgTimeOnMarket":
          aValue = calculateAverageTimeOnMarket(a);
          bValue = calculateAverageTimeOnMarket(b);
          break;
        default:
          return 0;
      }

      if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });
  };

  const renderSaleColumns = () => {
    return (
      <>
        <th
          className="flex-1 cursor-pointer pb-2 text-left font-medium hover:text-gray-700"
          onClick={() => onSort("pricePerSqm")}
        >
          {i18n("Avg Price/m²")}
          {sortConfig.key === "pricePerSqm" && (
            <span className="ml-1">
              {sortConfig.direction === "asc" ? "↑" : "↓"}
            </span>
          )}
        </th>
        <th
          className="flex-1 cursor-pointer pb-2 text-left font-medium hover:text-gray-700"
          onClick={() => onSort("avgPrice")}
        >
          {i18n("Avg Price")}
          {sortConfig.key === "avgPrice" && (
            <span className="ml-1">
              {sortConfig.direction === "asc" ? "↑" : "↓"}
            </span>
          )}
        </th>
        {saleType === "sale" && (
          <th
            className="flex-1 cursor-pointer pb-2 text-left font-medium hover:text-gray-700"
            onClick={() => onSort("avgTimeOnMarket")}
          >
            {i18n("Avg Time on Market")}
            {sortConfig.key === "avgTimeOnMarket" && (
              <span className="ml-1">
                {sortConfig.direction === "asc" ? "↑" : "↓"}
              </span>
            )}
          </th>
        )}
      </>
    );
  };

  const renderRentalColumns = () => {
    return (
      <>
        <th
          className="flex-1 cursor-pointer pb-2 text-left font-medium hover:text-gray-700"
          onClick={() => onSort("avgPrice")}
        >
          {i18n("Avg Price")}
          {sortConfig.key === "avgPrice" && (
            <span className="ml-1">
              {sortConfig.direction === "asc" ? "↑" : "↓"}
            </span>
          )}
        </th>
      </>
    );
  };

  const renderColumns = () => {
    if (saleType === "rent" || saleType === "rented") {
      return renderRentalColumns();
    }

    return renderSaleColumns();
  };

  return (
    <div
      className={`info-tray rounded-lg bg-white p-4 shadow-sm ${isExpanded ? "expanded" : "collapsed"}`}
    >
      <div className="mb-3 flex items-center justify-between ">
        <div className="flex items-center gap-2">
          <button className="text-gray-500 hover:text-gray-700">
            {isExpanded ? "−" : "+"}
          </button>
          <span
            onClick={() => setIsExpanded(!isExpanded)}
            className="cursor-pointer text-sm font-medium text-gray-700"
          >
            {i18n("Market Insights")}
          </span>
        </div>
      </div>

      {isExpanded && (
        <table className="w-full table-fixed text-sm">
          <thead>
            <tr className="border-b text-xs text-gray-500">
              <th
                className={`cursor-pointer pb-2 text-left font-medium hover:text-gray-700 ${
                  saleType === "sale" ? "w-1/6" : "w-1/4"
                }`}
                onClick={() => onSort("name")}
              >
                {i18n("Area")}
                {sortConfig.key === "name" && (
                  <span className="ml-1">
                    {sortConfig.direction === "asc" ? "↑" : "↓"}
                  </span>
                )}
              </th>
              <th
                className={`cursor-pointer pb-2 text-left font-medium hover:text-gray-700 ${
                  saleType === "sale" ? "w-1/6" : "w-1/4"
                }`}
                onClick={() => onSort("properties")}
              >
                {i18n("Properties")}
                {sortConfig.key === "properties" && (
                  <span className="ml-1">
                    {sortConfig.direction === "asc" ? "↑" : "↓"}
                  </span>
                )}
              </th>
              {renderColumns()}
              <th className="w-16 pb-2 text-left font-medium hover:text-gray-700">
                <span className="ml-1">{i18n("Actions")}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {getSortedPolygons().map((polygon, index) => (
              <tr
                key={index}
                onClick={() => goToPolygon(polygon)}
                className="cursor-pointer border-b border-gray-100 last:border-0"
              >
                <td
                  className={`py-2 ${saleType === "sale" ? "w-1/6" : "w-1/4"}`}
                >
                  <div className="flex items-center gap-2">
                    <div
                      className="h-3 w-3 flex-shrink-0 rounded-full"
                      style={{ backgroundColor: polygon.colourCode }}
                    ></div>
                    <span className="truncate pr-4">
                      {polygon.metadata
                        ? polygon.metadata.name
                        : i18n("Custom area")}
                    </span>
                  </div>
                </td>
                <td
                  className={`truncate py-2 text-left ${saleType === "sale" ? "w-1/6" : "w-1/4"}`}
                >
                  {propertiesCountInPolygon(polygon)}
                </td>
                {saleType !== "rent" && (
                  <td
                    className={`truncate py-2 text-left ${saleType === "sale" ? "w-1/6" : "w-1/4"}`}
                  >
                    {calculateAveragePricePerSqm(polygon) > 0
                      ? formatPrice(calculateAveragePricePerSqm(polygon))
                      : "-"}
                  </td>
                )}
                <td
                  className={`truncate py-2 text-left ${saleType === "sale" ? "w-1/6" : "w-1/4"}`}
                >
                  {calculateAveragePrice(polygon) > 0
                    ? formatPrice(calculateAveragePrice(polygon))
                    : "-"}{" "}
                  {saleType === "rent" && !isShortTermRentalMode && (
                    <span className="text-xs text-gray-500">
                      {i18n("/ month")}
                    </span>
                  )}
                  {saleType === "rent" && isShortTermRentalMode && (
                    <span className="text-xs text-gray-500">
                      {i18n("/ day")}
                    </span>
                  )}
                </td>
                {saleType === "sale" && (
                  <td
                    className={`truncate py-2 text-left ${saleType === "sale" ? "w-1/6" : "w-1/4"}`}
                  >
                    {calculateAverageTimeOnMarket(polygon) > 0
                      ? calculateAverageTimeOnMarket(polygon) +
                        " " +
                        i18n("days")
                      : "-"}
                  </td>
                )}
                <td className="flex w-16 items-center justify-center py-2">
                  <img
                    onClick={(e) => onDeletePolygon(e, polygon)}
                    src={closeIcon}
                    alt="Close icon"
                    className="h-4 w-4 transition-opacity hover:opacity-70"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default connect((state) => ({
  drawnPolygons: state.polygon.drawnPolygons,
  mapReducer: state.map,
  saleType: state.filters.saleType,
  filters: state.filters.filters,
}))(MarketInsightsTray);
