import { i18n } from "i18n/localisation";
import acIcon from "../../assets/property/ac.svg";
import accessibilityIcon from "../../assets/property/accessibility.svg";
import carIcon from "../../assets/property/car.svg";
import childrenIcon from "../../assets/property/children.svg";
import gardenIcon from "../../assets/property/garden.svg";
import heatingIcon from "../../assets/property/heating.svg";
import acIconHighlighted from "../../assets/property/highlighted/ac.svg";
import accessibilityIconHighlighted from "../../assets/property/highlighted/accessibility.svg";
import carIconHighlighted from "../../assets/property/highlighted/car.svg";
import childrenIconHighlighted from "../../assets/property/highlighted/children.svg";
import gardenIconHighlighted from "../../assets/property/highlighted/garden.svg";
import heatingIconHighlighted from "../../assets/property/highlighted/heating.svg";
import liftIconHighlighted from "../../assets/property/highlighted/lift.svg";
import petIconHighlighted from "../../assets/property/highlighted/pet.svg";
import poolIconHighlighted from "../../assets/property/highlighted/pool.svg";
import seaViewIconHighlighted from "../../assets/property/highlighted/seaview.svg";
import storageIconHighlighted from "../../assets/property/highlighted/storage.svg";
import terraceIconHighlighted from "../../assets/property/highlighted/terrace.svg";
import wardrobeIconHighlighted from "../../assets/property/highlighted/wardrobe.svg";
import liftIcon from "../../assets/property/lift.svg";
import petIcon from "../../assets/property/pet.svg";
import poolIcon from "../../assets/property/pool.svg";
import seaViewIcon from "../../assets/property/seaview.svg";
import storageIcon from "../../assets/property/storage.svg";
import terraceIcon from "../../assets/property/terrace.svg";
import apartmentIcon from "../../assets/property/types/apartment.svg";
import detachedIcon from "../../assets/property/types/detached.svg";
import apartmentIconHighlighted from "../../assets/property/types/highlighted/apartment.svg";
import detachedIconHighlighted from "../../assets/property/types/highlighted/detached.svg";
import semiDetachedIconHighlighted from "../../assets/property/types/highlighted/semi_detached.svg";
import villaIconHighlighted from "../../assets/property/types/highlighted/villa.svg";
import semiDetachedIcon from "../../assets/property/types/semi_detached.svg";
import villaIcon from "../../assets/property/types/villa.svg";
import wardrobeIcon from "../../assets/property/wardrobe.svg";
import {
  isPropertyVilla,
  propertyIncludesParking,
  propertyIncludesPrivateGarage,
} from "../../utils/properties";

// PLEASE NOTE: EVERY FILTER HAS TO HAVE A UNIQUE id FIELD!!!

export const PROPERTY_FILTER_TYPES = {
  propertyType: 0,
  feature: 1,
  price: 2,
  area: 3,
  plotSize: 19,
  bedrooms: 4,
  bathrooms: 5,
  condition: 6,
  multimedia: 7,
  sellerType: 8,
  buildingType: 9,
  landType: 10,
  landFeature: 11,
  landBuildableSize: 12,
  landBuildableFloors: 13,
  landClassification: 14,
  commercialType: 15,
  commercialFeature: 16,
  numFloors: 17,
  commercialBusinessActivities: 18,
  tags: 20,
  buildingLifts: 21,
  buildingGarageSpaces: 22,
  buildingFeature: 23,
  buildingUse: 24,
  yearConstructed: 25,
  garageFeature: 26,
  garageSpotCapacity: 27,
  garageParkingSpaces: 28,
  storageFeature: 29,
  officeFeature: 30,
  officeSecurity: 31,
  orientation: 32,
  officeLocation: 33,
  officeDistribution: 34,
  officeExclusiveUse: 35,
  floor: 36,
  isPrivateBankProperty: 37,
  isShortTerm: 38,
  yearSold: 39,
};

export const PROPERTY_FILTER_OPERATIONS = {
  equality: 0,
  range: 1,
  predicate: 2,
  contains: 3,
  notEquality: 4,
};

export const PROPERTY_FILTER_GROUPS = [
  {
    type: PROPERTY_FILTER_TYPES.propertyType,
    operator: "or",
  },
  {
    type: PROPERTY_FILTER_TYPES.feature,
    operator: "and",
  },
  {
    type: PROPERTY_FILTER_TYPES.price,
    operator: "and",
  },
  {
    type: PROPERTY_FILTER_TYPES.area,
    operator: "and",
  },
  {
    type: PROPERTY_FILTER_TYPES.bedrooms,
    operator: "and",
  },
  {
    type: PROPERTY_FILTER_TYPES.bathrooms,
    operator: "and",
  },
  {
    type: PROPERTY_FILTER_TYPES.condition,
    operator: "or",
  },
  {
    type: PROPERTY_FILTER_TYPES.multimedia,
    operator: "or",
  },
  {
    type: PROPERTY_FILTER_TYPES.sellerType,
    operator: "and",
  },
  {
    type: PROPERTY_FILTER_TYPES.landType,
    operator: "or",
  },
  {
    type: PROPERTY_FILTER_TYPES.landFeature,
    operator: "and",
  },
  {
    type: PROPERTY_FILTER_TYPES.landBuildableSize,
    operator: "and",
  },
  {
    type: PROPERTY_FILTER_TYPES.landBuildableFloors,
    operator: "and",
  },
  {
    type: PROPERTY_FILTER_TYPES.landClassification,
    operator: "and",
  },
  {
    type: PROPERTY_FILTER_TYPES.commercialType,
    operator: "or",
  },
  {
    type: PROPERTY_FILTER_TYPES.commercialFeature,
    operator: "and",
  },
  {
    type: PROPERTY_FILTER_TYPES.numFloors,
    operator: "and",
  },
  {
    type: PROPERTY_FILTER_TYPES.commercialBusinessActivities,
    operator: "or",
  },
  {
    type: PROPERTY_FILTER_TYPES.tags,
    operator: "or",
  },
  {
    type: PROPERTY_FILTER_TYPES.buildingLifts,
    operator: "and",
  },
  {
    type: PROPERTY_FILTER_TYPES.buildingGarageSpaces,
    operator: "and",
  },
  {
    type: PROPERTY_FILTER_TYPES.buildingFeature,
    operator: "and",
  },
  {
    type: PROPERTY_FILTER_TYPES.buildingUse,
    operator: "or",
  },
  {
    type: PROPERTY_FILTER_TYPES.yearConstructed,
    operator: "and",
  },
  {
    type: PROPERTY_FILTER_TYPES.garageFeature,
    operator: "and",
  },
  {
    type: PROPERTY_FILTER_TYPES.garageSpotCapacity,
    operator: "and",
  },
  {
    type: PROPERTY_FILTER_TYPES.garageParkingSpaces,
    operator: "or",
  },
  {
    type: PROPERTY_FILTER_TYPES.storageFeature,
    operator: "and",
  },
  {
    type: PROPERTY_FILTER_TYPES.officeFeature,
    operator: "and",
  },
  {
    type: PROPERTY_FILTER_TYPES.officeSecurity,
    operator: "and",
  },
  {
    type: PROPERTY_FILTER_TYPES.orientation,
    operator: "and",
  },
  {
    type: PROPERTY_FILTER_TYPES.officeLocation,
    operator: "and",
  },
  {
    type: PROPERTY_FILTER_TYPES.officeDistribution,
    operator: "and",
  },
  {
    type: PROPERTY_FILTER_TYPES.officeExclusiveUse,
    operator: "or",
  },
  {
    type: PROPERTY_FILTER_TYPES.floor,
    operator: "and",
  },
  {
    type: PROPERTY_FILTER_TYPES.isPrivateBankProperty,
    operator: "and",
  },
  {
    type: PROPERTY_FILTER_TYPES.isShortTerm,
    operator: "and",
  },
  {
    type: PROPERTY_FILTER_TYPES.yearSold,
    operator: "and",
  },
];

const IS_PROPERTY_VILLA_PREDICATE_KEY = "IS_PROPERTY_VILLA";
const PROPERTY_INCLUDES_PARKING_PREDICATE_KEY =
  "PROPERTY_INCLUDES_PARKING_PREDICATE_KEY";
const PROPERTY_INCLUDES_PRIVATE_GARAGE_PREDICATE_KEY =
  "PROPERTY_INCLUDES_PRIVATE_GARAGE_PREDICATE_KEY";
const PROPERTY_HAS_PHOTOS_PREDICATE_KEY = "PROPERTY_HAS_PHOTOS_PREDICATE_KEY";
const PROPERTY_HAS_VIDEOS_PREDICATE_KEY = "PROPERTY_HAS_VIDEOS_PREDICATE_KEY";
const PROPERTY_HAS_VIRTUAL_TOUR_PREDICATE_KEY =
  "PROPERTY_HAS_VIRTUAL_TOUR_PREDICATE_KEY";
const PROPERTY_HAS_FLOOR_PLAN_PREDICATE_KEY =
  "PROPERTY_HAS_FLOOR_PLAN_PREDICATE_KEY";

// because we can't store functions in the redux state (we can only store serializable values)
// we then have to store functions by their by an identifier, so in this case a string mapping
export const FILTER_PREDICATE_MAPPINGS = {
  [IS_PROPERTY_VILLA_PREDICATE_KEY]: isPropertyVilla,
  [PROPERTY_INCLUDES_PARKING_PREDICATE_KEY]: propertyIncludesParking,
  [PROPERTY_INCLUDES_PRIVATE_GARAGE_PREDICATE_KEY]:
    propertyIncludesPrivateGarage,
  [PROPERTY_HAS_PHOTOS_PREDICATE_KEY]: (property) =>
    property.multimedia.images && property.multimedia.images.length > 0,
  [PROPERTY_HAS_VIDEOS_PREDICATE_KEY]: (property) =>
    property.multimedia.videos && property.multimedia.videos.length > 0,
  [PROPERTY_HAS_FLOOR_PLAN_PREDICATE_KEY]: (property) =>
    property.multimedia.plans && property.multimedia.plans.length > 0,
  [PROPERTY_HAS_VIRTUAL_TOUR_PREDICATE_KEY]: (property) =>
    !!property.multimedia.virtualTour,
};

// because we can't store functions in the redux state (we can only store serializable values)
// we then have to store functions by their by an identifier, so in this case a string mapping
export const FILTER_LABEL_MAPPINGS = {
  Apartment: () => i18n("Apartment"),
  Duplex: () => i18n("Duplex"),
  Penthouse: () => i18n("Penthouse"),
  "Semi-detached": () => i18n("Semi-detached"),
  Detached: () => i18n("Detached"),
  Villa: () => i18n("Villa"),
  Terraced: () => i18n("Terraced"),
  "Country House": () => i18n("Country House"),
  "Parking / Garage": () => i18n("Parking / Garage"),
  Garden: () => i18n("Garden"),
  Pool: () => i18n("Pool"),
  "Air conditioning": () => i18n("Air conditioning"),
  Lift: () => i18n("Lift"),
  Terrace: () => i18n("Terrace"),
  "Sea view": () => i18n("Sea view"),
  Greenery: () => i18n("Greenery"),
  Balcony: () => i18n("Balcony"),
  "Fitted wardrobes": () => i18n("Fitted wardrobes"),
  Storage: () => i18n("Storage"),
  "Central Heating": () => i18n("Central Heating"),
  All: () => i18n("All"),
  Good: () => i18n("Good"),
  New: () => i18n("New"),
  "Needs renovating": () => i18n("Needs renovating"),
  Images: () => i18n("Images"),
  Video: () => i18n("Video"),
  "Virtual tour": () => i18n("Virtual tour"),
  "Floor plan": () => i18n("Floor plan"),
  Agent: () => i18n("Agent"),
  Owner: () => i18n("Owner"),
  Property: () => i18n("Property"),
  Residential: () => i18n("Residential"),
  Land: () => i18n("Land"),
  "Land Type": () => i18n("Land Type"),
  Urban: () => i18n("Urban"),
  Buildable: () => i18n("Buildable"),
  "Not buildable": () => i18n("Not buildable"),
  "Tertiary hotels": () => i18n("Tertiary hotels"),
  "Residential blocks (high-rise)": () =>
    i18n("Residential blocks (high rise)"),
  "Tertiary offices": () => i18n("Tertiary offices"),
  "Tertiary commercial": () => i18n("Tertiary commercial"),
  Industrial: () => i18n("Industrial"),
  Warehouse: () => i18n("Warehouse"),
  Other: () => i18n("Other"),
  "Residential house (detached)": () => i18n("Residential house"),
  Amenities: () => i18n("Amenities"),
  Commercial: () => i18n("Commercial"),
  Hospitality: () => i18n("Hospitality"),
  Services: () => i18n("Services"),
  Commerce: () => i18n("Commerce"),
  "Commercial property": () => i18n("Commercial property"),
  Pets: () => i18n("Pets"),
  Children: () => i18n("Children"),
  Accessibility: () => i18n("Accessibility"),
  Building: () => i18n("Building"),
  "Good Condition": () => i18n("Good Condition"),
  Security: () => i18n("Security"),
  Garage: () => i18n("Garage"),
  Car: () => i18n("Car"),
  Motorcycle: () => i18n("Motorcycle"),
  "Car & Motorcycle": () => i18n("Car & Motorcycle"),
  "Single space": () => i18n("Single space"),
  "Multiple spaces": () => i18n("Multiple spaces"),
  Office: () => i18n("Office"),
  "Open plan": () => i18n("Open plan"),
  "Divided by partitions": () => i18n("Divided by partitions"),
  "Divided by screens": () => i18n("Divided by screens"),
  "Only offices": () => i18n("Only offices"),
  "Mixed use": () => i18n("Mixed use"),
  // filter labels for the bank properties
  bankAndPublic: () => i18n("Public & Bank Properties"),
  public: () => i18n("Public Properties Only"),
  bank: () => i18n("Bank Properties Only"),
  Room: () => i18n("Room"),
  entireHome: () => i18n("Entire Property"),
  shortTerm: () => i18n("Short Term"),
  longTerm: () => i18n("Long Term"),
  shortAndLongTerm: () => i18n("Short Term & Long Term"),
};

export const PROPERTY_TYPE_FILTERS = [
  {
    id: 0,
    label: "Apartment",
    path: "detailedType.typology",
    icon: apartmentIcon,
    highlightedIcon: apartmentIconHighlighted,
    type: PROPERTY_FILTER_TYPES.propertyType,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "flat",
    code: "flat",
  },
  {
    id: 29,
    label: "Duplex",
    path: "detailedType.subTypology",
    icon: semiDetachedIcon,
    highlightedIcon: semiDetachedIconHighlighted,
    type: PROPERTY_FILTER_TYPES.propertyType,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "duplex",
    code: "duplex",
  },
  {
    id: 30,
    label: "Penthouse",
    path: "detailedType.subTypology",
    icon: apartmentIcon,
    highlightedIcon: apartmentIconHighlighted,
    type: PROPERTY_FILTER_TYPES.propertyType,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "penthouse",
    code: "penthouse",
  },
  {
    id: 1,
    label: "Semi-detached",
    path: "detailedType.subTypology",
    icon: semiDetachedIcon,
    highlightedIcon: semiDetachedIconHighlighted,
    type: PROPERTY_FILTER_TYPES.propertyType,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "semidetachedHouse",
    code: "semidetachedHouse",
  },
  {
    id: 2,
    label: "Detached",
    path: "detailedType.subTypology",
    icon: detachedIcon,
    highlightedIcon: detachedIconHighlighted,
    type: PROPERTY_FILTER_TYPES.propertyType,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "independantHouse",
    code: "independantHouse",
  },
  {
    id: 3,
    label: "Villa",
    path: "detailedType.isVilla",
    icon: villaIcon,
    highlightedIcon: villaIconHighlighted,
    type: PROPERTY_FILTER_TYPES.propertyType,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
    code: "villa",
  },
  // {
  //   id: 4,
  //   label: "Land",
  //   path: "detailedType.typology",
  //   icon: landIcon,
  //   highlightedIcon: landIconHighlighted,
  //   type: PROPERTY_FILTER_TYPES.propertyType,
  //   operation: PROPERTY_FILTER_OPERATIONS.equality,
  //   value: "land",
  // },
  // {
  //   id: 5,
  //   label: "Storage",
  //   path: "detailedType.typology",
  //   icon: storageIcon,
  //   highlightedIcon: storageIconHighlighted,
  //   type: PROPERTY_FILTER_TYPES.propertyType,
  //   operation: PROPERTY_FILTER_OPERATIONS.equality,
  //   value: "storage",
  // },
  {
    id: 14,
    label: "Terraced",
    path: "detailedType.subTypology",
    icon: semiDetachedIcon,
    highlightedIcon: semiDetachedIconHighlighted,
    type: PROPERTY_FILTER_TYPES.propertyType,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "terracedHouse",
    code: "terracedHouse",
  },
  {
    id: 34,
    label: "Country House",
    path: "detailedType.subTypology",
    icon: detachedIcon,
    highlightedIcon: detachedIconHighlighted,
    type: PROPERTY_FILTER_TYPES.propertyType,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "countryHouse",
    code: "countryHouse",
  },
];

export const PROPERTY_FEATURES_FILTERS = [
  // {
  //   id: 6,
  //   label: "Parking",
  //   path: "parkingSpace.hasParkingSpace",
  //   icon: carIcon,
  //   highlightedIcon: carIconHighlighted,
  //   type: PROPERTY_FILTER_TYPES.feature,
  //   operation: PROPERTY_FILTER_OPERATIONS.predicate,
  //   predicate: PROPERTY_INCLUDES_PARKING_PREDICATE_KEY,
  //   value: true,
  // },
  {
    id: 9,
    label: "Parking / Garage",
    path: "features.hasGarage",
    icon: carIcon,
    highlightedIcon: carIconHighlighted,
    type: PROPERTY_FILTER_TYPES.feature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 7,
    label: "Garden",
    path: "features.hasGarden",
    icon: gardenIcon,
    highlightedIcon: gardenIconHighlighted,
    type: PROPERTY_FILTER_TYPES.feature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 8,
    label: "Pool",
    path: "features.hasSwimmingPool",
    icon: poolIcon,
    highlightedIcon: poolIconHighlighted,
    type: PROPERTY_FILTER_TYPES.feature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 10,
    label: "Air conditioning",
    path: "features.hasAirConditioning",
    icon: acIcon,
    highlightedIcon: acIconHighlighted,
    type: PROPERTY_FILTER_TYPES.feature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 11,
    label: "Lift",
    path: "features.hasLift",
    icon: liftIcon,
    highlightedIcon: liftIconHighlighted,
    type: PROPERTY_FILTER_TYPES.feature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 12,
    label: "Terrace",
    path: "features.hasTerrace",
    icon: terraceIcon,
    highlightedIcon: terraceIconHighlighted,
    type: PROPERTY_FILTER_TYPES.feature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 35,
    label: "Sea view",
    path: "features.hasSeaView",
    icon: seaViewIcon,
    highlightedIcon: seaViewIconHighlighted,
    type: PROPERTY_FILTER_TYPES.feature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 36,
    label: "Greenery",
    path: "features.hasGreenery",
    icon: gardenIcon,
    highlightedIcon: gardenIconHighlighted,
    type: PROPERTY_FILTER_TYPES.feature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 37,
    label: "Balcony",
    path: "features.hasBalcony",
    icon: terraceIcon,
    highlightedIcon: terraceIconHighlighted,
    type: PROPERTY_FILTER_TYPES.feature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 38,
    label: "Fitted wardrobes",
    path: "features.hasFittedWardrobes",
    icon: wardrobeIcon,
    highlightedIcon: wardrobeIconHighlighted,
    type: PROPERTY_FILTER_TYPES.feature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 39,
    label: "Storage",
    path: "features.hasStorage",
    icon: storageIcon,
    highlightedIcon: storageIconHighlighted,
    type: PROPERTY_FILTER_TYPES.feature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 40,
    label: "Central Heating",
    path: "features.hasCentralHeating",
    icon: heatingIcon,
    highlightedIcon: heatingIconHighlighted,
    type: PROPERTY_FILTER_TYPES.feature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
];

export const PROPERTY_RENTAL_FEATURES_FILTERS = [
  ...PROPERTY_FEATURES_FILTERS,
  {
    id: 88,
    label: "Pets",
    path: "features.petsAllowed",
    icon: petIcon,
    highlightedIcon: petIconHighlighted,
    type: PROPERTY_FILTER_TYPES.feature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
    rental: true,
  },
  {
    id: 89,
    label: "Children",
    path: "features.childrenAllowed",
    icon: childrenIcon,
    highlightedIcon: childrenIconHighlighted,
    type: PROPERTY_FILTER_TYPES.feature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
    rental: true,
  },
  {
    id: 90,
    label: "Accessibility",
    path: "features.accessibility",
    icon: accessibilityIcon,
    highlightedIcon: accessibilityIconHighlighted,
    type: PROPERTY_FILTER_TYPES.feature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
    rental: true,
  },
];

export const PRICE_RANGE_FILTER_ID = 15;
export const AREA_RANGE_FILTER_ID = 16;
export const BEDROOMS_RANGE_FILTER_ID = 17;
export const BATHROOMS_RANGE_FILTER_ID = 18;
export const SURFACE_AREA_RANGE_FILTER_ID = 86;
export const NUM_FLOORS_RANGE_FILTER_ID = 102;
export const LIFTS_RANGE_FILTER_ID = 103;
export const GARAGE_SPACES_RANGE_FILTER_ID = 104;
export const YEAR_RANGE_FILTER_ID = 106;
export const SIZE_RANGE_FILTER_ID = 105;
export const YEAR_SOLD_RANGE_FILTER_ID = 160;

export const PROPERTY_CONDITTION_FILTERS = [
  {
    id: 22,
    label: "All",
    path: "status",
    type: PROPERTY_FILTER_TYPES.condition,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "all",
    cancel: true,
  },
  {
    id: 19,
    label: "Good",
    path: "status",
    type: PROPERTY_FILTER_TYPES.condition,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "good",
  },
  {
    id: 20,
    label: "New",
    path: "status",
    type: PROPERTY_FILTER_TYPES.condition,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "newdevelopment",
  },
  {
    id: 21,
    label: "Needs renovating",
    path: "status",
    type: PROPERTY_FILTER_TYPES.condition,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "renew",
  },
];

export const PROPERTY_MULTIMEDIA_FILTERS = [
  {
    id: 23,
    label: "All",
    path: "status",
    type: PROPERTY_FILTER_TYPES.multimedia,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "all",
    cancel: true,
  },
  {
    id: 24,
    label: "Images",
    path: "hasImages",
    type: PROPERTY_FILTER_TYPES.multimedia,
    operation: PROPERTY_FILTER_OPERATIONS.predicate,
    predicate: PROPERTY_HAS_PHOTOS_PREDICATE_KEY,
  },
  {
    id: 25,
    label: "Video",
    path: "hasVideo",
    type: PROPERTY_FILTER_TYPES.multimedia,
    operation: PROPERTY_FILTER_OPERATIONS.predicate,
    predicate: PROPERTY_HAS_VIDEOS_PREDICATE_KEY,
  },
  {
    id: 26,
    label: "Virtual tour",
    path: "hasVirtualTour",
    type: PROPERTY_FILTER_TYPES.multimedia,
    operation: PROPERTY_FILTER_OPERATIONS.predicate,
    predicate: PROPERTY_HAS_VIRTUAL_TOUR_PREDICATE_KEY,
  },
  {
    id: 27,
    label: "Floor plan",
    path: "hasPlan",
    type: PROPERTY_FILTER_TYPES.multimedia,
    operation: PROPERTY_FILTER_OPERATIONS.predicate,
    predicate: PROPERTY_HAS_FLOOR_PLAN_PREDICATE_KEY,
  },
];

export const FILTER_LOWER_BOUND_KEY = "lowerBound";
export const FILTER_UPPER_BOUND_KEY = "upperBound";

export const SELLER_TYPE_FILTERS = [
  {
    id: 32,
    label: "Agent",
    path: "contactInfo.userType",
    type: PROPERTY_FILTER_TYPES.sellerType,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "professional",
  },
  {
    id: 33,
    label: "Owner",
    path: "contactInfo.userType",
    type: PROPERTY_FILTER_TYPES.sellerType,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "private",
  },
];

export const BUILDING_TYPE_FILTERS = [
  {
    id: 41,
    label: "Residential",
    path: "buildingType",
    type: PROPERTY_FILTER_TYPES.buildingType,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "property",
  },
  {
    id: 42,
    label: "Land",
    path: "buildingType",
    type: PROPERTY_FILTER_TYPES.buildingType,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "land",
  },
  {
    id: 65,
    label: "Commercial",
    path: "buildingType",
    type: PROPERTY_FILTER_TYPES.buildingType,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "commercial",
  },
  {
    id: 91,
    label: "Building",
    path: "buildingType",
    type: PROPERTY_FILTER_TYPES.buildingType,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "building",
  },
  {
    id: 123,
    label: "Office",
    path: "buildingType",
    type: PROPERTY_FILTER_TYPES.buildingType,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "office",
  },
  {
    id: 107,
    label: "Garage",
    path: "buildingType",
    type: PROPERTY_FILTER_TYPES.buildingType,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "garage",
  },
  {
    id: 119,
    label: "Storage",
    path: "buildingType",
    type: PROPERTY_FILTER_TYPES.buildingType,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "storage",
  },
  {
    id: 157,
    label: "Room",
    path: "buildingType",
    type: PROPERTY_FILTER_TYPES.buildingType,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "room",
  },
];

export const LAND_TYPE_FILTERS = [
  {
    id: 44,
    label: "Urban",
    path: "land_type",
    type: PROPERTY_FILTER_TYPES.landType,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "urban",
  },
  {
    id: 45,
    label: "Buildable",
    path: "land_type",
    type: PROPERTY_FILTER_TYPES.landType,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "buildable",
  },
  {
    id: 46,
    label: "Not buildable",
    path: "land_type",
    type: PROPERTY_FILTER_TYPES.landType,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "unbuildable",
  },
];

export const BUILDABLE_AREA_RANGE_FILTER_ID = 47;
export const BUILDABLE_FLOORS_RANGE_FILTER_ID = 48;

export const LAND_FEATURES_FILTERS = [
  {
    id: 49,
    label: "Road access",
    path: "land_roadAccess",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.landFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 50,
    label: "Electricity",
    path: "land_features.hasElectricity",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.landFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 51,
    label: "Natural gas",
    path: "land_features.hasNaturalGas",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.landFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 52,
    label: "Street lighting",
    path: "land_features.hasStreetLighting",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.landFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 53,
    label: "Water",
    path: "land_features.hasWater",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.landFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 54,
    label: "Sewage system",
    path: "land_features.hasSewage",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.landFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 55,
    label: "Sidewalk",
    path: "land_features.hasSidewalk",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.landFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
];

export const LAND_CLASSIFICATION_FEATURES = [
  {
    id: 64,
    label: "All",
    path: "land_classifications",
    type: PROPERTY_FILTER_TYPES.landClassification,
    operation: PROPERTY_FILTER_OPERATIONS.contains,
    value: false,
    cancel: true,
  },
  {
    id: 56,
    label: "Residential blocks (high-rise)",
    path: "land_classifications",
    type: PROPERTY_FILTER_TYPES.landClassification,
    operation: PROPERTY_FILTER_OPERATIONS.contains,
    value: "blocks",
  },
  {
    id: 57,
    label: "Residential house (detached)",
    path: "land_classifications",
    type: PROPERTY_FILTER_TYPES.landClassification,
    operation: PROPERTY_FILTER_OPERATIONS.contains,
    value: "detached",
  },
  {
    id: 58,
    label: "Tertiary offices",
    path: "land_classifications",
    type: PROPERTY_FILTER_TYPES.landClassification,
    operation: PROPERTY_FILTER_OPERATIONS.contains,
    value: "offices",
  },
  {
    id: 59,
    label: "Tertiary commercial",
    path: "land_classifications",
    type: PROPERTY_FILTER_TYPES.landClassification,
    operation: PROPERTY_FILTER_OPERATIONS.contains,
    value: "commercial",
  },
  {
    id: 60,
    label: "Tertiary hotels",
    path: "land_classifications",
    type: PROPERTY_FILTER_TYPES.landClassification,
    operation: PROPERTY_FILTER_OPERATIONS.contains,
    value: "hotels",
  },
  {
    id: 61,
    label: "Industrial",
    path: "land_classifications",
    type: PROPERTY_FILTER_TYPES.landClassification,
    operation: PROPERTY_FILTER_OPERATIONS.contains,
    value: "industrial",
  },
  {
    id: 62,
    label: "Amenities",
    path: "land_classifications",
    type: PROPERTY_FILTER_TYPES.landClassification,
    operation: PROPERTY_FILTER_OPERATIONS.contains,
    value: "amenities",
  },
  {
    id: 63,
    label: "Other",
    path: "land_classifications",
    type: PROPERTY_FILTER_TYPES.landClassification,
    operation: PROPERTY_FILTER_OPERATIONS.contains,
    value: "other",
  },
];

export const COMMERCIAL_FEATURES_FILTERS = [
  {
    id: 66,
    label: "Heating",
    path: "commercial_features.hasHeating",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.commercialFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 67,
    label: "Extractor fan",
    path: "commercial_features.hasExtractorFan",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.commercialFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 77,
    label: "Safety gate",
    path: "commercial_features.hasSafetyGate",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.commercialFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 68,
    label: "CCTV",
    path: "commercial_features.hasCCTV",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.commercialFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 69,
    label: "Corner",
    path: "commercial_features.isOnCorner",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.commercialFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 70,
    label: "Office included",
    path: "commercial_features.hasOffice",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.commercialFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 71,
    label: "Accessible bathrooms",
    path: "commercial_features.hasAccessibleBathrooms",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.commercialFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 72,
    label: "Air conditioning",
    path: "commercial_features.hasAirConditioning",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.commercialFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 73,
    label: "Full kitchen",
    path: "commercial_features.hasFullyEquippedKitchen",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.commercialFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 78,
    label: "Alarm system",
    path: "commercial_features.hasAlarmSystem",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.commercialFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 74,
    label: "Store room",
    path: "commercial_features.hasStoreroom",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.commercialFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 75,
    label: "Smoke extractor",
    path: "commercial_features.hasSmokeExtractor",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.commercialFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 76,
    label: "Auxiliary entrance",
    path: "commercial_features.hasAuxiliaryEntrance",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.commercialFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
];

export const COMMERCIAL_TYPE_FILTERS = [
  {
    id: 79,
    label: "Commercial property",
    path: "commercial_type",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.commercialType,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "commercial",
  },
  {
    id: 80,
    label: "Warehouse",
    path: "commercial_type",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.commercialType,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "industrial",
  },
];

export const FLOOR_RANGE_FILTER_ID = 81;

export const COMMERCIAL_BUSINESS_ACTIVITIES_FILTERS = [
  {
    id: 82,
    label: "Hospitality",
    path: "commercial_businessActivities",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.commercialBusinessActivities,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "hospitality",
  },
  {
    id: 83,
    label: "Services",
    path: "commercial_businessActivities",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.commercialBusinessActivities,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "services",
  },
  {
    id: 84,
    label: "Commerce",
    path: "commercial_businessActivities",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.commercialBusinessActivities,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "commerce",
  },
  {
    id: 85,
    label: "Other",
    path: "commercial_businessActivities",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.commercialBusinessActivities,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "other",
  },
];

export const BUILDING_CONDITION_STATUS_FILTERS = [
  {
    id: 92,
    label: "Good Condition",
    path: "status",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.condition,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "good",
    code: "good",
  },
  {
    id: 93,
    label: "Needs renovating",
    path: "status",
    icon: null,
    highlightedIcon: null,
    type: PROPERTY_FILTER_TYPES.condition,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "renew",
    code: "renew",
  },
];

export const BUILDING_ALLOWED_USE_FILTERS = [
  {
    id: 94,
    label: "Residential",
    path: "building_use",
    type: PROPERTY_FILTER_TYPES.buildingUse,
    operation: PROPERTY_FILTER_OPERATIONS.contains,
    value: "residential",
  },
  {
    id: 95,
    label: "Commercial",
    path: "building_use",
    type: PROPERTY_FILTER_TYPES.buildingUse,
    operation: PROPERTY_FILTER_OPERATIONS.contains,
    value: "commercial",
  },
  {
    id: 96,
    label: "Industrial",
    path: "building_use",
    type: PROPERTY_FILTER_TYPES.buildingUse,
    operation: PROPERTY_FILTER_OPERATIONS.contains,
    value: "industrial",
  },
  {
    id: 97,
    label: "Offices",
    path: "building_use",
    type: PROPERTY_FILTER_TYPES.buildingUse,
    operation: PROPERTY_FILTER_OPERATIONS.contains,
    value: "offices",
  },
  {
    id: 98,
    label: "Hotel",
    path: "building_use",
    type: PROPERTY_FILTER_TYPES.buildingUse,
    operation: PROPERTY_FILTER_OPERATIONS.contains,
    value: "hotel",
  },
  {
    id: 99,
    label: "Other",
    path: "building_use",
    type: PROPERTY_FILTER_TYPES.buildingUse,
    operation: PROPERTY_FILTER_OPERATIONS.contains,
    value: "other",
  },
];

export const BUILDING_FEATURES_FILTERS = [
  {
    id: 100,
    label: "Garden",
    path: "building_features.hasGarden",
    type: PROPERTY_FILTER_TYPES.buildingFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 101,
    label: "Security",
    path: "building_features.hasSecurity",
    type: PROPERTY_FILTER_TYPES.buildingFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
];

export const GARAGE_FEATURES_FILTERS = [
  {
    id: 111,
    label: "Security staff",
    path: "garage_features.hasSecurity",
    type: PROPERTY_FILTER_TYPES.garageFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 109,
    label: "Covered space",
    path: "garage_features.isCovered",
    type: PROPERTY_FILTER_TYPES.garageFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 112,
    label: "Alarm systems",
    path: "garage_features.hasAlarm",
    type: PROPERTY_FILTER_TYPES.garageFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 113,
    label: "Automatic door",
    path: "garage_features.hasAutomaticDoor",
    type: PROPERTY_FILTER_TYPES.garageFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 108,
    label: "Elevator",
    path: "garage_features.hasLift",
    type: PROPERTY_FILTER_TYPES.garageFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 110,
    label: "CCTV",
    path: "garage_features.hasCCTV",
    type: PROPERTY_FILTER_TYPES.garageFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
];

export const GARAGE_SPOT_CAPACITY_FILTERS = [
  {
    id: 114,
    label: "Car",
    path: "garage_vehicle",
    type: PROPERTY_FILTER_TYPES.garageSpotCapacity,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "car",
  },
  {
    id: 115,
    label: "Motorcycle",
    path: "garage_vehicle",
    type: PROPERTY_FILTER_TYPES.garageSpotCapacity,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "motorcycle",
  },
  {
    id: 116,
    label: "Car & Motorcycle",
    path: "garage_vehicle",
    type: PROPERTY_FILTER_TYPES.garageSpotCapacity,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "carAndMotorcycle",
  },
];

export const GARAGE_PARKING_SPACES_FILTERS = [
  {
    id: 117,
    label: "Single space",
    path: "garage_multipleSpaces",
    type: PROPERTY_FILTER_TYPES.garageParkingSpaces,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: false,
  },
  {
    id: 118,
    label: "Multiple spaces",
    path: "garage_multipleSpaces",
    type: PROPERTY_FILTER_TYPES.garageParkingSpaces,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
];

export const STORAGE_FEATURES_FILTERS = [
  {
    id: 120,
    label: "Accessible 24 hrs",
    path: "storage_features.is24hAccess",
    type: PROPERTY_FILTER_TYPES.storageFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 121,
    label: "Security 24 hrs",
    path: "storage_features.hasSecurity",
    type: PROPERTY_FILTER_TYPES.storageFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 122,
    label: "Loading/unloading area",
    path: "storage_features.hasLoadingBay",
    type: PROPERTY_FILTER_TYPES.storageFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
];

export const OFFICE_FEATURES_FILTERS = [
  {
    id: 124,
    label: "Air conditioning",
    path: "office_features.hasAirConditioning",
    type: PROPERTY_FILTER_TYPES.officeFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 125,
    label: "Heating",
    path: "office_features.hasCentralHeating",
    type: PROPERTY_FILTER_TYPES.officeFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 126,
    label: "Hot water",
    path: "office_features.hasHotWater",
    type: PROPERTY_FILTER_TYPES.officeFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 127,
    label: "Warehouse",
    path: "office_features.isWarehouse",
    type: PROPERTY_FILTER_TYPES.officeFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 128,
    label: "Raised Floor",
    path: "office_features.hasRaisedFloor",
    type: PROPERTY_FILTER_TYPES.officeFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 129,
    label: "Toilet/bathrooms",
    path: "office_features.hasToilets",
    type: PROPERTY_FILTER_TYPES.officeFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 130,
    label: "Kitchen",
    path: "office_features.hasKitchen",
    type: PROPERTY_FILTER_TYPES.officeFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 131,
    label: "Disabled access",
    path: "office_features.hasDisabledAccess",
    type: PROPERTY_FILTER_TYPES.officeFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 132,
    label: "Double glazing",
    path: "office_features.hasDoubleGlazing",
    type: PROPERTY_FILTER_TYPES.officeFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 133,
    label: "False ceiling",
    path: "office_features.hasFalseCeiling",
    type: PROPERTY_FILTER_TYPES.officeFeature,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
];

export const OFFICE_SECURITY_FILTERS = [
  {
    id: 135,
    label: "Sprinklers",
    path: "office_security.hasSprinklers",
    type: PROPERTY_FILTER_TYPES.officeSecurity,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 136,
    label: "Alarm systems",
    path: "office_security.hasAlarm",
    type: PROPERTY_FILTER_TYPES.officeSecurity,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 137,
    label: "Emergency exit",
    path: "office_security.hasEmergencyExit",
    type: PROPERTY_FILTER_TYPES.officeSecurity,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 138,
    label: "Controlled access",
    path: "office_security.hasControlledAccess",
    type: PROPERTY_FILTER_TYPES.officeSecurity,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 139,
    label: "Fire extinguisher",
    path: "office_security.hasFireExtinguisher",
    type: PROPERTY_FILTER_TYPES.officeSecurity,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 140,
    label: "Fire door",
    path: "office_security.hasFireproofDoor",
    type: PROPERTY_FILTER_TYPES.officeSecurity,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 134,
    label: "Safety gate",
    path: "office_security.hasSafetyGate",
    type: PROPERTY_FILTER_TYPES.officeSecurity,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 141,
    label: "Fire detectors",
    path: "office_security.hasFireDetectors",
    type: PROPERTY_FILTER_TYPES.officeSecurity,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 142,
    label: "Emergency exit lights",
    path: "office_security.hasEmergencyLighting",
    type: PROPERTY_FILTER_TYPES.officeSecurity,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
];

export const OFFICE_ORIENTATION_FILTERS = [
  {
    id: 143,
    label: "North",
    path: "orientations",
    type: PROPERTY_FILTER_TYPES.orientation,
    operation: PROPERTY_FILTER_OPERATIONS.contains,
    value: "north",
  },
  {
    id: 144,
    label: "East",
    path: "orientations",
    type: PROPERTY_FILTER_TYPES.orientation,
    operation: PROPERTY_FILTER_OPERATIONS.contains,
    value: "east",
  },
  {
    id: 145,
    label: "South",
    path: "orientations",
    type: PROPERTY_FILTER_TYPES.orientation,
    operation: PROPERTY_FILTER_OPERATIONS.contains,
    value: "south",
  },
  {
    id: 146,
    label: "West",
    path: "orientations",
    type: PROPERTY_FILTER_TYPES.orientation,
    operation: PROPERTY_FILTER_OPERATIONS.contains,
    value: "west",
  },
];

export const OFFICE_LOCATION_FILTERS = [
  {
    id: 147,
    label: "Interior",
    path: "office_location",
    type: PROPERTY_FILTER_TYPES.officeLocation,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "interior",
  },
  {
    id: 148,
    label: "Exterior",
    path: "office_location",
    type: PROPERTY_FILTER_TYPES.officeLocation,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "exterior",
  },
];

export const OFFICE_DISTRIBUTION_FILTERS = [
  {
    id: 149,
    label: "Open plan",
    path: "office_distribution",
    type: PROPERTY_FILTER_TYPES.officeDistribution,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "openPlan",
  },
  {
    id: 150,
    label: "Divided by partitions",
    path: "office_distribution",
    type: PROPERTY_FILTER_TYPES.officeDistribution,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "dividedByPartitions",
  },
  {
    id: 151,
    label: "Divided by screens",
    path: "office_distribution",
    type: PROPERTY_FILTER_TYPES.officeDistribution,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: "dividedByScreens",
  },
];

export const OFFICE_EXCLUSIVE_USE_FILTERS = [
  {
    id: 152,
    label: "Only offices",
    path: "office_exclusiveUse",
    type: PROPERTY_FILTER_TYPES.officeExclusiveUse,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
  {
    id: 153,
    label: "Mixed use",
    path: "office_exclusiveUse",
    type: PROPERTY_FILTER_TYPES.officeExclusiveUse,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: false,
  },
];

export const PRIVATE_BANK_PROPERTY_FILTERS = [
  {
    id: 154,
    label: "bankAndPublic",
    path: "isPrivateBankProperty",
    type: PROPERTY_FILTER_TYPES.isPrivateBankProperty,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: null,
    cancel: true,
  },
  {
    id: 155,
    label: "public",
    path: "isPrivateBankProperty",
    type: PROPERTY_FILTER_TYPES.isPrivateBankProperty,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: false,
  },
  {
    id: 156,
    label: "bank",
    path: "isPrivateBankProperty",
    type: PROPERTY_FILTER_TYPES.isPrivateBankProperty,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
  },
];

export const SHORT_TERM_FILTERS = [
  {
    id: 158,
    label: "longTerm",
    path: "rental_isShortTerm",
    type: PROPERTY_FILTER_TYPES.isShortTerm,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: false,
    cancel: true,
    rental: true,
  },
  {
    id: 159,
    label: "shortTerm",
    path: "rental_isShortTerm",
    type: PROPERTY_FILTER_TYPES.isShortTerm,
    operation: PROPERTY_FILTER_OPERATIONS.equality,
    value: true,
    rental: true,
  },
];

export const TAG_FILTER_ID = 87;

export const ALL_CHIP_FILTERS = [
  ...PROPERTY_FEATURES_FILTERS,
  ...PROPERTY_TYPE_FILTERS,
  ...COMMERCIAL_BUSINESS_ACTIVITIES_FILTERS,
  ...COMMERCIAL_TYPE_FILTERS,
];
