import { createReducer } from "@reduxjs/toolkit";
import {
  addValuationMetaCondition,
  editValuationMetaCondition,
  deleteValuationMetaCondition,
  setValuationMetaConditions,
} from "actions/metaValuationConditionsActions";

const initialState = {
  conditions: [],
};

const metaValuationConditionsReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(addValuationMetaCondition, (state, action) => {
        state.conditions.push(action.payload);
      })
      .addCase(editValuationMetaCondition, (state, action) => {
        const index = state.conditions.findIndex(
          (c) => c.id === action.payload.id,
        );
        if (index !== -1) {
          state.conditions[index] = action.payload;
        }
      })
      .addCase(deleteValuationMetaCondition, (state, action) => {
        state.conditions = state.conditions.filter(
          (c) => c.id !== action.payload.id,
        );
      })
      .addCase(setValuationMetaConditions, (state, action) => {
        state.conditions = action.payload;
      });
  },
);

export default metaValuationConditionsReducer;
