import hamburgerIcon from "assets/core/hamburger.svg";
import { i18n } from "i18n/localisation";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import eyeIcon from "../../assets/core/eye.svg";
import leftArrow from "../../assets/core/left_chevron.svg";
import shareIcon from "../../assets/core/share.svg";
import Button from "../ui/Button/Button";

const MyPropertiesHeader = ({ onClickSharePropertyButton }) => {
  const navigate = useNavigate();
  const { title, listingType } = useSelector((state) => ({
    title: state.myProperties.title,
    listingType: state.myProperties.listingType,
  }));
  const propertyId = useSelector((state) => state.myProperties.propertyId);

  return (
    <div className="upload-page_header">
      <div className="upload-page_header_wrapper">
        <div className="upload-page_header_title-subtitle">
          <div className="upload-page_header_title">
            <img
              src={leftArrow}
              alt="back"
              onClick={() => navigate("/my-properties")}
              className="cursor-pointer"
            />
            <span>{title}</span>
          </div>
          <div className="upload-page_header_subtitle">
            <p>{i18n(`${listingType} Property`)}</p>
          </div>
        </div>
        <div className="upload-page_header_viewer-share-content">
          <Button
            onClick={() =>
              window.open(
                `/property/${propertyId}`,
                "_blank",
                "noopener,noreferrer",
              )
            }
            variant="secondary"
          >
            <img src={eyeIcon} alt="share icon" />
            <span className="ml-[8px]">{i18n("See as a Viewer")}</span>
          </Button>
          <Button onClick={onClickSharePropertyButton} variant="secondary">
            <img src={shareIcon} alt="share icon" />
            <span className="ml-[8px]">{i18n("Share Property")}</span>
          </Button>
          <div>
            <img src={hamburgerIcon} alt="hambuguer icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPropertiesHeader;
