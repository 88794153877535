import plusIcon from "assets/core/plus.svg";
import Button from "components/ui/Button/Button";
import { i18n } from "i18n/localisation";
import IconComponent from "./IconComponent";

const EmptySection = ({ setContent, buttonLabel, helperText, icon }) => {
  return (
    <div className="flex h-[267px] w-full justify-center rounded-2xl border-4 border-dashed border-[#bababa] bg-[#fafbfc]">
      <div className="flex max-w-[354px] flex-col items-center justify-center gap-[16px]">
        {icon && (
          <div className="h-[40px] w-[40px]">
            <IconComponent
              icon={icon.icon}
              color={"#717171"}
              parameter={icon.parameter}
            />
          </div>
        )}

        <Button
          variant="secondary"
          className="flex h-[44px] max-w-[253px] justify-around gap-[8px]"
          onClick={setContent}
        >
          <img src={plusIcon} alt="add media" />
          <span className="text-[16px] font-medium">{i18n(buttonLabel)}</span>
        </Button>
        <p className="text-center text-[14px] text-[#717171]">
          {i18n(helperText)}
        </p>
      </div>
    </div>
  );
};

export default EmptySection;
