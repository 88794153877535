import deleteIcon from "assets/core/delete.svg";
import duplicateIcon from "assets/core/duplicate.svg";
import editIcon from "assets/core/edit.svg";
import clsx from "clsx";
import HamburgerMenu from "components/core/HamburgerMenu";
import { i18n } from "i18n/localisation";
import { formatNumber } from "utils/helpers";
import houseIcon from "../../assets/core/house_crm.svg";
import Checkbox from "../ui/Checkbox/Checkbox";

const PropertyTile = ({
  handleOnClick,
  className,
  isCheckbox = false,
  checked = false,
  showHamburger = false,
  onEditTitle,
  onDuplicateProperty,
  onDeleteProperty,
  property,
  ...props
}) => {
  const handleClick = () => {
    handleOnClick();
  };

  const menuItems = [
    {
      text: i18n("Delete"),
      icon: deleteIcon,
      onClick: () => onDeleteProperty(property),
    },
  ];

  const renderTileImage = (object) => {
    const hasThumbnail = !!object.thumbnail;

    return (
      <div className="flex h-full w-full items-center justify-center ">
        <img
          src={hasThumbnail ? object.thumbnail : houseIcon}
          alt={object.title}
          className={`absolute object-cover ${hasThumbnail ? "h-full w-full rounded-xl" : "h-[80px] w-[80px]"}`}
        />
      </div>
    );
  };

  return (
    <div
      className={clsx(
        className,
        "tile flex flex-col",
        props.active ? "active-element" : "",
      )}
      {...props}
    >
      <div
        className={clsx(
          "relative h-[282px] w-[282px] cursor-pointer rounded-2xl border-4 bg-gray-200 transition-all duration-200 ease-in-out",
          checked ? "border-gray-dark" : "border-white",
        )}
        id={property.title}
        onClick={handleClick}
      >
        {renderTileImage(property)}

        {isCheckbox && (
          <Checkbox checked={checked} className={"absolute right-4 top-4"} />
        )}
      </div>
      <div className="flex w-[268px] flex-col px-1 py-3">
        <p className="text-lg font-medium">{property.title}</p>
        <p className="font-[14px] text-[#717171]">
          {formatNumber(property.price)} €
        </p>
      </div>
      {showHamburger && <HamburgerMenu items={menuItems} />}
    </div>
  );
};

export default PropertyTile;
