import { i18n } from "i18n/localisation";
import { useEffect, useRef, useState } from "react";
import downChevron from "../../assets/core/down_chevron.svg";
import DropdownList from "./DropdownList";

export default function (props) {
  const [isActive, setIsActive] = useState(false);
  const dropdownRef = useRef(null);

  let className = "dropdown";
  let dividerClassName = "divider";

  if (isActive) {
    className += " active";

    if (props.isInverted) {
      className += " inverted";
      dividerClassName += " inverted";
    }
  }

  const dropdownCanceller = (e) => {
    // Check if click is inside dropdown
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsActive(false);
    }
  };

  const onDropdownClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Dispatch event to close all other dropdowns before opening this one
    document.dispatchEvent(new Event("closeAllDropdowns"));

    setTimeout(() => {
      setIsActive(!isActive);
    });
  };

  useEffect(() => {
    const closeAllDropdowns = () => setIsActive(false);
    document.body.addEventListener("click", dropdownCanceller);
    document.addEventListener("closeAllDropdowns", closeAllDropdowns);

    return () => {
      document.body.removeEventListener("click", dropdownCanceller);
      document.removeEventListener("closeAllDropdowns", closeAllDropdowns);
    };
  }, []);

  return (
    <div
      ref={dropdownRef}
      style={props.style}
      className={
        "dropdown-container" + (props.className ? ` ${props.className}` : "")
      }
    >
      <div onClick={onDropdownClick} className={className}>
        <div className="dropdown_label-container">
          {props.placeholder && !props.selected && (
            <span className="dropdown_placeholder">{props.placeholder}</span>
          )}
          <span>{i18n(props.label)}</span>
        </div>
        <img src={downChevron} />
      </div>
      {isActive && <div className={dividerClassName}></div>}
      {isActive && (
        <DropdownList
          selectedIds={props.selectedIds}
          noMaxHeight={props.noMaxHeight}
          isMulti={props.isMulti}
          onCancel={props.onCancel}
          cancelButton={props.cancelButton}
          defaultValue={props.defaultValue}
          isInverted={props.isInverted}
          items={props.items}
          setIsActive={setIsActive}
          selected={props.selected}
        />
      )}
    </div>
  );
}
